import { EdgeProps, GraphEdge, GraphNode } from '@morph-mapper/node-logic';
import { NodeProps } from 'reactflow';
import { GenericNode } from './node-generic';
import { NodeOutput } from './node-output';
import { NodeSeed } from './node-seed';
import { EdgeAdd } from './edge-add';

export { GraphErrorBoundary } from './graph-error-boundary';
export * from './header';

interface NodeRenderer {
  (props: NodeProps): React.ReactNode;
}

interface NodeCreator {
  (): NodeRenderer;
}

export const Nodes: Record<GraphNode, NodeCreator> = {
  [GraphNode.Generic]: () => GenericNode,
  [GraphNode.Output]: () => NodeOutput,
  [GraphNode.Seed]: () => NodeSeed,
};

export const Edges: Record<
  GraphEdge,
  () => (props: EdgeProps) => React.ReactNode
> = {
  [GraphEdge.Add]: () => EdgeAdd,
};
