import { Text, Stack, Button } from '@mantine/core';
import { RowLayout } from '../config-row';
import { SectionLayout } from '@/common';
import { Add, Edit } from '@morph-mapper/ui';
import { useEntryContext } from '../../context';
import { useNavigate } from 'react-router-dom';
import { useStructureBlocks } from '@morph-mapper/node-logic';
import { useStore } from '@/store';

export const IteratorPanel = () => {
  const variant = useStore(({ config: c }) => c.getVariant());
  const [hasIterator, iteratorId, outputType] = useEntryContext((s) => [
    s.hasIterator(),
    s.getIteratorId(),
    s.getOutputType(),
  ]);
  const navigate = useNavigate();
  const { structureBlocks } = useStructureBlocks(variant);

  const handleChangeMapType = () => {
    navigate('structure');
  };
  const handleEditIterator = () => {
    navigate(`../../${iteratorId}/graph`);
  };
  const handleCreateIterator = () => {
    navigate('iterator/new');
  };

  return (
    <Stack spacing={'xl'}>
      <Text c="dimmed">
        Iterators are used to extract values which are represented in a
        repeating structure. Iterators can be referenced in the graph of nested
        variables.
      </Text>
      <SectionLayout title="General" description="">
        <RowLayout
          title="Map Type"
          description="Configure the current map type."
        >
          <Button onClick={handleChangeMapType} variant="default">
            {structureBlocks[outputType].title}
          </Button>
        </RowLayout>
        <RowLayout
          title="Iterator"
          description="Iterator definition and configuration"
        >
          {hasIterator ? (
            <Edit onClick={handleEditIterator} />
          ) : (
            <Add onClick={handleCreateIterator} />
          )}
        </RowLayout>
      </SectionLayout>
    </Stack>
  );
};
