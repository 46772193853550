import { Stepper as MantineStepper } from '@mantine/core';

interface StepperProps {
  step: number;
}

export const Stepper = ({ step }: StepperProps) => {
  // Adjust the active step
  let activeStep = step;
  if (step === 3 || step === 4) {
    activeStep--;
  }

  return (
    <MantineStepper active={activeStep} orientation="vertical" size="lg">
      <MantineStepper.Step
        label="Domain"
        description="Domain Selection"
        h={150}
      />
      <MantineStepper.Step
        label="Template"
        description="Template Selection"
        h={150}
      />
      <MantineStepper.Step label="File" description="File Selection" h={150} />
      <MantineStepper.Step
        label="Build"
        description="Create/Edit Template"
        h={150}
      />
    </MantineStepper>
  );
};
