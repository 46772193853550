import { useMemo, useRef } from 'react';
import { MapEntryContext } from './context';
import { MapEntryStore, createMapEntryStore } from '../../store';
import { useStore } from '@/store';

interface EntryProviderProps extends React.PropsWithChildren {
  id: string;
}

export const MapEntryProvider = ({ children, id }: EntryProviderProps) => {
  const globalStore = useStore();
  const storeRef = useRef<MapEntryStore>();

  storeRef.current = useMemo(() => {
    return createMapEntryStore(globalStore, { id });
  }, [globalStore]);

  return (
    <MapEntryContext.Provider value={storeRef.current}>
      {children}
    </MapEntryContext.Provider>
  );
};
