/**
 * This function takes an object and returns an array of its keys.
 * Object.keys usually returns a string[], but this function returns narrower types
 * based on the type of the object.
 */
export const getKeys = Object.keys as <T extends object>(
  obj: T
) => Array<keyof T>;

export const getValues = Object.values as <T extends object>(
  obj: T
) => Array<T[keyof T]>;

// TODO: move
type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

/**
 * This function takes an object and returns an array of its entries.
 * Object.entries usually loosens the return type, but this function returns narrower types
 * based on the type of the object.
 */
export const getEntries = <T extends object>(obj: T) =>
  Object.entries(obj) as Entries<T>;

/**
 * This function takes an object and a key, and returns a new object with the key omitted.
 */
export const omit = <T extends object, K extends keyof T>(
  obj: T,
  key: K
): Omit<T, K> => {
  const { [key]: omitted, ...rest } = obj;
  return rest;
};
