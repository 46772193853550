import {
  useCategoriesOfOperatorIdByDomain,
  useCreateCategory,
} from '@/services';
import { useSetupStore } from '../../store';
import { useEffect } from 'react';

export const useDomainItem = (domain: string) => {
  const [operatorId, selectedDomain, setDomain] = useSetupStore((s) => [
    s.operatorId,
    s.domain,
    s.setDomain,
  ]);

  const { data: categories } = useCategoriesOfOperatorIdByDomain(
    operatorId,
    domain
  );
  const { mutate: addCategory } = useCreateCategory();

  const isSelected = selectedDomain === domain;

  const handleSelect = () => {
    setDomain(domain);
  };

  useEffect(() => {
    if (categories === undefined) return;

    const defaultCategoryExists = categories.some(
      ({ name, domain: d, operatorId: oid }) =>
        name === 'default' && d === domain && oid === operatorId
    );

    if (categories && domain && operatorId && !defaultCategoryExists) {
      addCategory({ operatorId: operatorId, name: 'default', domain });
    }
  }, [categories, domain]);

  return { isSelected, handleSelect };
};
