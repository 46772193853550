import { useFileInteraction } from '@morph-mapper/file-viewer';

export const useTextSelector = (handleChange: (value: string) => void) => {
  const { getPath } = useFileInteraction();

  const handleSetPath = () => {
    const path = getPath();

    if (path !== undefined) {
      handleChange(path);
    }
  };

  const handleClearPath = () => {
    handleChange('');
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    handleChange(newValue); 
  };

  return { handleSetPath, handleClearPath, handleInputChange };
};
