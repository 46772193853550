import { IMapEntry, StoreSlice } from '@/store';
import { EntryType } from '@morph-mapper/types';
import { createStore } from 'zustand';
import { immer } from 'zustand/middleware/immer';

export interface MapEntryProps {
  id: string;
}

export type InitMapEntryProps = MapEntryProps;

export interface MapEntryState extends MapEntryProps {}

export type MapEntryStore = ReturnType<typeof createMapEntryStore>;

export const createMapEntryStore = (
  global: StoreSlice,
  initProps: InitMapEntryProps
) => {
  return createStore<MapEntryState & IMapEntry>()(
    immer((set, get) => ({
      ...global.entries.entry(initProps.id, EntryType.Map),
      ...initProps,
    }))
  );
};
