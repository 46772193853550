import { Title, Text, Checkbox } from '@mantine/core';
import { TemplateRef } from '@morph-mapper/types';
import { Edit } from '@morph-mapper/ui';
import { TemplateItemMenu } from '../template-item-menu';
import { ItemSettingsContainer, ListItem, TextWrapper } from './styles';
import { useManageTemplate } from './use-manage-template';

interface TemplateListItemProps extends TemplateRef {}

export const TemplateListItem = ({
  id,
  name,
  path,
  deployPath,
  description,
  enabled,
}: TemplateListItemProps) => {
  const { handleEnable, handleSelect } = useManageTemplate(
    id,
    path,
    deployPath,
    enabled
  );

  return (
    <>
      <ListItem>
        <td>
          <Checkbox onClick={handleEnable} checked={enabled} readOnly />
        </td>
        <td>
          <TextWrapper>
            <Title order={5} fw={500}>
              {name}
            </Title>
            <Text>{description}</Text>
          </TextWrapper>
        </td>
        <td>
          <ItemSettingsContainer>
            <Edit onClick={handleSelect} />
            <TemplateItemMenu id={id} />
          </ItemSettingsContainer>
        </td>
      </ListItem>
    </>
  );
};
