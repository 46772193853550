import detectElementOverflow from "detect-element-overflow";
import { RefObject, useState } from "react";

export interface OverflowProps {
  parentRef: RefObject<HTMLElement>;
  firstChildRef: RefObject<HTMLElement>;
  lastChildRef: RefObject<HTMLElement>;
}

export const useListOverflow = ({
  parentRef,
  firstChildRef,
  lastChildRef,
}: OverflowProps) => {
  const [collidedLeft, setCollidedLeft] = useState(false);
  const [collidedRight, setCollidedRight] = useState(false);

  const checkCollisions = () => {
    const parent = parentRef.current;
    const firstChild = firstChildRef.current;
    const lastChild = lastChildRef.current;

    if (parent === null || firstChild === null || lastChild === null) {
      return;
    }

    const { overflowLeft } = detectElementOverflow(firstChild, parent);
    const { overflowRight } = detectElementOverflow(lastChild, parent);

    const leftCollision = overflowLeft > 1;
    const rightCollision = overflowRight > 1;

    // We only assign the computed values if they have changed compared to the state
    // to avoid an infinite rerendering loop.
    if (leftCollision !== collidedLeft) {
      setCollidedLeft(overflowLeft > 1);
    }
    if (rightCollision !== collidedRight) {
      setCollidedRight(overflowRight > 1);
    }
  };

  return {
    collidedLeft,
    collidedRight,
    checkCollisions,
  };
};
