import { useCreatePredefinedGraph } from '@/hooks';
import { LogicBlockIndex } from '@morph-mapper/node-logic';
import { ReservedNodeType } from '@morph-mapper/types';
import { EntryType } from '@morph-mapper/types';
import { useStore } from '@/store';
import { useNavigate, useParams } from 'react-router-dom';

export const useBlockItem = (type: LogicBlockIndex) => {
  const [init, createItem, linkGraph, linkChild] = useStore(
    ({ graphs: g, entries: e }) => [
      g.init,
      e.createItem,
      e.linkGraph,
      e.linkChild,
    ]
  );
  const navigate = useNavigate();
  const { createIteratorGraph } = useCreatePredefinedGraph(type);
  //TODO: Move entryId into a context
  const { entryId } = useParams();

  const handleCreate = () => {
    const graphId = init(...createIteratorGraph());
    if (entryId) {
      const id = createItem(
        { key: ReservedNodeType.Iterator, parentId: entryId },
        EntryType.Graph
      );
      linkGraph(id, graphId);
      linkChild(entryId, id);

      navigate(`../../../../${id}/graph`);
    }
  };

  return { handleCreate };
};
