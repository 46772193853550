import { useRef } from 'react';
import { TreeContext } from './context';
import { TreeStore, createTreeStore } from '../../store';
import { useStore } from '@/store';

interface TreeProviderProps extends React.PropsWithChildren {
  id: string;
}

export const TreeProvider = ({ children, id }: TreeProviderProps) => {
  const globalStore = useStore();
  const storeRef = useRef<TreeStore>();

  if (!storeRef.current) {
    storeRef.current = createTreeStore(globalStore, {
      id,
    });
  }

  return (
    <TreeContext.Provider value={storeRef.current!}>
      {children}
    </TreeContext.Provider>
  );
};
