import { AppWrapper, Workspace, WorkspaceWrapper } from './styles';
import { FileProvider, RawView } from '@morph-mapper/file-viewer';
import { useStore } from '@/store';
import { NavigateModal } from '../navigate-modal';
import { EditorHeader } from '../../molecules/editorHeader';
import { useState } from 'react';
import { useNotify } from '@/hooks';

export const RawEditor = () => {
  const [direction, text, setTextContent] = useStore(({ ui, config: c }) => [
    ui.layoutDirection,
    c.getTextContent(),
    c.setTextContent,
  ]);


  const [rawText, setRawText] = useState(
    typeof text === 'string' ? text : JSON.stringify(text, null, 2)
  );

  return (
    <FileProvider textContent={text}>
      <AppWrapper>
        <EditorHeader rawText={rawText} />
        <NavigateModal />
        <WorkspaceWrapper direction={direction}>
          <Workspace>
            <RawView rawText={rawText} setRawText={setRawText} />
          </Workspace>
        </WorkspaceWrapper>
      </AppWrapper>
    </FileProvider>
  );
};

export default RawEditor;
