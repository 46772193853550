import { z } from 'zod';
import { fileExtensionSchema, mergingTypeSchema } from '../../shared';
import { Input } from '@morph-mapper/node-inputs';
import { Schema } from '../../../types';
import { EntryType, ReservedNodeType } from '@morph-mapper/types';

export const retailTableSchema: Schema = {
  updateTemplateDiscardFields: [
    '_id',
    'id',
    'timestampedid',
    'hiddenreference',
    'dateofentry',
    'commissioner',
    'commissionerid',
    'clientidentifierraw',
    'clientidentifier',
  ],
  config: {
    _xlsIterator: {
      title: 'Table',
      description: 'Define the table which structures the document.',
      options: {
        from: {
          type: z.string(),
          title: 'From Marker',
          description:
            'The marker in the file to start composing the table from',
          input: Input.TextSelector,
          default: undefined,
          options: {
            required: true,
          },
        },
        shiftRow: {
          type: z.number(),
          title: 'Shift Row',
          description:
            'The number of rows to skip from the start of the table before iterating over values',
          input: Input.Number,
          default: 1,
        },
        advanced: {
          type: z.boolean(),
          title: 'Advanced Configuration',
          description:
            'Enable advanced configurations (From Line, Find by Columns, Skip)',
          input: Input.Checkbox,
          default: false,
        },
        to: {
          type: z.string(),
          title: 'To Marker',
          description:
            'The marker in the file where to end composing the table',
          input: Input.TextSelector,
          default: undefined,
          conditions: {
            advanced: {
              hasValue: true,
            },
          },
        },
        columns: {
          type: z.any(),
          title: 'Columns',
          description:
            'Column definitions used to index the cells of the table',
          input: Input.SingleSelect,
          selectionDeclareVariable: true,
          default: undefined,
          conditions: {
            advanced: {
              hasValue: true,
            },
          },
        },
        fromLine: {
          type: z.number(),
          title: 'From Line',
          description:
            'The number of lines to skip from the marker before starting to compose the table',
          input: Input.Number,
          default: 0,
          conditions: {
            advanced: {
              hasValue: true,
            },
          },
        },
        findByColumns: {
          type: z.boolean(),
          title: 'Find by Columns',
          description:
            'Enable different compose algorithm to compose the table',
          input: Input.Checkbox,
          default: undefined,
          conditions: {
            advanced: {
              hasValue: true,
            },
          },
        },
        skip: {
          type: z.any(),
          title: 'Skip',
          description: 'Define which rows to skip from the table',
          input: Input.SingleSelect,
          selectionDeclareVariable: true,
          default: undefined,
          conditions: {
            advanced: {
              hasValue: true,
            },
          },
        },
      },
      mutate: (options: any) => {
        const { from, to, findByColumns, columns, skip, fromLine, shiftRow } =
          options;

        return {
          table: {
            from,
            to,
            findByColumns,
            columns,
            skip,
            fromLine,
            shiftRow,
          },
        };
      },
    },
    _xlsClassify: {
      title: 'Classify',
      description: 'Group the table entries by a (combination of) identifiers.',
      options: {
        identifiers: {
          type: z.array(z.string()),
          title: 'Identifiers',
          description: 'The identifiers to group the table entries by',
          input: Input.MultiSelect,
          selectionDeclareVariable: true,
          default: [],
        },
      },
      mutate: (options: any) => {
        const { identifiers } = options;
        if (!identifiers) return true;

        return {
          '$utils.concat.selective': identifiers.map((identifier: string) => {
            return {
              $getVariable: identifier,
            };
          }),
        };
      },
    },
    _updateConfig: {
      title: 'Update Template Settings',
      description: '',
      childConfigurations: [
        '_containerMergingConfig',
        '_containerSortDefault',
        '_containerFieldsConfig',
        '_skipDiffsConfig',
      ],
      options: {
        mergingType: {
          type: z.boolean(),
          title: 'Update Template',
          description: 'Check if this is an update to an existing template.',
          input: Input.Checkbox,
          default: '',
        },
      },
      mutate: (options: any) => {
        const { mergingType } = options;

        if (mergingType) {
          return 'extend';
        }
        return 'createIfNotExist';
      },
    },
    _containerMergingConfig: {
      title: '',
      description: '',
      options: {
        containerMerging: {
          type: z.string(),
          title: 'Container Merging',
          description:
            'Select whether single or all containers are being updated.',
          input: Input.SingleSelect,
          selection: [
            { label: 'Single', value: 'single' },
            { label: 'All Update', value: 'allUpdate' },
          ],
          default: 'allUpdate',
        },
      },
      conditions: {
        _updateConfig: {
          mergingType: {
            hasValue: true,
          },
        },
      },
      mutate: (options: any) => {
        const { containerMerging } = options;

        return containerMerging;
      },
    },
    _containerFieldsConfig: {
      title: '',
      description: '',
      options: {
        containerFields: {
          type: z.array(z.string()),
          title: 'Container Fields',
          description: 'Select zero or more container fields to be updated.',
          input: Input.MultiSelect,
          placeholder: 'Select container fields',
          selection: [
            { label: 'import', value: 'import' },
            { label: 'container', value: 'container' },
          ],
          default: [],
        },
      },
      conditions: {
        _updateConfig: {
          mergingType: {
            hasValue: true,
          },
        },
      },
      mutate: (options: any) => {
        const { containerFields } = options;

        return containerFields;
      },
    },
    _skipDiffsConfig: {
      title: '',
      description: '',
      options: {
        skipDiffs: {
          type: z.array(z.string()),
          title: 'Skip Fields',
          description: 'Select fields/items to be skipped.',
          input: Input.MultiSelect,
          placeholder: 'Select fields to ignore',
          selection: [
            { label: 'field 1', value: 'field 1' },
            { label: 'field2', value: 'field2' },
          ],
          default: [],
        },
      },
      conditions: {
        _updateConfig: {
          mergingType: {
            hasValue: true,
          },
        },
      },
      mutate: (options: any) => {
        const { skipDiffs } = options;

        if (skipDiffs) return { ignoreitems: skipDiffs };

        return undefined;
      },
    },
    _containerSortDefault: {
      title: '',
      description: '',
      hidden: true,
      options: {
        sort: {
          type: z.boolean(),
          title: 'Sort Confitional Default (Hidden)',
          description: '',
          input: Input.Checkbox,
          default: true,
        },
      },
      conditions: {
        _updateConfig: {
          mergingType: {
            hasValue: true,
          },
        },
      },
      mutate: (options: any) => {
        const { sort } = options;

        if (sort) return { dateofentry: -1 };
        return undefined;
      },
    },
    _autosubmitConfig: {
      title: 'Submission Settings',
      description: '',
      options: {
        autosubmit: {
          type: z.boolean(),
          title: 'Autosubmit Template',
          description:
            'Check if the template is ready and can be autosubmitted by default.',
          input: Input.Checkbox,
          default: false,
        },
      },
      mutate: (options: any) => {
        const { autosubmit } = options;

        if (!autosubmit) {
          return;
        }

        return {
          url: 'http://localhost:8080/InSight/TriggerReader',
          template: {
            semantics: 'product.ordermanagement.OrderAutoSubmitREST',
            selector: '$selector',
            stage: 'autosubmit',
          },
          skipIfSent: true,
        };
      },
    },
  },
  definition: {
    values: {
      type: {
        validation: fileExtensionSchema,
        type: EntryType.Internal,
      },
      checking: {
        validation: z.boolean(),
        type: EntryType.Graph,
      },
      template: {
        values: {
          '$Array.forEach': {
            outputType: 'forEach',
            values: {
              autosubmit: {
                validation: z.any(),
                type: EntryType.Internal,
                configKey: '_autosubmitConfig',
              },
              mergingType: {
                validation: mergingTypeSchema,
                type: EntryType.Internal,
                configKey: '_updateConfig',
              },
              containerMerging: {
                validation: z.string(),
                type: EntryType.Internal,
                configKey: '_containerMergingConfig',
              },
              sort: {
                validation: z.literal(JSON.stringify({ dateofentry: -1 })),
                type: EntryType.Internal,
                configKey: '_containerSortDefault',
              },
              containerFields: {
                validation: z.array(z.string()),
                type: EntryType.Internal,
                configKey: '_containerFieldsConfig',
              },
              skipDiff: {
                validation: z.literal(
                  JSON.stringify({ ignoreitems: z.array(z.string()) })
                ),
                type: EntryType.Internal,
                configKey: '_skipDiffsConfig',
              },
              selector: {
                validation: z.literal(
                  JSON.stringify({
                    _id: {
                      $exists: false,
                    },
                  })
                ),
                type: EntryType.Internal,
              },
              doc: {
                values: {
                  nlinkstate: {
                    validation: z.literal('operational'),
                    type: EntryType.Internal,
                  },
                  operatorid: {
                    validation: z.literal('$data.0.operatorid'),
                    type: EntryType.Internal,
                  },
                  templatetype: {
                    validation: z.literal('order'),
                    type: EntryType.Internal,
                  },
                  source: {
                    validation: z.literal('$data.0.source'),
                    type: EntryType.Internal,
                  },
                  _id: {
                    validation: z.literal(
                      JSON.stringify([
                        [
                          'order_',
                          '$data.0.clientidentifierraw',
                          '_',
                          '$data.0.clientreference',
                        ],
                      ])
                    ),
                    type: EntryType.Internal,
                  },
                  id: {
                    validation: z.literal(
                      JSON.stringify([
                        [
                          '$data.0.clientidentifierraw',
                          '_',
                          '$data.0.clientreference',
                        ],
                      ])
                    ),
                    type: EntryType.Internal,
                  },
                  timestampedid: {
                    validation: z.literal(
                      JSON.stringify([
                        [
                          '$data.0.clientidentifierraw',
                          '_',
                          '$data.0.clientreference',
                          '_',
                          '#currentTimestamp',
                        ],
                      ])
                    ),
                    type: EntryType.Internal,
                  },
                  hiddenreference: {
                    validation: z.literal(
                      JSON.stringify([
                        [
                          '$data.0.clientidentifierraw',
                          '_',
                          '$data.0.clientreference',
                        ],
                      ])
                    ),
                    type: EntryType.Internal,
                  },
                  status: {
                    validation: z.literal('create'),
                    type: EntryType.Internal,
                  },
                  workload: {
                    validation: z.literal('digest'),
                    type: EntryType.Internal,
                  },
                  dateofentry: {
                    validation: z.literal(
                      JSON.stringify({ '$time.stamp': [] })
                    ),
                    type: EntryType.Internal,
                  },
                  lastupdate: {
                    validation: z.literal(
                      JSON.stringify({ '$time.stamp': [] })
                    ),
                    type: EntryType.Internal,
                  },
                  bookingtyperaw: {
                    validation: z.literal('$data.0.bookingtyperaw'),
                    type: EntryType.Internal,
                  },
                  ordersession: {
                    validation: z.literal('orderexport'),
                    type: EntryType.Internal,
                  },
                  type: {
                    validation: z.literal('Export'),
                    type: EntryType.Internal,
                  },
                  bookdate: {
                    validation: z.literal(
                      JSON.stringify({ '$time.stamp': [] })
                    ),
                    type: EntryType.Internal,
                  },
                  fileId: {
                    validation: z.literal('$FILEID'),
                    type: EntryType.Internal,
                  },
                  genericorigin: {
                    validation: z.literal('$genericOrigin'),
                    type: EntryType.Internal,
                  },
                  genericTemplateName: {
                    validation: z.literal('$data.0.genericTemplateName'),
                    type: EntryType.Internal,
                  },
                  commissioner: {
                    validation: z.literal('$data.0.clientidentifierraw'),
                    type: EntryType.Internal,
                  },
                  commissionerid: {
                    validation: z.literal('$data.0.clientidentifierraw'),
                    type: EntryType.Internal,
                  },
                  clientidentifierraw: {
                    validation: z.literal('$data.0.clientidentifierraw'),
                    type: EntryType.Internal,
                  },
                  clientidentifier: {
                    validation: z.literal('$data.0.clientidentifier'),
                    type: EntryType.Internal,
                  },
                  debtorraw: {
                    validation: z.literal('$data.0.debtorraw'),
                    type: EntryType.Internal,
                  },
                  bookingreference: {
                    validation: z.literal('$data.0.bookingreference'),
                    type: EntryType.Internal,
                  },
                  clientreference: {
                    validation: z.literal('$data.0.clientreference'),
                    type: EntryType.Internal,
                  },
                  loadingdatereadable: {
                    validation: z.literal('$data.0.loadingdatereadable'),
                    type: EntryType.Internal,
                  },
                  loadingdate: {
                    validation: z.literal('$data.0.loadingdate'),
                    type: EntryType.Internal,
                  },
                  dischargedate: {
                    validation: z.literal('$data.0.dischargedate'),
                    type: EntryType.Internal,
                  },
                  startdatereadable: {
                    validation: z.literal('$data.0.startdatereadable'),
                    type: EntryType.Internal,
                  },
                  startdate: {
                    validation: z.literal('$data.0.startdate'),
                    type: EntryType.Internal,
                  },
                  starttimeroutereadable: {
                    validation: z.literal('$data.0.starttimeroutereadable'),
                    type: EntryType.Internal,
                  },
                  starttimeroute: {
                    validation: z.literal(
                      JSON.stringify({
                        '$math.plus': [
                          {
                            '$math.multiply': [5, 3600000],
                          },
                          {
                            '$math.multiply': [0, 60000],
                          },
                        ],
                      })
                    ),
                    type: EntryType.Internal,
                  },
                  endtimeroutereadable: {
                    validation: z.literal('$data.0.endtimeroutereadable'),
                    type: EntryType.Internal,
                  },
                  endtimeroute: {
                    validation: z.literal(
                      JSON.stringify({
                        '$math.plus': [
                          {
                            '$math.multiply': [22, 3600000],
                          },
                          {
                            '$math.multiply': [0, 60000],
                          },
                        ],
                      })
                    ),
                    type: EntryType.Internal,
                  },
                  totaldistance: {
                    validation: z.literal('$data.0.totaldistance'),
                    type: EntryType.Internal,
                  },
                  totaltimereadable: {
                    validation: z.literal('$data.0.totaltimereadable'),
                    type: EntryType.Internal,
                  },
                  totaltime: {
                    validation: z.literal('$data.0.totaltime'),
                    type: EntryType.Internal,
                  },
                  totalquantity: {
                    validation: z.literal('$data.0.totalquantity'),
                    type: EntryType.Internal,
                  },
                  vehicletype: {
                    validation: z.literal('$data.0.vehicletype'),
                    type: EntryType.Internal,
                  },
                  servicelevel: {
                    validation: z.literal('$data.0.servicelevel'),
                    type: EntryType.Internal,
                  },
                  incoterms: {
                    validation: z.literal('$data.0.incoterms'),
                    type: EntryType.Internal,
                  },
                  containers: {
                    values: {
                      '$Array.forEach': {
                        outputType: 'forEach',
                        values: {
                          [ReservedNodeType.DeclareVariable]: {
                            values: {
                              producttyperaw: {
                                validation: z.literal(''),
                                type: EntryType.Internal,
                              },
                            },
                          },
                          shipmentreference: {
                            validation: z.literal(
                              '$data.containers.shipmentreference'
                            ),
                            type: EntryType.Internal,
                          },
                          invoicereference: {
                            validation: z.literal(
                              '$data.containers.invoicereference'
                            ),
                            type: EntryType.Internal,
                          },
                          container: {
                            validation: z.literal('$data.containers.container'),
                            type: EntryType.Internal,
                          },
                          containerid: {
                            validation: z.literal('$data.0.clientreference'),
                            type: EntryType.Internal,
                          },
                          packagetyperaw: {
                            validation: z.literal(''),
                            type: EntryType.Internal,
                          },
                          packagetype: {
                            validation: z.literal(
                              '$data.containers.packagetype'
                            ),
                            type: EntryType.Internal,
                          },
                          producttyperaw: {
                            validation: z.literal('$producttyperaw'),
                            type: EntryType.Internal,
                          },
                          producttype: {
                            validation: z.literal(
                              JSON.stringify({
                                $mongoRequest: [
                                  {
                                    '$db.ordermanagement_conversion.findOne': {
                                      key: 'producttype',
                                      commissionerid:
                                        '$data.0.clientidentifierraw',
                                      crefcode:
                                        '$data.containers.producttyperaw',
                                    },
                                    $result: {
                                      $getVariable: 'orefcode',
                                    },
                                  },
                                  'morphjs',
                                  ['ordermanagement_conversion'],
                                ],
                              })
                            ),
                            type: EntryType.Internal,
                          },
                          weight: {
                            validation: z.literal('$data.containers.weight'),
                            type: EntryType.Internal,
                          },
                          quantity: {
                            validation: z.literal('$data.containers.quantity'),
                            type: EntryType.Internal,
                          },
                          actualquantity: {
                            validation: z.literal(
                              JSON.stringify({
                                '$math.multiply': [
                                  {
                                    '$math.round': '$data.containers.quantity',
                                  },
                                  1,
                                ],
                              })
                            ),
                            type: EntryType.Internal,
                          },
                          length: {
                            validation: z.literal(0),
                            type: EntryType.Internal,
                          },
                          volume: {
                            validation: z.literal(0),
                            type: EntryType.Internal,
                          },
                          height: {
                            validation: z.literal(0),
                            type: EntryType.Internal,
                          },
                          ftl: {
                            validation: z.literal(false),
                            type: EntryType.Internal,
                          },
                          emb: {
                            validation: z.literal(false),
                            type: EntryType.Internal,
                          },
                          cargodescription: {
                            validation: z.literal(
                              '$data.containers.cargodescription'
                            ),
                            type: EntryType.Internal,
                          },
                          loading: {
                            values: {
                              addresses: {
                                outputType: 'array',
                                values: {
                                  _addressid: {
                                    validation: z.literal(0),
                                    type: EntryType.Internal,
                                  },
                                  loadingaddressraw: {
                                    validation: z.literal(
                                      '$data.containers.loading.loadingaddressraw'
                                    ),
                                    type: EntryType.Internal,
                                  },
                                  loadingdriverinstructions: {
                                    validation: z.literal(
                                      '$data.containers.loading.loadingdriverinstructions'
                                    ),
                                    type: EntryType.Internal,
                                  },
                                  loadingdate: {
                                    validation: z.literal(
                                      '$data.containers.loading.loadingdate'
                                    ),
                                    type: EntryType.Internal,
                                  },
                                  loadingdateend: {
                                    validation: z.literal(
                                      '$data.containers.loading.loadingdateend'
                                    ),
                                    type: EntryType.Internal,
                                  },
                                  loadingreference: {
                                    validation: z.literal(
                                      '$data.containers.loading.loadingreference'
                                    ),
                                    type: EntryType.Internal,
                                  },
                                },
                              },
                              addresscounter: {
                                validation: z.literal(1),
                                type: EntryType.Internal,
                              },
                            },
                          },
                          discharge: {
                            values: {
                              addresses: {
                                outputType: 'array',
                                values: {
                                  _addressid: {
                                    validation: z.literal(0),
                                    type: EntryType.Internal,
                                  },
                                  dischargeaddressraw: {
                                    validation: z.literal(
                                      '$data.containers.discharge.dischargeaddressraw'
                                    ),
                                    type: EntryType.Internal,
                                  },
                                  dischargedriverinstructions: {
                                    validation: z.literal(
                                      '$data.containers.discharge.dischargedriverinstructions'
                                    ),
                                    type: EntryType.Internal,
                                  },
                                  dischargedate: {
                                    validation: z.literal(
                                      '$data.containers.discharge.dischargedate'
                                    ),
                                    type: EntryType.Internal,
                                  },
                                  dischargedateend: {
                                    validation: z.literal(
                                      '$data.containers.discharge.dischargedateend'
                                    ),
                                    type: EntryType.Internal,
                                  },
                                  dischargereference: {
                                    validation: z.literal(
                                      '$data.containers.discharge.dischargereference'
                                    ),
                                    type: EntryType.Internal,
                                  },
                                },
                              },
                              addresscounter: {
                                validation: z.literal(1),
                                type: EntryType.Internal,
                              },
                            },
                          },
                          _containerid: {
                            validation: z.literal(
                              JSON.stringify({
                                '$math.plus': [
                                  {
                                    '$math.parseInt': '$CONTAINERNR',
                                  },
                                  1,
                                ],
                              })
                            ),
                            type: EntryType.Internal,
                          },
                          containercounter: {
                            validation: z.literal(
                              JSON.stringify({
                                '$math.plus': [
                                  {
                                    '$math.parseInt': '$CONTAINERNR',
                                  },
                                  1,
                                ],
                              })
                            ),
                            type: EntryType.Internal,
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
          $data: {
            outputType: 'data',
            values: {
              '$Array.classify': {
                validation: z.any(),
                type: EntryType.Internal,
                configKey: '_xlsClassify',
              },
              $data: {
                outputType: 'data',
                values: {
                  [ReservedNodeType.Iterator]: {
                    validation: z.any(),
                    type: EntryType.Internal,
                    configKey: '_xlsIterator',
                  },
                  [ReservedNodeType.DeclareVariable]: {
                    values: {
                      operatorid: {
                        validation: z.string(),
                        type: EntryType.Internal,
                        prefill: ({ operatorId }) => operatorId,
                      },
                      clientid: {
                        validation: z.string(),
                        type: EntryType.Internal,
                        prefill: ({ name }) => `${name}`,
                      },
                      clientidentifier: {
                        validation: z.literal(
                          JSON.stringify({
                            $mongoRequest: [
                              {
                                '$db.ordermanagement_conversion.findOne': {
                                  key: 'clientidentifier',
                                  commissionerid: '$clientid',
                                  crefcode: '$clientid',
                                },
                                $result: {
                                  $getVariable: 'orefcode',
                                },
                              },
                              'morphjs',
                              ['ordermanagement_conversion'],
                            ],
                          })
                        ),
                        type: EntryType.Internal,
                      },
                      clientreference: {
                        validation: z.string(),
                        displayName: 'Klant referentie',
                        type: EntryType.Cell,
                      },
                      loadingaddressraw: {
                        validation: z.string(),
                        displayName: 'Laad adres',
                        type: EntryType.Cell,
                      },
                      loadingdatereadable: {
                        displayName: 'Laad datum',
                        validation: z.string(),
                        type: EntryType.Cell,
                      },
                      loadingdateformat: {
                        validation: z.enum([
                          'DD-MM-YYYY',
                          'DD-MM-YY',
                          'DD-MM',
                          'DDD',
                        ]),
                        displayName: 'Laad datum formaat',
                        type: EntryType.Simple,
                      },
                      loadingdate: {
                        validation: z.literal(
                          JSON.stringify({
                            '$time.stamp': [
                              [['$loadingdatereadable']],
                              '$loadingdateformat',
                            ],
                          })
                        ),
                        type: EntryType.Internal,
                      },
                      loadingtimereadable: {
                        validation: z.string(),
                        displayName: 'Laad tijd',
                        type: EntryType.Cell,
                      },
                      loadingtime: {
                        validation: z.literal(6120000),
                        type: EntryType.Internal,
                      },
                      dischargeaddressraw: {
                        validation: z.string(),
                        displayName: 'Los adres',
                        type: EntryType.Cell,
                      },
                      dischargedatereadable: {
                        validation: z.string(),
                        displayName: 'Los datum',
                        type: EntryType.Cell,
                      },
                      dischargedateformat: {
                        validation: z.enum([
                          'DD-MM-YYYY',
                          'DD-MM-YY',
                          'DD-MM',
                          'DDD',
                        ]),
                        displayName: 'Los datum formaat',
                        type: EntryType.Simple,
                      },
                      dischargedate: {
                        validation: z.literal(
                          JSON.stringify({
                            '$time.stamp': [
                              [['$dischargedatereadable']],
                              '$dischargedateformat',
                            ],
                          })
                        ),
                        type: EntryType.Internal,
                      },
                      dischargetimereadable: {
                        validation: z.string(),
                        displayName: 'Los tijd',
                        type: EntryType.Cell,
                      },
                      dischargetime: {
                        validation: z.literal(6120000),
                        type: EntryType.Internal,
                      },
                    },
                  },
                  operatorid: {
                    validation: z.string(),
                    type: EntryType.Internal,
                    prefill: ({ operatorId }) => operatorId,
                  },
                  source: {
                    validation: z.string(),
                    type: EntryType.Internal,
                    prefill: ({ name }) => `${name}`,
                  },
                  clientidentifierraw: {
                    validation: z.literal('$clientid'),
                    type: EntryType.Internal,
                  },
                  clientidentifier: {
                    validation: z.literal('$clientidentifier'),
                    type: EntryType.Internal,
                  },
                  clientreference: {
                    validation: z.literal('$clientreference'),
                    type: EntryType.Internal,
                  },
                  bookingtyperaw: {
                    validation: z.literal(
                      JSON.stringify([
                        ['$clientidentifier', '_', '$dischargeaddressraw'],
                      ])
                    ),
                    displayName: 'Afdeling',
                    type: EntryType.Internal,
                  },
                  genericTemplateName: {
                    validation: z.string(),
                    type: EntryType.Internal,
                    prefill: ({ name }) => `${name}`,
                  },
                  clientid: {
                    validation: z.literal('$clientid'),
                    type: EntryType.Internal,
                  },
                  debtorraw: {
                    validation: z.string(),
                    type: EntryType.Internal,
                    prefill: ({ name }) => `Debtor ${name}`,
                  },
                  bookingreference: {
                    validation: z.literal(
                      JSON.stringify([
                        [
                          {
                            $mongoRequest: [
                              {
                                '$db.ordermanagement_conversion.findOne': {
                                  crefcode: '$dischargeaddressraw',
                                },
                                $result: {
                                  $getVariable: 'orefcode',
                                },
                              },
                              'morphjs',
                              ['ordermanagement_conversion'],
                            ],
                          },
                          '|',
                          {
                            $mongoRequest: [
                              {
                                '$db.ordermanagement_conversion.findOne': {
                                  crefcode: '$loadingaddressraw',
                                },
                                $result: {
                                  $getVariable: 'orefcode',
                                },
                              },
                              'morphjs',
                              ['ordermanagement_conversion'],
                            ],
                          },
                          '_',
                          '$loadingdate',
                        ],
                      ])
                    ),
                    type: EntryType.Internal,
                  },
                  department: {
                    validation: z.literal(
                      JSON.stringify([
                        ['$clientidentifier', '-', '$dischargeaddressraw'],
                      ])
                    ),
                    displayName: 'Order soort',
                    type: EntryType.Internal,
                  },
                  leg: {
                    validation: z.literal(
                      JSON.stringify([
                        [
                          {
                            $mongoRequest: [
                              {
                                '$db.feature.findOne': {
                                  templatetype: 'feature',
                                  id: {
                                    '$math.parseInt': {
                                      $mongoRequest: [
                                        {
                                          '$db.ordermanagement_conversion.findOne':
                                            {
                                              crefcode: '$loadingaddressraw',
                                            },
                                          $result: {
                                            $getVariable: 'orefcode',
                                          },
                                        },
                                        'morphjs',
                                        ['ordermanagement_conversion'],
                                      ],
                                    },
                                  },
                                },
                                $result: {
                                  $getVariable: 'name',
                                },
                              },
                              'morphjs',
                              ['feature'],
                            ],
                          },
                          ' ',
                          {
                            $mongoRequest: [
                              {
                                '$db.feature.findOne': {
                                  templatetype: 'feature',
                                  id: {
                                    '$math.parseInt': {
                                      $mongoRequest: [
                                        {
                                          '$db.ordermanagement_conversion.findOne':
                                            {
                                              crefcode: '$loadingaddressraw',
                                            },
                                          $result: {
                                            $getVariable: 'orefcode',
                                          },
                                        },
                                        'morphjs',
                                        ['ordermanagement_conversion'],
                                      ],
                                    },
                                  },
                                },
                                $result: {
                                  $getVariable: 'city',
                                },
                              },
                              'morphjs',
                              ['feature'],
                            ],
                          },
                          ' - ',
                          {
                            $mongoRequest: [
                              {
                                '$db.feature.findOne': {
                                  templatetype: 'feature',
                                  id: {
                                    '$math.parseInt': {
                                      $mongoRequest: [
                                        {
                                          '$db.ordermanagement_conversion.findOne':
                                            {
                                              crefcode: '$dischargeaddressraw',
                                            },
                                          $result: {
                                            $getVariable: 'orefcode',
                                          },
                                        },
                                        'morphjs',
                                        ['ordermanagement_conversion'],
                                      ],
                                    },
                                  },
                                },
                                $result: {
                                  $getVariable: 'city',
                                },
                              },
                              'morphjs',
                              ['feature'],
                            ],
                          },
                        ],
                      ])
                    ),
                    type: EntryType.Internal,
                  },
                  loadingdatereadable: {
                    validation: z.literal('$loadingdatereadable'),
                    type: EntryType.Internal,
                  },
                  loadingdateformat: {
                    validation: z.literal('$loadingdateformat'),
                    type: EntryType.Internal,
                  },
                  loadingdate: {
                    validation: z.literal('$loadingdate'),
                    type: EntryType.Internal,
                  },
                  loadingtimereadable: {
                    validation: z.literal('$loadingtimereadable'),
                    type: EntryType.Internal,
                  },
                  loadingtime: {
                    validation: z.literal('$loadingtime'),
                    type: EntryType.Internal,
                  },
                  dischargedatereadable: {
                    validation: z.literal('$dischargedatereadable'),
                    type: EntryType.Internal,
                  },
                  dischargedateformat: {
                    validation: z.literal('$dischargedateformat'),
                    type: EntryType.Internal,
                  },
                  dischargedate: {
                    validation: z.literal('$dischargedate'),
                    type: EntryType.Internal,
                  },
                  startdatereadable: {
                    validation: z.literal('$loadingdatereadable'),
                    type: EntryType.Internal,
                  },
                  startdate: {
                    validation: z.literal('$loadingdate'),
                    type: EntryType.Internal,
                  },
                  starttimeroutereadable: {
                    validation: z.literal('$loadingtimereadable'),
                    type: EntryType.Internal,
                  },
                  endtimeroutereadable: {
                    validation: z.literal('$dischargetimereadable'),
                    type: EntryType.Internal,
                  },
                  totaldistance: {
                    validation: z.number(),
                    type: EntryType.Internal,
                  },
                  totaltimereadable: {
                    validation: z.string(),
                    type: EntryType.Internal,
                  },
                  totaltime: {
                    validation: z.number(),
                    type: EntryType.Internal,
                  },
                  totalquantity: {
                    displayName: 'Aantal',
                    validation: z.number(),
                    type: EntryType.Cell,
                  },
                  vehicletype: {
                    validation: z.string(),
                    type: EntryType.Internal,
                  },
                  servicelevel: {
                    validation: z.string(),
                    type: EntryType.Internal,
                  },
                  incoterms: {
                    validation: z.string(),
                    type: EntryType.Internal,
                  },
                  containers: {
                    values: {
                      shipmentreference: {
                        validation: z.literal('$clientreference'),
                        type: EntryType.Internal,
                      },
                      invoicereference: {
                        validation: z.literal('$clientreference'),
                        type: EntryType.Internal,
                      },
                      container: {
                        validation: z.literal('$clientreference'),
                        type: EntryType.Internal,
                      },
                      packagetyperaw: {
                        validation: z.literal(
                          JSON.stringify([
                            ['PackageType', '$clientidentifier', '-', [[]]],
                          ])
                        ),
                        type: EntryType.Internal,
                      },
                      packagetype: {
                        validation: z.literal(
                          JSON.stringify({
                            $mongoRequest: [
                              {
                                '$db.ordermanagement_conversion.findOne': {
                                  crefcode: [
                                    [
                                      'PackageType',
                                      '$clientidentifier',
                                      '-',
                                      [[]],
                                    ],
                                  ],
                                  key: 'packagetype',
                                },
                                $result: {
                                  $getVariable: 'orefcode',
                                },
                              },
                              'morphjs',
                              ['ordermanagement_conversion'],
                            ],
                          })
                        ),
                        type: EntryType.Internal,
                      },
                      producttyperaw: {
                        validation: z.literal(
                          JSON.stringify([
                            [
                              'ProductType',
                              '$clientidentifier',
                              '-',
                              '$clientidentifier',
                            ],
                          ])
                        ),
                        type: EntryType.Internal,
                      },
                      producttype: {
                        validation: z.literal('GOO'),
                        type: EntryType.Internal,
                      },
                      weight: {
                        validation: z.number(),
                        type: EntryType.Cell,
                      },
                      quantity: {
                        validation: z.number(),
                        type: EntryType.Cell,
                      },
                      cargodescription: {
                        validation: z.string(),
                        type: EntryType.Cell,
                      },
                      loading: {
                        values: {
                          loadingaddressraw: {
                            validation: z.literal('$loadingaddressraw'),
                            type: EntryType.Internal,
                          },
                          loadingaddress: {
                            validation: z.literal(
                              JSON.stringify({
                                $mongoRequest: [
                                  {
                                    '$db.ordermanagement_conversion.findOne': {
                                      crefcode: '$loadingaddressraw',
                                      key: 'loadingaddress',
                                    },
                                    $result: {
                                      $getVariable: 'orefcode',
                                    },
                                  },
                                  'morphjs',
                                  ['ordermanagement_conversion'],
                                ],
                              })
                            ),
                            type: EntryType.Internal,
                          },
                          loadingaddressoriginalid: {
                            validation: z.literal(
                              JSON.stringify([
                                [
                                  {
                                    $mongoRequest: [
                                      {
                                        '$db.feature.findOne': {
                                          templatetype: 'feature',
                                          id: {
                                            '$math.multiply': [
                                              {
                                                $mongoRequest: [
                                                  {
                                                    '$db.ordermanagement_conversion.findOne':
                                                      {
                                                        crefcode:
                                                          '$loadingaddressraw',
                                                      },
                                                    $result: {
                                                      $getVariable: 'orefcode',
                                                    },
                                                  },
                                                  'morphjs',
                                                  [
                                                    'ordermanagement_conversion',
                                                  ],
                                                ],
                                              },
                                              1,
                                            ],
                                          },
                                        },
                                        $result: {
                                          $getVariable: 'idaddress',
                                        },
                                      },
                                      'morphjs',
                                      ['feature'],
                                    ],
                                  },
                                  '',
                                ],
                              ])
                            ),
                            type: EntryType.Internal,
                          },
                          loadingdriverinstructions: {
                            validation: z.string(),
                            type: EntryType.Cell,
                          },
                          loadingdate: {
                            validation: z.literal(
                              JSON.stringify({
                                '$math.plus': ['$loadingdate', '$loadingtime'],
                              })
                            ),
                            type: EntryType.Internal,
                          },
                          loadingdateend: {
                            validation: z.literal(
                              JSON.stringify({
                                '$math.plus': ['$loadingdate', '$loadingtime'],
                              })
                            ),
                            type: EntryType.Internal,
                          },
                          loadingreference: {
                            validation: z.string(),
                            type: EntryType.Cell,
                          },
                        },
                      },
                      discharge: {
                        values: {
                          dischargeaddressraw: {
                            validation: z.literal('$dischargeaddressraw'),
                            type: EntryType.Internal,
                          },
                          dischargeaddress: {
                            validation: z.literal(
                              JSON.stringify({
                                $mongoRequest: [
                                  {
                                    '$db.ordermanagement_conversion.findOne': {
                                      crefcode: '$dischargeaddressraw',
                                      key: 'dischargeaddress',
                                    },
                                    $result: {
                                      $getVariable: 'orefcode',
                                    },
                                  },
                                  'morphjs',
                                  ['ordermanagement_conversion'],
                                ],
                              })
                            ),
                            type: EntryType.Internal,
                          },
                          dischargeaddressoriginalid: {
                            validation: z.literal(
                              JSON.stringify([
                                [
                                  {
                                    $mongoRequest: [
                                      {
                                        '$db.feature.findOne': {
                                          templatetype: 'feature',
                                          id: {
                                            '$math.multiply': [
                                              {
                                                $mongoRequest: [
                                                  {
                                                    '$db.ordermanagement_conversion.findOne':
                                                      {
                                                        crefcode:
                                                          '$dischargeaddressraw',
                                                      },
                                                    $result: {
                                                      $getVariable: 'orefcode',
                                                    },
                                                  },
                                                  'morphjs',
                                                  [
                                                    'ordermanagement_conversion',
                                                  ],
                                                ],
                                              },
                                              1,
                                            ],
                                          },
                                        },
                                        $result: {
                                          $getVariable: 'idaddress',
                                        },
                                      },
                                      'morphjs',
                                      ['feature'],
                                    ],
                                  },
                                  '',
                                ],
                              ])
                            ),
                            type: EntryType.Internal,
                          },
                          dischargedriverinstructions: {
                            validation: z.string(),
                            type: EntryType.Cell,
                          },
                          dischargedate: {
                            validation: z.literal(
                              JSON.stringify({
                                '$math.plus': [
                                  '$dischargedate',
                                  '$dischargetime',
                                ],
                              })
                            ),
                            type: EntryType.Internal,
                          },
                          dischargedateend: {
                            validation: z.literal(
                              JSON.stringify({
                                '$math.plus': [
                                  '$dischargedate',
                                  '$dischargetime',
                                ],
                              })
                            ),
                            type: EntryType.Internal,
                          },
                          dischargereference: {
                            validation: z.string(),
                            type: EntryType.Cell,
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};
