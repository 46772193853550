import { EntryProvider, GraphProvider } from '../../context';
import { GraphView } from '../../organisms';
import { useParams } from 'react-router-dom';

export const GraphEditor = () => {
  const { entryId } = useParams();
  if (!entryId) throw new Error('No active entry');

  return (
    <EntryProvider id={entryId}>
      <GraphProvider>
        <GraphView />
      </GraphProvider>
    </EntryProvider>
  );
};
