import { useRef } from 'react';
import { BooleanEntryContext } from './context';
import { useStore } from '@/store';
import { BooleanEntryStore, createBooleanEntryStore } from '../../store';

interface BooleanEntryProviderProps extends React.PropsWithChildren {
  id: string;
}

export const BooleanEntryProvider = ({
  children,
  id,
}: BooleanEntryProviderProps) => {
  const globalStore = useStore();
  const storeRef = useRef<BooleanEntryStore>();

  if (!storeRef.current) {
    storeRef.current = createBooleanEntryStore(globalStore, {
      id,
    });
  }

  return (
    <BooleanEntryContext.Provider value={storeRef.current}>
      {children}
    </BooleanEntryContext.Provider>
  );
};
