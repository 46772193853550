import styled from '@emotion/styled';
import { Group } from '@mantine/core';
import { Dropzone } from '@mantine/dropzone';
import { FiFileText, FiUpload, FiX } from 'react-icons/fi';

export const DropzoneWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const StyledDropZone = styled(Dropzone)(({ theme }) => ({
  borderWidth: 1,
  width: '100%',
  borderRadius: '0.5rem',
  borderColor: theme.colors.blue[6],
  backgroundColor: theme.colors.gray[0],
}));

export const StyledGroup = styled(Group)`
  pointer-events: none;
  padding: 1rem;
  justify-content: flex-start;
`;

export const FileText = styled(FiFileText)(({ theme }) => ({
  fontSize: '2rem',
  strokeWidth: 0.8,
  color: theme.colors.blue[6],
}));

export const FileUpload = styled(FiUpload)(({ theme }) => ({
  fontSize: '2rem',
  strokeWidth: 0.8,
  color: theme.colors.blue[6],
}));

export const XError = styled(FiX)(({ theme }) => ({
  fontSize: '2rem',
  strokeWidth: 0.8,
  color: theme.colors.red[6],
}));
