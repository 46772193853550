import { useContext } from "react";
import { useStore } from "zustand";
import { TreeState } from "../../store";
import { TreeContext } from "./context";

export const useTreeContext = <T>(
  selector: (state: TreeState) => T,
  equalityFn?: (left: T, right: T) => boolean
): T => {
  const store = useContext(TreeContext);
  if (!store) throw new Error("Missing TreeContext.Provider in the tree");
  return useStore(store, selector, equalityFn);
};
