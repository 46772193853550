import { useContext } from 'react';
import { useStore } from 'zustand';
import { NodeState } from '../../store';
import { NodeContext } from './context';
import { INode } from '@/store';

export const useNodeContext = <T>(
  selector: (state: NodeState & INode) => T,
  equalityFn?: (left: T, right: T) => boolean
): T => {
  const store = useContext(NodeContext);
  if (!store) throw new Error('Missing NodeContext.Provider in the node');
  return useStore(store, selector, equalityFn);
};
