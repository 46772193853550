import _ from 'lodash';
import { OptionsAsArgument, OptionsRecord } from '@morph-mapper/node-logic';
import { NodeOptionsWrapper, isOptionEntryMap } from '@morph-mapper/types';

/**
 * Parses the options of a node into a plain object.
 * It removes state variables used by the application and prepares the options to be used as arguments
 * for the logic functions, which transform the options into template code.
 */
export const parseNodeOptions = <T extends OptionsRecord>(
  options: NodeOptionsWrapper<any>
) => {
  return Object.entries(options).reduce((acc, [key, variable]) => {
    if (isOptionEntryMap(variable)) {
      const nested = Object.entries(variable.value).reduce(
        (acc, [name, property]) => {
          return {
            ...acc,
            [name]: property.value,
          };
        },
        {}
      );

      return {
        ...acc,
        [key]: nested,
      };
    }

    return {
      ...acc,
      [key]: variable.value,
    };
  }, {} as OptionsAsArgument<T>);
};
