import styled from "@emotion/styled";
import { Text, TextProps, createPolymorphicComponent } from "@mantine/core";

export const StyledRow = styled("tr")`
  background-color: ${({ theme }) => theme.colors.gray[0]};
  position: sticky;
  top: 0;
  z-index: 1;
`;

export const _StyledTitle = styled(Text)`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.gray[6]};
`;

export const StyledTitle = createPolymorphicComponent<"text", TextProps>(
  _StyledTitle
);
