import { useMemo, useRef } from 'react';
import { EntryContext } from './context';
import { EntryStore, createEntryStore } from '../../store';
import { useStore } from '@/store';

interface EntryProviderProps extends React.PropsWithChildren {
  id: string;
}

export const EntryProvider = ({ children, id }: EntryProviderProps) => {
  const globalStore = useStore();
  const storeRef = useRef<EntryStore>();

  storeRef.current = useMemo(() => {
    return createEntryStore(globalStore, { id });
  }, [globalStore]);

  return (
    <EntryContext.Provider value={storeRef.current}>
      {children}
    </EntryContext.Provider>
  );
};
