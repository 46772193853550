import { useContext } from 'react';
import { useStore } from 'zustand';
import { EntryState } from '../../store';
import { EntryContext } from './context';
import { IMapEntry } from '@/store';

export const useEntryContext = <T>(
  // TODO: refactor these hooks with the new Zustand Context API
  selector: (state: EntryState & IMapEntry) => T,
  equalityFn?: (left: T, right: T) => boolean
): T => {
  const store = useContext(EntryContext);
  if (!store) throw new Error('Missing EntryContext.Provider in the entry');
  return useStore(store, selector, equalityFn);
};
