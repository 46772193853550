import {
  CellEntryProvider,
  MapEntryProvider,
  GraphEntryProvider,
  SimpleEntryProvider,
} from '../../context';
import { ItemRow, MapRow } from '../../molecules';
import { useStore } from '@/store';
import { EditSimpleItem } from '../../molecules/edit-simple-item';
import { EditCellItem } from '../../molecules/edit-cell-item';
import { EntryType } from '@morph-mapper/types';
import { match } from 'ts-pattern';
import { BooleanEntryProvider } from '../../context/boolean-entry';
import { EditBooleanItem } from '../../molecules/edit-boolean-item';

interface ProviderSelectorProps {
  entryId: string;
}

export const ProviderSelector = ({ entryId }: ProviderSelectorProps) => {
  const { getType, getName } = useStore(({ entries: e }) => e.entry(entryId));

  const name = getName();
  if (!name) {
    return null;
  }

  return match(getType())
    .with(EntryType.Boolean, () => (
      <BooleanEntryProvider id={entryId}>
        <ItemRow editView={<EditBooleanItem />} />
      </BooleanEntryProvider>
    ))
    .with(EntryType.Simple, () => (
      <SimpleEntryProvider id={entryId}>
        <ItemRow editView={<EditSimpleItem />} />
      </SimpleEntryProvider>
    ))
    .with(EntryType.Cell, () => (
      <CellEntryProvider id={entryId}>
        <ItemRow editView={<EditCellItem />} />
      </CellEntryProvider>
    ))
    .with(EntryType.Graph, () => (
      <GraphEntryProvider id={entryId}>
        <ItemRow />
      </GraphEntryProvider>
    ))
    .with(EntryType.Map, () => {
      return (
        <MapEntryProvider id={entryId}>
          <MapRow />
        </MapEntryProvider>
      );
    })
    .with(EntryType.Internal, () => null)
    .exhaustive();
};
