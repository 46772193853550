import { Add } from "@morph-mapper/ui";
import { Text, Group, Stack, Accordion } from "@mantine/core";
import { useState } from "react";
import { Handle, Position } from "reactflow";
import { useListDependency } from "./use-list-dependency";
import { DIRECTION } from "@morph-mapper/node-logic";

interface DependencyListProps {
  nodeId: string;
  dependencyArray: string[];
  isHandleConnected: (
    nodeId: string,
    handleId: string,
    direction: DIRECTION
  ) => boolean;
}

export const DependencyList = ({
  nodeId,
  dependencyArray,
  isHandleConnected,
}: DependencyListProps) => {
  const { handleCreateDependency } = useListDependency(nodeId);
  const [value, setValue] = useState<string | null>(null);

  return (
    <>
      <Accordion
        variant="contained"
        value={value}
        onChange={setValue}
        sx={(theme) => ({
          background: theme.colors.gray[0],
          marginBottom: "0.25rem",
        })}
      >
        <Accordion.Item value="dependencies">
          <Accordion.Control>
            <Text tt="uppercase" c="dimmed" size={10}>
              Dependencies
            </Text>
          </Accordion.Control>
          <Accordion.Panel>
            <Stack spacing={"xs"}>
              {dependencyArray.map((property) => {
                return (
                  <Group position="apart" key={property}>
                    <Text size={13}>{property}</Text>
                    <Add onClick={() => handleCreateDependency(property)} />
                  </Group>
                );
              })}
            </Stack>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>

      {dependencyArray.map((property, index) => {
        const isConnected = isHandleConnected(
          nodeId,
          property,
          DIRECTION.TARGET
        );

        return (
          <Handle
            id={property}
            key={property}
            type={DIRECTION.TARGET}
            position={Position.Left}
            style={{
              visibility: isConnected ? "visible" : "hidden",
              left: -2,
              top: value ? 30 + 37.8 * (index + 1) : 25,
            }}
          />
        );
      })}
    </>
  );
};
