import { NoBorderCell, Styled } from "./styles";

interface EditPropertyRowProps {
  children: React.ReactNode[] | React.ReactNode;
}

export const EditPropertyRow = ({ children }: EditPropertyRowProps) => {
  return (
    <NoBorderCell>
      <Styled>{children}</Styled>
    </NoBorderCell>
  );
};
