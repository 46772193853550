import { Box, Select } from '@mantine/core';
import { ComputedCell, ConfirmCell, EditCell } from '@morph-mapper/ui';
import { Badge } from '@morph-mapper/ui';
import { PiBracketsCurlyBold } from 'react-icons/pi';
import { StyledRow } from './styles';
import { ActionCell } from '../action-cell';
import { useParameterRowContext } from '../context';
import { ParameterMenu } from '../menu';
import { OptionType } from '@morph-mapper/types';

export const ParameterRow = () => {
  const [
    name,
    tempValue,
    type,
    computed,
    isConfirm,
    confirmDelete,
    cancelDelete,
    isEditing,
    setTempValue,
    confirmEdit,
    availableNames,
  ] = useParameterRowContext((s) => [
    s.name,
    s.tempValue,
    s.getType(),
    s.getComputed(),
    s.isConfirm,
    s.confirmDelete,
    s.cancelDelete,
    s.isEditing,
    s.setTempValue,
    s.confirmEdit,
    s.getAvailableNames(),
  ]);
  const handleSetReference = (value: string) => {
    setTempValue(value);
    confirmEdit();
  };

  const pickCell = () => {
    if (type === OptionType.Reference) {
      return (
        <Select
          onChange={handleSetReference}
          searchable
          placeholder="Select variable"
          nothingFound="No variable found"
          icon={<PiBracketsCurlyBold />}
          data={availableNames}
        />
      );
    }
    if (isConfirm)
      return (
        <ConfirmCell handleAccept={confirmDelete} handleDeny={cancelDelete} />
      );
    if (isEditing)
      return <EditCell handleChange={setTempValue} value={tempValue} />;
    return <ComputedCell value={computed} />;
  };

  return (
    <StyledRow>
      <td>
        <Badge>{name}</Badge>
      </td>
      <td>{pickCell()}</td>
      <td>
        <Box sx={{ display: 'flex', flex: 1, justifyContent: 'space-between' }}>
          <ActionCell />
          <ParameterMenu />
        </Box>
      </td>
    </StyledRow>
  );
};
