import { FileWithPath } from '@mantine/dropzone';
import { notifications } from '@mantine/notifications';
import { FileRejection } from '@/types';
import { useNotify } from '@/hooks';

export const useFileDrop = (load: (file: File) => void) => {
  const { error } = useNotify();

  const handleFileAccept = (files: FileWithPath[]) => {
    if (files.length > 1) {
      error('Only 1 file is allowed');
      return;
    }
    if (files.length === 0 || files[0] === undefined) {
      error('No file selected');
      return;
    }

    load(files[0]);
  };

  const handleFileReject = (rejections: FileRejection[]) => {
    rejections.forEach((rejection) => {
      rejection.errors.forEach((error) => {
        notifications.show({
          title: 'Error loading file',
          message: error.message,
        });
      });
    });
  };

  return {
    handleFileAccept,
    handleFileReject,
  };
};
