import { StoreSlice } from '@/store';
import { createStore } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

export interface TreeProps {
  pointer: string[];
}

type InitTreeProps = { id: string };

export interface TreeState extends TreeProps {
  moveIn: (reference: string) => void;
  moveOut: () => void;

  getCurrentId: () => string;
  getChildrenIds: () => string[];
}

export type TreeStore = ReturnType<typeof createTreeStore>;

export const createTreeStore = (
  { entries }: StoreSlice,
  { id }: InitTreeProps
) => {
  return createStore<TreeState>()(
    persist(
      immer((set, get) => ({
        pointer: [id],

        moveIn: (nextFocus) =>
          set((s) => {
            s.pointer.push(nextFocus);
          }),
        moveOut: () =>
          set((s) => {
            s.pointer.pop();
          }),

        getCurrentId: () => {
          const currentId = get().pointer[get().pointer.length - 1];
          if (currentId === undefined) {
            throw new Error('Pointer is empty.');
          }

          return currentId;
        },

        getChildrenIds: () => {
          const currentId = get().pointer[get().pointer.length - 1];
          if (currentId === undefined) {
            throw new Error('Pointer is empty.');
          }

          return entries.children(currentId).getIds();
        },
      })),
      {
        name: 'entry-editor-tree',
        storage: createJSONStorage(() => sessionStorage),
      }
    )
  );
};
