import { LOGIC_CONNECTORS } from "../logic";

/**
 * This hook provides an external interface to the logic connectors.
 * It is used to dynamically render UI elements based on the type of connector.
 */
export const useConnectors = () => {
  return {
    connectors: LOGIC_CONNECTORS,
  };
};
