import { Accordion } from '@mantine/core';
import {
  useCategoriesOfOperatorIdByDomain,
  useTemplateRefsOfOperatorId,
} from '@/services';
import { useSetupStore } from '../../store';
import { TemplateRef } from '@morph-mapper/types';
import { TemplateListItem } from '../template-list-item/template-list-item';
import { TemplateAccordionItem } from '../template-accordion-item';
import { getEntries } from '@morph-mapper/utils';

export const TemplateAccordion = () => {
  const [domain, operatorId] = useSetupStore((s) => [
    s.getDomain(),
    s.getOperatorId(),
  ]);
  const { data: categories } = useCategoriesOfOperatorIdByDomain(
    operatorId,
    domain
  );
  const { data: templates } = useTemplateRefsOfOperatorId(operatorId);

  if (templates === undefined || categories === undefined) return null;

  const sortCategories = () => {
    return categories.sort((a, b) => {
      if (a.name === 'Other') return 1;
      if (b.name === 'Other') return -1;

      return a.name.localeCompare(b.name);
    });
  };

  const buildCategoriesObj = () => {
    // If categories are empty, initialize 'default'
    const sortedCategories = sortCategories();
  
    return sortedCategories.reduce((acc, category) => {
      acc[category.name] = [];
      return acc;
    }, {} as Record<string, TemplateRef[]>);
  };

  const groupByCategory = (templates: TemplateRef[]) => {
    // Filter out deleted templates
    const activeCategories = templates.filter(
      (template) => template.deleted !== true
    );
    return activeCategories.reduce((acc, template) => {
      const category = template.category;
      const objRef = acc[category];

      // TODO: Optimize
      // During domain switching the categories hook has not yet been updated
      // before the templates query. First execution then fails.
      if (objRef === undefined) {
        return acc;
      }

      objRef.push(template);

      return acc;
    }, buildCategoriesObj());
  };

  const orderByPriority = (templates: TemplateRef[]) => {
    return templates.sort((a, b) => a.priority - b.priority);
  };

  const filterByDomain = (templates: TemplateRef[]) => {
    return templates.filter((template) => template.domain === domain);
  };

  return (
    <Accordion chevronPosition="left" styles={{ content: { padding: 0 } }}>
      {getEntries(
        groupByCategory(orderByPriority(filterByDomain(templates)))
      ).map(([category, templates]) => (
        <TemplateAccordionItem
          key={category}
          category={category}
          domain={domain}
        >
          {templates.map((template) => (
            <TemplateListItem key={template.id} {...template} />
          ))}
        </TemplateAccordionItem>
      ))}
    </Accordion>
  );
};
