import { TreeNode } from '@/types';
import { IMapChildren } from './children';
import { z } from 'zod';
import { Getter, Setter } from '@dhmk/zustand-lens';
import { Draft } from 'immer';
import { EntrySlice } from '.';
import { EntryItemType, EntryType } from '@morph-mapper/types';
import { StructureBlockIndex } from '@morph-mapper/node-logic';
import { match } from 'ts-pattern';

// TODO: Seperate out the EntryItemType from the EntryType

export interface IBooleanEntry extends IEntry {
  setBoolean: (bool: boolean) => void;
  getBoolean: () => boolean | undefined;
}

const createBooleanEntryAPI = (
  _set: Setter<Draft<EntrySlice>>,
  get: Getter<Draft<EntrySlice>>,
  id: string
): Omit<IBooleanEntry, keyof IEntry> => {
  return {
    setBoolean: (literal) => get().setUnderlyingValue(id, literal),
    getBoolean: () => get().getUnderlyingValue(id),
  };
};

export interface ISimpleEntry extends IEntry {
  setLiteral: (literal: string | undefined) => void;
  getLiteral: () => string | undefined;
}

const createSimpleEntryAPI = (
  _set: Setter<Draft<EntrySlice>>,
  get: Getter<Draft<EntrySlice>>,
  id: string
): Omit<ISimpleEntry, keyof IEntry> => {
  return {
    setLiteral: (literal) => get().setUnderlyingValue(id, literal),
    getLiteral: () => get().getUnderlyingValue(id),
  };
};

export interface ICellEntry extends IEntry {
  getColumn: () => number | undefined;
  setColumn: (column: number | undefined) => void;
}

const createCellEntryAPI = (
  _set: Setter<Draft<EntrySlice>>,
  get: Getter<Draft<EntrySlice>>,
  id: string
): Omit<ICellEntry, keyof IEntry> => {
  return {
    getColumn: () => get().getUnderlyingValue(id),
    setColumn: (column) => get().setUnderlyingValue(id, column),
  };
};

export interface IGraphEntry extends IEntry {
  linkGraph: (graphId: string) => void;
  getGraphId: () => string | undefined;
}

const createGraphEntryAPI = (
  _set: Setter<Draft<EntrySlice>>,
  get: Getter<Draft<EntrySlice>>,
  id: string
): Omit<IGraphEntry, keyof IEntry> => {
  return {
    linkGraph: (graphId) => get().linkGraph(id, graphId),
    getGraphId: () => get().getUnderlyingValue(id),
  };
};

export interface IInternalEntry extends IEntry {}

export interface IMapEntry extends IEntry {
  children: () => IMapChildren;

  linkChild: (childId: string) => void;
  getOutputType: () => StructureBlockIndex;
  setOutputType: (outputType: StructureBlockIndex) => void;
}

const createMapEntryAPI = (
  _set: Setter<Draft<EntrySlice>>,
  get: Getter<Draft<EntrySlice>>,
  id: string
): Omit<IMapEntry, keyof IEntry> => {
  return {
    children: () => get().children(id),
    linkChild: (childId) => get().linkChild(id, childId),
    getOutputType: () => get().getOutputType(id),
    setOutputType: (outputType) => get().setOutputType(id, outputType),
  };
};

export interface IEntry {
  getParent: () => IMapEntry | undefined;
  getById: () => TreeNode;
  getParentId: () => string | undefined;
  getAllowedTypes: () => EntryItemType[];
  getType: () => EntryType;
  setType: (type: EntryItemType) => void;
  getId: () => string;
  getKey: () => string;
  getName: () => string | undefined;
  getComputed: () => any;
  setComputed: (computed: any) => void;
  getValidation: () => z.ZodType<any, any, any> | undefined;
  getDependsOn: () => string[];
  getRequiredBy: () => string[];
  getPath: () => string[];
  getTemplateValue: () => any;
  setTemplateValue: (value: any) => void;
  hasValue: () => boolean;
  clear: () => void;
  delete: () => void;
}

export type EntryReturnType<T extends EntryType> = T extends EntryType.Cell
  ? ICellEntry
  : T extends EntryType.Graph
  ? IGraphEntry
  : T extends EntryType.Simple
  ? ISimpleEntry
  : T extends EntryType.Map
  ? IMapEntry
  : T extends EntryType.Internal
  ? IInternalEntry
  : T extends EntryType.Boolean
  ? IBooleanEntry
  : IEntry;

export const createEntryAPI = <T extends EntryType>(
  _set: Setter<Draft<EntrySlice>>,
  get: Getter<Draft<EntrySlice>>,
  id: string,
  type?: T
): EntryReturnType<T> => {
  const baseEntry: IEntry = {
    getParent: () => get().getParent(id),
    getById: () => get().getById(id),
    getParentId: () => get().getParentId(id),
    getAllowedTypes: () => get().getAllowedTypes(id),
    getType: () => get().getType(id),
    setType: (type) => get().setType(id, type),
    getId: () => id,
    getKey: () => get().getKey(id),
    getName: () => get().getName(id),
    getComputed: () => get().getComputed(id),
    setComputed: (computed) => get().setComputed(id, computed),
    getValidation: () => get().getValidation(id),
    getDependsOn: () => get().getDependsOn(id),
    getRequiredBy: () => get().getRequiredBy(id),
    getPath: () => get().getPath(id),
    getTemplateValue: () => get().getTemplateValue(id),
    setTemplateValue: (value) => get().setTemplateValue(id, value),
    hasValue: () => get().hasValue(id),
    clear: () => get().clear(id),
    delete: () => get().delete(id),
  };

  if (!type) {
    return baseEntry as EntryReturnType<T>;
  }

  return match<EntryType>(type)
    .with(EntryType.Cell, () => ({
      ...baseEntry,
      ...createCellEntryAPI(_set, get, id),
    }))
    .with(EntryType.Boolean, () => ({
      ...baseEntry,
      ...createBooleanEntryAPI(_set, get, id),
    }))
    .with(EntryType.Simple, () => ({
      ...baseEntry,
      ...createSimpleEntryAPI(_set, get, id),
    }))
    .with(EntryType.Map, () => ({
      ...baseEntry,
      ...createMapEntryAPI(_set, get, id),
    }))
    .with(EntryType.Graph, () => ({
      ...baseEntry,
      ...createGraphEntryAPI(_set, get, id),
    }))
    .with(EntryType.Internal, () => ({
      ...baseEntry,
    }))
    .exhaustive() as EntryReturnType<T>;
};
