import { useEffect, useState } from 'react';
import { useEntryParser } from '@/services';
import { useParams } from 'react-router-dom';
import { useEntryContext } from '../../context';

export const useOutputNode = () => {
  /* Context State */
  const [computed, setComputed, entryId] = useEntryContext((s) => [
    s.getComputed(),
    s.setComputed,
    s.getId(),
  ]);
  /* Local State */
  const [output, setOutput] = useState<string>('');
  /* Hooks */
  const { variableId } = useParams();
  /* Queries */
  const { data } = useEntryParser(entryId);

  useEffect(() => {
    if (data === undefined) return;

    // Flatten data if it is wrapped in an array
    const flattenedData =
      Array.isArray(data) && data.length === 1 && Array.isArray(data[0])
        ? data[0]
        : data;

    if (computed === flattenedData) return;

    setComputed(flattenedData, variableId);
    setOutput(JSON.stringify(flattenedData).replace(/"/g, ''));
  }, [data]);

  return {
    output,
  };
};
