import { useStore } from '../context';
import { Path, PathMode } from '../types';
import { getOrderedIds } from '../utils';
import { match } from 'ts-pattern';
import { getKeys } from '@morph-mapper/utils';
import { useEffect } from 'react';

export const useRelativePaths = () => {
  const [
    absolutePath,
    relativePath,
    setRelativePath,
    relativePaths,
    pathMode,
    setMode,
  ] = useStore(({ path: p }) => [
    p.getAbsolutePath(),
    p.relativePath,
    p.setRelativePath,
    p.getRelativePaths(),
    p.getPathMode(),
    p.setPathMode,
  ]);

  /*
   * When the absolute path changes, we need to check if the selected relative path is
   * still partially equal to the new absolute path. If it is not, we try to find a new
   * relative path that is partially equal to the new absolute path. If no such path is found,
   * we set the path mode to absolute.
   */
  useEffect(() => {
    if (pathMode === PathMode.Absolute) return;
    if (relativePath === undefined) {
      setMode(PathMode.Absolute);
      return;
    }
    if (hasEqualValuePath(relativePath.path, absolutePath)) return;

    const selected = getLongestPartialEqualValuePath();
    if (selected === undefined) {
      setMode(PathMode.Absolute);
      return;
    }

    setRelativePath(selected);
  }, [absolutePath]);

  /*
   * When the list of relative paths changes, we need to check if there are any relative paths
   * present. If there are none, we set the path mode to absolute.
   */
  useEffect(() => {
    if (pathMode === PathMode.Absolute) return;
    if (relativePaths.length === 0) {
      setMode(PathMode.Absolute);
      return;
    }
  }, [relativePaths]);

  /**
   * Used to get the longest relative path that has a partially equal value path to the selected absolute path.
   */
  const getLongestPartialEqualValuePath = () => {
    return relativePaths
      .filter(({ path }) => hasEqualValuePath(path, absolutePath))
      .sort((a, b) => getKeys(a.path).length - getKeys(b.path).length)
      .pop();
  };

  /**
   * Used to determine if a relative path has a partially equal value path to the selected absolute path.
   * A relative path is of equal value if all its values are equal to the start of the absolute path.
   * Note that we do not include operators in this comparison.
   *
   * E.g.: tag1.0 is partially equal to tag1.1.tag2, but not to tag2.0.tag3.
   */
  const hasEqualValuePath = (relative: Path, absolute: Path) => {
    const relativeIds = getOrderedIds(relative);
    const absoluteIds = getOrderedIds(absolute);

    return relativeIds.every(
      (id, i) => relative[id].value === absolute[absoluteIds[i]].value
    );
  };

  /**
   * Used by UI elements to allow or block the activation of a certain path mode.
   * Absolute paths are always available, but relative paths are only available
   * if there are any present in the list of relative paths,
   * and if one of the relative paths has a partially equal value path to the selected absolute path.
   */
  const pathModeIsAvailable = (mode: PathMode) =>
    match(mode)
      .with(PathMode.Absolute, () => true)
      .with(PathMode.Relative, () =>
        relativePaths.some(({ path }) => hasEqualValuePath(path, absolutePath))
      )
      .exhaustive();

  /**
   * Used to set the path mode to either absolute or relative. When selecting relative,
   * the selected relative path is set to the longest relative path that has a partially equal value path.
   */
  const setPathMode = (mode: PathMode) =>
    match(mode)
      .with(PathMode.Absolute, () => setMode(PathMode.Absolute))
      .with(PathMode.Relative, () => {
        const selected = getLongestPartialEqualValuePath();
        if (selected === undefined)
          throw 'No relative path found with a partially equal value path to the selected absolute path.';

        setRelativePath(selected);
        setMode(PathMode.Relative);
      })
      .exhaustive();

  /**
   * Filters the list of relative paths to only include those
   * that have a partially equal value path to the selected absolute path.
   */
  const getRelativePathSelection = () =>
    relativePaths
      .filter(({ path }) => hasEqualValuePath(path, absolutePath))
      .map(({ reference }) => ({
        value: reference,
        label: reference,
      }));

  /**
   * Used to set the selected relative path to the one with the selected reference.
   */
  const onRelativePathChange = (value: string) => {
    const selected = relativePaths.find(({ reference }) => reference === value);
    if (selected === undefined)
      throw 'No relative path found with the selected reference.';

    setRelativePath(selected);
  };

  return {
    pathModeIsAvailable,
    setPathMode,
    getRelativePathSelection,
    onRelativePathChange,
  };
};
