import { createStore } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { Node } from '@/types';
import { NavigateFunction } from '@/hooks';
import { INode, StoreSlice } from '@/store';
import { Getter, Setter, createLens } from '@dhmk/zustand-lens';
import { Draft } from 'immer';
import { LogicBlockIndex } from '@morph-mapper/node-logic';
import { OptionType } from '@morph-mapper/types';

export interface NodeProps {
  graphId: string;
  nodeId: string;
}

export type InitNodeProps = NodeProps & { logicBlocks: any };

export interface NodeState extends NodeProps {
  // Navigation
  // TODO: make node navigation, possible no specific implementation needed
  navigate: NavigateFunction;

  // Local state operations
  getType: () => LogicBlockIndex;
  getOptionSelection: (property: string) => any;
  getOptionDefault: (property: string) => any;

  getOptions: () => Record<string, any>;

  getOptionValue: (property: string) => any;
  setOptionValue: (property: string, value: any) => void;

  getOptionType: (property: string) => OptionType;
  setOptionType: (property: string, type: OptionType) => void;

  getDependencies: () => Record<string, boolean | Record<string, boolean>>;
  setDependency: (dependency: string, topic?: string) => void;
  clearDependency: (dependency: string) => void;
}

type InitNodeState = Pick<NodeState, 'navigate'>;

export type NodeStore = ReturnType<typeof createNodeStore>;

export const createNodeStore = (
  global: StoreSlice,
  { navigate, graphId, nodeId, logicBlocks }: InitNodeProps & InitNodeState
) => {
  return createStore<NodeState & StoreSlice & INode>()(
    immer((_set, _get) => {
      const [set, get] = createLens(_set, _get, [
        'graphs',
        'graphs',
        graphId,
        'nodes',
        nodeId,
      ]) as [Setter<Draft<Node>>, Getter<Draft<Node>>];

      return {
        ...global,
        ...global.graphs.node(graphId, nodeId),
        graphId,
        nodeId,
        navigate,

        getOptions: () => {
          return get().data.logic.options;
        },

        getOptionSelection: (property) => {
          // TODO: refactor to narrowed type
          const config = logicBlocks[get().type].options[property];

          if (config.selection) {
            return config.selection;
          }

          return undefined;
        },

        getOptionDefault: (property) => {
          const config = logicBlocks[get().type].options[property];

          return config.default;
        },
      };
    })
  );
};
