import { useSetupStore } from '../../store';
import { TemplateSelect } from '../template-select';
import { DomainSelect } from '../domain-select';
import { FileSelect } from '../file-select';
import { useStore } from '@/store';
import { useFetchOperatorIdByUserId, useDomainsbyOperatorId } from '@/services';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useNotify } from '@/hooks';
import { assertNotEmpty } from '@morph-mapper/utils';
import { useSchema } from '@morph-mapper/schemas';

export const useSetup = () => {
  /* Context state */
  const { error } = useNotify();
  const [setOperatorIdConfig, setDomainAccess, setUserId] = useStore(
    ({ config: c }) => [c.setOperatorId, c.setDomainAccess, c.setUserId]
  );
  const [step, setCurrentStep, setOperatorId] = useSetupStore((s) => [
    s.currentStep,
    s.setCurrentStep,
    s.setOperatorId,
  ]);
  const [opId, setOpId] = useState<number>();

  const { search } = useLocation();
  const { validateDomains } = useSchema();

  const params = new URLSearchParams(search);
  const userIdParam = params.get('userId') ?? undefined;

  const { data: operatorId } = useFetchOperatorIdByUserId(userIdParam);
  const { data: domains } = useDomainsbyOperatorId(operatorId?.toString());
  const { invalidDomains } = validateDomains(domains);

  /**
   * Handlers
   */
  const init = () => {
    sessionStorage.clear();

    if (userIdParam === undefined) {
      error('No userId provided in the URL');
    } else {
      setUserId(userIdParam);
    }
  };

  useEffect(() => {
    if (userIdParam === undefined) return;
    setUserId(userIdParam);
  }, [userIdParam]);

  useEffect(() => {
    if (!opId) return;
    if (!domains || domains.length === 0) return;

    assertNotEmpty(domains, 'No domains available for the current operator.');

    if (invalidDomains.length > 0) {
      error(`Invalid domains: ${invalidDomains.join(', ')}`);
    }

    setDomainAccess(domains);
  }, [domains]);

  useEffect(() => {
    if (operatorId === undefined) return;

    if (operatorId) {
      setOpId(operatorId);
      setOperatorId(operatorId);
      setOperatorIdConfig(operatorId);
    }
  }, [operatorId]);

  const handlePrevious = () => {
    setCurrentStep(step - 1);
  };

  const renderStep = () => {
    switch (step) {
      case 0:
        return DomainSelect;
      case 1:
        return TemplateSelect;
      case 2:
        return FileSelect;
      default:
        throw new Error('Invalid step');
    }
  };

  return {
    opId,
    init,
    step,
    handlePrevious,
    renderStep,
  };
};
