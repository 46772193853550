import { RowProvider, RowProviderProps } from "./context";
import { Row } from "./molecules";

export const VariableRow = (props: RowProviderProps) => {
  return (
    <RowProvider {...props}>
      <Row />
    </RowProvider>
  );
};
