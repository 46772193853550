import { Cancel, Complete, Edit, Reset } from "@morph-mapper/ui";
import { Group } from "@mantine/core";
import { StyledActionCell } from "./styles";
import { useRowContext } from "../../context";

export const ActionCell = () => {
  const [isEditing, cancelEdit, confirmEdit, onEdit, onClear, enableClear] =
    useRowContext((s) => [
      s.isEditing,
      s.cancelEdit,
      s.confirmEdit,
      s.onEdit,
      s.onClear,
      s.canClear(),
    ]);

  return (
    <StyledActionCell>
      <Group>
        {isEditing ? (
          <>
            <Complete onClick={confirmEdit} />
            <Cancel onClick={cancelEdit} />
          </>
        ) : (
          <>
            <Edit onClick={onEdit} />
            <Reset onClick={onClear} disabled={!enableClear} />
          </>
        )}
      </Group>
    </StyledActionCell>
  );
};
