import { Accordion, Box, Group, Table, TextInput } from "@mantine/core";
import { RenameFormLayout, StyledAccordionControl } from "./styles";
import { CategoryItemMenu } from "../category-item-menu";
import { useSetupStore } from "../../store";
import { useEffect, useState } from "react";
import { useTemplateAccordionItem } from "./use-template-accordion-item";
import { Cancel, Complete } from "@morph-mapper/ui";
import { match } from "ts-pattern";

interface TemplateAccordionItemProps {
  category: string;
  domain: string;
  children: React.ReactNode;
}

export const TemplateAccordionItem = ({
  category,
  domain,
  children,
}: TemplateAccordionItemProps) => {
  /* Global State */
  const [isRenameOpen, categoryName] = useSetupStore((s) => [
    s.isRenameCategoryOpen,
    s.categoryName,
  ]);

  /* Local State */
  const [newName, setNewCategoryName] = useState(category);

  const { handleRenameSave, handleRenameCancel, renameSaveDisabled } =
    useTemplateAccordionItem(category, newName);

  useEffect(() => {
    if (category !== categoryName) return;

    setNewCategoryName(category);
  }, [categoryName]);

  const handleName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewCategoryName(e.target.value);
  };

  const isRenameActive = isRenameOpen && category === categoryName;

  return (
    <Accordion.Item value={category}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {match(isRenameActive)
          .with(true, () => (
            <RenameFormLayout>
              <Group position="apart">
                <TextInput
                  type="text"
                  size="sm"
                  value={newName}
                  onChange={handleName}
                  autoFocus
                />
                <Group>
                  <Complete
                    onClick={handleRenameSave}
                    disabled={renameSaveDisabled}
                  />
                  <Cancel onClick={handleRenameCancel} />
                </Group>
              </Group>
            </RenameFormLayout>
          ))
          .with(false, () => (
            <>
              <StyledAccordionControl>
                <span>{category}</span>
              </StyledAccordionControl>

              {category !== "default" && (
                <Box sx={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
                  <CategoryItemMenu categoryName={category} domain={domain} />
                </Box>
              )}
            </>
          ))
          .exhaustive()}
      </Box>

      <Accordion.Panel>
        <Table>
          <thead>
            <tr>
              <th>Enabled</th>
              <th>Template</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{children}</tbody>
        </Table>
      </Accordion.Panel>
    </Accordion.Item>
  );
};
