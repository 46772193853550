import { TextInput } from '@mantine/core';
import { useStore } from '../../context';

export const TextSelectionView = () => {
  // TODO: Misuse of path store, this is essentially a different type of reference (direct text reference).
  // Refactor to use a separate store.
  const referenceValues = useStore(({ path: p }) => [p.getPathValues()]);

  return (
    <TextInput
      disabled
      value={referenceValues.join(' ')}
      style={{
        flex: 1,
        marginLeft: '1.5rem',
      }}
      styles={{
        input: {
          ':disabled': {
            fontWeight: 500,
            color: 'black',
          },
        },
      }}
    />
  );
};
