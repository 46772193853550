import { type SchemaVariant } from '@morph-mapper/types';
import { useLogicBlocks } from './use-logic-blocks';
import { BlockRule, type LogicBlockIndex } from '../types';

/**
 * This hook provides an external interface to the rules of a logic block.
 * It is used to conditionally render UI elements based on the rules of a logic block.
 */
export const useRules = (variant: SchemaVariant) => {
  const { logicBlocks } = useLogicBlocks(variant);

  const getRules = (type: LogicBlockIndex) => {
    return logicBlocks[type].rules;
  };

  const hasNoChildren = (type: LogicBlockIndex) =>
    getRules(type).has(BlockRule.NoChildren);

  const hasSingleChild = (type: LogicBlockIndex) =>
    getRules(type).has(BlockRule.SingleChild);

  const hasMultipleChildren = (type: LogicBlockIndex) =>
    getRules(type).has(BlockRule.MultipleChildren);

  const isConfigurable = (type: LogicBlockIndex) =>
    getRules(type).has(BlockRule.Configurable);

  const hasMultipleElements = (type: LogicBlockIndex) =>
    getRules(type).has(BlockRule.MultipleElement);

  return {
    hasNoChildren,
    hasSingleChild,
    hasMultipleChildren,
    isConfigurable,
    hasMultipleElements,
  };
};
