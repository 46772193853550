import styled from "@emotion/styled";

interface EdgeIconWrapperProps {
  labelX: number;
  labelY: number;
}

export const EdgeIconWrapper = styled.div<EdgeIconWrapperProps>(
  ({ labelX, labelY }) => ({
    position: "absolute",
    transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
    pointerEvents: "all",
  })
);
