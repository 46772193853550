import styled from "@emotion/styled";
import { ScrollArea } from "@mantine/core";

export const Container = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: "2rem",
});

export const ContentWrapper = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
});

export const StyledScrollArea = styled(ScrollArea)(({ theme }) => ({
  border: `1px solid ${theme.colors.gray[3]}`,
  backgroundColor: "white",
  borderRadius: theme.radius.sm,
  overflowY: "scroll",
}));
