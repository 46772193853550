import { LogicBlockIndex, useLogicBlocks } from '@morph-mapper/node-logic';
import { initialEdges } from '../components/graph-editor/config';
import { Edge, Node } from '@/types';
import { parseDefaultOptions } from '@/utils';
import { useStore } from '@/store';
import { HandleType } from '@morph-mapper/types';

export const useCreatePredefinedGraph = <K extends LogicBlockIndex>(
  type: K
) => {
  const variant = useStore(({ config: c }) => c.getVariant());
  const { logicBlocks } = useLogicBlocks(variant);
  const options = logicBlocks[type].options;

  const createIteratorGraph = (): [
    Record<string, Node>,
    Record<string, Edge>
  ] => {
    const nodes = {
      seed: {
        id: 'seed',
        position: { x: 0, y: 400 },
        data: {
          graph: {
            dependencies: {},
            handles: {
              source: [],
              target: [],
            },
          },
          logic: {
            //@ts-expect-error Need to retype the options optionality
            options: parseDefaultOptions(options),
          },
        },
        type,
      },
      root: {
        id: 'root',
        position: { x: 0, y: 0 },
        data: {
          graph: {
            dependencies: {},
            handles: {
              source: [
                {
                  id: '1',
                  type: HandleType.Template,
                },
              ],
              target: [],
            },
          },
          logic: {
            options: {},
          },
        },
        type: 'context' as LogicBlockIndex,
      },
    };
    const edges = initialEdges;

    return [nodes, edges];
  };

  return { createIteratorGraph };
};
