import { useStore } from '@/store';
import _ from 'lodash';
import { EntryType, SearchPathResult } from '@morph-mapper/types';
import { match, P } from 'ts-pattern';

enum SearchMode {
  Paths = 'paths',
  Variables = 'variables',
}

export enum SearchTarget {
  Data = 'data',
  DeclareVariable = 'declareVariable',
}

export type SearchVariableResult = {
  id: string;
  path: string[];
  source: SearchTarget;
};
type Pointer = { id: string; path: string[] };

// TODO: we need to support paths with an index access ex. containers.0.container...
export const useContext = (entryId: string) => {
  const [IEntry] = useStore(({ entries: e }) => [e.entry]);

  const verifyIsPath = (value: unknown) => {
    // We do not support complex objects as paths (limitation). Furthermore, we do not support variables which may reference a path.
    // The latter option is not supported as the variable is in the context of the entry,
    // which the algorithm will find later and thus make the path available without requiring variable fetching logic. (is this true)

    // TODO: implement recursive fetching of variables
    return typeof value === 'string' && value.startsWith('$inputdocument');
  };

  const searchChildrenWithProcessing = (
    entryId: string,
    target: SearchTarget,
    processEntry: (entryId: string, type: EntryType) => Pointer[],
    shouldInclude: (entryId: string) => boolean
  ): Pointer[] => {
    const results: Pointer[] = [];

    IEntry(entryId, EntryType.Map)
      .children()
      .getIds()
      .forEach((id) => {
        const entry = IEntry(id);
        const type = entry.getType();

        if (
          type === EntryType.Map &&
          target === SearchTarget.Data &&
          shouldInclude(id)
        ) {
          results.push(
            ...searchChildrenWithProcessing(
              id,
              target,
              processEntry,
              shouldInclude
            )
          );
        } else if (shouldInclude(id)) {
          results.push(...processEntry(id, type));
        }
      });

    // If we are in data mode we want to add the current reference of the path.
    return results.map(({ id, path }) => ({
      id,
      path: [entryId, ...path],
    }));
  };

  const processEntryForPaths = (
    entryId: string,
    type: EntryType
  ): Pointer[] => {
    // FIXME: We need to handle the case here that data may not be a map but some other type.

    if (type !== EntryType.Map) {
      const value = IEntry(entryId).getTemplateValue();

      if (verifyIsPath(value)) {
        return [{ id: entryId, path: [] }];
      }
    }

    return [];
  };

  const processEntryForVariables = (entryId: string): Pointer[] => {
    return [{ id: entryId, path: [] }];
  };

  const shouldInclude = (id: string) => {
    const entry = IEntry(id);
    // We do not want to include the current entry in the search results,
    // otherwise a circular reference would be created.
    if (id === entryId) return false;

    // TODO: we probably should not match on the key but also using outputtype
    return match(entry.getKey())
      .with(P.string.regex(/^(#|\$(?!data$)).*/), () => false)
      .otherwise(() => true);
  };

  const search = (
    entryId: string,
    target: SearchTarget,
    mode: SearchMode,
    shouldInclude: (entryId: string) => boolean
  ): SearchVariableResult[] => {
    const entry = IEntry(entryId);
    const type = entry.getType();
    let result: Pointer[] = [];

    const processEntry = match(mode)
      .with(SearchMode.Paths, () => processEntryForPaths)
      .with(SearchMode.Variables, () => processEntryForVariables)
      .exhaustive();

    if (type === EntryType.Map) {
      result = searchChildrenWithProcessing(
        entryId,
        target,
        processEntry,
        shouldInclude
      );
    } else {
      result = processEntry(entryId, type);
    }

    return result.map((pointer) => ({
      ...pointer,
      source: target,
    }));
  };

  const findEntryIds = (entryId: string, mode: SearchMode) => {
    const results: SearchVariableResult[] = [];

    let current = IEntry(entryId);
    let parent = current.getParent();
    if (parent === undefined) return results;

    // We only search for the closest $data object.
    let foundData = false;

    while (parent !== undefined) {
      parent
        .children()
        .getEntries()
        .forEach(([id, name]) => {
          // We assume there are no duplicates within $declareVariable
          // TODO: what if the parent is the target that we want to find? ($declareVariable, --> $data <--)
          // TODO: data is also not available from within data (same as above)
          // TODO: no hardcoded values, we also should have one representation for special keys

          // $data is always in scope, except if we are coming from a $declareVariable on the same level as $data.
          // We furthermore assume that when a $data object is found, there are no non-function keys on the same level.
          // TODO: Fix possible undefined, this cannot actually happen but is a limitation in the typing of the current implementation.
          if (
            foundData === false &&
            ['data', '$data'].includes(name) &&
            !['$declareVariable'].includes(current.getName() || '')
          ) {
            foundData = true;
            results.push(...search(id, SearchTarget.Data, mode, shouldInclude));
          }

          // $declareVariable is always in scope when found in levels equal or higher than the current level.
          if (['$declareVariable'].includes(name)) {
            results.push(
              ...search(id, SearchTarget.DeclareVariable, mode, shouldInclude)
            );
          }
        });

      current = parent;
      parent = current.getParent();
    }

    return results;
  };

  const findRelativePaths = (): SearchPathResult[] => {
    return findEntryIds(entryId, SearchMode.Paths).map(({ id, path }) => {
      const pointer = path
        .map((id) => IEntry(id).getKey())
        .filter(filterCompositeFunctions)
        .join('.');
      const reference = `${pointer}.${IEntry(id).getKey()}`.replace(
        /\$declareVariable\.?/,
        '$'
      );

      // TODO: we should recursively resolve the path to its actual value without references
      const value = IEntry(id).getTemplateValue();

      return {
        reference,
        value,
      };
    });
  };

  const filterCompositeFunctions = (key: string, index: number) => {
    if (index === 0) return true;
    return !key.startsWith('$');
  };

  const findVariables = () => {
    return findEntryIds(entryId, SearchMode.Variables).map(
      ({ id, path, source }) => {
        const pointer = path
          .map((id) => IEntry(id).getKey())
          .filter(filterCompositeFunctions)
          .join('.');
        const reference = `${pointer}.${IEntry(id).getKey()}`.replace(
          /\$declareVariable\.?/,
          '$'
        );

        return {
          label: IEntry(id).getName() ?? '',
          value: reference,
          source,
        };
      }
    );
  };

  return { findRelativePaths, findVariables };
};
