import { EntryType, ReservedNodeType } from '@morph-mapper/types';
import { Schema } from '../../../types';
import { z } from 'zod';
import { Input } from '@morph-mapper/node-inputs';
import { mergingTypeSchema } from '../../shared';

export const retailEmailSchema: Schema = {
  updateTemplateDiscardFields: [
    '_id',
    'id',
    'timestampedid',
    'hiddenreference',
    'dateofentry',
    'commissioner',
    'commissionerid',
    'clientidentifierraw',
    'clientidentifier',
  ],
  config: {
    _updateConfig: {
      title: 'Update Template Settings',
      description: '',
      childConfigurations: [
        '_containerMergingConfig',
        '_containerSortDefault',
        '_containerFieldsConfig',
        '_skipDiffsConfig',
      ],
      options: {
        mergingType: {
          type: z.boolean(),
          title: 'Update Template',
          description: 'Check if this is an update to an existing template.',
          input: Input.Checkbox,
          default: '',
        },
      },
      mutate: (options: any) => {
        const { mergingType } = options;

        if (mergingType) {
          return 'extend';
        }
        return 'createIfNotExist';
      },
    },
    _containerMergingConfig: {
      title: '',
      description: '',
      options: {
        containerMerging: {
          type: z.string(),
          title: 'Container Merging',
          description:
            'Select whether single or all containers are being updated.',
          input: Input.SingleSelect,
          selection: [
            { label: 'Single', value: 'single' },
            { label: 'All Update', value: 'allUpdate' },
          ],
          default: 'allUpdate',
        },
      },
      conditions: {
        _updateConfig: {
          mergingType: {
            hasValue: true,
          },
        },
      },
      mutate: (options: any) => {
        const { containerMerging } = options;

        return containerMerging;
      },
    },
    _containerFieldsConfig: {
      title: '',
      description: '',
      options: {
        containerFields: {
          type: z.array(z.string()),
          title: 'Container Fields',
          description: 'Select zero or more container fields to be updated.',
          input: Input.MultiSelect,
          placeholder: 'Select container fields',
          selection: [
            { label: 'import', value: 'import' },
            { label: 'container', value: 'container' },
          ],
          default: [],
        },
      },
      conditions: {
        _updateConfig: {
          mergingType: {
            hasValue: true,
          },
        },
      },
      mutate: (options: any) => {
        const { containerFields } = options;

        return containerFields;
      },
    },
    _skipDiffsConfig: {
      title: '',
      description: '',
      options: {
        skipDiffs: {
          type: z.array(z.string()),
          title: 'Skip Fields',
          description: 'Select fields/items to be skipped.',
          input: Input.MultiSelect,
          placeholder: 'Select fields to ignore',
          selection: [
            { label: 'field 1', value: 'field 1' },
            { label: 'field2', value: 'field2' },
          ],
          default: [],
        },
      },
      conditions: {
        _updateConfig: {
          mergingType: {
            hasValue: true,
          },
        },
      },
      mutate: (options: any) => {
        const { skipDiffs } = options;

        if (skipDiffs) return { ignoreitems: skipDiffs };

        return undefined;
      },
    },
    _containerSortDefault: {
      title: '',
      description: '',
      hidden: true,
      options: {
        sort: {
          type: z.boolean(),
          title: 'Sort Confitional Default (Hidden)',
          description: '',
          input: Input.Checkbox,
          default: true,
        },
      },
      conditions: {
        _updateConfig: {
          mergingType: {
            hasValue: true,
          },
        },
      },
      mutate: (options: any) => {
        const { sort } = options;

        if (sort) return { dateofentry: -1 };
        return undefined;
      },
    },
    _autosubmitConfig: {
      title: 'Submission Settings',
      description: '',
      options: {
        autosubmit: {
          type: z.boolean(),
          title: 'Autosubmit Template',
          description:
            'Check if the template is ready and can be autosubmitted by default.',
          input: Input.Checkbox,
          default: false,
        },
      },
      mutate: (options: any) => {
        const { autosubmit } = options;

        if (!autosubmit) {
          return;
        }

        return {
          url: 'http://localhost:8080/InSight/TriggerReader',
          template: {
            semantics: 'product.ordermanagement.OrderAutoSubmitREST',
            selector: '$selector',
            stage: 'autosubmit',
          },
          skipIfSent: true,
        };
      },
    },
  },
  definition: {
    values: {
      type: {
        validation: z.literal('eml'),
        type: EntryType.Internal,
      },
      checking: {
        validation: z.boolean(),
        type: EntryType.Graph,
      },
      template: {
        values: {
          [ReservedNodeType.DeclareVariable]: {
            values: {
              clientidentifier: {
                validation: z.string(),
                displayName: 'Klant naam',
                type: EntryType.Internal,
                prefill: ({ name }) => `Debtor ${name}`,
              },
              clientreference: {
                validation: z.string(),
                displayName: 'Klant referentie',
                type: EntryType.Graph,
              },
              invoicereference: {
                validation: z.literal('$clientreference'),
                type: EntryType.Internal,
              },
              operatorid: {
                validation: z.string(),
                type: EntryType.Internal,
                prefill: ({ operatorId }) => operatorId,
              },
              loadingaddressraw: {
                validation: z.string(),
                displayName: 'Laad adres',
                type: EntryType.Graph,
              },
              loadingdatereadable: {
                validation: z.string(),
                displayName: 'Laad datum',
                type: EntryType.Graph,
              },
              loadingdateformat: {
                validation: z.enum(['DD-MM-YYYY', 'DD-MM-YY', 'DD-MM', 'DDD']),
                displayName: 'Laad datum formaat',
                type: EntryType.Simple,
              },
              loadingdate: {
                validation: z.literal(
                  JSON.stringify({
                    '$time.stamp': [
                      [['$loadingdatereadable']],
                      '$loadingdateformat',
                    ],
                  })
                ),
                type: EntryType.Internal,
              },
              loadingtimereadable: {
                validation: z.string(),
                displayName: 'Laad tijd',
                type: EntryType.Graph,
              },
              loadingtime: {
                validation: z.literal(6120000),
                type: EntryType.Internal,
              },
              loadingreference: {
                validation: z.string(),
                displayName: 'Laad referentie',
                type: EntryType.Graph,
              },
              dischargeaddressraw: {
                validation: z.string(),
                displayName: 'Los adres',
                type: EntryType.Graph,
              },
              dischargedatereadable: {
                validation: z.string(),
                displayName: 'Los datum',
                type: EntryType.Graph,
              },
              dischargedateformat: {
                validation: z.enum(['DD-MM-YYYY', 'DD-MM-YY', 'DD-MM', 'DDD']),
                displayName: 'Los datum formaat',
                type: EntryType.Simple,
              },
              dischargedate: {
                validation: z.literal(
                  JSON.stringify({
                    '$time.stamp': [
                      [['$dischargedatereadable']],
                      '$dischargedateformat',
                    ],
                  })
                ),
                type: EntryType.Internal,
              },
              dischargetimereadable: {
                validation: z.string(),
                displayName: 'Los tijd',
                type: EntryType.Graph,
              },
              dischargetime: {
                validation: z.literal(46800000),
                type: EntryType.Internal,
              },
              dischargereference: {
                validation: z.string(),
                displayName: 'Los referentie',
                type: EntryType.Graph,
              },
              quantity: {
                validation: z.number(),
                displayName: 'Aantal pallets',
                type: EntryType.Graph,
              },
              package: {
                validation: z.string(),
                displayName: 'Pallet type',
                type: EntryType.Graph,
              },
              cargodescription: {
                validation: z.string(),
                displayName: 'Lading omschrijving',
                type: EntryType.Graph,
              },
              selector: {
                validation: z.literal(
                  JSON.stringify({
                    [ReservedNodeType.DeclareVariable]: {
                      PARSE_SETTINGS: {
                        noResultIfUndefined: true,
                        noDollarKeys: false,
                        noDollarStrings: true,
                        templateKeys: true,
                      },
                    },
                    $and: [
                      {
                        operatorid: '$operatorid',
                        bookingreference: [
                          [
                            {
                              $mongoRequest: [
                                {
                                  '$db.ordermanagement_conversion.findOne': {
                                    crefcode: '$dischargeaddressraw',
                                  },
                                  $result: {
                                    $getVariable: 'orefcode',
                                  },
                                },
                                'morphjs',
                                ['ordermanagement_conversion'],
                              ],
                            },
                            '|',
                            {
                              $mongoRequest: [
                                {
                                  '$db.ordermanagement_conversion.findOne': {
                                    crefcode: '$loadingaddressraw',
                                  },
                                  $result: {
                                    $getVariable: 'orefcode',
                                  },
                                },
                                'morphjs',
                                ['ordermanagement_conversion'],
                              ],
                            },
                            '_',
                            '$loadingdate',
                          ],
                        ],
                      },
                      {
                        $or: [
                          {
                            invoicereference: '$invoicereference',
                          },
                          {
                            invoicereference: '',
                          },
                        ],
                      },
                    ],
                  })
                ),
                type: EntryType.Internal,
              },
            },
          },
          autosubmit: {
            validation: z.any(),
            type: EntryType.Internal,
            configKey: '_autosubmitConfig',
          },
          mergingType: {
            validation: mergingTypeSchema,
            type: EntryType.Internal,
            configKey: '_updateConfig',
          },
          containerMerging: {
            validation: z.string(),
            type: EntryType.Internal,
            configKey: '_containerMergingConfig',
          },
          sort: {
            validation: z.literal(JSON.stringify({ dateofentry: -1 })),
            type: EntryType.Internal,
            configKey: '_containerSortDefault',
          },
          containerFields: {
            validation: z.array(z.string()),
            type: EntryType.Internal,
            configKey: '_containerFieldsConfig',
          },
          skipDiff: {
            validation: z.literal(
              JSON.stringify({ ignoreitems: z.array(z.string()) })
            ),
            type: EntryType.Internal,
            configKey: '_skipDiffsConfig',
          },
          selector: {
            validation: z.literal('$selector'),
            type: EntryType.Internal,
          },
          doc: {
            values: {
              operatorid: {
                validation: z.literal('$operatorid'),
                type: EntryType.Internal,
              },
              templatetype: {
                validation: z.literal('order'),
                type: EntryType.Internal,
              },
              source: {
                validation: z.literal('$clientidentifier'),
                type: EntryType.Internal,
              },
              _id: {
                validation: z.literal(
                  JSON.stringify([
                    ['order_', '$clientidentifier', '_', '$clientreference'],
                  ])
                ),
                type: EntryType.Internal,
              },
              id: {
                validation: z.literal(
                  JSON.stringify([
                    ['$clientidentifier', '_', '$clientreference'],
                  ])
                ),
                type: EntryType.Internal,
              },
              timestampedid: {
                validation: z.literal(
                  JSON.stringify([
                    [
                      '$clientidentifier',
                      '_',
                      '$clientreference',
                      '_',
                      '#currenttimestamp',
                    ],
                  ])
                ),
                type: EntryType.Internal,
              },
              hiddenreference: {
                validation: z.literal(
                  JSON.stringify([
                    ['$clientidentifier', '_', '$clientreference'],
                  ])
                ),
                type: EntryType.Internal,
              },
              status: {
                validation: z.literal('create'),
                type: EntryType.Internal,
              },
              workload: {
                validation: z.literal('digest'),
                type: EntryType.Internal,
              },
              dateofentry: {
                validation: z.literal(JSON.stringify({ '$time.stamp': [] })),
                type: EntryType.Internal,
              },
              lastupdate: {
                validation: z.literal(JSON.stringify({ '$time.stamp': [] })),
                type: EntryType.Internal,
              },
              bookingtyperaw: {
                validation: z.literal(
                  JSON.stringify([
                    ['$clientidentifier', '_', '$dischargeaddressraw'],
                  ])
                ),
                displayName: 'Afdeling',
                type: EntryType.Internal,
              },
              ordersession: {
                validation: z.literal('orderexport'),
                type: EntryType.Internal,
              },
              type: {
                validation: z.literal('Export'),
                type: EntryType.Internal,
              },
              bookdate: {
                validation: z.literal(JSON.stringify({ '$time.stamp': [] })),
                type: EntryType.Internal,
              },
              fileId: {
                validation: z.literal('$FILEID'),
                type: EntryType.Internal,
              },
              genericorigin: {
                validation: z.literal('$genericOrigin'),
                type: EntryType.Internal,
              },
              genericTemplateName: {
                validation: z.string(),
                type: EntryType.Graph,
              },
              commissioner: {
                validation: z.literal('$clientidentifier'),
                type: EntryType.Internal,
              },
              commissionerid: {
                validation: z.literal('$clientidentifier'),
                type: EntryType.Internal,
              },
              clientidentifierraw: {
                validation: z.literal('$clientidentifier'),
                type: EntryType.Internal,
              },
              clientidentifier: {
                validation: z.literal(
                  JSON.stringify({
                    $mongoRequest: [
                      {
                        '$db.ordermanagement_conversion.findOne': {
                          crefcode: '$clientidentifier',
                          key: 'clientidentifier',
                        },
                        $result: {
                          $getVariable: 'orefcode',
                        },
                      },
                      'morphjs',
                      ['ordermanagement_conversion'],
                    ],
                  })
                ),
                type: EntryType.Internal,
              },
              debtor: {
                validation: z.literal(
                  JSON.stringify({
                    $mongoRequest: [
                      {
                        '$db.ordermanagement_conversion.findOne': {
                          crefcode: '$clientidentifier',
                          key: 'clientidentifier',
                        },
                        $result: {
                          $getVariable: 'orefcode',
                        },
                      },
                      'morphjs',
                      ['ordermanagement_conversion'],
                    ],
                  })
                ),
                type: EntryType.Internal,
              },
              bookingreference: {
                validation: z.literal(
                  JSON.stringify([
                    [
                      {
                        $mongoRequest: [
                          {
                            '$db.ordermanagement_conversion.findOne': {
                              crefcode: '$dischargeaddressraw',
                            },
                            $result: {
                              $getVariable: 'orefcode',
                            },
                          },
                          'morphjs',
                          ['ordermanagement_conversion'],
                        ],
                      },
                      '|',
                      {
                        $mongoRequest: [
                          {
                            '$db.ordermanagement_conversion.findOne': {
                              crefcode: '$loadingaddressraw',
                            },
                            $result: {
                              $getVariable: 'orefcode',
                            },
                          },
                          'morphjs',
                          ['ordermanagement_conversion'],
                        ],
                      },
                      '_',
                      '$loadingdate',
                    ],
                  ])
                ),
                type: EntryType.Internal,
              },
              department: {
                validation: z.literal(
                  JSON.stringify([
                    ['$clientidentifier', '-', '$dischargeaddressraw'],
                  ])
                ),
                displayName: 'Order soort',
                type: EntryType.Internal,
              },
              leg: {
                validation: z.literal(
                  JSON.stringify([
                    [
                      {
                        $mongoRequest: [
                          {
                            '$db.feature.findOne': {
                              templatetype: 'feature',
                              id: {
                                '$math.parseInt': {
                                  $mongoRequest: [
                                    {
                                      '$db.ordermanagement_conversion.findOne':
                                        {
                                          crefcode: '$loadingaddressraw',
                                        },
                                      $result: {
                                        $getVariable: 'orefcode',
                                      },
                                    },
                                    'morphjs',
                                    ['ordermanagement_conversion'],
                                  ],
                                },
                              },
                            },
                            $result: {
                              $getVariable: 'name',
                            },
                          },
                          'morphjs',
                          ['feature'],
                        ],
                      },
                      ' ',
                      {
                        $mongoRequest: [
                          {
                            '$db.feature.findOne': {
                              templatetype: 'feature',
                              id: {
                                '$math.parseInt': {
                                  $mongoRequest: [
                                    {
                                      '$db.ordermanagement_conversion.findOne':
                                        {
                                          crefcode: '$loadingaddressraw',
                                        },
                                      $result: {
                                        $getVariable: 'orefcode',
                                      },
                                    },
                                    'morphjs',
                                    ['ordermanagement_conversion'],
                                  ],
                                },
                              },
                            },
                            $result: {
                              $getVariable: 'city',
                            },
                          },
                          'morphjs',
                          ['feature'],
                        ],
                      },
                      ' - ',
                      {
                        $mongoRequest: [
                          {
                            '$db.feature.findOne': {
                              templatetype: 'feature',
                              id: {
                                '$math.parseInt': {
                                  $mongoRequest: [
                                    {
                                      '$db.ordermanagement_conversion.findOne':
                                        {
                                          crefcode: '$dischargeaddressraw',
                                        },
                                      $result: {
                                        $getVariable: 'orefcode',
                                      },
                                    },
                                    'morphjs',
                                    ['ordermanagement_conversion'],
                                  ],
                                },
                              },
                            },
                            $result: {
                              $getVariable: 'city',
                            },
                          },
                          'morphjs',
                          ['feature'],
                        ],
                      },
                    ],
                  ])
                ),
                type: EntryType.Internal,
              },
              clientreference: {
                validation: z.literal('$clientreference'),
                displayName: 'Klant referentie',
                type: EntryType.Internal,
              },
              shipmentreference: {
                validation: z.literal('$clientreference'),
                type: EntryType.Internal,
              },
              invoicereference: {
                validation: z.literal('$clientreference'),
                type: EntryType.Internal,
              },
              loadingdatereadable: {
                validation: z.literal('$loadingdatereadable'),
                type: EntryType.Internal,
              },
              loadingdate: {
                validation: z.literal('$loadingdate'),
                type: EntryType.Internal,
              },
              dischargedate: {
                validation: z.literal('$dischargedate'),
                type: EntryType.Internal,
              },
              startdatereadable: {
                validation: z.literal('$loadingdatereadable'),
                type: EntryType.Internal,
              },
              startdate: {
                validation: z.literal('$loadingdate'),
                type: EntryType.Internal,
              },
              starttimeroutereadable: {
                validation: z.literal('$loadingtime'),
                type: EntryType.Internal,
              },
              starttimeroute: {
                validation: z.literal(
                  JSON.stringify({
                    '$math.plus': [
                      {
                        '$math.multiply': [5, 3600000],
                      },
                      {
                        '$math.multiply': [0, 60000],
                      },
                    ],
                  })
                ),
                type: EntryType.Internal,
              },
              endtimeroutereadable: {
                validation: z.literal('$dischargetimereadable'),
                type: EntryType.Internal,
              },
              endtimeroute: {
                validation: z.literal(
                  JSON.stringify({
                    '$math.plus': [
                      {
                        '$math.multiply': [22, 3600000],
                      },
                      {
                        '$math.multiply': [0, 60000],
                      },
                    ],
                  })
                ),
                type: EntryType.Internal,
              },
              totaldistance: {
                validation: z.number(),
                type: EntryType.Internal,
              },
              totaltimereadable: {
                validation: z.string(),
                type: EntryType.Internal,
              },
              totaltime: {
                validation: z.number(),
                type: EntryType.Internal,
              },
              totalquantity: {
                validation: z.literal('$quantity'),
                type: EntryType.Internal,
              },
              vehicletype: {
                validation: z.string(),
                type: EntryType.Internal,
              },
              servicelevel: {
                validation: z.string(),
                type: EntryType.Internal,
              },
              incoterms: {
                validation: z.string(),
                type: EntryType.Internal,
              },
              containers: {
                outputType: 'array',
                values: {
                  container: {
                    validation: z.string(),
                    type: EntryType.Internal,
                  },
                  containerid: {
                    validation: z.string(),
                    type: EntryType.Internal,
                  },
                  packagetyperaw: {
                    validation: z.literal(
                      JSON.stringify([
                        [
                          'PackageType',
                          '$clientidentifier',
                          '-',
                          [['$package']],
                        ],
                      ])
                    ),
                    type: EntryType.Internal,
                  },
                  packagetype: {
                    validation: z.literal(
                      JSON.stringify({
                        $mongoRequest: [
                          {
                            '$db.ordermanagement_conversion.findOne': {
                              crefcode: [
                                [
                                  'PackageType',
                                  '$clientidentifier',
                                  '-',
                                  [['$package']],
                                ],
                              ],
                              key: 'packagetype',
                            },
                            $result: {
                              $getVariable: 'orefcode',
                            },
                          },
                          'morphjs',
                          ['ordermanagement_conversion'],
                        ],
                      })
                    ),
                    type: EntryType.Internal,
                  },
                  producttyperaw: {
                    validation: z.literal(
                      JSON.stringify([
                        [
                          'ProductType',
                          '$clientidentifier',
                          '-',
                          '$clientidentifier',
                        ],
                      ])
                    ),
                    type: EntryType.Internal,
                  },
                  producttype: {
                    validation: z.literal('GOO'),
                    type: EntryType.Internal,
                  },
                  weight: {
                    validation: z.number(),
                    displayName: 'Gewicht',
                    type: EntryType.Graph,
                  },
                  quantity: {
                    validation: z.literal('$quantity'),
                    displayName: 'Aantal pallet plaatsen',
                    type: EntryType.Internal,
                  },
                  actualquantity: {
                    validation: z.literal('$quantity'),
                    displayName: 'Aantal pallets',
                    type: EntryType.Internal,
                  },
                  actualquantityconverted: {
                    validation: z.literal('$quantity'),
                    type: EntryType.Internal,
                  },
                  length: {
                    validation: z.literal(0),
                    type: EntryType.Internal,
                  },
                  volume: {
                    validation: z.literal(0),
                    type: EntryType.Internal,
                  },
                  height: {
                    validation: z.literal(0),
                    type: EntryType.Internal,
                  },
                  ftl: {
                    validation: z.literal(false),
                    type: EntryType.Internal,
                  },
                  emb: {
                    validation: z.literal(false),
                    type: EntryType.Internal,
                  },
                  cargodescription: {
                    validation: z.literal('$cargodescription'),
                    type: EntryType.Internal,
                  },
                  loading: {
                    values: {
                      addresses: {
                        outputType: 'array',
                        values: {
                          _addressid: {
                            validation: z.literal(0),
                            type: EntryType.Internal,
                          },
                          loadingaddressraw: {
                            validation: z.literal('$loadingaddressraw'),
                            type: EntryType.Internal,
                          },
                          loadingaddress: {
                            validation: z.literal(
                              JSON.stringify({
                                $mongoRequest: [
                                  {
                                    '$db.ordermanagement_conversion.findOne': {
                                      crefcode: '$loadingaddressraw',
                                      key: 'loadingaddress',
                                    },
                                    $result: {
                                      $getVariable: 'orefcode',
                                    },
                                  },
                                  'morphjs',
                                  ['ordermanagement_conversion'],
                                ],
                              })
                            ),
                            type: EntryType.Internal,
                          },
                          loadingaddressoriginalid: {
                            validation: z.literal(
                              JSON.stringify([
                                [
                                  {
                                    $mongoRequest: [
                                      {
                                        '$db.feature.findOne': {
                                          templatetype: 'feature',
                                          id: {
                                            '$math.multiply': [
                                              {
                                                $mongoRequest: [
                                                  {
                                                    '$db.ordermanagement_conversion.findOne':
                                                      {
                                                        crefcode:
                                                          '$loadingaddressraw',
                                                      },
                                                    $result: {
                                                      $getVariable: 'orefcode',
                                                    },
                                                  },
                                                  'morphjs',
                                                  [
                                                    'ordermanagement_conversion',
                                                  ],
                                                ],
                                              },
                                              1,
                                            ],
                                          },
                                        },
                                        $result: {
                                          $getVariable: 'idaddress',
                                        },
                                      },
                                      'morphjs',
                                      ['feature'],
                                    ],
                                  },
                                  '',
                                ],
                              ])
                            ),
                            type: EntryType.Internal,
                          },
                          loadingdriverinstructions: {
                            validation: z.string(),
                            type: EntryType.Graph,
                          },
                          loadingdate: {
                            validation: z.literal(
                              JSON.stringify({
                                '$math.plus': ['$loadingdate', '$loadingtime'],
                              })
                            ),
                            type: EntryType.Internal,
                          },
                          loadingdateend: {
                            validation: z.literal(
                              JSON.stringify({
                                '$math.plus': ['$loadingdate', '$loadingtime'],
                              })
                            ),
                            type: EntryType.Internal,
                          },
                          loadingreference: {
                            validation: z.literal('$loadingreference'),
                            type: EntryType.Internal,
                          },
                          loadingproduct: {
                            validation: z.literal('$cargodescription'),
                            type: EntryType.Internal,
                          },
                        },
                      },
                      addresscounter: {
                        validation: z.literal(1),
                        type: EntryType.Internal,
                      },
                    },
                  },
                  discharge: {
                    values: {
                      addresses: {
                        outputType: 'array',
                        values: {
                          _addressid: {
                            validation: z.literal(0),
                            type: EntryType.Internal,
                          },
                          dischargeaddressraw: {
                            validation: z.literal('$dischargeaddressraw'),
                            type: EntryType.Internal,
                          },
                          dischargeaddress: {
                            validation: z.literal(
                              JSON.stringify({
                                $mongoRequest: [
                                  {
                                    '$db.ordermanagement_conversion.findOne': {
                                      crefcode: '$dischargeaddressraw',
                                      key: 'dischargeaddress',
                                    },
                                    $result: {
                                      $getVariable: 'orefcode',
                                    },
                                  },
                                  'morphjs',
                                  ['ordermanagement_conversion'],
                                ],
                              })
                            ),
                            type: EntryType.Internal,
                          },
                          dischargeaddressoriginalid: {
                            validation: z.literal(
                              JSON.stringify([
                                [
                                  {
                                    $mongoRequest: [
                                      {
                                        '$db.feature.findOne': {
                                          templatetype: 'feature',
                                          id: {
                                            '$math.multiply': [
                                              {
                                                $mongoRequest: [
                                                  {
                                                    '$db.ordermanagement_conversion.findOne':
                                                      {
                                                        crefcode:
                                                          '$dischargeaddressraw',
                                                      },
                                                    $result: {
                                                      $getVariable: 'orefcode',
                                                    },
                                                  },
                                                  'morphjs',
                                                  [
                                                    'ordermanagement_conversion',
                                                  ],
                                                ],
                                              },
                                              1,
                                            ],
                                          },
                                        },
                                        $result: {
                                          $getVariable: 'idaddress',
                                        },
                                      },
                                      'morphjs',
                                      ['feature'],
                                    ],
                                  },
                                  '',
                                ],
                              ])
                            ),
                            type: EntryType.Internal,
                          },
                          dischargedriverinstructions: {
                            validation: z.string(),
                            type: EntryType.Graph,
                          },
                          dischargedate: {
                            validation: z.literal(
                              JSON.stringify({
                                '$math.plus': [
                                  '$dischargedate',
                                  '$dischargetime',
                                ],
                              })
                            ),
                            type: EntryType.Internal,
                          },
                          dischargedateend: {
                            validation: z.literal(
                              JSON.stringify({
                                '$math.plus': [
                                  '$dischargedate',
                                  '$dischargetime',
                                ],
                              })
                            ),
                            type: EntryType.Internal,
                          },
                          dischargereference: {
                            validation: z.literal('$loadingreference'),
                            type: EntryType.Internal,
                          },
                          dischargeproduct: {
                            validation: z.literal('$cargodescription'),
                            type: EntryType.Internal,
                          },
                        },
                      },
                      addresscounter: {
                        validation: z.literal(1),
                        type: EntryType.Internal,
                      },
                    },
                  },
                  _containerid: {
                    validation: z.literal(
                      JSON.stringify({
                        '$math.plus': [
                          {
                            '$math.parseInt': '$CONTAINERNR',
                          },
                          1,
                        ],
                      })
                    ),
                    type: EntryType.Internal,
                  },
                  containercounter: {
                    validation: z.literal(
                      JSON.stringify({
                        '$math.plus': [
                          {
                            '$math.parseInt': '$CONTAINERNR',
                          },
                          1,
                        ],
                      })
                    ),
                    type: EntryType.Internal,
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};
