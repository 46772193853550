import { Move, Options, Settings } from '@morph-mapper/ui';
import { StyledActionCell, StyledObjectRow } from './styles';
import { Group, Tooltip, useMantineTheme, Badge, Menu } from '@mantine/core';
import { useMapRow } from './use-map-row';
import { useMapEntryContext } from '../../context';
import { useStore } from '@/store';
import { useStructureBlocks } from '@morph-mapper/node-logic';
import { TbSettings } from 'react-icons/tb';
import { FiTrash2 } from 'react-icons/fi';

export const MapRow = () => {
  const variant = useStore(({ config: c }) => c.getVariant());
  const [name, outputType, onDelete] = useMapEntryContext((s) => [
    s.getName(),
    s.getOutputType(),
    s.delete,
  ]);
  const { handleConfigureEntry, handleFocusChange } = useMapRow();
  const { structureBlocks } = useStructureBlocks(variant);
  const theme = useMantineTheme();

  return (
    <StyledObjectRow>
      <td>{name}</td>
      <td>
        <Tooltip label={structureBlocks[outputType].description}>
          <Badge color="cyan" radius="xs" variant="outline" sx={{ opacity: 1 }}>
            {structureBlocks[outputType].title}
          </Badge>
        </Tooltip>
      </td>
      <td>
        <StyledActionCell>
          <Group>
            <Settings onClick={handleConfigureEntry} />
            <Move onClick={handleFocusChange} />
            <Menu shadow="md" width={150}>
              <Menu.Target>
                <Options />
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Label>Actions</Menu.Label>
                <Menu.Item icon={<TbSettings />} onClick={handleConfigureEntry}>
                  Configure
                </Menu.Item>
                <Menu.Item
                  icon={<FiTrash2 color={theme.colors.red[6]} />}
                  onClick={onDelete}
                >
                  Remove
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Group>
        </StyledActionCell>
      </td>
    </StyledObjectRow>
  );
};
