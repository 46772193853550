import { Box } from "@mantine/core";

interface ConfigInputProps {
  children: React.ReactNode;
}

export const ConfigInput = ({ children }: ConfigInputProps) => {
  return (
    <Box
      sx={{
        flexGrow: 1,
        maxWidth: 300,
        display: "flex",
        alignItems: "center",
      }}
    >
      {children}
    </Box>
  );
};
