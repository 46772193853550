import { TextInput as MantineTextInput } from '@mantine/core';
import type { InputProps } from '../../types';

export const TextInput = ({ value, handleChange }: InputProps<string>) => {
  const handleInputFormat = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(event.target.value);
  };

  return <MantineTextInput onChange={handleInputFormat} value={value} />;
};
