import { createLens } from '@dhmk/zustand-lens';
import { Extractor, Path, PathMode, Reference } from '../types';
import { StateCreator } from 'zustand';
import { getKeys, getValues, isNotNull } from '@morph-mapper/utils';
import { nanoid } from 'nanoid';
import { match } from 'ts-pattern';

//TODO: move
export type RelativePath = {
  reference: string;
  path: Path;
};

export interface PathProps {
  absolutePath: Path;
  activeReference: string | null;
  relativePaths: RelativePath[];
  relativePath: RelativePath | undefined;
  mode: PathMode;
}

export interface PathState extends PathProps {
  getAbsolutePath: () => Path;
  setAbsolutePath: (
    ref: Omit<Reference, 'next' | 'prev'>[],
    ids?: string[]
  ) => void;

  getReference: (id: string) => Reference;
  createReference: (ref: Omit<Reference, 'next' | 'prev'>, id?: string) => void;
  removeReference: (id: string) => void;
  setReferenceValue: (id: string, value: string) => void;
  setExtractor: (id: string, type: Extractor) => void;
  setValue: (id: string, value: string) => void;
  setOperation: (id: string, operation: string | number | null) => void;
  // TODO: Ids are not ordered, this will work most of the time but should be explicit.
  getReferenceIds: (mode: PathMode) => string[];
  // TODO: rename, its not just value but also operation, name is misleading
  getPathValues: () => string[];

  setActiveReference: (id: string) => void;
  getActiveReference: () => string;

  getRelativePaths: () => RelativePath[];
  setRelativePaths: (paths: RelativePath[]) => void;
  clearRelativePaths: () => void;

  getRelativePath: () => RelativePath;
  setRelativePath: (path: RelativePath) => void;

  getPathMode: () => PathMode;
  setPathMode: (mode: PathMode) => void;
}

// TODO: this has only been imlpemented for xml, but should be extended to other types
// (maybe abstract away how references are parsed in a util function or hook)
export const pathState: () => StateCreator<
  { path: PathState },
  [['zustand/immer', never]],
  [],
  PathState
> = () => (_set, _get) => {
  const [set, get] = createLens(_set, _get, ['path']);

  return {
    absolutePath: {},
    activeReference: null,
    relativePaths: [],
    relativePath: undefined,
    mode: PathMode.Absolute,

    getAbsolutePath: () => get().absolutePath,
    setAbsolutePath: (references, ids) => {
      set((s) => {
        s.absolutePath = references
          .map((ref, i) => ({
            ...ref,
            id: ids ? ids[i] : nanoid(),
          }))
          .reduce((acc, { id, ...obj }, index, arr) => {
            const next = index < arr.length - 1 ? arr[index + 1].id : null;
            const prev = index > 0 ? arr[index - 1].id : null;

            return {
              ...acc,
              [id]: {
                ...obj,
                next,
                prev,
              },
            };
          }, {});
      });
    },
    getReference: (id) => {
      const ref = get().absolutePath[id];

      if (!ref) {
        throw new Error(`Path with id ${id} not found.`);
      }

      return ref;
    },
    createReference: (reference, refId) => {
      set((s) => {
        const endRefId = getKeys(s.absolutePath).pop();
        const id = refId ?? nanoid();

        // Reference is the first element in the path.
        if (endRefId === undefined) {
          s.absolutePath[id] = {
            ...reference,
            next: null,
            prev: null,
          };

          return;
        } else {
          s.absolutePath[endRefId].next = id;

          s.absolutePath[id] = {
            ...reference,
            next: null,
            prev: endRefId,
          };
        }
      });
    },
    removeReference: (id) => {
      let elementId: string | null = id;

      set((s) => {
        while (elementId !== null) {
          const current = elementId;
          elementId = s.absolutePath[elementId].next;

          delete s.absolutePath[current];
        }
      });
    },
    setExtractor: (id, type) => {
      set((s) => {
        s.absolutePath[id].type = type;
      });
    },
    setValue: (id, value) => {
      set((s) => {
        s.absolutePath[id].value = value;
      });
    },
    setOperation: (id, operation) => {
      set((s) => {
        s.absolutePath[id].operation = operation;
      });
    },
    setReferenceValue: (id, value) => {
      set((s) => {
        s.absolutePath[id].value = value;
      });
    },
    getReferenceIds: (mode) => {
      return match(mode)
        .with(PathMode.Absolute, () => {
          return getKeys(get().absolutePath);
        })
        .with(PathMode.Relative, () => {
          const selected = get().relativePath;
          if (!selected) throw 'No relative path selected.';

          return getKeys(get().absolutePath).slice(
            getKeys(selected.path).length
          );
        })
        .exhaustive();
    },
    getPathValues: () => {
      return getValues(get().absolutePath)
        .map(({ value, operation }) =>
          operation ? `${value}.${operation}` : value
        )
        .filter(isNotNull);
    },
    setActiveReference: (id) => {
      set((s) => {
        s.activeReference = id;
      });
    },
    getActiveReference: () => {
      const id = get().activeReference;
      if (!id) throw new Error('No active reference.');

      return id;
    },
    getRelativePaths: () => get().relativePaths,
    setRelativePaths: (path) => {
      set((s) => {
        s.relativePaths = path;
      });
    },
    clearRelativePaths: () => {
      set((s) => {
        s.relativePaths = [];
      });
    },
    getRelativePath: () => {
      const path = get().relativePath;
      if (!path) throw new Error('No relative path selected.');

      return path;
    },
    setRelativePath: (path) => {
      set((s) => {
        s.relativePath = path;
      });
    },
    getPathMode: () => get().mode,
    setPathMode: (mode) => {
      set((s) => {
        s.mode = mode;
      });
    },
  };
};
