import {
  skipToken,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import {
  createCategory,
  createTemplateSave,
  deleteTemplateRef,
  fetchTemplateRefById,
  fetchTemplateSaveByPath,
  createTemplateRef,
  updateTemplateRef,
  updateTemplateSave,
  deleteCategory,
  updateCategory,
  fetchTemplateRefsByOperatorId,
  fetchCategoriesOfOperatorIdByDomain,
  deployTemplateSave,
  rawDeployTemplateSave,
} from './crud';
import {
  RawTemplateDeploy,
  TemplateCategory,
  TemplateDeploy,
  TemplateRef,
  TemplateSave,
} from '@morph-mapper/types';
import { reviveState } from '@morph-mapper/utils';

/**
 * Queries: Template Ref
 **/

// Creates a new template ref
export const useCreateTemplateRef = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (ref: Omit<TemplateRef, 'id' | 'path'>) =>
      createTemplateRef(ref),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['template-refs'] });
    },
  });
};

// Fetches a template ref by its id
export const useTemplateRef = (id: string | undefined) => {
  return useQuery({
    queryKey: ['template-ref', id],
    queryFn: id ? () => fetchTemplateRefById(id) : skipToken,
  });
};

// Fetches all template refs by operator id
export const useTemplateRefsOfOperatorId = (id: number | undefined) => {
  return useQuery({
    queryKey: ['template-refs', id],
    queryFn: id ? () => fetchTemplateRefsByOperatorId(id) : skipToken,
  });
};

// Deletes a template ref
export const useDeleteTemplateRef = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: string) => deleteTemplateRef(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['template-refs'] });
    },
  });
};

// Updates a template ref
export const useUpdateTemplateRef = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      id,
      template,
    }: {
      id: string;
      template: Partial<TemplateRef>;
    }) => updateTemplateRef(id, template),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['template-refs'] });
    },
  });
};

/**
 * Queries: Category
 **/

// TODO: quite a long name, consider refactoring for making it more generic
// Fetches all categories by operator id and current domain
export const useCategoriesOfOperatorIdByDomain = (
  operatorId: number | undefined,
  domain: string | undefined
) => {
  const isActive = operatorId !== undefined && domain !== undefined;

  return useQuery({
    queryKey: ['categories', operatorId, domain],
    queryFn: isActive
      ? () => fetchCategoriesOfOperatorIdByDomain(operatorId, domain)
      : skipToken,
  });
};

// Creates a new category
export const useCreateCategory = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (category: Omit<TemplateCategory, 'id'>) =>
      createCategory(category),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['categories'] });
    },
  });
};

// Deletes a category
export const useDeleteCategory = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: string) => deleteCategory(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['categories'] });
    },
  });
};

// Updates a category
export const useUpdateCategory = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      id,
      category,
    }: {
      id: string;
      category: Partial<TemplateCategory>;
    }) => updateCategory(id, category),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['categories'] });
    },
  });
};

/**
 * Queries: Template Save
 **/

// Creates a new template save (file storage)
export const useCreateTemplateSave = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (template: Omit<TemplateSave, 'id'> & { refId: string }) =>
      createTemplateSave(template),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['template-refs'] });
    },
  });
};

// Updates a template save (file storage)
export const useUpdateTemplateSave = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (
      template: Omit<TemplateSave, 'id'> & { refId: string; savePath: string }
    ) => updateTemplateSave(template),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: ['template-save', variables.savePath],
      });
      queryClient.invalidateQueries({ queryKey: ['template-refs'] });
      queryClient.refetchQueries({
        queryKey: ['template-save', variables.savePath],
      });
    },
  });
};

// Fetches a template save by its path (from file storage)
export const useTemplateSave = (path: string | null, active: boolean) => {
  const isActive = path !== undefined && path !== null && active;

  return useQuery({
    queryKey: ['template-save', path],
    queryFn: isActive ? () => fetchTemplateSaveByPath(path) : skipToken,
    select: (data) => reviveState(data),
  });
};

// Updates or creates a deployed template (if deployPath does not exist on template)
export const useUpdateDeployTemplateSave = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (template: Omit<TemplateDeploy, 'id'> & { refId: string }) =>
      deployTemplateSave(template),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['template-refs'] });
    },
  });
};

export const useRawDeployTemplateSave = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (
      template: Omit<RawTemplateDeploy, 'id'> & { deployPath: string }
    ) => rawDeployTemplateSave(template),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: ['template-save', variables.deployPath],
      });
      queryClient.refetchQueries({
        queryKey: ['template-save', variables.deployPath],
      });
    },
  });
};
