import { stringifyState } from '@morph-mapper/utils';
import api from './api';
import {
  RawTemplateDeploy,
  TemplateCategory,
  TemplateDeploy,
  TemplateRef,
  TemplateSave,
} from '@morph-mapper/types';

export const fetchTemplateRefs = async (): Promise<TemplateRef[]> => {
  const { data } = await api.get('/templates/references');
  return data;
};

export const fetchTemplateRefById = async (
  id: string
): Promise<TemplateRef> => {
  const { data } = await api.get(`/templates/references/${id}`);
  return data;
};

export const fetchTemplateRefsByOperatorId = async (
  opId: number
): Promise<TemplateRef[]> => {
  const { data } = await api.get(`/templates/references/operator/${opId}`);
  return data;
};

export const fetchCategoriesOfOperatorIdByDomain = async (
  operatorId: number,
  domain: string
): Promise<TemplateCategory[]> => {
  const { data } = await api.get(
    `/categories/operator/${operatorId}/${domain}`
  );
  return data;
};

export const createCategory = async (
  category: Omit<TemplateCategory, 'id'>
) => {
  const { data } = await api.post('/categories', category);
  return data;
};

export const deleteCategory = async (id: string) => {
  const { data } = await api.delete(`/categories/${id}`);
  return data;
};

export const updateCategory = async (
  id: string,
  ref: Partial<TemplateCategory>
) => {
  const { data } = await api.put(`/categories/${id}`, ref);
  return data;
};

// TODO: type express response
export const createTemplateRef = async (
  ref: Omit<TemplateRef, 'id' | 'path'>
): Promise<{ id: string }> => {
  const { data } = await api.post('/templates/references', ref);
  return data;
};

export const deleteTemplateRef = async (id: string) => {
  const { data } = await api.delete(`/templates/references/${id}`);
  return data;
};

export const updateTemplateRef = async (
  id: string,
  ref: Partial<TemplateRef>
) => {
  const { data } = await api.put(`/templates/references/${id}`, ref);
  return data;
};

export const createTemplateSave = async (
  template: Omit<TemplateSave, 'id'> & { refId: string }
) => {
  const { data } = await api.post(`/templates/saves`, stringifyState(template));
  return data;
};

export const updateTemplateSave = async (
  template: Omit<TemplateSave, 'id'> & { refId: string; savePath: string }
) => {
  const { data } = await api.post(
    `/templates/saves/update/${template.savePath}`,
    stringifyState(template)
  );
  return data;
};

export const fetchTemplateSaveByPath = async (path: string) => {
  const { data } = await api.get(`/templates/saves/${path}`);
  return data;
};

export const deployTemplateSave = async (
  template: Omit<TemplateDeploy, 'id'> & { refId: string }
) => {
  const { data } = await api.post(`/templates/saves/deploy`, template);
  return data;
};

export const rawDeployTemplateSave = async (
  template: Omit<RawTemplateDeploy, 'id'> & { deployPath: string }
) => {
  const { deployPath, content } = template;

  const { data } = await api.post(`/templates/saves/deploy/raw/${deployPath}`, {
    content,
  });

  return data;
};