import { useNavigate } from 'react-router-dom';
import { Button } from '@mantine/core';
import { useStore } from '@/store';
import { ErrorBoundary } from '@morph-mapper/ui';

export const GlobalErrorBoundary = () => {
  const userId = useStore(({ config: c }) => c.getUserId());
  const navigate = useNavigate();

  const handleHomePage = () => {
    navigate('/?userId=' + userId);
  };

  return (
    <ErrorBoundary
      message="We are working on fixing the issue. In the meantime, we recommend resetting the graph."
      buttons={[
        <Button color="blue" variant="filled" onClick={handleHomePage}>
          Go to Homepage
        </Button>,
      ]}
    />
  );
};
