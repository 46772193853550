import { useParams } from 'react-router-dom';
import { EntryProvider, NodeProvider } from '../../context';
import { ConfigView } from '../../organisms';

export const NodeConfigurator = () => {
  const { entryId } = useParams();
  if (!entryId) throw new Error('No active entry');

  return (
    <EntryProvider id={entryId}>
      <NodeProvider>
        <ConfigView />
      </NodeProvider>
    </EntryProvider>
  );
};
