import { Button } from '@mantine/core';
import { SegmentRelative } from '../segment-relative';
import { SegmentList } from '../segment-list';
import { FiPlus } from 'react-icons/fi';
import { PathWrapper, ReferencesSpacer, ScrollView } from './styles';
import { useDynamicUI } from './use-dynamic-ui';
import { useStore } from '../../context';
import { Direction, PathMode } from '../../types';
import { usePath } from './use-path';
import { ShiftButton } from '../../atoms';

export const PathSelectionView = () => {
  const [pathMode, referenceIds, setModal] = useStore(({ path: p, ui }) => [
    p.getPathMode(),
    p.getReferenceIds(PathMode.Absolute),
    ui.setCreateRefModal,
  ]);
  const { isCreateAvailable } = usePath();
  const {
    parentRef,
    firstChildRef,
    lastChildRef,
    collidedLeft,
    collidedRight,
    handleShift,
    handleScroll,
  } = useDynamicUI(referenceIds);

  return (
    <PathWrapper>
      <ShiftButton
        direction={Direction.Left}
        collision={collidedLeft}
        handleShift={handleShift}
      />
      <ScrollView ref={parentRef} onWheel={handleScroll}>
        <ReferencesSpacer>
          {PathMode.Relative === pathMode && <SegmentRelative />}
          <SegmentList
            firstChildRef={firstChildRef}
            lastChildRef={lastChildRef}
          />
          <Button
            variant="default"
            onClick={() => setModal(true)}
            sx={{ display: isCreateAvailable() ? 'flex' : 'none' }}
          >
            <FiPlus />
          </Button>
        </ReferencesSpacer>
      </ScrollView>
      <ShiftButton
        direction={Direction.Right}
        collision={collidedRight}
        handleShift={handleShift}
      />
    </PathWrapper>
  );
};
