import { FC } from "react";
import { EdgeLabelRenderer } from "reactflow";
import { EdgeIconWrapper } from "./styles";
import { useEdgeAdd } from "./use-edge-add";
import { Add } from "@morph-mapper/ui";
import { EdgeProps } from "@morph-mapper/node-logic";

export const EdgeAdd: FC<EdgeProps> = (edge) => {
  const { handleNodeCreation, edgePath, labelX, labelY } = useEdgeAdd(edge);

  return (
    <>
      <path id={edge.id} className="react-flow__edge-path" d={edgePath} />

      <EdgeLabelRenderer>
        <EdgeIconWrapper labelX={labelX} labelY={labelY}>
          <Add onClick={handleNodeCreation} />
        </EdgeIconWrapper>
      </EdgeLabelRenderer>
    </>
  );
};
