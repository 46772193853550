import { z } from 'zod';
import { Input } from '@morph-mapper/node-inputs';
import { Schema } from '../../../types';
import { EntryType, ReservedNodeType } from '@morph-mapper/types';

export const easyeomXLSInvoiceSchema: Schema = {
  updateTemplateDiscardFields: [],
  config: {
    _xlsIterator: {
      title: 'Table',
      description: 'Define the table which structures the document.',
      options: {
        from: {
          type: z.string(),
          title: 'From Marker',
          description:
            'The marker in the file to start composing the table from',
          input: Input.TextSelector,
          default: '',
          options: {
            required: true,
          },
        },
        to: {
          type: z.string(),
          title: 'To Marker',
          description:
            'The marker in the file where to end composing the table',
          input: Input.TextSelector,
          default: '',
        },
        fromLine: {
          type: z.number(),
          title: 'From Line',
          description:
            'The number of lines to skip from the marker before starting to compose the table',
          input: Input.Number,
          default: 0,
        },
        shiftRow: {
          type: z.number(),
          title: 'Shift Row',
          description:
            'The number of rows to skip from the start of the table before iterating over values',
          input: Input.Number,
          default: 0,
        },
        findByColumns: {
          type: z.boolean(),
          title: 'Find by Columns',
          description:
            'Enable different compose algorithm to compose the table',
          input: Input.Checkbox,
          default: false,
        },
        columns: {
          type: z.any(),
          title: 'Columns',
          description:
            'Column definitions used to index the cells of the table',
          input: Input.TextInput,
          default: undefined,
        },
        skip: {
          type: z.any(),
          title: 'Skip',
          description: 'Define which rows to skip from the table',
          input: Input.TextInput,
          default: undefined,
        },
      },
      mutate: (options: any) => {
        const { from, to, findByColumns, columns, skip, fromLine, shiftRow } =
          options;

        return {
          table: {
            from,
            to,
            findByColumns,
            columns,
            skip,
            fromLine,
            shiftRow,
          },
        };
      },
    },
    _xlsClassify: {
      title: 'Classify',
      description: 'Group the table entries by a (combination of) identifiers.',
      options: {
        identifiers: {
          type: z.array(z.string()),
          title: 'Identifiers',
          description: 'The identifiers to group the table entries by',
          input: Input.MultiSelectCreatable,
          default: [],
        },
      },
      mutate: (options: any) => {
        const { identifiers } = options;
        if (!identifiers) return true;

        return {
          '$utils.concat.selective': identifiers.map((identifier: string) => {
            return {
              $getVariable: identifier,
            };
          }),
        };
      },
    },
    _autosubmitConfig: {
      title: 'Submission Settings',
      description: '',
      options: {
        autosubmit: {
          type: z.boolean(),
          title: 'Autosubmit Template',
          description:
            'Check if the template is ready and can be autosubmitted by default.',
          input: Input.Checkbox,
          default: false,
        },
      },
      mutate: (options: any) => {
        const { autosubmit } = options;

        if (!autosubmit) {
          return;
        }

        return {
          url: 'http://localhost:8080/InSight/TriggerReader',
          template: {
            semantics: 'product.ordermanagement.OrderAutoSubmitREST',
            selector: '$selector',
            stage: 'autosubmit',
          },
          skipIfSent: true,
        };
      },
    },
  },
  definition: {
    values: {
      type: {
        validation: z.literal('xls'),
        type: EntryType.Internal,
      },
      checking: {
        validation: z.boolean(),
        type: EntryType.Graph,
      },
      template: {
        values: {
          '$Array.forEach': {
            outputType: 'forEach',
            values: {
              [ReservedNodeType.DeclareVariable]: {
                values: {
                  operatorid: {
                    validation: z.number(),
                    type: EntryType.Internal,
                    prefill: ({ operatorId }) => operatorId,
                  },
                  company: {
                    validation: z.literal('$data.0.company'),
                    type: EntryType.Internal,
                  },
                  uniqueid: {
                    validation: z.literal('$data.0.uniqueid'),
                    type: EntryType.Internal,
                  },
                  companyName: {
                    validation: z.literal(
                      JSON.stringify({
                        $mongoRequest: [
                          {
                            '$db.companiesconversion.findOne': {
                              operatorid: '$operatorid',
                              key: 'company',
                              crefcode: '$data.0.company',
                            },
                            $result: {
                              $getVariable: 'orefcode',
                            },
                          },
                          'morphjs',
                          ['companiesconversion'],
                        ],
                      })
                    ),
                    type: EntryType.Internal,
                  },
                  companyWithoutSpaces: {
                    validation: z.literal(
                      JSON.stringify([
                        [
                          {
                            '$utils.regexp.replace': [
                              '$data.0.company',
                              [' ', 'gi'],
                              '',
                            ],
                          },
                        ],
                      ])
                    ),
                    type: EntryType.Internal,
                  },
                  service: {
                    validation: z.literal('$data.0.service'),
                    type: EntryType.Internal,
                  },
                  logo: {
                    validation: z.literal('$data.0.logopath'),
                    type: EntryType.Internal,
                  },
                  cost: {
                    validation: z.literal(
                      JSON.stringify({
                        '$math.parseFloat': '$data.0.costNumber',
                      })
                    ),
                    type: EntryType.Internal,
                  },
                  price: {
                    validation: z.literal(
                      JSON.stringify({
                        '$math.parseFloat': '$data.0.priceNumber',
                      })
                    ),
                    type: EntryType.Internal,
                  },
                  quantity2: {
                    validation: z.literal(
                      JSON.stringify({
                        '$math.sum': {
                          '$Array.forEach': {
                            '$math.parseInt': '$data.quantity',
                          },
                          $data: '$data',
                        },
                      })
                    ),
                    type: EntryType.Internal,
                  },
                  quantity: {
                    validation: z.literal(
                      JSON.stringify({
                        '$Array.forEach': {
                          '$math.parseFloat': '$data.quantity',
                        },
                        $data: '$data',
                        $result: {
                          '$math.sum': '$data',
                        },
                      })
                    ),
                    type: EntryType.Internal,
                  },
                  selector: {
                    validation: z.literal(
                      JSON.stringify({
                        [ReservedNodeType.DeclareVariable]: {
                          PARSE_SETTINGS: {
                            noResultIfUndefined: true,
                            noDollarKeys: false,
                            noDollarStrings: true,
                            templateKeys: true,
                          },
                        },
                        companyNameraw: '$company',
                      })
                    ),
                    type: EntryType.Internal,
                  },
                },
              },
              autosubmit: {
                validation: z.any(),
                type: EntryType.Internal,
                configKey: '_autosubmitConfig',
              },
              mergingType: {
                validation: z.literal('extend'),
                type: EntryType.Internal,
              },
              selector: {
                validation: z.literal('$selector'),
                type: EntryType.Internal,
              },
              sort: {
                validation: z.literal(
                  JSON.stringify({
                    dateofentry: -1,
                  })
                ),
                type: EntryType.Internal,
              },
              contractFields: {
                validation: z.literal(
                  JSON.stringify([
                    'contractName',
                    'contractServices.services.name',
                  ])
                ),
                type: EntryType.Internal,
              },
              doc: {
                values: {
                  operatorid: {
                    validation: z.literal('$operatorid'),
                    type: EntryType.Internal,
                  },
                  templatetype: {
                    validation: z.literal('company'),
                    type: EntryType.Internal,
                  },
                  source: {
                    validation: z.literal('$data.0.vendor'),
                    type: EntryType.Internal,
                  },
                  _id: {
                    validation: z.literal(
                      JSON.stringify([
                        [
                          '$operatorid',
                          '_',
                          '$data.0.vendor',
                          '_',
                          '$service',
                          '_',
                          '$company',
                          '_',
                          '$uniqueid',
                        ],
                      ])
                    ),
                    type: EntryType.Internal,
                  },
                  id: {
                    validation: z.literal(
                      JSON.stringify({
                        $if: '$companyName',
                        $then: {
                          $mongoRequest: [
                            {
                              '$db.companies.findOne': {
                                operatorid: '$operatorid',
                                companyName: {
                                  $mongoRequest: [
                                    {
                                      '$db.companiesconversion.findOne': {
                                        operatorid: '$operatorid',
                                        key: 'company',
                                        crefcode: '$company',
                                      },
                                      $result: {
                                        $getVariable: 'orefcode',
                                      },
                                    },
                                    'morphjs',
                                    ['companiesconversion'],
                                  ],
                                },
                              },
                              $result: {
                                $getVariable: 'id',
                              },
                            },
                            'morphjs',
                            ['companies'],
                          ],
                        },
                        $else: '$companyWithoutSpaces',
                      })
                    ),
                    type: EntryType.Internal,
                  },
                  comapnyCN: {
                    validation: z.literal('$companyWithoutSpaces'),
                    type: EntryType.Internal,
                  },
                  companyNameraw: {
                    validation: z.literal('$company'),
                    type: EntryType.Internal,
                  },
                  companyName: {
                    validation: z.literal('$companyName'),
                    type: EntryType.Internal,
                  },
                  timestamp: {
                    validation: z.literal(
                      JSON.stringify({ '$time.stamp': [] })
                    ),
                    type: EntryType.Internal,
                  },
                  contracts: {
                    values: {
                      contractNameraw: {
                        validation: z.literal(
                          JSON.stringify([['$service', '_', '$company']])
                        ),
                        type: EntryType.Internal,
                      },
                      contractName: {
                        validation: z.literal(
                          JSON.stringify({
                            $mongoRequest: [
                              {
                                '$db.companiesconversion.findOne': {
                                  companyCN: '$companyWithoutSpaces',
                                  key: 'contract',
                                  crefcode: [['$service', '_', '$company']],
                                },
                                $result: {
                                  $getVariable: 'orefcode',
                                },
                              },
                              'morphjs',
                              ['companiesconversion'],
                            ],
                          })
                        ),
                        type: EntryType.Internal,
                      },
                      contractServices: {
                        outputType: 'array',
                        values: {
                          services: {
                            outputType: 'array',
                            values: {
                              nameraw: {
                                validation: z.literal('$service'),
                                type: EntryType.Internal,
                              },
                              logo: {
                                validation: z.literal('$data.0.logopath'),
                                type: EntryType.Internal,
                              },
                              name: {
                                validation: z.literal(
                                  JSON.stringify({
                                    $mongoRequest: [
                                      {
                                        '$db.companiesconversion.findOne': {
                                          key: 'service',
                                          crefcode: '$service',
                                        },
                                        $result: {
                                          $getVariable: 'orefcode',
                                        },
                                      },
                                      'morphjs',
                                      ['companiesconversion'],
                                    ],
                                  })
                                ),
                                type: EntryType.Internal,
                              },
                              activeunit: {
                                values: {
                                  [ReservedNodeType.DeclareVariable]: {
                                    values: {
                                      epochFstMonth: {
                                        validation: z.literal(
                                          JSON.stringify({
                                            '$time.stamp': [
                                              [
                                                [
                                                  '01-',
                                                  {
                                                    '$time.format': [
                                                      {
                                                        '$time.stamp': [],
                                                      },
                                                      'MM-YYYY',
                                                    ],
                                                  },
                                                ],
                                              ],
                                              'DD-MM-YYYY',
                                            ],
                                          })
                                        ),
                                        type: EntryType.Internal,
                                      },
                                      fstMonth: {
                                        validation: z.literal(
                                          JSON.stringify({
                                            '$time.format': [
                                              '$epochFstMonth',
                                              'YYYY-MM-DD',
                                            ],
                                          })
                                        ),
                                        type: EntryType.Internal,
                                      },
                                    },
                                  },
                                  timestamp: {
                                    validation: z.literal(
                                      JSON.stringify({ '$time.stamp': [] })
                                    ),
                                    type: EntryType.Internal,
                                  },
                                  vendorraw: {
                                    validation: z.literal('$data.0.vendor'),
                                    type: EntryType.Internal,
                                  },
                                  effectiveDateReadable: {
                                    validation: z.literal(
                                      JSON.stringify([
                                        ['$fstMonth', 'T00:00:00.000Z'],
                                      ])
                                    ),
                                    type: EntryType.Internal,
                                  },
                                  effectiveDate: {
                                    validation: z.literal('$epochFstMonth'),
                                    type: EntryType.Internal,
                                  },
                                  unitCost: {
                                    validation: z.literal('$cost'),
                                    type: EntryType.Internal,
                                  },
                                  unitPrice: {
                                    validation: z.literal('$price'),
                                    type: EntryType.Internal,
                                  },
                                  unitCount: {
                                    validation: z.literal('$quantity'),
                                    type: EntryType.Internal,
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
          $data: {
            outputType: 'data',
            values: {
              '$Array.classify': {
                validation: z.any(),
                type: EntryType.Internal,
                configKey: '_xlsClassify',
              },
              $data: {
                outputType: 'data',
                values: {
                  [ReservedNodeType.Iterator]: {
                    validation: z.any(),
                    type: EntryType.Internal,
                    configKey: '_xlsIterator',
                  },
                  [ReservedNodeType.DeclareVariable]: {
                    values: {
                      vendor: {
                        validation: z.string(),
                        displayName: 'Vendor on PDF',
                        type: EntryType.Graph,
                        prefill: ({ name }) => `${name}`,
                      },
                      logo: {
                        validation: z.string(),
                        displayName: 'URL path to logo of service by vendor',
                        type: EntryType.Graph,
                      },
                      company: {
                        validation: z.string(),
                        displayName: 'Company',
                        type: EntryType.Cell,
                      },
                      service: {
                        validation: z.string(),
                        displayName: 'Service',
                        type: EntryType.Cell,
                      },
                      uniqueid: {
                        validation: z.string(),
                        displayName: 'Id (or date)',
                        type: EntryType.Cell,
                      },
                      priceNumber: {
                        validation: z.number(),
                        displayName: 'Prijs',
                        type: EntryType.Cell,
                      },
                      costNumber: {
                        validation: z.number(),
                        displayName: 'Kostprijs',
                        type: EntryType.Cell,
                      },
                      quantity: {
                        validation: z.number(),
                        displayName: 'Quantity',
                        type: EntryType.Cell,
                      },
                    },
                  },
                  vendor: {
                    validation: z.literal('$vendor'),
                    type: EntryType.Internal,
                  },
                  logo: {
                    validation: z.literal('$logo'),
                    type: EntryType.Internal,
                  },
                  logopath: {
                    validation: z.literal(
                      JSON.stringify([
                        [
                          '<img src="',
                          '$logo',
                          '" style="margin-right: -60px;" width="60"/>',
                        ],
                      ])
                    ),
                    type: EntryType.Internal,
                  },
                  company: {
                    validation: z.literal('$company'),
                    type: EntryType.Internal,
                  },
                  service: {
                    validation: z.literal('$service'),
                    type: EntryType.Internal,
                  },
                  uniqueid: {
                    validation: z.literal('$uniqueid'),
                    type: EntryType.Internal,
                  },
                  priceNumber: {
                    validation: z.literal('$priceNumber'),
                    type: EntryType.Internal,
                  },
                  costNumber: {
                    validation: z.literal('$costNumber'),
                    type: EntryType.Internal,
                  },
                  quantity: {
                    validation: z.literal('$quantity'),
                    type: EntryType.Internal,
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};
