export const parseOutput = (output: string) => {
  return JSON.stringify(output).replace(/"/g, '');
};

const replaceUndefined = (k: string, v: any) => (v === undefined ? null : v);

const replaceSet = (k: string, v: any) => {
  if (v instanceof Set) {
    return { _stringified_set_: Array.from(v) };
  }
  return v;
};

export const stringifyState = (obj: any) => {
  const replacer = (k: string, v: any) => {
    return replaceSet(k, replaceUndefined(k, v));
  };

  return JSON.stringify(obj, replacer, 4);
};

const reviveSet = (k: string, v: any) => {
  if (v && v._stringified_set_) {
    return new Set(v._stringified_set_);
  }
  return v;
};

export const reviveState = (obj: any) => {
  const reviver = (k: string, v: any) => {
    return reviveSet(k, v);
  };

  return JSON.parse(obj, reviver);
};

export const capitalizeWord = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

/**
 * This function is used to replace escaped characters in a string. It is used
 * so that user input does not result in incorrectly rendered template code.
 */
export const replaceEscapedCharacter = (text: string) => {
  // TODO: this does not include \ and " characters (for security reasons?)
  return text
    .replace(/\\n/g, '\n')
    .replace(/\\t/g, '\t')
    .replace(/\\r/g, '\r')
    .replace(/\\f/g, '\f')
    .replace(/\\b/g, '\b')
    .replace(/'/g, "'");
};

/**
 * This function is used to sanitize the selected text by removing any new line
 * characters and trimming the text.
 */
export const sanitizeSelectedText = (text: string | undefined): string | null => {
  if (!text) return null;

  let cleanedText = text.trim();

  if (cleanedText.length === 0) return null;

  cleanedText = cleanedText.replace(/[\n\r\t]/g, '');

  return cleanedText; 
};
