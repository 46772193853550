import { Accordion, Box, Text } from '@mantine/core';
import { Header } from '../../molecules';
import { ListContent, StyledAccordion } from './styles';
import { ListContextVariables } from '../list-context-variables';
import { ListTree } from '../list-tree';
import { useListView } from './use-list-view';
import { Add } from '@morph-mapper/ui';
import { CreateEntryModal } from '../create-entry-modal';

export const ListView = () => {
  const {
    isCreateEntryModalOpen,
    closeCreateEntry,
    active,
    handleCreateEntry,
    handleChangeActive,
    getVariables,
    contextListActive,
  } = useListView();

  return (
    <>
      <Header />
      <ListContent>
        <StyledAccordion
          value={active}
          onChange={handleChangeActive}
          multiple
          unstyled
        >
          <Accordion.Item value="tree">
            <Accordion.Control>
              <Box>
                <Text>Template</Text>
                <Text size="sm" color="dimmed" weight={400}>
                  Fields defined in the template at the current context
                </Text>
              </Box>
              <Add onClick={handleCreateEntry} />
            </Accordion.Control>
            <Accordion.Panel>
              <ListTree />
            </Accordion.Panel>
          </Accordion.Item>
          <Accordion.Item value="entries">
            <Accordion.Control disabled={!contextListActive}>
              <Box>
                <Text>Environment</Text>
                <Text size="sm" color="dimmed" weight={400}>
                  Variables configured in the current context
                </Text>
              </Box>
            </Accordion.Control>
            <Accordion.Panel>
              <ListContextVariables variableIds={getVariables()} />
            </Accordion.Panel>
          </Accordion.Item>
        </StyledAccordion>
      </ListContent>

      <CreateEntryModal
        opened={isCreateEntryModalOpen}
        close={closeCreateEntry}
      />
    </>
  );
};
