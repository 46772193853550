import { getBezierPath } from "reactflow";
import { useNavigate } from "react-router-dom";
import { GraphOperation } from "@morph-mapper/types";
import { EdgeProps } from "@morph-mapper/node-logic";

export const useEdgeAdd = (edge: EdgeProps) => {
  const navigation = useNavigate();
  const {
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    data,
    target,
    targetHandleId,
    source,
    sourceHandleId,
  } = edge;

  const handleNodeCreation = () => {
    navigation("nodes/new", {
      state: {
        operation: GraphOperation.Insert,
        sourceEdge: {
          source,
          sourceHandle: sourceHandleId,
        },
        targetEdge: {
          id,
          data,
          target,
          targetHandle: targetHandleId,
        },
      },
    });
  };

  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return { handleNodeCreation, edgePath, labelX, labelY };
};
