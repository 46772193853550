import { useEffect, useState } from 'react';
import { useCellEntryContext } from '../../context';

export const useEditCell = () => {
  /* Context State */
  const [currentColumn, setNewValue] = useCellEntryContext((s) => [
    s.getColumn(),
    s.setNewValue,
  ]);
  /* Local State */
  const [column, setColumn] = useState<'' | number>(currentColumn ?? '');

  /**
   * Effects
   **/
  useEffect(() => {
    if (column === '') return;

    setNewValue(column);
  }, [column]);

  return { column, setColumn };
};
