import { Group } from '@mantine/core';
import { StyledHeader } from './styles';
import { Move } from '@morph-mapper/ui';
import { useNavigate } from 'react-router-dom';
import { useEntryContext } from '../../context';

export const Header = () => {
  // TODO: remove typecast, this is a fix for the tree children not updating
  const name = useEntryContext((s) => s.getName()) as string;
  const navigate = useNavigate();

  const handleClose = () => {
    navigate('../..');
  };

  return (
    <StyledHeader>
      <Group>
        <Move into={false} onClick={handleClose} />
        Configure {name.charAt(0).toUpperCase() + name.slice(1)} Context
      </Group>
    </StyledHeader>
  );
};
