import { MultiSelect } from "@mantine/core";
import { useState } from "react";
import { replaceEscapedCharacter } from "@morph-mapper/utils";
import type { InputProps } from "../../types";

export const MultiSelectCreatable = ({
  value,
  handleChange,
}: InputProps<string[]>) => {
  const [availableValues, setAvailableValues] = useState<
    { label: string; value: string }[]
  >([]);

  return (
    <MultiSelect
      searchable
      creatable
      clearable
      value={value ?? []}
      onChange={handleChange}
      getCreateLabel={(query) => `+ Create ${query}`}
      onCreate={(query) => {
        // TODO: this escape assumes the user actually wants to escape the character
        // make this ecape optional
        const item = {
          value: replaceEscapedCharacter(query),
          label: query,
        };
        setAvailableValues((current) => [...current, item]);
        return item;
      }}
      data={[
        ...availableValues,
        ...(value ?? []).map((word) => ({
          label: JSON.stringify(word).slice(1, -1),
          value: word,
        })),
      ]}
    />
  );
};
