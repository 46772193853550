import { createStore } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { IGraphEntry, StoreSlice, type IEntry } from '@/store';
import { EntryType } from '@morph-mapper/types';

export interface EntryProps {
  id: string;
}

export type InitEntryProps = EntryProps;

export interface EntryState extends EntryProps {
  // Local state operations
  setComputed: (value: any, variableId?: string) => void;

  getId: () => string;
  getName: () => string | undefined;
  // setGraph: (graphId: string, variableId?: string) => void;
  setEntryGraph: (graphId: string) => void;
}

export type EntryStore = ReturnType<typeof createEntryStore>;

export const createEntryStore = (
  global: StoreSlice,
  initProps: InitEntryProps
) => {
  return createStore<EntryState & StoreSlice & IGraphEntry>()(
    immer((set, get) => ({
      ...global,
      ...global.entries.entry(initProps.id, EntryType.Graph),
      ...initProps,

      setEntryGraph(graphId) {
        set((s) => {
          s.linkGraph(graphId);
        });
      },
    }))
  );
};
