import { Cancel, Complete, Connect, Delete, Edit } from '@morph-mapper/ui';
import { Group } from '@mantine/core';
import { StyledActionCell } from './styles';
import { OptionType } from '@morph-mapper/types';
import { useParameterRowContext } from '../context';

export const ActionCell = () => {
  const [
    name,
    type,
    isEditing,
    cancelEdit,
    confirmEdit,
    onEdit,
    onDelete,
    enableDelete,
  ] = useParameterRowContext((s) => [
    s.name,
    s.getType(),
    s.isEditing,
    s.cancelEdit,
    s.confirmEdit,
    s.onEdit,
    s.onDelete,
    s.canDelete(),
  ]);

  if (type === OptionType.UserDefined) {
    return (
      <StyledActionCell>
        <Group>
          {isEditing ? (
            <>
              <Complete onClick={confirmEdit} />
              <Cancel onClick={cancelEdit} />
            </>
          ) : (
            <>
              <Edit onClick={onEdit} />
              <Delete onClick={onDelete} disabled={!enableDelete} />
            </>
          )}
        </Group>
      </StyledActionCell>
    );
  }

  if (type === OptionType.Dependency) {
    return (
      <StyledActionCell>
        <Connect />
        {/* FIXME: dependency handler should be injected */}
        {/* <Connect onClick={() => setDependency(name, "parameters")} /> */}
      </StyledActionCell>
    );
  }

  return null;
};
