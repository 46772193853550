import { Cancel, Complete, Edit, Options } from '@morph-mapper/ui';
import { Group, Menu, useMantineTheme } from '@mantine/core';
import { StyledActionCell } from './styles';
import { useItemEntryContext, useInlineEntryContext } from '../../context';
import { LuEraser } from 'react-icons/lu';
import { FiTrash2 } from 'react-icons/fi';
import { TypeChangeMenuSection } from '../type-change-menu-section';

export const ActionCell = () => {
  const [isEditing] = useItemEntryContext((s) => [s.isEditing]);

  return (
    <StyledActionCell>
      <Group>{isEditing ? <ConfirmActions /> : <EditActions />}</Group>
    </StyledActionCell>
  );
};

export const EditActions = () => {
  const [canClear, onEdit, onClear, onDelete] = useItemEntryContext((s) => [
    s.isClearable(),
    s.onEdit,
    s.onClear,
    s.onDelete,
  ]);
  const theme = useMantineTheme();

  return (
    <StyledActionCell>
      <Group>
        <Edit onClick={onEdit} />
        <Menu shadow="md" width={150}>
          <Menu.Target>
            <Options />
          </Menu.Target>
          <Menu.Dropdown>
            <TypeChangeMenuSection />
            <Menu.Label>Actions</Menu.Label>
            <Menu.Item
              icon={<LuEraser color={theme.colors.gray[6]} />}
              onClick={onClear}
              disabled={!canClear}
            >
              Clear
            </Menu.Item>
            <Menu.Item
              icon={<FiTrash2 color={theme.colors.red[6]} />}
              onClick={onDelete}
            >
              Remove
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Group>
    </StyledActionCell>
  );
};

export const ConfirmActions = () => {
  const [confirmEdit, cancelEdit] = useInlineEntryContext((s) => [
    s.confirmEdit,
    s.cancelEdit,
  ]);

  return (
    <StyledActionCell>
      <Group>
        <Complete onClick={confirmEdit} />
        <Cancel onClick={cancelEdit} />
      </Group>
    </StyledActionCell>
  );
};
