import styled from '@emotion/styled';

export const MenuBar = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  backgroundColor: theme.colors.gray[0],
  paddingLeft: '1.5rem',
  paddingRight: '1.5rem',
  paddingTop: '0.5rem',
  paddingBottom: '0.5rem',
  gap: '1rem',
}));
