import { ReservedNodeType } from '@morph-mapper/types';
import { z } from 'zod';

export const fileExtensionSchema = z.enum([
  'pdf',
  'xls',
  'xml',
  'txt',
  'eml',
  'message-txt',
  'message-html-txt',
]);
export const mergingTypeSchema = z.enum(['createIfNotExist', 'extend']);
export const workloadSchema = z.enum(['digest', 'capture']);
export const templateTypeSchema = z.enum([
  'order',
  'feature',
  'resource',
  'truck',
]);
export const docStatusSchema = z.enum(['New', 'Update']);
export const docTypeSchema = z.enum(['Import', 'Export']);
export const transportDirectionSchema = z.literal(
  '{"$if":{"$utils.eq":["$type","Import"]},"$then":"I","$else":"E"}'
);
export const orderSessionSchema = z.literal(
  '{"$if":{"$utils.eq":["$type","Import"]},"$then":"orderimport","$else":"orderexport"}'
);
export const loadingDischargeSchema = z.literal(
  '{"$if":{"$utils.eq":["$type","Import"]},"$then":"D","$else":"L"}'
);

// TODO: Move or put in reservednodetype
export const reservedNames = new Set([
  'Array.classify',
  'Array.forEach',
  'Array.map',
  'CONTAINERNR',
  'FILEID',
  'RegExp',
  'db',
  'data',
  'declareVariable',
  'emailData',
  'emailMessage',
  'emailMessage.from.0.address',
  'flag',
  'genericOrigin',
  'getVariable',
  'keyGen',
  'mongoRequest',
  'options',
  'pdfString',
  'result',
  'skip',
  'time',
  'utils',
  'exists',
  'xls',
  'inputdocument',
]);

export const isReservedNodeType = (nodeType: string): boolean => {
  return Object.values(ReservedNodeType).includes(nodeType as ReservedNodeType);
};
