import { nanoid } from 'nanoid';

export const generateUniqueId = (exists: (id: string) => boolean) => {
  let id = nanoid(10);

  while (exists(id)) {
    id = nanoid(10);
  }

  return id;
};
