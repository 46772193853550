import { useEffect, useState } from 'react';
import { useSimpleEntryContext } from '../../context';
import { useDebouncedValue } from '@mantine/hooks';

export const useEditSimple = () => {
  /* Context State */
  const [currentLiteral, setNewValue] = useSimpleEntryContext((s) => [
    s.getLiteral(),
    s.setNewValue,
  ]);
  /* Local State */
  const [literal, setLiteral] = useState<string>(currentLiteral ?? '');
  const [debounced] = useDebouncedValue(literal, 500);

  /**
   * Effects
   **/
  useEffect(() => {
    if (literal === '') return;

    setNewValue(literal);
  }, [debounced]);

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLiteral(event.target.value);
  };

  return { literal, handleTextChange };
};
