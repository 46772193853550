import { Select } from "@mantine/core";

interface SingleSelectProps {
  selection: {
    label: string;
    value: any;
  }[];
  value: string;
  handleChange: (value: string) => void;
}

export const SingleSelect = ({
  value,
  handleChange,
  selection,
}: SingleSelectProps) => {
  return <Select value={value} onChange={handleChange} data={selection} />;
};
