import { Node as FlowNode, Edge as FlowEdge } from 'reactflow';
import { LogicBlockIndex } from '@morph-mapper/node-logic';
import { NodeData } from '@morph-mapper/types';
import { hasProperty } from '@morph-mapper/utils';

/*
 * Types
 */

// Rewrite types from ReactFlow with stricter requirements
export type Node<T = any> = FlowNode<NodeData<T>> & {
  type: LogicBlockIndex;
};
// Rewrite types from ReactFlow with stricter requirements
export type Edge<T = any> = FlowEdge<T> & {
  data: {
    type: 'template' | 'dependency';
    dependencies?: T;
  };
};

export type LogicGraph = {
  id: string;
  nodes: Record<string, Node>;
  edges: Record<string, Edge>;
};

/*
 * Type Guards
 */

export const isBoolean = (value: any): value is boolean => {
  return typeof value === 'boolean';
};

export const isLogicGraph = (graph: any): graph is LogicGraph => {
  if (graph === undefined) return false;
  // If not an object the following checks will fail
  if (typeof graph !== 'object') {
    return false;
  }

  return hasProperty(graph, 'nodes') && hasProperty(graph, 'edges');
};
