import { useEffect } from 'react';
import { useStore } from '../../context';
import { useTextSelect } from '../../hooks';
import {
  FileViewContent,
  FileViewWrapper,
  HtmlContent,
  TextContent,
} from './styles';
import { useEmail } from './use-email';

export const EmailView = () => {
  const file = useStore(({ file: f }) => f.getFile());
  const { readEmail, isLoading, emailContent } = useEmail();

  useTextSelect();

  useEffect(() => {
    if (file) {
      readEmail();
    }
  }, [file]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <FileViewWrapper>
      <FileViewContent>
        {emailContent.html ? (
          <HtmlContent
            dangerouslySetInnerHTML={{ __html: emailContent.html }}
          />
        ) : (
          <TextContent>{emailContent.text}</TextContent>
        )}
      </FileViewContent>
    </FileViewWrapper>
  );
};
