import { OptionsRecord } from '@morph-mapper/node-logic';
import { NodeOptionsWrapper, OptionType } from '@morph-mapper/types';
import { getEntries } from '@morph-mapper/utils';

/**
 * This function parses the options field of a LOGIC_BLOCKS object and returns
 * a record of default values for each option.
 */
export const parseDefaultOptions = (options: OptionsRecord) => {
  return getEntries(options).reduce((acc, [name, optionConfig]) => {
    const isConnector = optionConfig.conditions.isConnector;

    acc[name] = {
      type: isConnector ? OptionType.Dependency : OptionType.UserDefined,
      value: optionConfig.default,
    };
    return acc;
  }, {} as NodeOptionsWrapper<OptionsRecord>);
};
