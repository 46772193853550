import styled from "@emotion/styled";
import { Stack, Title } from "@mantine/core";

export const StyledStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing.xs,
  marginBottom: theme.spacing.xl,
}));

export const SectionTitle = styled(Title)(({ theme }) => ({
  color: theme.colors.gray[7],
}));

export const SectionDescription = styled("div")(({ theme }) => ({
  fontSize: theme.fontSizes.md,
  color: theme.colors.gray[6],
}));
