import {
  HeaderProps as MantineHeaderProps,
  useMantineTheme,
} from "@mantine/core";
import { StyledHeader } from "./styles";

export interface BaseHeaderProps extends Omit<MantineHeaderProps, "height"> {
  children: React.ReactNode[] | React.ReactNode;
}

export const BaseHeader = ({
  children,
  sx: styles,
  ...props
}: BaseHeaderProps) => {
  const theme = useMantineTheme();

  return (
    <StyledHeader
      {...props}
      sx={{
        justifyContent: "space-between",
        borderBottom: `0.07em solid ${theme.colors.gray[2]}`,
        ...styles,
      }}
    >
      {children}
    </StyledHeader>
  );
};
