import { createStore } from "zustand";
import { PathState, pathState } from "./path";
import { FileState, InitFileProps, fileState } from "./file";
import { immer } from "zustand/middleware/immer";
import { UIState, uiState } from "./ui";

export interface InitStoreProps extends InitFileProps {}

export type StoreState = {
  ui: UIState;
  file: FileState;
  path: PathState;
};

export const createPackageStore = (props: InitStoreProps) => {
  return createStore<StoreState>()(
    immer((...a) => ({
      ui: uiState()(...a),
      file: fileState(props)(...a),
      path: pathState()(...a),
    }))
  );
};
