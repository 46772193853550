import { EntryItemType, EntryType } from '@morph-mapper/types';
import {
  BaseTreeNode,
  CreateEntryItem,
  CreateMapItem,
  TreeBooleanItem,
  TreeCellItem,
  TreeGraphItem,
  TreeInternalItem,
  TreeMap,
  TreeSimpleItem,
  UnregisteredTreeItem,
} from '@/types';
import { match } from 'ts-pattern';
import { z } from 'zod';

// TODO: maybe we can place this in the function below?
export const createUnregisteredMap = (
  partial: CreateMapItem
): Omit<TreeMap, 'id'> => {
  return {
    ...partial,
    children: {
      forwardMap: {},
      reverseMap: {},
    },
    validation: z.any(),
    type: EntryType.Map,
    dependencies: {
      dependsOn: new Set(),
      requiredBy: new Set(),
    },
  };
};

const createBooleanItem = (
  value: boolean
): UnregisteredTreeItem<TreeBooleanItem> => {
  return {
    type: EntryType.Boolean,
    value,
  };
};

const createSimpleItem = (value: any): UnregisteredTreeItem<TreeSimpleItem> => {
  return {
    type: EntryType.Simple,
    value,
  };
};

const createCellItem = (): UnregisteredTreeItem<TreeCellItem> => {
  return {
    type: EntryType.Cell,
    column: undefined,
    value: undefined,
  };
};

const createGraphItem = (): UnregisteredTreeItem<TreeGraphItem> => {
  return {
    type: EntryType.Graph,
    graphId: undefined,
    value: undefined,
  };
};

const createInternalItem = (
  value: any
): UnregisteredTreeItem<TreeInternalItem> => {
  return {
    type: EntryType.Internal,
    value,
  };
};

export const createUnregisteredItem = (
  type: EntryItemType,
  { name, parentId, validation, key, value, allowedTypes }: CreateEntryItem
) => {
  const base: Omit<BaseTreeNode, 'id'> = {
    key,
    name: name ?? key,
    parentId,
    computed: undefined,
    validation: validation ?? z.any(),
    allowedTypes,
    dependencies: {
      dependsOn: new Set(),
      requiredBy: new Set(),
    },
  };

  return {
    ...base,
    ...match(type)
      .with(EntryType.Boolean, () => createBooleanItem(value))
      .with(EntryType.Simple, () => createSimpleItem(value))
      .with(EntryType.Cell, () => createCellItem())
      .with(EntryType.Graph, () => createGraphItem())
      .with(EntryType.Internal, () => {
        if (validation instanceof z.ZodLiteral) {
          value = value ?? validation.value;
        }

        return createInternalItem(value);
      })
      .exhaustive(),
  };
};
