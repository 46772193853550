import { Group, Button } from '@mantine/core';
import { Stepper } from '../../molecules';
import { useSetup } from './use-setup';
import { Container, LeftColumn, RightColumn, StepWrapper } from './styles';
import { useEffect } from 'react';

export const Setup = () => {
  const { init, step, handlePrevious, renderStep, opId } = useSetup();
  const Step = renderStep();

  useEffect(() => {
    init();
  }, []);

  if (!opId) {
    return;
  }

  return (
    <Container>
      <LeftColumn>
        <StepWrapper>
          <Step>
            {(progress, disabled) => (
              <Group position="apart">
                <Button
                  variant="default"
                  onClick={handlePrevious}
                  sx={{ visibility: step !== 0 ? 'visible' : 'hidden' }}
                >
                  Previous
                </Button>
                <Button
                  disabled={disabled}
                  onClick={progress}
                  sx={{ visibility: step !== 1 ? 'visible' : 'hidden' }}
                >
                  Next
                </Button>
              </Group>
            )}
          </Step>
        </StepWrapper>
      </LeftColumn>

      <RightColumn>
        <Stepper step={step} />
      </RightColumn>
    </Container>
  );
};
