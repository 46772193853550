import { Input, Modal, Select } from '@mantine/core';
import { useStore } from '../../context';
import { JsonSelector, PathMode } from '../../types';
import { Body, FormLayout } from './styles';
import { getKeys } from '@morph-mapper/utils';
import { nanoid } from 'nanoid';

export const JsonNodeCreate = () => {
  const [
    textContent,
    referenceIds,
    getReference,
    createReference,
    setActiveReference,
    isModalOpen,
    setCreateModal,
    setConfigRefModal,
  ] = useStore(({ file: f, path: p, ui }) => [
    f.getTextContent(),
    p.getReferenceIds(PathMode.Absolute),
    p.getReference,
    p.createReference,
    p.setActiveReference,
    ui.isCreateRefModalOpen(),
    ui.setCreateRefModal,
    ui.setConfigRefModal,
  ]);

  const getAvailableReferences = () => {
    let json = JSON.parse(textContent);

    for (let i = 0; i < referenceIds.length; i++) {
      const ref = getReference(referenceIds[i]);
      if (ref.value === null) {
        throw new Error('Invalid reference');
      }

      if (ref.type !== JsonSelector.Array) {
        json = json[ref.value];
        continue;
      }

      if (ref.operation === null) {
        throw new Error('Invalid reference');
      }

      json = json[ref.value][ref.operation];
    }

    return getKeys(json).map((key) => ({
      value: key.toString(),
      label: key.toString(),
    }));
  };

  const handleCreateReference = (key: string) => {
    const id = nanoid();
    const tagRef = {
      type: JsonSelector.Base,
      value: key,
      operation: null,
    };

    createReference(tagRef, id);
    setActiveReference(id);

    setCreateModal(false);
    setConfigRefModal(true);
  };

  return (
    <Modal
      opened={isModalOpen}
      onClose={() => setCreateModal(false)}
      title="Add reference to path"
      centered
    >
      <Body>
        <FormLayout>
          <Input.Wrapper label="Reference">
            <Select
              data={getAvailableReferences()}
              onChange={handleCreateReference}
              withinPortal
            />
          </Input.Wrapper>
        </FormLayout>
      </Body>
    </Modal>
  );
};
