import { Menu, Portal } from '@mantine/core';
import { Options } from '@morph-mapper/ui';
import { FiTrash2 } from 'react-icons/fi';
import { AiFillEdit } from 'react-icons/ai';
import React from 'react';
import { useSetupStore } from '../../store';
import { useCategoryItemDelete } from './use-category-item-delete';

interface CategoryItemMenuProps {
  categoryName: string;
  domain: string;
}

export const CategoryItemMenu = ({
  categoryName,
  domain,
}: CategoryItemMenuProps) => {
  const [setIsRenameOpen, setCategoryName, operatorId] = useSetupStore((s) => [
    s.setIsRenameCategoryOpen,
    s.setCategoryName,
    s.getOperatorId(),
  ]);

  const { handleDelete } = useCategoryItemDelete(
    operatorId,
    domain,
    categoryName
  );

  const handleRename = (e: React.MouseEvent<HTMLButtonElement>) => {
    setIsRenameOpen(true);
    setCategoryName(categoryName);
  };

  return (
    <Menu
      trigger="hover"
      openDelay={200}
      closeDelay={400}
      position="right-start"
    >
      <Menu.Target>
        <Options variant="subtle" />
      </Menu.Target>
      <Portal>
        <Menu.Dropdown>
          <Menu.Item icon={<AiFillEdit />} onClick={handleRename}>
            Rename
          </Menu.Item>
          <Menu.Item icon={<FiTrash2 />} color="red" onClick={handleDelete}>
            Delete Category
          </Menu.Item>
        </Menu.Dropdown>
      </Portal>
    </Menu>
  );
};
