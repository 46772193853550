import { BlockRule, GraphNode, typeLogicBlockRecord } from '../../types';
import { LOGIC_BLOCKS as logic } from '../base';
import { Input } from '@morph-mapper/node-inputs';
import { z } from 'zod';
import { FiAnchor } from 'react-icons/fi';
import { HandleType } from '@morph-mapper/types';

const overrides = typeLogicBlockRecord({
  anchor: {
    title: 'Anchor',
    description:
      'Select path to act as a reference point of the value you want to extract.',
    category: 'core',
    type: 'anchor',
    node: GraphNode.Generic,
    icon: FiAnchor,
    mutation: ({ options }) => {
      const { selected } = options;

      if(!selected) return;

      return `${selected}`;
    },
    options: {
      selected: {
        type: z.string(),
        title: 'Selector',
        description:
          'Select point in the file which will act as a reference cell to extract data',
        input: Input.TextSelector,
        default: undefined,
        conditions: {},
      },
    },
    handles: {
      source: [
        {
          id: '1',
          type: HandleType.Template,
        },
      ],
      target: [],
    },

    rules: [BlockRule.Configurable, BlockRule.NoChildren],
  },
});

export const LOGIC_BLOCKS = {
  ...logic,
  ...overrides,
};
