import styled from "@emotion/styled";
import { Tabs } from "@mantine/core";

export const SideBarWrapper = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: theme.colors.gray[1],
}));

export const SideBarContent = styled.div`
  padding: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  height: 100%;
`;

export const TabsListHeader = styled(Tabs.List)(({ theme }) => ({
  backgroundColor: theme.colors.gray[2],
  borderTop: `1px solid ${theme.colors.gray[3]}`,
}));
