import styled from "@emotion/styled";
import {
  Box,
  BoxProps,
  Group,
  createPolymorphicComponent,
} from "@mantine/core";

export const StyledGroup = styled(Group)`
  height: 100%;
`;

const _StyledNavigation = styled(Box)(({ theme }) => ({
  display: "flex",
  paddingRight: theme.spacing.md,
  alignItems: "center",
  height: "100%",
  borderRight: `1px solid ${theme.colors.gray[2]}`,
}));

export const StyledNavigation = createPolymorphicComponent<"div", BoxProps>(
  _StyledNavigation
);
