import { useXmlView } from 'react-interactive-xml-viewer';
import { match } from 'ts-pattern';
import { JsonSelector, PathMode, XmlSelector } from '../../types';
import { useStore } from '../../context';
import { SchemaVariant } from '@morph-mapper/types';

export const usePath = () => {
  const [referenceIds, getReference, variant, textContent] = useStore(
    ({ path: p, file: f }) => [
      p.getReferenceIds(PathMode.Absolute),
      p.getReference,
      f.getVariant(),
      f.getTextContent(),
    ]
  );
  const { getNode } = useXmlView();

  const createAvailableXml = () => {
    const endRefId = referenceIds[referenceIds.length - 1];
    const node = getNode(endRefId);
    const reference = getReference(endRefId);

    const hasChildren = node.getChildren().length > 0;
    const canHaveChildren = match(reference.type)
      .with(XmlSelector.Base, XmlSelector.Array, () => true)
      .otherwise(() => false);

    return hasChildren && canHaveChildren;
  };

  const createAvailableJson = () => {
    let json = JSON.parse(textContent);

    for (const id of referenceIds) {
      const ref = getReference(id);
      if (ref.value === null) {
        return false;
      }

      if (ref.type !== JsonSelector.Array) {
        json = json[ref.value];
        continue;
      }

      if (ref.operation === null) {
        return false;
      }

      json = json[ref.value][ref.operation];
    }

    return json instanceof Object && !Array.isArray(json);
  };

  const isCreateAvailable = () => {
    return match(variant)
      .with(SchemaVariant.Xml, createAvailableXml)
      .with(SchemaVariant.Json, createAvailableJson)
      .otherwise(() => false);
  };

  return { isCreateAvailable };
};
