import { Cancel, Complete } from "@morph-mapper/ui";
import { Text, Group, useMantineTheme, TextInput } from "@mantine/core";
import { useCreateVariable } from "./use-create-variable";
import { ContentWrapper } from "./styles";

export const CreateVariable = () => {
  const theme = useMantineTheme();
  const { edit, name, handleNew, handleSetName, handleCreate, handleCancel } =
    useCreateVariable();

  if (edit) {
    return (
      <ContentWrapper>
        <Group>
          <TextInput
            value={name}
            onChange={handleSetName}
            placeholder="Variable name"
          />
          <Complete onClick={handleCreate} />
          <Cancel onClick={handleCancel} />
        </Group>
      </ContentWrapper>
    );
  }

  return (
    <ContentWrapper onClick={handleNew}>
      <Group>
        <Text color={theme.colors.gray[6]}>Add new variable</Text>
      </Group>
    </ContentWrapper>
  );
};
