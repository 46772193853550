import { useEffect, useRef } from 'react';
import { GraphContext } from './context';
import { GraphStore, createGraphStore } from '../../store';
import { useStore } from '@/store';
import { useEntryContext } from '../entry';
import { useNavigate, useParams } from 'react-router-dom';
import { useLogicTree } from '@/hooks';
import { DirtyType } from '@/types';

interface GraphProviderProps extends React.PropsWithChildren {}

export const GraphProvider = ({ children }: GraphProviderProps) => {
  /* Global State */
  const [initGraph, addDirty] = useStore(({ graphs: g, entries: e }) => [
    g.init,
    e.addDirty,
  ]);
  /* Context State */
  const [entryId, linkGraph, savedGraphId, setTemplateValue] = useEntryContext(
    (s) => [s.getId(), s.linkGraph, s.getGraphId(), s.setTemplateValue]
  );
  /* Hooks */
  const { graphId } = useParams();
  const { logicTreeToTemplate } = useLogicTree();
  const navigate = useNavigate();

  // Track whether we've already handled the initial navigation
  const hasInitializedRef = useRef(false);

  /**
   * If we reach the provider without a graphId in the URL, we redirect
   * after either creating a new graph or fetching an existing one.
   *
   * The graphId is necessary for subsequent providers inside
   * the graph editor. TODO: as the graphId is already available in the store
   * we should remove dependencies on useParams.
   */
  useEffect(() => {
    // Skip if we've already handled initialization
    if (hasInitializedRef.current) return;
    hasInitializedRef.current = true;

    if (savedGraphId) {
      // We have a saved graph but no URL graphId - navigate to it
      if (!graphId) {
        navigate(savedGraphId, { replace: true });
      }
    } else {
      // No saved graph - create one and link it
      const createdGraphId = initGraph();
      linkGraph(createdGraphId);

      if (!graphId) {
        navigate(createdGraphId, { replace: true });
      }
    }
  }, [savedGraphId, graphId]);

  /**
   * When leaving the graph editor we set the current template based on the
   * graph definition. This is necessary for other components that want to make use
   * of the graph template but cannot access the graph store directly.
   */
  // We set the updated value in the global store when the graph changes
  // so the dependencies are updated, required for correct rendering.
  // TODO: This is a workaround, we should find a better solution.
  const updateValueOnGraphChange = () => {
    if (!savedGraphId) return;
    const template = logicTreeToTemplate(savedGraphId);
    setTemplateValue(template);
    addDirty(entryId, DirtyType.Template);
  };

  useEffect(() => {
    updateValueOnGraphChange();

    return () => updateValueOnGraphChange();
  }, []);

  const globalStore = useStore();
  const storeRef = useRef<GraphStore>();
  if (!savedGraphId) return null;
  if (!storeRef.current) {
    storeRef.current = createGraphStore(globalStore, {
      id: savedGraphId,
    });
  }

  return (
    <GraphContext.Provider value={storeRef.current}>
      {children}
    </GraphContext.Provider>
  );
};
