import styled from "@emotion/styled";
import { List } from "@mantine/core";

// Below makes the itemwrapper take up the full width of the sidebar
export const StyledList = styled(List)`
  list-style: none;
  & > li {
    margin-bottom: 0.5rem;
  }
  & > li > div {
    width: 100%;
  }
`;
