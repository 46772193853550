import styled from '@emotion/styled';
import { Box, BoxProps, createPolymorphicComponent } from '@mantine/core';
import { BaseHeader } from '@morph-mapper/ui';

export const JsonViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const _JsonViewContent = styled(Box)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 0px;
  overflow: auto;
`;

export const JsonViewContent = createPolymorphicComponent<'div', BoxProps>(
  _JsonViewContent
);

export const JsonViewHeader = styled(BaseHeader)`
  justify-content: center;
`;
