import styled from "@emotion/styled";
import { Accordion } from "@mantine/core";

export const SideBarWrapper = styled.div(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: theme.colors.gray[1],
}));

export const SideBarContent = styled.div`
  height: calc(100% - 60px);
  overflow-y: auto;
  padding: 2rem;
`;

export const StyledAccordion = styled(Accordion)`
  margin-top: 2rem;
`;
