import { Text, Stack } from "@mantine/core";

interface FormLabelProps {
  title: string;
  description: string;
  fullWidth?: boolean;
}

export const ConfigLabel = ({
  title,
  description,
  fullWidth,
}: FormLabelProps) => {
  return (
    <Stack spacing={0} sx={{ maxWidth: fullWidth ? undefined : 250 }}>
      <Text fz="sm" fw={500}>
        {title}
      </Text>
      <Text c="dimmed" fz="xs">
        {description}
      </Text>
    </Stack>
  );
};
