import { useQuery, useMutation} from "@tanstack/react-query";
import { sendResultToUI } from "./crud";

/**
 * Mutation wrapper for sending the parsed result
 * of a rendered template to an external UI.
 */
export const useSendResultToUI = () => {
  return useMutation({
    mutationFn: (template: string) => sendResultToUI(template),
  });
};
