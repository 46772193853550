import { useRef } from 'react';
import { RowContext } from './context';
import { useNavigate } from 'react-router-dom';
import { RowStore, createRowStore } from '../../store';
import { useStore } from '@/store';

export interface RowProviderProps extends React.PropsWithChildren {
  id: string;
}

export const RowProvider = ({ id, children }: RowProviderProps) => {
  const navigate = useNavigate();

  const globalStore = useStore();
  const storeRef = useRef<RowStore>();

  if (!storeRef.current) {
    storeRef.current = createRowStore(globalStore, {
      id,
      navigate,
    });
  }

  return (
    <RowContext.Provider value={storeRef.current}>
      {children}
    </RowContext.Provider>
  );
};
