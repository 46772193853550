import { useStore } from '@/store';
import {
  useDeployTemplate,
  useExportTemplate,
  useSaveState,
  useTestTemplate,
} from '@/hooks';
import { LayoutDirection } from '@/types';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { DOCS_USER_BASE_URL } from '@/config';

export const useHeader = () => {
  /* Global State */
  const setLayoutDirection = useStore(({ ui }) => ui.setLayoutDirection);
  /* Hooks */
  const navigate = useNavigate();
  const { handleTestTemplate } = useTestTemplate();
  const { save } = useSaveState();
  const { deployTemplate } = useDeployTemplate();
  const { localExport } = useExportTemplate();

  const [setNavTemplateOpen, setNavDomainOpen, isSavingTemplate] = useStore(
    ({ config: c }) => [
      c.setIsNavTemplateModalOpen,
      c.setisNavDomainModalOpen,
      c.isSavingTemplate,
    ]
  );

  /**
   * Effects
   */

  useEffect(() => {
    if (isSavingTemplate) {
      handleSaveFile();
    }
  }, [isSavingTemplate]);

  /**
   * Handlers
   **/
  const handleSaveFile = async () => {
    save();
  };

  const handleDocumentation = () => {
    window.open(DOCS_USER_BASE_URL, '_blank');
  };

  const handleExportTemplate = () => {
    localExport();
  };

  const handleDeployTemplate = () => {
    deployTemplate();
  };

  const handleConfigureTemplate = () => {
    navigate('configure');
  };

  const setLayoutVertical = () => {
    setLayoutDirection(LayoutDirection.Column);
  };

  const setLayoutHorizontal = () => {
    setLayoutDirection(LayoutDirection.Row);
  };

  const handleNavigateToTemplateSelection = () => {
    setNavTemplateOpen(true);
  };

  const handleNavigateToDomainSelection = () => {
    setNavDomainOpen(true);
  };

  return {
    handleTestTemplate,
    handleSaveFile,
    handleExportTemplate,
    handleDeployTemplate,
    handleConfigureTemplate,
    setLayoutVertical,
    setLayoutHorizontal,
    handleDocumentation,
    handleNavigateToTemplateSelection,
    handleNavigateToDomainSelection,
  };
};
