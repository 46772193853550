import { useMemo, useRef } from 'react';
import { EntryContext } from './context';
import { EntryStore, createEntryStore } from '../../store';
import { useStore } from '@/store';
import { useRelativePath } from '@/hooks';

interface EntryProviderProps extends React.PropsWithChildren {
  id: string;
}

export const EntryProvider = ({ children, id }: EntryProviderProps) => {
  const globalStore = useStore();
  const storeRef = useRef<EntryStore>();

  // TODO: check if placement is required at other components (inline not supported currently)
  useRelativePath({
    entryId: id,
  });

  if (!id) return null;
  if (!storeRef.current) {
    storeRef.current = createEntryStore(globalStore, {
      id,
    });
  }

  return (
    <EntryContext.Provider value={storeRef.current}>
      {children}
    </EntryContext.Provider>
  );
};
