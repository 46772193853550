import { useNotify } from '@/hooks';
import { useEffect } from 'react';
import { useSetupStore } from '../../store';
import {
  useCategoriesOfOperatorIdByDomain,
  useTemplateRefsOfOperatorId,
  useUpdateCategory,
  useUpdateTemplateRef,
} from '@/services';

export const useTemplateAccordionItem = (oldName: string, newName: string) => {
  /* Global State */
  const [domain, operatorId, setIsRenameOpen, setCategoryName] = useSetupStore(
    (s) => [
      s.getDomain(),
      s.getOperatorId(),
      s.setIsRenameCategoryOpen,
      s.setCategoryName,
    ]
  );

  /* Hooks */
  const { success, warn } = useNotify();

  /* Queries */
  const { data: categories } = useCategoriesOfOperatorIdByDomain(
    operatorId,
    domain
  );
  const { data: templates } = useTemplateRefsOfOperatorId(operatorId);

  const { mutate: updateCategory, isSuccess: updateSuccess } =
    useUpdateCategory();
  const { mutate: updateTemplate } = useUpdateTemplateRef();

  useEffect(() => {
    if (updateSuccess) {
      success('Category renamed');
    }
    setIsRenameOpen(false);
    setCategoryName('');
  }, [updateSuccess]);

  useEffect(() => {
    if (hasNameConflict(newName) && oldName !== newName) {
      warn('Category name already exists');
    }
  }, [newName]);

  /**
   * Handlers
   **/
  const handleRenameCancel = () => {
    setIsRenameOpen(false);
    setCategoryName('');
  };

  const handleRenameSave = () => {
    handleRename(oldName, newName);
  };

  const handleRename = (oldName: string, newName: string) => {
    if (categories === undefined || domain === undefined || !templates) return;

    const categoryId = categories.find(
      (category) => category.name === oldName
    )?.id;

    if (categoryId === undefined) return;

    // Rename category and update template refs
    // TODO: lets move this to the backend, frontend should not manage the internal state of the database
    const templatesOfCategory = templates.filter(
      (template) => template.category === oldName && template.domain === domain
    );

    const templateIds = templatesOfCategory.map((template) => template.id);
    updateTemplatesOfCategory(templateIds);

    updateCategory({
      id: categoryId,
      category: {
        name: newName,
      },
    });
  };

  const hasNameConflict = (newName: string) => {
    if (categories === undefined || domain === undefined) return;

    return categories.some(
      (category) => category.name === newName && category.domain === domain
    );
  };

  // No longer necessary if above TODO is implemented
  const updateTemplatesOfCategory = (templateIds: string[]) => {
    templateIds.forEach((templateId) => {
      updateTemplate({
        id: templateId,
        template: {
          category: newName,
        },
      });
    });
  };

  return {
    handleRenameSave,
    handleRenameCancel,
    renameSaveDisabled: newName === '' || hasNameConflict(newName),
  };
};
