import { NumberInput, Text } from '@mantine/core';
import { EditPropertyRow } from '../../atoms';
import { useEditCell } from './use-edit-cell';
import { InfoTooltip } from '@morph-mapper/ui';

export const EditCellItem = () => {
  const { column, setColumn } = useEditCell();

  return (
    <tbody>
      <tr>
        <EditPropertyRow>
          <Text>Column</Text>
          <InfoTooltip label="Select target column to extract cells from rows." />
        </EditPropertyRow>
        <td style={{ border: 'none' }}>
          <NumberInput
            sx={{ maxWidth: 200 }}
            value={column}
            onChange={setColumn}
          />
        </td>
      </tr>
    </tbody>
  );
};
