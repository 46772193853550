import { useEffect, useState } from 'react';
import { useStore } from '@/store';
import { ReservedNodeType } from '@morph-mapper/types';
import { EntryType } from '@morph-mapper/types';
import { useTreeContext } from '../../context';
import { useDisclosure } from '@mantine/hooks';

export const useListView = () => {
  /* Global state */
  const entry = useStore(({ entries: e }) => e.entry);
  /* Context state */
  const currentId = useTreeContext((s) => s.getCurrentId());
  /* Local state */
  const [active, setActive] = useState<string[]>([]);
  const [contextListActive, setContextListActive] = useState(false);
  const [
    isCreateEntryModalOpen,
    { open: openCreateEntry, close: closeCreateEntry },
  ] = useDisclosure(false);

  useEffect(() => {
    if (!contextListActive) {
      setActive(['tree']);
    } else {
      setActive(['tree', 'entries']);
    }
  }, [currentId, contextListActive]);

  useEffect(() => {
    setContextListActive(getVariables().length > 0);
  }, [currentId]);

  // TODO: Cumulative variables may make it difficult to understand context.
  // A future feauture would be to show all declareVariables through a tabs panel.
  const recursiveFindDeclareVariables = (entryId: string): string[] => {
    const ids: string[] = [];
    const children = entry(entryId, EntryType.Map).children();

    const foundId = children.getId(ReservedNodeType.DeclareVariable);
    if (foundId !== undefined) {
      ids.push(...entry(foundId, EntryType.Map).children().getIds());
    }

    children
      .getIds()
      .filter((id) => entry(id).getType() === EntryType.Map)
      .map((id) => ids.push(...recursiveFindDeclareVariables(id)));

    return ids;
  };

  const getVariables = () => {
    return recursiveFindDeclareVariables(currentId);
  };

  const handleChangeActive = (value: string[]) => {
    setActive(value);
  };

  const handleCreateEntry = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    openCreateEntry();
  };

  return {
    isCreateEntryModalOpen,
    closeCreateEntry,
    active,
    handleCreateEntry,
    handleChangeActive,
    getVariables,
    contextListActive,
  };
};
