import { SideBarContent, SideBarWrapper, StyledList } from './styles';
import { BlockItem, Header } from '../../molecules';
import { useStore } from '@/store';
import { useLogicBlocks } from '@morph-mapper/node-logic';

export const SelectorView = () => {
  const variant = useStore(({ config: c }) => c.getVariant());
  const { logicBlocksByCategory } = useLogicBlocks(variant);

  return (
    <SideBarWrapper>
      <Header />
      <SideBarContent>
        <StyledList>
          {logicBlocksByCategory['iterator'].map(({ type }) => (
            <BlockItem type={type} key={type} />
          ))}
        </StyledList>
      </SideBarContent>
    </SideBarWrapper>
  );
};
