import { Select, Text, TextInput } from '@mantine/core';
import { EditPropertyRow } from '../../atoms';
import { useSimpleEntryContext } from '../../context';
import { InfoTooltip } from '@morph-mapper/ui';
import { isZodEnum } from '@morph-mapper/types';
import { useEditSimple } from './use-edit-simple';

export const EditSimpleItem = () => {
  const [validation, setNewValue] = useSimpleEntryContext((s) => [
    s.getValidation(),
    s.setNewValue,
  ]);

  const { literal, handleTextChange } = useEditSimple();

  if (isZodEnum(validation)) {
    return (
      <tbody>
        <tr>
          <EditPropertyRow>
            <Text>Item</Text>
            <InfoTooltip label="Select item from a collection." />
          </EditPropertyRow>
          <td style={{ border: 'none' }}>
            <Select
              sx={{ maxWidth: 200 }}
              data={validation?.options}
              value={literal}
              onChange={(value) =>
                value ? setNewValue(value) : setNewValue(undefined)
              }
            />
          </td>
        </tr>
      </tbody>
    );
  } else {
    return (
      <tbody>
        <tr>
          <EditPropertyRow>
            <Text>Literal</Text>
            <InfoTooltip label="Define a custom value." />
          </EditPropertyRow>
          <td style={{ border: 'none' }}>
            <TextInput
              sx={{ maxWidth: 200 }}
              value={literal}
              onChange={handleTextChange}
            />
          </td>
        </tr>
      </tbody>
    );
  }
};
