import { Extractor } from '.';

/**
 * A reference represents an element in the file's structure.
 * Multiple references can be combined to form a reference path.
 *
 * @property type - The type of the reference.
 * @property value - The value of the reference.
 * @property operation - The operation performed on the reference value, based on the reference type.
 * @property next - The next reference in the reference path.
 * @property prev - The previous reference in the reference path.
 */
export type Reference = {
  type: Extractor;
  value: string | null;
  operation: string | number | null;
  next: string | null;
  prev: string | null;
};

/**
 * A path represents a collection of references. It is used to infer the location of the user request,
 * using the file's structure. The path is comprised of a record where the key is the id of the accompanying reference.
 */
export type Path = Record<string, Reference>;

/**
 * The path mode gives the user the choise to create paths in 2 ways:
 * - Absolute, the path is created from the root of the file structure.
 * - Relative, the path is created by extending an existing accessible path defined in another part of the template.
 */
export enum PathMode {
  Absolute = 'absolute',
  Relative = 'relative',
}
