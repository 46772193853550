import { Select, TextInput } from "@mantine/core";
import { isZodEnum } from "@morph-mapper/types";
import { ZodType } from "zod";

interface EditCellProps {
  handleChange: (value: string | undefined) => void;
  value: string | undefined;
  validation?: ZodType<any>;
}

export const EditCell = ({
  handleChange,
  value,
  validation,
}: EditCellProps) => {
  if (isZodEnum(validation)) {
    return (
      <Select
        defaultValue={value}
        data={validation.options}
        onChange={(value) =>
          value ? handleChange(value) : handleChange(undefined)
        }
      />
    );
  }

  return (
    <TextInput
      value={value ?? ""}
      onChange={(event) => handleChange(event.target.value)}
    />
  );
};
