import { useEffect } from 'react';
import { useBooleanEntryContext } from '../../context/boolean-entry';

export const useEditBoolean = () => {
  /* Context State */
  const [currentBool, setNewValue] = useBooleanEntryContext((s) => [
    s.getBoolean(),
    s.setNewValue,
  ]);

  useEffect(() => {
    if (currentBool === undefined) {
      setNewValue(false);
    }
  }, []);

  const setBoolValue = (value: string) => {
    setNewValue(value === 'true');
  };

  return { bool: currentBool, setBoolValue };
};
