import { BaseHeader, Grid } from '@morph-mapper/ui';
import { Box, Group, Menu, Text } from '@mantine/core';
import { useStore } from '@/store';
import { FiSave } from 'react-icons/fi';
import {
  RiLayoutColumnFill,
  RiLayoutRowFill,
  RiTestTubeLine,
  RiQuestionLine,
} from 'react-icons/ri';
import { TbFileExport, TbSettings } from 'react-icons/tb';
import { useHeader } from './use-header';
import { LayoutDirection } from '@/types';
import { MdSaveAlt, MdFirstPage, MdFormatListBulleted } from 'react-icons/md';

export const Header = () => {
  const saveDisabled = useStore(({ config: c }) => c.getIsSaveDisabled());
  const direction = useStore(({ ui }) => ui.layoutDirection);
  const {
    handleTestTemplate,
    handleSaveFile,
    handleExportTemplate,
    handleDeployTemplate,
    handleConfigureTemplate,
    handleDocumentation,
    setLayoutVertical,
    setLayoutHorizontal,
    handleNavigateToTemplateSelection,
    handleNavigateToDomainSelection,
  } = useHeader();

  return (
    <BaseHeader>
      <Text>MorphMapper</Text>
      <Group>
        <Box sx={{ display: 'flex', alignItems: 'center', zIndex: 100 }}>
          <Menu shadow="md" width={150} position="bottom-end">
            <Menu.Target>
              <Grid />
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Label>Templates</Menu.Label>
              <Menu.Item
                disabled={saveDisabled}
                icon={<RiTestTubeLine />}
                onClick={handleTestTemplate}
              >
                Test
              </Menu.Item>
              <Menu.Item
                disabled={saveDisabled}
                icon={<FiSave />}
                onClick={handleSaveFile}
              >
                Save
              </Menu.Item>
              <Menu.Item
                disabled={saveDisabled}
                icon={<MdSaveAlt />}
                onClick={handleExportTemplate}
              >
                Local Save
              </Menu.Item>
              <Menu.Item
                disabled={saveDisabled}
                icon={<TbFileExport />}
                onClick={handleDeployTemplate}
              >
                Deploy
              </Menu.Item>
              <Menu.Item
                icon={<TbSettings />}
                onClick={handleConfigureTemplate}
              >
                Configure
              </Menu.Item>
              <Menu.Label>Layout</Menu.Label>
              {direction === LayoutDirection.Column ? (
                <Menu.Item
                  icon={<RiLayoutRowFill />}
                  onClick={setLayoutHorizontal}
                >
                  Horizontal
                </Menu.Item>
              ) : (
                <Menu.Item
                  icon={<RiLayoutColumnFill />}
                  onClick={setLayoutVertical}
                >
                  Vertical
                </Menu.Item>
              )}
              <Menu.Label>Documentation</Menu.Label>
              <Menu.Item
                icon={<RiQuestionLine />}
                onClick={handleDocumentation}
              >
                Help
              </Menu.Item>
              <Menu.Label>Navigate</Menu.Label>
              <Menu.Item
                icon={<MdFormatListBulleted />}
                onClick={handleNavigateToTemplateSelection}
              >
                Template Selection
              </Menu.Item>
              <Menu.Item
                icon={<MdFirstPage />}
                onClick={handleNavigateToDomainSelection}
              >
                Domain Selection
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Box>
      </Group>
    </BaseHeader>
  );
};
