import { Tooltip } from "@mantine/core";
import { IconWrapper } from "./styles";
import { IoMdInformation } from "react-icons/io";

interface InfoTooltipProps {
  label: string;
}

export const InfoTooltip = ({ label }: InfoTooltipProps) => {
  return (
    <Tooltip sx={{ maxWidth: 220 }} label={label} multiline>
      <IconWrapper>
        <IoMdInformation color="gray" size={15} />
      </IconWrapper>
    </Tooltip>
  );
};
