import styled from "@emotion/styled";
import { Badge, BadgeProps, createPolymorphicComponent } from "@mantine/core";

export const Layout = styled("div")(() => ({
  display: "flex",
  gap: "1rem",
}));

export const _StyledBadge = styled(Badge)(() => ({
  height: "inherit",

  "& .mantine-Badge-inner": {
    display: "flex",
    minWidth: "20px",
    justifyContent: "center",
  },
}));

export const StyledBadge = createPolymorphicComponent<"div", BadgeProps>(
  _StyledBadge
);
