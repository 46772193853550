import { useMantineTheme } from "@mantine/core";
import { FiTrash2 } from "react-icons/fi";
import { Action, ActionProps } from "../action";

export const Delete = (props: ActionProps) => {
  const { colors } = useMantineTheme();

  return (
    <Action
      Icon={FiTrash2}
      color={"red"}
      variant="outline"
      borderColor={`1px solid ${colors.red[3]}`}
      {...props}
    />
  );
};
