import { Group, Radio } from "@mantine/core";

interface RadioSelectorProps {
  value: string;
  handleChange: (value: string) => void;
  selection: { value: string; label: string }[];
}

export const RadioSelector = ({
  value,
  handleChange,
  selection,
}: RadioSelectorProps) => {
  return (
    <Radio.Group onChange={handleChange} defaultValue={value}>
      <Group>
        {selection.map((s) => (
          <Radio value={s.value} label={s.label} key={s.label} />
        ))}
      </Group>
    </Radio.Group>
  );
};
