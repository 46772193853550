import { LogicBlockIndex } from "@morph-mapper/node-logic";
import { useGraphContext } from "../../context";
import { useNavigate } from "react-router-dom";

export const useBlockItem = (type: LogicBlockIndex) => {
  const createSubGraph = useGraphContext((s) => s.createSubGraph);
  const navigate = useNavigate();

  const handleCreate = () => {
    createSubGraph(type);
    navigate(-1);
  };

  return { handleCreate };
};
