import { useContext } from "react";
import { useStore } from "zustand";
import { ParameterRowState } from "../../store";
import { ParameterRowContext } from "./context";

export const useParameterRowContext = <T>(
  selector: (state: ParameterRowState) => T,
  equalityFn?: (left: T, right: T) => boolean
): T => {
  const store = useContext(ParameterRowContext);
  if (!store)
    throw new Error("Missing ParameterRowContext.Provider in the parameterrow");
  return useStore(store, selector, equalityFn);
};
