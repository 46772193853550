import styled from "@emotion/styled";

export const SectionWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 1rem;
  padding-right: 1rem;
  padding-left: 1rem;
`;
