import { readEml } from 'eml-parse-js';
import { useStore } from '../../context';
import { useState } from 'react';

interface ParsedEmlAttachment {
  id?: string;
  contentId?: string;
  contentType: string;
  data: Uint8Array;
}

interface ParsedEmlContent {
  text: string;
  html: string;
  attachments: ParsedEmlAttachment[];
}

const sanitizeHtml = (html: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');

  // Remove script elements
  const scripts = doc.querySelectorAll('script');
  scripts.forEach((script) => script.remove());

  // Remove potentially harmful attributes
  const elements = doc.body.querySelectorAll('*');
  elements.forEach((element) => {
    const attributes = Array.from(element.attributes);
    for (const attr of attributes) {
      if (
        attr.name.startsWith('on') ||
        (['src', 'href'].includes(attr.name) && !attr.value.startsWith('http'))
      ) {
        element.removeAttribute(attr.name);
      }
    }
  });

  return doc.body.innerHTML;
};

// Convert Uint8Array to Base64
const arrayBufferToBase64 = (buffer: Uint8Array) => {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};

const extractImages = (emlContent: ParsedEmlContent) => {
  const images: { cid: string; data: string; mimeType: string }[] = [];

  if (emlContent.attachments) {
    emlContent.attachments.forEach((attachment: ParsedEmlAttachment) => {
      const cid = attachment.contentId || attachment.id; // Check both fields
      if (attachment.contentType.startsWith('image/') && cid) {
        images.push({
          cid,
          data: arrayBufferToBase64(attachment.data),
          mimeType: attachment.contentType,
        });
      }
    });
  }

  return images;
};

const serveImage = (image: { cid: string; data: string; mimeType: string }) => {
  const base64Image = `data:${image.mimeType};base64,${image.data}`;
  return base64Image;
};

const replaceCidUrls = (
  htmlContent: string,
  images: { cid: string; data: string; mimeType: string }[]
) => {
  let updatedHtml = htmlContent;

  images.forEach((image) => {
    const cidUrl = `cid:${image.cid}`;
    const imageUrl = serveImage(image);
    const regex = new RegExp(`src=["']cid:${image.cid}["']`, 'g');
    updatedHtml = updatedHtml.replace(regex, `src="${imageUrl}"`);
  });

  return updatedHtml;
};

export const useEmail = () => {
  const file = useStore(({ file: f }) => f.getFile());
  const [emailContent, setEmailContent] = useState<{
    text: string;
    html: string;
  }>({ text: '', html: '' });
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchParsedEml = async (file: File): Promise<ParsedEmlContent> => {
    const fileText = await file.text();
    return new Promise((resolve, reject) => {
      readEml(fileText, (error: any, data: any) => {
        if (error) {
          reject(error);
        } else {
          const parsedContent: ParsedEmlContent = {
            text: data.text || '',
            html: data.html || '',
            attachments: data.attachments || [],
          };
          resolve(parsedContent);
        }
      });
    });
  };

  const readEmail = async () => {
    if (!file) {
      console.error('File is null. Cannot read email.');
      setIsLoading(false);
      return;
    }

    const parsedContent = await fetchParsedEml(file);
    const images = extractImages(parsedContent);
    // TODO: image rendering not working, fix it
    const updatedHtml = replaceCidUrls(parsedContent.html, images);
    const sanitizedHtml = sanitizeHtml(updatedHtml);
    setEmailContent({ text: parsedContent.text, html: sanitizedHtml });
    setIsLoading(false);
  };

  return {
    readEmail,
    isLoading,
    emailContent,
  };
};
