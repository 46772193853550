import styled from "@emotion/styled";

export const Body = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  gap: 20,
}));

export const FormLayout = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  gap: 20,
}));

export const Footer = styled("div")(() => ({
  display: "flex",
  justifyContent: "flex-end",
}));
