import _ from 'lodash';

export const findAllOccurrences = (obj: any, key: string) => {
  const results: any[] = [];

  const recursiveSearch = (obj: any, key: string) => {
    _.forOwn(obj, (value, k) => {
      if (k === key) {
        results.push(value);
      }
      if (_.isObject(value)) {
        recursiveSearch(value, key);
      }
    });
  };

  recursiveSearch(obj, key);

  return results;
};
