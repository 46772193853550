import { MIME_TYPES } from '@mantine/dropzone';
import { SchemaVariant } from '@morph-mapper/types';

export const PDF_MIME_TYPES = [MIME_TYPES.pdf];
export const TABLE_MIME_TYPES = [
  MIME_TYPES.xls,
  MIME_TYPES.xlsx,
  MIME_TYPES.csv,
];
export const XML_MIME_TYPES = ['application/xml', 'text/xml'];
export const JSON_MIME_TYPES = ['application/json'];
export const TEXT_MIME_TYPES = ['text/plain'];
export const EMAIL_MIME_TYPES = ['message/rfc822'];

export const MIME_TYPE_TO_EXTENSION: { [key: string]: string } = {
  [MIME_TYPES.pdf]: 'PDF',
  [MIME_TYPES.xls]: 'XLS',
  [MIME_TYPES.xlsx]: 'XLSX',
  [MIME_TYPES.csv]: 'CSV',
  'application/xml': 'XML',
  'text/xml': 'XML',
  'application/json': 'JSON',
  'text/plain': 'TXT',
  'message/rfc822': 'EML',
};

export const ACCEPTED_FILE_EXTENSIONS: Record<SchemaVariant, string[]> = {
  [SchemaVariant.Pdf]: PDF_MIME_TYPES,
  [SchemaVariant.Table]: TABLE_MIME_TYPES,
  [SchemaVariant.Xml]: XML_MIME_TYPES,
  [SchemaVariant.Text]: TEXT_MIME_TYPES,
  [SchemaVariant.Email]: EMAIL_MIME_TYPES,
  [SchemaVariant.Json]: JSON_MIME_TYPES,
};

export const NODE_LOCATION_TYPES = ['anchor'];
