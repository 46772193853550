import styled from '@emotion/styled';

export const StyledObjectRow = styled('tr')(({ theme }) => ({
  height: '60px',
}));

export const StyledActionCell = styled('div')`
  display: flex;
  height: 100%
  align-items: center;
  min-width: 80px;
`;
