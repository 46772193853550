import { Text } from "@mantine/core";
import { useSetupStore } from "../../store";
import { Badge, ListItem, TextWrapper } from "./styles";

interface TemplateSelectHeaderProps {
  children: React.ReactNode;
}

export const TemplateSelectHeader = ({
  children,
}: TemplateSelectHeaderProps) => {
  const domain = useSetupStore((s) => s.domain);

  return (
    <ListItem
      style={{
        position: "sticky",
        top: 0,
        zIndex: 2,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <TextWrapper>
        <Text fz="md" color="black">
          <Badge>Domain</Badge>
        </Text>

        <Text fz="md" color="black" pl={"md"} pr={"md"}>
          {domain}
        </Text>
      </TextWrapper>
      {children}
    </ListItem>
  );
};
