import {
  FileViewContent,
  FileViewHeader,
  FileViewWrapper,
  TextContent,
} from './styles';
import { Footer } from '../../molecules';
import { Move } from '@morph-mapper/ui';
import { useTextSelect } from '../../hooks';
import { Group } from '@mantine/core';
import { useText } from './use-text';

export const TextView = () => {
  const { pageNumber, numPages, getPageText, nextPage, previousPage } =
    useText();
  useTextSelect();

  return (
    <FileViewWrapper>
      <FileViewHeader>
        <Group>
          <Move
            into={false}
            onClick={previousPage}
            disabled={pageNumber <= 1}
          />
          {pageNumber} / {numPages}
          <Move onClick={nextPage} disabled={pageNumber >= numPages} />
        </Group>
      </FileViewHeader>
      <FileViewContent>
        <TextContent>{getPageText()}</TextContent>
      </FileViewContent>
      <Footer />
    </FileViewWrapper>
  );
};
