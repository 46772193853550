import { Group, Select } from '@mantine/core';
import { useRelativePaths } from '../../hooks';
import { useStore } from '../../context';

export const SegmentRelative = () => {
  const { reference } = useStore(({ path: p }) => p.getRelativePath());
  const { getRelativePathSelection, onRelativePathChange } = useRelativePaths();

  return (
    <Group>
      <Select
        value={reference}
        data={getRelativePathSelection()}
        onChange={onRelativePathChange}
      />
    </Group>
  );
};
