import { XmlOperator } from './operator';
import { JsonSelector, XmlSelector } from './selector';

/**
 * Determines the selection mode of the file viewer.
 * Text mode allows for selecting text directly in the file,
 * while reference mode infers the location of the user request using the file's structure.
 */
export enum SelectionMode {
  Text = 'text',
  Reference = 'reference',
}

/**
 * The extractor determines how the value of a node is extracted from the file.
 */
export type JsonExtractor = JsonSelector;
export type XmlExtractor = XmlOperator | XmlSelector;
export type Extractor = XmlExtractor | JsonExtractor;

/**
 * A reference represents an element in the file's structure.
 * Multiple references can be combined to form a reference path.
 *
 * @property type - The type of the reference.
 * @property value - The value of the reference.
 * @property operation - The operation performed on the reference value, based on the reference type.
 * @property next - The next reference in the reference path.
 * @property prev - The previous reference in the reference path.
 */
export type Reference = {
  type: Extractor;
  value: string | null;
  operation: string | number | null;
  next: string | null;
  prev: string | null;
};

export const isXmlOperator = (value: string): value is XmlOperator =>
  Object.values(XmlOperator).includes(value as XmlOperator);

export * from './operator';
export * from './selector';
export * from './path';
export * from './ui';
