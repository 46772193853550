import { Group, Radio, Text } from '@mantine/core';
import { EditPropertyRow } from '../../atoms';
import { InfoTooltip } from '@morph-mapper/ui';
import { useEditBoolean } from './use-edit-boolean';

export const EditBooleanItem = () => {
  const { bool, setBoolValue } = useEditBoolean();

  return (
    <tbody>
      <tr>
        <EditPropertyRow>
          <Text>Boolean</Text>
          <InfoTooltip label="Define a value." />
        </EditPropertyRow>
        <td style={{ border: 'none' }}>
          <Radio.Group value={bool ? 'true' : 'false'} onChange={setBoolValue}>
            <Group>
              <Radio value="true" label="True" />
              <Radio value="false" label="False" />
            </Group>
          </Radio.Group>
        </td>
      </tr>
    </tbody>
  );
};
