import { SchemaVariant } from '@morph-mapper/types';
import { PdfView } from '../pdf';
import { TableView } from '../table';
import { XmlView } from '../xml';
import { TextView } from '../text';
import { EmailView } from '../email';
import { useStore } from '../../context';
import { match } from 'ts-pattern';
import { XmlModal } from '../../molecules/xml-modal';
import { XmlNodeCreate } from '../../molecules/xml-node-create';
import { JsonView } from '../json';
import { JsonNodeCreate } from '../../molecules/json-node-create';
import { JsonModal } from '../../molecules/json-modal';

export const FileView = () => {
  const [variant, isConfigRefModalOpen, isCreateRefModalOpen] = useStore(
    ({ file: f, ui }) => [
      f.getVariant(),
      ui.isConfigRefModalOpen(),
      ui.isCreateRefModalOpen(),
    ]
  );

  if (variant === null) return null;

  return (
    <>
      {match(variant)
        .with(SchemaVariant.Pdf, () => <PdfView />)
        .with(SchemaVariant.Table, () => <TableView />)
        .with(SchemaVariant.Xml, () => <XmlView />)
        .with(SchemaVariant.Text, () => <TextView />)
        .with(SchemaVariant.Email, () => <EmailView />)
        .with(SchemaVariant.Json, () => <JsonView />)
        .exhaustive()}

      {isConfigRefModalOpen &&
        match(variant)
          .with(SchemaVariant.Pdf, () => {
            throw 'Not implemented';
          })
          .with(SchemaVariant.Table, () => {
            throw 'Not implemented';
          })
          .with(SchemaVariant.Xml, () => <XmlModal />)
          .with(SchemaVariant.Text, () => {
            throw 'Not implemented';
          })
          .with(SchemaVariant.Email, () => {
            throw 'Not implemented';
          })
          .with(SchemaVariant.Json, () => <JsonModal />)
          .exhaustive()}

      {isCreateRefModalOpen &&
        match(variant)
          .with(SchemaVariant.Pdf, () => {
            throw 'Not implemented';
          })
          .with(SchemaVariant.Table, () => {
            throw 'Not implemented';
          })
          .with(SchemaVariant.Xml, () => <XmlNodeCreate />)
          .with(SchemaVariant.Text, () => {
            throw 'Not implemented';
          })
          .with(SchemaVariant.Email, () => {
            throw 'Not implemented';
          })
          .with(SchemaVariant.Json, () => <JsonNodeCreate />)
          .exhaustive()}
    </>
  );
};
