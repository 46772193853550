import { Table } from '@mantine/core';
import { StyledRow, StyledTitle } from './styles';
import { match } from 'ts-pattern';
import {
  CellEntryProvider,
  GraphEntryProvider,
  SimpleEntryProvider,
} from '../../context';
import { ItemRow } from '../../molecules';
import { EditSimpleItem } from '../../molecules/edit-simple-item';
import { EditCellItem } from '../../molecules/edit-cell-item';
import { EntryType } from '@morph-mapper/types';
import { useStore } from '@/store';
import { BooleanEntryProvider } from '../../context/boolean-entry';
import { EditBooleanItem } from '../../molecules/edit-boolean-item';

interface ListContextVariablesProps {
  variableIds: string[];
}

export const ListContextVariables = ({
  variableIds,
}: ListContextVariablesProps) => {
  return (
    <Table highlightOnHover sx={{ tableLayout: 'fixed' }}>
      <thead>
        <StyledRow>
          <th style={{ width: '30%' }}>
            <StyledTitle>Name</StyledTitle>
          </th>
          <th>
            <StyledTitle>Value</StyledTitle>
          </th>
          <th style={{ width: 160 }}>
            <StyledTitle>Actions</StyledTitle>
          </th>
        </StyledRow>
      </thead>
      <tbody>
        {variableIds.map((id) => {
          return <ProviderSelector key={id} entryId={id} />;
        })}
      </tbody>
    </Table>
  );
};

// TODO: This is duplicated code from apps/builder-ui/src/components/entry-editor/organisms/provider-selector/provider-selector.tsx
// Create a shared component for this
interface ProviderSelectorProps {
  entryId: string;
}

export const ProviderSelector = ({ entryId }: ProviderSelectorProps) => {
  const { getType, getName } = useStore(({ entries: e }) => e.entry(entryId));

  // FIXME: The getName function crashes when deleting an entry.
  // This is due to the tree not registering changes to the children of the current parent.
  // For now we check if undefined, but this leaves the entry until you move up and down the tree.
  const name = getName();
  if (!name) {
    return null;
  }

  return match(getType())
    .with(EntryType.Boolean, () => (
      <BooleanEntryProvider id={entryId}>
        <ItemRow editView={<EditBooleanItem />} />
      </BooleanEntryProvider>
    ))
    .with(EntryType.Simple, () => (
      <SimpleEntryProvider id={entryId}>
        <ItemRow editView={<EditSimpleItem />} />
      </SimpleEntryProvider>
    ))
    .with(EntryType.Cell, () => (
      <CellEntryProvider id={entryId}>
        <ItemRow editView={<EditCellItem />} />
      </CellEntryProvider>
    ))
    .with(EntryType.Graph, () => (
      <GraphEntryProvider id={entryId}>
        <ItemRow />
      </GraphEntryProvider>
    ))
    .with(EntryType.Map, EntryType.Internal, () => null)
    .exhaustive();
};
