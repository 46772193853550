import { useStore } from '@/store';
import { Button, Group, Modal } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { useSetupStore } from '../../../setup/store';

export const NavigateModal = () => {
  const [navTemplateOpen, navDomainOpen, setIsOpenTemplate, setIsOpenDomain, isSaveDisabled, setIsSaving, userId] = useStore(({ config: c }) => [
    c.isNavTemplateModalOpen,
    c.isNavDomainModalOpen,
    c.setIsNavTemplateModalOpen,
    c.setisNavDomainModalOpen,
    c.isSaveDisabled,
    c.setIsSavingTemplate,
    c.userId,
  ]);

  const [setCurrentStep] = useSetupStore((s) => [
    s.setCurrentStep,
  ]);

  const navigate = useNavigate();


  let modalTitle = '';
  let modalContent = '';

  const isOpen = navTemplateOpen || navDomainOpen;

  if (navTemplateOpen) {
    modalTitle = 'Navigate to Template Selection';
    modalContent = "You are about to navigate to the template selection. Do you want to save your changes before navigating?";
  } else if (navDomainOpen) {
    modalTitle = 'Navigate to Domain Selection';
    modalContent = "You are about to navigate to the domain selection. Do you want to save your changes before navigating?";
  }

  const setStepforNavigation = () => {
    if (navTemplateOpen) {
      setCurrentStep(1);
    } else if (navDomainOpen) {
      setCurrentStep(0);
    }
  }

  const handleContinueWithoutSaving = () => {
    setStepforNavigation();
    navigate(`/?userId=${userId}`);

    closeModal(); 
  };

  const handleSaveAndContinue = () => {
    setStepforNavigation();
    setIsSaving(true);

    closeModal(); 
  };

  const closeModal = () => {
    if (navTemplateOpen) setIsOpenTemplate(false);
    if (navDomainOpen) setIsOpenDomain(false);
  };

  return (
    <Modal
      title={'Warning'}
      size="xl"
      padding="lg"
      opened={isOpen}
      onClose={closeModal}
    >
      {modalContent}
      <Group position="right" mt="md">
        <Button
          variant="outline"
          onClick={handleContinueWithoutSaving}
        >
          Continue without saving
        </Button>
        <Button
          color="blue"
          onClick={handleSaveAndContinue}
          disabled={isSaveDisabled}
        >
          Save and continue
        </Button>
      </Group>
    </Modal>
  );
};

export default NavigateModal;
