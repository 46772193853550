import { isTreeMap, TreeNode } from '@/types';
import { createTemplateReferencesFinder } from './template-references';
import Queue from 'queue-fifo';
import { getEntries, getKeys } from '@morph-mapper/utils';
import { match } from 'ts-pattern';
import { ROOT_KEY } from '@/config';

export enum ScanMode {
  Singular = 'singular',
  All = 'all',
}

// Factory function to create a dependency scanner
export const createDependencyScanner = (entries: Record<string, TreeNode>) => {
  const { resolveReferences } = createTemplateReferencesFinder(entries);
  const dependencies = new Map<
    string,
    { dependsOn: Set<string>; requiredBy: Set<string> }
  >();

  const registerRequiredBy = (id: string, requiredBy: string) => {
    const entry = dependencies.get(id);
    if (entry === undefined) {
      dependencies.set(id, {
        dependsOn: new Set(),
        requiredBy: new Set([requiredBy]),
      });
    } else {
      entry.requiredBy.add(requiredBy);
    }
  };

  const registerDependsOn = (id: string, dependsOn: string) => {
    const entry = dependencies.get(id);
    if (entry === undefined) {
      dependencies.set(id, {
        dependsOn: new Set([dependsOn]),
        requiredBy: new Set(),
      });
    } else {
      entry.dependsOn.add(dependsOn);
    }
  };

  const registerDependency = (id: string, dependencyId: string) => {
    registerDependsOn(id, dependencyId);
    registerRequiredBy(dependencyId, id);
  };

  const removeRequiredBy = (id: string, requiredBy: string) => {
    const entry = dependencies.get(id);
    if (entry === undefined) {
      return;
    } else {
      entry.requiredBy.delete(requiredBy);
    }
  };

  const removeDependsOn = (id: string, dependsOn: string) => {
    const entry = dependencies.get(id);
    if (entry === undefined) {
      return;
    } else {
      entry.dependsOn.delete(dependsOn);
    }
  };

  const removeDependency = (id: string, dependencyId: string) => {
    removeDependsOn(id, dependencyId);
    removeRequiredBy(dependencyId, id);
  };

  /**
   * Scans dependencies within a graph structure and registers them.
   *
   * @param {ScanMode} mode - The mode of scanning (`All` for full graph, `Singular` for a specific entry).
   * @param {string | null} [entryId=null] - The starting entry ID for `Singular` mode. Not required for `All` mode.
   * @returns {DependenciesMap} - The map of registered dependencies after the scan.
   *
   * @throws Will throw an error if:
   * - `entryId` is null when `ScanMode.Singular` is used.
   * - A parent entry is not a map where expected.
   * - The root is a reserved map entry.
   */
  const scanDependencies = (mode: ScanMode, entryId: string | null = null) => {
    const queue = new Queue<{ currentId: string; reservedMapIds: string[] }>();

    // Initialize the queue based on the scanning mode.
    match(mode)
      .with(ScanMode.All, () => {
        queue.enqueue({ currentId: ROOT_KEY, reservedMapIds: [] });
      })
      .with(ScanMode.Singular, () => {
        if (entryId === null) {
          throw new Error('Entry ID is required for singular scan mode');
        }
        queue.enqueue({ currentId: entryId, reservedMapIds: [] });
      })
      .exhaustive();

    // Process the queue until all entries have been handled.
    while (!queue.isEmpty()) {
      const { currentId, reservedMapIds } = queue.dequeue()!;
      const entry = entries[currentId];

      // Keys:

      /**
       * Case 0: Register a dependency between the current entry and its parent.
       */
      if (entry.parentId !== undefined) {
        registerDependency(currentId, entry.parentId);
      }

      /**
       * Case 1.5: Register a dependency to an iterator in the parent's children, if one exists.
       */
      if (entry.parentId !== undefined) {
        const parent = entries[entry.parentId];
        if (!isTreeMap(parent)) {
          throw new Error('Parent is not a map');
        }

        const iterator = getEntries(parent.children.forwardMap).find(
          ([_, name]) => name.startsWith('#iterator')
        );
        if (iterator !== undefined) {
          const [iteratorId, _] = iterator;
          registerDependency(currentId, iteratorId);
        }
      }

      /**
       * Case 1: Register dependencies for reserved map entries in the path from the current entry to the root.
       */
      if (reservedMapIds.length > 0) {
        reservedMapIds.forEach((id) => {
          registerDependency(currentId, id);
        });
      }

      /**
       * Case 2: If the current entry is a reserved map entry, register dependencies to its siblings.
       */
      if (entry.key.startsWith('$')) {
        if (entry.parentId === undefined) {
          throw new Error('The root cannot be a reserved map entry');
        }

        const parent = entries[entry.parentId];
        if (!isTreeMap(parent)) {
          throw new Error('Parent is not a map');
        }

        const siblings = getEntries(parent.children.forwardMap)
          .filter(([_, name]) => name.startsWith('$'))
          .map(([id, _]) => id);

        siblings.forEach((id) => {
          registerDependency(currentId, id);
        });
      }

      // Values:

      /**
       * Case 3 & 4: Resolve nested and direct value references.
       * - Case 3: Handles `$` references for variable declarations.
       * - Case 4: Handles `#>` references for iterators.
       * Uses `resolveReferences` to handle all nested and direct value references in the entry.
       */
      resolveReferences(entry.id, (declarationId) => {
        registerDependency(currentId, declarationId);
      });

      // Determine the next entries to process
      if (isTreeMap(entry)) {
        const newMapIds = entry.key.startsWith('$')
          ? [...reservedMapIds, currentId]
          : reservedMapIds;

        getKeys(entry.children.forwardMap).forEach((childId) => {
          queue.enqueue({ currentId: childId, reservedMapIds: newMapIds });
        });
      }

      // Remove self-dependency
      removeDependency(currentId, currentId);
    }

    return dependencies;
  };

  return {
    scanDependencies,
  };
};
