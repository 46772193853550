import { XmlView as View } from 'react-interactive-xml-viewer';
import { XmlViewContent, XmlViewHeader, XmlViewWrapper } from './styles';
import { useStore } from '../../context';
import { Group } from '@mantine/core';
import { Pointer } from '@morph-mapper/ui';
import { Footer } from '../../molecules';
import { SelectionMode } from '../../types';
import { useTextSelect } from '../../hooks';
import { useXmlPath } from './use-xml-path';
import { useXmlFile } from './use-xml-file';

export const XmlView = () => {
  const [setMode, mode] = useStore(({ file: f }) => [
    f.setSelectionMode,
    f.getSelectionMode(),
  ]);

  const { xml } = useXmlFile();
  const { createTagPath, createAttributePath, createValuePath } = useXmlPath();

  useTextSelect();

  if (xml === null) {
    return null;
  }

  return (
    <XmlViewWrapper>
      <XmlViewHeader>
        <Group>
          <Pointer
            onClick={() => setMode(SelectionMode.Reference)}
            tooltip="Select reference in document."
            variant={mode === SelectionMode.Reference ? 'filled' : 'default'}
            color={mode === SelectionMode.Reference ? 'orange' : undefined}
          />
        </Group>
      </XmlViewHeader>
      <XmlViewContent>
        <View
          xml={xml}
          onClickTag={
            mode === SelectionMode.Reference ? createTagPath : undefined
          }
          onClickAttribute={
            mode === SelectionMode.Reference ? createAttributePath : undefined
          }
          onClickValue={
            mode === SelectionMode.Reference ? createValuePath : undefined
          }
        />
      </XmlViewContent>
      <Footer />
    </XmlViewWrapper>
  );
};
