import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button } from '@mantine/core';
import { useStore } from '@/store';
import { ErrorBoundary } from '@morph-mapper/ui';
import { initialEdges, initialNodes } from '../../config';

export const GraphErrorBoundary = () => {
  const init = useStore(({ graphs: g }) => g.init);
  const { graphId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const isResetDisabled = graphId === undefined ? true : false;

  const handleResetGraph = () => {
    if (graphId === undefined) return;

    init(initialNodes, initialEdges, graphId);
    // Navigate in-place so that we do not break the relative navigation
    // of the header associated with this panel.
    navigate(location.pathname, { replace: true });
  };

  const handleOverview = () => {
    navigate('/entries');
  };

  return (
    <ErrorBoundary
      message="There was an error when generating the graph. We are working on fixing the issue. In the meantime, you can try one of the options below."
      buttons={[
        <Button
          color="blue"
          variant="filled"
          onClick={handleResetGraph}
          disabled={isResetDisabled}
        >
          Reset Graph
        </Button>,
        <Button color="gray" variant="outline" onClick={handleOverview}>
          Go to Overview
        </Button>,
      ]}
    />
  );
};
