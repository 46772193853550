import { createContext } from "@morph-mapper/context";
import { InitStoreProps, StoreState, createPackageStore } from "../store";

/**
 * Create a new store instance which will be used by the provider.
 * All useStore calls have to be children of the provider.
 *
 * @param props Initial properties when creating the store.
 * @returns Store instance.
 */
const useSetupStore = (props: InitStoreProps) => {
  return createPackageStore(props);
};

/**
 * Creates context provider and hook for external use.
 */
const { Provider, useStore } = createContext<StoreState, InitStoreProps>(
  useSetupStore
);

export { Provider, useStore };
