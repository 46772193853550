import { useStore } from '@/store';
import { useNotify, useRenderTemplate } from '@/hooks';
import { isTreeSimpleItem } from '@/types';
import { downloadFile } from '@/utils';

export const useExportTemplate = () => {
  /* Global state */
  const [name, entries] = useStore(({ config: c, entries: e }) => [
    c.getName(),
    e.entries,
  ]);

  /* Hooks */
  const { renderTemplate } = useRenderTemplate();
  const { error } = useNotify();

  const exportTemplate = () => {
    const template = renderTemplate();
    if (!template) {
      error('Failed to render template.');
      return;
    }

    const file = new Blob([JSON.stringify(template, null, 4)], {
      type: 'application/json',
    });

    const getUpdateTag = () => {
      const [_, node] =
        Object.entries(entries).find(([_, entry]) => entry.name === 'status') ??
        [];

      if (isTreeSimpleItem(node)) {
        return node.value === 'update' ? 'update' : undefined;
      }

      return undefined;
    };

    const updateTag = getUpdateTag();

    downloadFile(
      file,
      `${name ?? 'template'}${updateTag ? '-' + updateTag : ''}.json`
    );
  };

  const localExport = () => {
    exportTemplate();
  };

  return { localExport };
};
