import { useParams } from 'react-router-dom';
import { EntryProvider } from '../../context';
import { ConfigureView } from '..';

export const ContextEditor = () => {
  const { entryId } = useParams();
  if (!entryId) throw new Error('No active entry');

  return (
    <EntryProvider id={entryId}>
      <ConfigureView />
    </EntryProvider>
  );
};
