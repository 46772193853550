import { StructureBlockIndex } from '@morph-mapper/node-logic';
import { useNavigate } from 'react-router-dom';
import { useEntryContext } from '../../context';

export const useBlockItem = (type: StructureBlockIndex) => {
  const setOutputType = useEntryContext((s) => s.setOutputType);
  const navigate = useNavigate();

  const handleCreate = () => {
    setOutputType(type);
    navigate(-1);
  };

  return { handleCreate };
};
