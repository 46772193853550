import { OptionEntry, OptionType } from "@morph-mapper/types";
import { createStore } from "zustand";
import { immer } from "zustand/middleware/immer";

export interface ParameterRowProps {
  name: string;
  value: OptionEntry<any>;

  // state for entry in-row editing
  tempValue: any;

  // UI state for entry in-row editing
  isConfirm: boolean;
  isEditing: boolean;
}

export type InitParameterRowProps = Pick<ParameterRowProps, "name" | "value">;

export interface ParameterRowState extends ParameterRowProps {
  updateParameter: (name: string, option: OptionEntry<any>) => void;

  // parameter in row operations
  getAvailableNames: () => string[];
  getComputed: () => any;
  getType: () => OptionType;
  setType: (type: OptionType) => void;
  setTempValue: (tempValue: any) => void;
  setConfirm: (toggle: boolean) => void;
  setEditing: (toggle: boolean) => void;

  cancelDelete: () => void;
  confirmDelete: () => void;
  cancelEdit: () => void;
  confirmEdit: () => void;
  onEdit: () => void;
  onDelete: () => void;

  canDelete: () => boolean;
}

export type InitParameterRowState = Pick<ParameterRowState, "updateParameter">;

export type ParameterRowStore = ReturnType<typeof createParameterRowStore>;

export const createParameterRowStore = (
  initProps: InitParameterRowProps & InitParameterRowState
) => {
  const DEFAULT_VALUES: Omit<ParameterRowProps, keyof InitParameterRowProps> = {
    tempValue: undefined,
    isConfirm: false,
    isEditing: false,
  };

  return createStore<ParameterRowState>()(
    immer((set, get) => ({
      ...DEFAULT_VALUES,
      ...initProps,

      getAvailableNames: () => {
        // FIXME: implement
        return ["todo"];
      },
      getType: () => {
        return get().value.type;
      },
      setType: (type) => {
        set((s) => {
          s.value.type = type;
        });

        get().updateParameter(get().name, get().value);
      },
      getComputed: () => {
        const value = get().value;
        if (value.type === OptionType.UserDefined) {
          return value.value;
        }
      },
      setTempValue: (tempValue) =>
        set((s) => {
          s.tempValue = tempValue;
        }),
      setConfirm: (toggle) => {
        set((s) => {
          s.isConfirm = toggle;
        });
      },
      setEditing: (toggle) => {
        set((s) => {
          s.isEditing = toggle;
        });
      },
      cancelDelete: () => {
        set((s) => {
          s.isConfirm = false;
        });
      },
      confirmDelete: () => {
        set((s) => {
          s.value.value = undefined;
          s.isConfirm = false;
          s.isEditing = false;
        });
      },
      cancelEdit: () => {
        set((s) => {
          s.tempValue = undefined;
          s.isConfirm = false;
          s.isEditing = false;
        });
      },
      confirmEdit: () => {
        set((s) => {
          if (s.value.type === OptionType.UserDefined || OptionType.Reference) {
            s.value.value = s.tempValue;
          }

          s.tempValue = undefined;
          s.isConfirm = false;
          s.isEditing = false;
        });

        get().updateParameter(get().name, get().value);
      },

      onEdit: () => {
        set((s) => {
          if (s.value.type === OptionType.UserDefined) {
            s.isEditing = true;
          }
        });
      },
      onDelete: () => {
        set((s) => {
          s.isConfirm = true;
          s.isEditing = false;
        });
      },

      canDelete: () => {
        const value = get().value;

        if (value.value === undefined) {
          return false;
        }

        return true;
      },
    }))
  );
};
