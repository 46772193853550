import { getEntries } from '@morph-mapper/utils';
import { Path, Reference } from '../types';

export const getOrderedIds = (path: Path) => {
  const orderedIds: string[] = [];
  const pathEntries = getEntries(path);
  const start = pathEntries.find(([_, { prev }]) => prev === null);

  if (!start) {
    return orderedIds;
  }

  const [id, reference] = start;
  orderedIds.push(id);

  let next = reference.next;
  while (next) {
    orderedIds.push(next);
    next = path[next].next;
  }

  return orderedIds;
};

export const renderReference = (reference: Reference) => {
  return `${reference.value}${
    reference.operation ? `.${reference.operation}` : ''
  }`;
};
