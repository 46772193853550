import { useSetupStore } from '../../store';
import {
  useUpdateTemplateRef,
  useTemplateSave,
  useCreateTemplateSave,
  useCreateTemplateRef,
  useTemplateRefsOfOperatorId,
  useCreateCategory,
} from '@/services';
import { useEffect, useState } from 'react';
import { useNotify } from '@/hooks';
import { Category } from '../../types';
import { SchemaSource } from '@morph-mapper/types';

export const useConfigureTemplate = (categories: Category[] | undefined) => {
  const [
    domain,
    operatorId,
    setOpenEdit,
    openDuplicate,
    setDuplicateOpen,
    modalId,
    setModalId,
  ] = useSetupStore((s) => [
    s.getDomain(),
    s.getOperatorId(),
    s.setIsEditModalOpen,
    s.isDuplicateModalOpen,
    s.setIsDuplicateModalOpen,
    s.modalId,
    s.setModalId,
  ]);

  const { success, error } = useNotify();
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [description, setDescription] = useState('');
  const [priority, setPriority] = useState(0);
  const [category, setCategory] = useState('');
  const [source, setSource] = useState('');
  const [mainTemplatePath, setMainTemplatePath] = useState<string | null>(null);
  const [mainTemplate, setMainTemplate] = useState<any>(undefined);
  const [isDuplicating, setIsDuplicating] = useState(false);

  /* Queries */
  const { data: templates } = useTemplateRefsOfOperatorId(operatorId);

  const { mutate: addCategory } = useCreateCategory();
  const {
    mutate: createTemplateRef,
    isSuccess: refSuccess,
    data: templateRefId,
  } = useCreateTemplateRef();
  const { mutate: updateTemplate, isSuccess: updateSuccess } =
    useUpdateTemplateRef();
  const { data: duplTemplateFetched } = useTemplateSave(mainTemplatePath, true);
  const {
    mutate: createTemplateSave,
    isSuccess: saveSuccess,
    isError: saveError,
  } = useCreateTemplateSave();

  useEffect(() => {
    if (templates === undefined || modalId === undefined) return;

    const modalTemplate = templates.find((template) => template.id === modalId);
    setMainTemplate(modalTemplate);
  }, [templates, modalId]);

  useEffect(() => {
    if (name === '') return;
    checkNameConflict();
  }, [name, category]);

  useEffect(() => {
    if (mainTemplate === undefined) return;

    // Update modal state with mainTemplate values
    setName(mainTemplate.name);
    setCategory(mainTemplate.category);
    setSource(mainTemplate.inputsource);
    setPriority(mainTemplate.priority);
    setDescription(mainTemplate.description ?? '');
    setMainTemplatePath(mainTemplate.path);
  }, [mainTemplate]);

  //useEffect for update success for configure
  useEffect(() => {
    if (!updateSuccess) return;

    createCategoryIfNotExists();

    setOpenEdit(false);
    success('Template updated');
    setName('');
    setDescription('');
    setPriority(0);
    setCategory('');
    setModalId(undefined);
    setMainTemplate(undefined);
  }, [updateSuccess]);

  //useEffect for save success for duplication
  useEffect(() => {
    if (templateRefId === undefined) return;

    if (saveSuccess) {
      createCategoryIfNotExists();
      success('Template duplicated');
    }

    if (saveError) {
      error('Failed to duplicate template');
    }

    setName('');
    setDescription('');
    setPriority(0);
    setCategory('');
    setModalId(undefined);
    setMainTemplate(undefined);
    setIsDuplicating(false);
  }, [saveSuccess, saveError]);

  //useEffect for templateRef success for duplication
  useEffect(() => {
    if (!isDuplicating || !openDuplicate || !refSuccess) return;
    if (duplTemplateFetched === undefined) return;

    const { id: duplicatedTemplateId } = templateRefId;
    const {
      id: mainId,
      name: mainName,

      ...templateWithoutIdandName
    } = duplTemplateFetched;

    const template = {
      ...templateWithoutIdandName,
      refId: duplicatedTemplateId,
      name,
    };

    createTemplateSave(template);
    setDuplicateOpen(false);
  }, [isDuplicating, refSuccess, duplTemplateFetched]);

  const handleName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleDescription = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value);
  };

  const handlePriority = (value: number | '') => {
    if (value === '') {
      setPriority(0);
      return;
    }

    setPriority(value);
  };

  const handleCategory = (value: string | null) => {
    if (value === null) return;
    setCategory(value);
  };

  const handleSourceChange = (source: SchemaSource) => {
    setSource(source);
  };

  const handleEditTemplate = () => {
    if (modalId === undefined) return;
    setNameError('');
    updateTemplate({
      id: modalId,
      template: {
        name,
        description,
        priority,
        category,
        inputsource: source,
      },
    });
  };

  const handleDuplicateTemplate = () => {
    setNameError('');
    setIsDuplicating(true);
    createTemplateRef({
      operatorId,
      deployPath: null,
      name,
      domain,
      templatetype: 'MorphMapper',
      category,
      enabled: false,
      priority,
      description,
      inputsource: source,
    });
  };

  const handleCloseModal = () => {
    setOpenEdit(false);
    setDuplicateOpen(false);

    setNameError('');
    setName('');
    setDescription('');
    setPriority(0);
    setCategory('');
    setModalId(undefined);
    setMainTemplate(undefined);
  };

  const createCategoryIfNotExists = async () => {
    if (categories === undefined) return;

    if (categories.every(({ name }) => name !== category)) {
      addCategory({ operatorId, name: category, domain });
    }
  };

  const checkNameConflict = async () => {
    if (templates === undefined) return;
    const isNameDuplicate = templates.find(
      (template) =>
        template.domain === domain &&
        template.category === category &&
        template.name === name &&
        template.deleted !== true &&
        (openDuplicate || template.id !== modalId)
    );
    if (isNameDuplicate) {
      setNameError(
        'A template with this name already exists in the selected category'
      );
    } else {
      setNameError('');
    }
  };

  return {
    name,
    nameError,
    description,
    priority,
    category,
    source,
    handleName,
    handleDescription,
    handlePriority,
    handleCategory,
    handleSourceChange,
    handleEditTemplate,
    handleDuplicateTemplate,
    handleCloseModal,
  };
};
