import { useStore } from '@/store';
import { TreeProvider } from '../../context';
import { ListView } from '../../organisms';

export const EntryEditor = () => {
  const rootId = useStore(({ entries: e }) => e.getRootId());

  return (
    <TreeProvider id={rootId}>
      <ListView />
    </TreeProvider>
  );
};
