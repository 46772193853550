import { createStore } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { ZodType } from 'zod';
import { StoreSlice, ISimpleEntry } from '@/store';
import { EntryItemType, EntryType } from '@morph-mapper/types';

export interface SimpleEntryProps {
  id: string;

  // state for entry in-row editing
  backupValue: string | undefined;

  // UI state for entry in-row editing
  isConfirm: boolean;
  isEditing: boolean;
}

export type InitSimpleEntryProps = Pick<SimpleEntryProps, 'id'>;

export interface SimpleEntryState extends SimpleEntryProps {
  getId: () => string;
  getType: () => EntryType;
  setType: (type: EntryItemType) => void;
  getName: () => string | undefined;
  getComputed: () => any;
  getValidation: () => ZodType<any> | undefined;

  // functions for entry in-row editing
  setNewValue: (newValue: any) => void;
  setConfirm: (toggle: boolean) => void;
  setEditing: (toggle: boolean) => void;

  cancelDelete: () => void;
  confirmDelete: () => void;
  cancelEdit: () => void;
  confirmEdit: () => void;
  onEdit: () => void;
  onClear: () => void;
  onDelete: () => void;

  isClearable: () => boolean;
}

export type SimpleEntryStore = ReturnType<typeof createSimpleEntryStore>;

export const createSimpleEntryStore = (
  global: StoreSlice,
  initProps: InitSimpleEntryProps
) => {
  const DEFAULT_VALUES: Omit<SimpleEntryProps, keyof InitSimpleEntryProps> = {
    backupValue: undefined,
    isConfirm: false,
    isEditing: false,
  };

  return createStore<SimpleEntryState & StoreSlice & ISimpleEntry>()(
    immer((set, get) => ({
      ...global,
      ...global.entries.entry(initProps.id, EntryType.Simple),
      ...DEFAULT_VALUES,
      ...initProps,

      setNewValue: (newValue) =>
        set((s) => {
          s.setLiteral(newValue);
        }),
      setConfirm: (toggle) => {
        set((s) => {
          s.isConfirm = toggle;
        });
      },
      setEditing: (toggle) => {
        set((s) => {
          s.isEditing = toggle;
        });
      },
      cancelDelete: () => {
        set((s) => {
          s.isConfirm = false;
        });
      },
      confirmDelete: () => {
        set((s) => {
          s.clear();

          s.isConfirm = false;
          s.isEditing = false;
        });
      },
      cancelEdit: () => {
        set((s) => {
          s.setLiteral(s.backupValue);

          s.backupValue = undefined;
          s.isConfirm = false;
          s.isEditing = false;
        });
      },
      confirmEdit: () => {
        set((s) => {
          s.backupValue = undefined;
          s.isConfirm = false;
          s.isEditing = false;
        });
      },
      onEdit: () => {
        set((s) => {
          s.backupValue = s.getLiteral();
          s.isEditing = true;
        });
      },
      onClear: () => {
        set((s) => {
          s.clear();

          s.isConfirm = true;
          s.isEditing = false;
        });
      },
      onDelete: () => {
        get().delete();
      },

      isClearable: () => {
        return get().hasValue() !== undefined;
      },
    }))
  );
};
