import ReactJson from '@uiw/react-json-view';
import { useJsonFile } from './use-json-file';
import { JsonViewContent, JsonViewHeader, JsonViewWrapper } from './styles';
import { Group } from '@mantine/core';
import { Pointer } from '@morph-mapper/ui';
import { useStore } from '../../context';
import { SelectionMode } from '../../types';
import { useJsonPath } from './use-json-path';
import { Footer } from '../../molecules';

export const JsonView = () => {
  const [setMode, mode] = useStore(({ file: f }) => [
    f.setSelectionMode,
    f.getSelectionMode(),
  ]);
  const { json } = useJsonFile();
  const { createPath } = useJsonPath();

  if (json === null) {
    return null;
  }

  return (
    <JsonViewWrapper>
      <JsonViewHeader>
        <Group>
          <Pointer
            onClick={() => setMode(SelectionMode.Reference)}
            tooltip="Select reference in document."
            variant={mode === SelectionMode.Reference ? 'filled' : 'default'}
            color={mode === SelectionMode.Reference ? 'orange' : undefined}
          />
        </Group>
      </JsonViewHeader>
      <JsonViewContent>
        <ReactJson value={json} onExpand={undefined}>
          <ReactJson.KeyName
            render={(props, { keys = [] }) => {
              return (
                <span
                  {...props}
                  onClick={(e) => {
                    e.stopPropagation();
                    createPath(keys);
                  }}
                />
              );
            }}
          />
          <ReactJson.Row
            render={(props, { keys = [] }) => {
              return (
                <div
                  {...props}
                  onClick={() => {
                    createPath(keys);
                  }}
                />
              );
            }}
          />
        </ReactJson>
      </JsonViewContent>
      <Footer />
    </JsonViewWrapper>
  );
};
