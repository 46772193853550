import { Group } from "@mantine/core";
import { StyledHeader } from "./styles";
import { Move } from "@morph-mapper/ui";
import { useNavigate } from "react-router-dom";

export const Header = () => {
  const navigate = useNavigate();
  const handleClose = () => {
    navigate(-1);
  };

  return (
    <StyledHeader>
      <Group>
        <Move into={false} onClick={handleClose} />
        Select Iterator
      </Group>
    </StyledHeader>
  );
};
