import { useState } from "react";
import { useEntryContext } from "../../context";

export const useCreateVariable = () => {
  /* Context State */
  const createVariable = useEntryContext((s) => s.createVariable);
  /* Local State */
  const [edit, setEdit] = useState(false);
  const [name, setName] = useState("");

  /**
   * Handlers
   **/
  const handleNew = () => {
    setEdit(true);
  };

  const handleSetName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.currentTarget.value);
  };

  const handleCreate = () => {
    if (name.length <= 0) return;
    setEdit(false);
    setName("");
    createVariable(name);
  };

  const handleCancel = () => {
    setEdit(false);
    setName("");
  };

  return {
    edit,
    name,
    handleNew,
    handleSetName,
    handleCreate,
    handleCancel,
  };
};
