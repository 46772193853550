import { Action, ActionProps } from "../action";
import { forwardRef } from "react";
import { PiDotsThreeOutline } from "react-icons/pi";

export const Options = forwardRef<HTMLButtonElement, ActionProps>(
  ({ variant, ...props }: ActionProps, ref) => {
    return (
      <Action
        {...props}
        ref={ref}
        Icon={PiDotsThreeOutline}
        variant={variant ?? "light"}
      />
    );
  }
);
