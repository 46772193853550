import {
  Autocomplete,
  Box,
  Button,
  Group,
  Modal,
  NumberInput,
  Radio,
  TextInput,
  Textarea,
} from '@mantine/core';
import { useConfigureTemplate } from './use-configure-template';
import { useSetupStore } from '../../store';
import { useCategoriesOfOperatorIdByDomain } from '@/services';
import { getKeys } from '@morph-mapper/utils';
import { SchemaSource } from '@morph-mapper/types';

export const ConfigTemplateModal = () => {
  const [domain, operatorId, openEdit, openDuplicate] = useSetupStore((s) => [
    s.getDomain(),
    s.getOperatorId(),
    s.isEditModalOpen,
    s.isDuplicateModalOpen,
  ]);
  const { data: categories } = useCategoriesOfOperatorIdByDomain(
    operatorId,
    domain
  );
  const {
    name,
    nameError,
    description,
    priority,
    category,
    source,
    handleName,
    handleDescription,
    handlePriority,
    handleCategory,
    handleSourceChange,
    handleEditTemplate,
    handleDuplicateTemplate,
    handleCloseModal,
  } = useConfigureTemplate(categories);

  if (categories === undefined) return null;

  return (
    <Modal
      opened={openEdit || openDuplicate}
      onClose={handleCloseModal}
      title={openEdit ? 'Configure template' : 'Duplicate template'}
      centered
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minWidth: 300,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            marginTop: '0.5rem',
            marginBottom: '2rem',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
              minHeight: '80px',
            }}
          >
            <TextInput
              label="Name"
              value={name}
              onChange={handleName}
              error={nameError}
              withAsterisk
              data-autofocus
            />
          </Box>
          <Radio.Group
            label="Input Source"
            withAsterisk
            onChange={handleSourceChange}
            value={source}
          >
            <Group mt="md">
              {getKeys(SchemaSource).map((source) => {
                const label = source.charAt(0).toUpperCase() + source.slice(1);
                return (
                  <Radio
                    key={source}
                    value={SchemaSource[source]}
                    label={label}
                  />
                );
              })}
            </Group>
          </Radio.Group>
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <Autocomplete
              label="Category"
              placeholder={category}
              data={categories.map(({ name }) => name)}
              onChange={handleCategory}
              withAsterisk
            />

            <NumberInput
              label="Priority"
              value={priority}
              onChange={handlePriority}
              withAsterisk
            />
          </Box>

          <Textarea
            label="Description"
            value={description}
            onChange={handleDescription}
          />
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'end' }}>
          {openEdit && (
            <Button onClick={handleEditTemplate} disabled={nameError !== ''}>
              Update
            </Button>
          )}
          {openDuplicate && (
            <Button
              onClick={handleDuplicateTemplate}
              disabled={nameError !== ''}
            >
              Save
            </Button>
          )}
        </Box>
      </Box>
    </Modal>
  );
};
