import styled from "@emotion/styled";
import { Badge, BadgeProps, createPolymorphicComponent } from "@mantine/core";

export const Layout = styled("div")(() => ({
  display: "flex",
  gap: "1rem",
}));

export const _StyledBadge = styled(Badge)(({ theme }) => ({
  border: `1px solid ${theme.colors.gray[3]}`,
  color: theme.colors.gray[7],
  backgroundColor: "transparent",
  height: "inherit",

  "& .mantine-Badge-inner": {
    display: "flex",
    minWidth: "20px",
    justifyContent: "center",
  },
}));

export const StyledBadge = createPolymorphicComponent<"div", BadgeProps>(
  _StyledBadge
);
