import styled from "@emotion/styled";
import { Table as MantineTable } from "@mantine/core";

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

export const Table = styled(MantineTable)(({ theme }) => ({
  border: `1px solid ${theme.colors.gray[3]}`,
  borderRadius: theme.radius.sm,
  backgroundColor: theme.colors.gray[0],
  borderCollapse: "separate",
}));
