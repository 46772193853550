import { useEffect } from 'react';
import { SelectorView } from '../../organisms';

export const IteratorSelector = () => {
  // TODO: we redirect when we return from the graph view and have already created an iterator
  useEffect(() => {
    // Check if there already as a created iterator
  }, []);

  return <SelectorView />;
};
