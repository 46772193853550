import { useContext } from 'react';
import { useStore } from 'zustand';
import { BooleanEntryState } from '../../store';
import { BooleanEntryContext } from './context';
import { IBooleanEntry } from '@/store';

export const useBooleanEntryContext = <T>(
  selector: (state: BooleanEntryState & IBooleanEntry) => T,
  equalityFn?: (left: T, right: T) => boolean
): T => {
  const store = useContext(BooleanEntryContext);
  if (!store) throw new Error('Missing EntryContext.Provider in the entry');
  return useStore(store, selector, equalityFn);
};
