import { ActionIcon, ActionIconProps, Tooltip } from "@mantine/core";
import { forwardRef } from "react";
import { IconType } from "react-icons";

type Mantineprops = ActionIconProps & React.RefAttributes<HTMLButtonElement>;

interface ActionTemplateProps extends Mantineprops {
  onClick?: React.MouseEventHandler;
  variant?: "default" | "outline" | "filled" | "light" | "subtle";
  Icon: IconType;
  borderColor?: string;
  ref?: any;
  tooltip?: string;
}

export interface ActionProps extends Omit<ActionTemplateProps, "Icon"> {}

export const Action = forwardRef<HTMLButtonElement, ActionTemplateProps>(
  ({ Icon, borderColor, tooltip, ...props }: ActionTemplateProps, ref) => {
    const actionIcon = (
      <ActionIcon
        {...props}
        ref={ref}
        sx={() => ({
          border: borderColor,
        })}
      >
        <Icon
          size="1rem"
          style={{
            display: "block",
          }}
        />
      </ActionIcon>
    );

    if (tooltip) {
      return <Tooltip label={tooltip}>{actionIcon}</Tooltip>;
    } else {
      return actionIcon;
    }
  }
);
