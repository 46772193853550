import { SchemaVariant } from '@morph-mapper/types';
import { SelectionMode } from '../types';
import { StateCreator } from 'zustand';
import { createLens } from '@dhmk/zustand-lens';
import { match } from 'ts-pattern';

export interface FileProps {
  variant: SchemaVariant | null;
  file: File | null;
  textContent: string;

  selectionMode: SelectionMode;
}

export type InitFileProps = Partial<Pick<FileProps, 'variant' | 'file'>> & {
  textContent: string; 
};

export interface FileState extends FileProps {
  getVariant: () => SchemaVariant | null;
  getFile: () => File | null;
  getTextContent: () => string;

  setSelectionMode: (mode: SelectionMode) => void;
  getSelectionMode: () => SelectionMode;
}

export const fileState: (
  init: InitFileProps
) => StateCreator<
  { file: FileState },
  [['zustand/immer', never]],
  [],
  FileState
> =
  ({ variant, file, textContent }) =>
  (_set, _get) => {
    const [set, get] = createLens(_set, _get, ['file']);

    // Helper function to determine the variant
    const determineVariant = (): SchemaVariant | null => {
      if (variant) return variant;
      if (textContent) return SchemaVariant.Json; // Default to JSON if textContent exists
      return null;
    };

    // Helper function to set the default selection mode based on the variant
    const setDefaultSelectionMode = (): SelectionMode =>
      match(determineVariant())
        .with(SchemaVariant.Pdf, () => SelectionMode.Text)
        .with(SchemaVariant.Table, () => SelectionMode.Text)
        .with(SchemaVariant.Xml, () => SelectionMode.Reference)
        .with(SchemaVariant.Text, () => SelectionMode.Text)
        .with(SchemaVariant.Email, () => SelectionMode.Text)
        .with(SchemaVariant.Json, () => SelectionMode.Reference)
        .otherwise(() => SelectionMode.Text);

    return {
      variant: determineVariant(),
      file: file ?? null,
      textContent,
      selectionMode: setDefaultSelectionMode(),
      reference: [],

      getVariant: () => {
        return get().variant;
      },
      getFile: () => {
        return get().file;
      },
      getTextContent: () => {
        return get().textContent;
      },

      setSelectionMode: (mode) => {
        set((s) => {
          s.selectionMode = mode;
        });
      },
      getSelectionMode: () => {
        return get().selectionMode;
      },
    };
  };
