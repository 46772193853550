import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import { Action, ActionProps } from "../action";

interface MoveProps extends ActionProps {
  into?: boolean;
}

export const Move = ({ into = true, ...props }: MoveProps) => {
  return (
    <Action
      {...props}
      Icon={into ? FiArrowRight : FiArrowLeft}
      variant="default"
    />
  );
};
