import { useStore } from "@/store";
import {
  Box,
  Modal,
} from "@mantine/core";

export const DocumentationModal = () => {
  const [IsDocModalOpen, setIsDocModalOpen] = useStore(({ config: c }) => [
    c.getIsDocModalOpen(), c.setIsDocModalOpen
  ]);

  const handleCloseModal = () => {
    setIsDocModalOpen(false);
  };

  return (
    <Modal
      opened={IsDocModalOpen}
      onClose={handleCloseModal}
      title="Documentation"
      overlayProps={{
        opacity: 0.55,
        blur: 3,
      }}
      size = "50rem"
      centered
    >
      <Box style={{ height: '70vh' }}> 
        Documentation goes here
      </Box>
    </Modal>
  );
};
