import { useCallback, useEffect } from 'react';
import { SelectionMode, XmlSelector } from '../types';
import { useStore } from '../context';
import { sanitizeSelectedText } from '@morph-mapper/utils';

export const useTextSelect = () => {
  const [mode, setPath] = useStore(({ file: f, path: p }) => [
    f.getSelectionMode(),
    p.setAbsolutePath,
  ]);

  const handleMouseSelection = useCallback(() => {
    const foundText = window.getSelection()?.toString();
    const sanitizedText = sanitizeSelectedText(foundText);
    
    if (!sanitizedText) return;

    // TODO: refactor, misuse of path store.
    setPath([
      {
        type: XmlSelector.Base,
        value: sanitizedText,
        operation: null,
      },
    ]);
  }, []);

  useEffect(() => {
    if (mode === SelectionMode.Text) {
      window.addEventListener('mouseup', handleMouseSelection);
    } else {
      window.removeEventListener('mouseup', handleMouseSelection);
    }

    return () => {
      window.removeEventListener('mouseup', handleMouseSelection);
    };
  }, [mode]);
};
