import { useSendResultToUI, useTemplateParser } from '@/services';
import { useEffect, useState } from 'react';
import { useRenderTemplate } from '@/hooks';

export const useTestTemplate = () => {
  /* Local State */
  const [template, setTemplate] = useState<Record<string, any> | undefined>(
    undefined
  );
  const [result, setResult] = useState<string | undefined>(undefined);
  const [guard, setGuard] = useState<boolean>(false);
  const [dataGuard, setDataGuard] = useState<boolean>(false);

  /* Hooks */
  const { renderTemplate } = useRenderTemplate();
  /* Queries */
  const sendResult = useSendResultToUI();
  const { data } = useTemplateParser(template);

  /**
   * Effects
   **/
  useEffect(() => {
    if (!data || !guard) return;
    // TODO: type
    setResult(data);
    setDataGuard(true);
  }, [data]);

  useEffect(() => {
    if (!result || !guard || !dataGuard) return;
    sendResult.mutate(result);
    setGuard(false);
    setDataGuard(false);
  }, [result, guard]);

  /**
   * Handlers
   **/
  const handleTestTemplate = async () => {
    const template = renderTemplate();
    setTemplate(template);
    setGuard(true);
  };

  return { handleTestTemplate };
};
