import { Badge, Group, Text, TextInput } from '@mantine/core';
import { Move } from '../../atoms';
import { parseOutput } from '@morph-mapper/utils';
import { useEffect, useState } from 'react';

interface ComputedCellProps {
  value: string | string[] | undefined;
}

export const ComputedCell = ({ value }: ComputedCellProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Flatten nested array if value is [[...]]
  const flattenedValue =
    Array.isArray(value) && value.length === 1 && Array.isArray(value[0])
      ? value[0]
      : value;

  const handleIndexIncrease = () => {
    if (currentIndex + 1 < flattenedValue!.length) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handleIndexDecrease = () => {
    if (currentIndex - 1 >= 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  if (flattenedValue === undefined) {
    return (
      <Text italic color={'gray'}>
        undefined
      </Text>
    );
  }

  if (Array.isArray(flattenedValue)) {
    return (
      <>
        <Group>
          <Badge sx={{ width: 'auto', height: 30, borderRadius: '0.3rem' }}>
            {currentIndex + 1}/{flattenedValue.length}
          </Badge>
          <TextInput value={flattenedValue[currentIndex]} disabled />
          <Move into={false} onClick={handleIndexDecrease} />
          <Move onClick={handleIndexIncrease} />
        </Group>
      </>
    );
  }

  return <Text>{parseOutput(flattenedValue)}</Text>;
};
