import { z } from 'zod';

/**
 * Resolves a Zod schema from a JSON schema. Used to hydrate from saved file.
 *
 * @param schema Zod schema
 * @returns
 */
export const parseZodSchema = (schema: any): z.ZodSchema => {
  if (!schema._def || !schema._def.typeName) {
    return z.any();
  }

  const definition = schema._def;

  if (definition.typeName === 'ZodAny') {
    return z.any();
  }
  if (definition.typeName === 'ZodEnum') {
    return z.enum(definition.values);
  }
  if (definition.typeName === 'ZodLiteral') {
    return z.literal(definition.value);
  }
  if (definition.typeName === 'ZodString') {
    return z.string();
  }
  if (definition.typeName === 'ZodNumber') {
    return z.number();
  }
  if (definition.typeName === 'ZodBoolean') {
    return z.boolean();
  }
  if (definition.typeName === 'ZodArray') {
    return z.array(parseZodSchema(definition.type));
  } else {
    //FIXME: this is wrong
  }

  throw new Error('Unsupported schema');
};

/**
 * This function converts event based javascript file reader to promise based.
 * It uses readAsBinaryString so the format is supported by the template engine API.
 *
 * @param file Object which represents the example document used to generate the template
 * @returns string representation of the file
 */
export const fileToText = (file: File) => {
  return new Promise<string>((resolve, reject) => {
    const fr = new FileReader();
    fr.onload = () => {
      const result = fr.result;

      if (typeof result === 'string') {
        resolve(result);
      } else if (result instanceof ArrayBuffer) {
        resolve(arrayBufferToBinaryString(result));
      } else {
        reject('Error reading file');
      }
    };
    fr.onerror = reject;
    fr.readAsArrayBuffer(file);
  });
};

const arrayBufferToBinaryString = (buffer: ArrayBuffer) => {
  let binaryString = '';
  const bytes = new Uint8Array(buffer);
  const length = bytes.length;
  for (let i = 0; i < length; i++) {
    binaryString += String.fromCharCode(bytes[i] as number);
  }
  return binaryString;
};
