import { z } from 'zod';
import { EntryItemType, EntryType } from '@morph-mapper/types';
import { StructureBlockIndex } from '@morph-mapper/node-logic';

/*
 *  Types
 */

export enum VariableType {
  Simple = 'simple',
  Graph = 'graph',
}

export type BaseTreeNode = {
  id: string;
  key: string;
  name: string;
  validation: z.ZodType<any, any>;
  computed: any;
  dependencies: {
    dependsOn: Set<string>;
    requiredBy: Set<string>;
  };
  parentId: string;
  allowedTypes?: EntryItemType[];
};

export type TreeGraphItem = {
  type: EntryType.Graph;
  graphId: string | undefined;
  value: any;
} & BaseTreeNode;

export type TreeBooleanItem = {
  type: EntryType.Boolean;
  value: boolean | undefined;
} & BaseTreeNode;

export type TreeSimpleItem = {
  type: EntryType.Simple;
  value: any;
} & BaseTreeNode;

export type TreeCellItem = {
  type: EntryType.Cell;
  column: number | undefined;
  value: any;
} & BaseTreeNode;

export type TreeInternalItem = {
  type: EntryType.Internal;
  value: any;
} & BaseTreeNode;

export type TreeItem =
  | TreeGraphItem
  | TreeBooleanItem
  | TreeSimpleItem
  | TreeInternalItem
  | TreeCellItem;

export type TreeMap = {
  type: EntryType.Map;
  parentId: string | undefined;
  children: {
    forwardMap: Record<string, string>;
    reverseMap: Record<string, string>;
  };
  outputType: StructureBlockIndex;
} & Omit<BaseTreeNode, 'parentId' | 'computed'>;

export type TreeNode = TreeItem | TreeMap;

export type UnregisteredTreeItem<T extends TreeItem> = Omit<
  Omit<T, 'id'>,
  keyof Omit<BaseTreeNode, 'id'>
>;

/*
 *  Type Guards
 */
export const isTreeGraphItem = (item: any): item is TreeGraphItem => {
  if (item === undefined) return false;
  return item.type === EntryType.Graph;
};

export const isTreeSimpleItem = (item: any): item is TreeSimpleItem => {
  if (item === undefined) return false;
  return item.type === EntryType.Simple;
};

export const isTreeItem = (entry: any): entry is TreeItem => {
  if (entry === undefined) return false;
  return entry.type !== EntryType.Map;
};

export const isTreeMap = (entry: any): entry is TreeMap => {
  if (entry === undefined) return false;
  return entry.type === EntryType.Map;
};
