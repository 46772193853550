import { fileSave, supported } from "browser-fs-access";

export const downloadFile = (file: Blob, name: string) => {
  // We are using the File System Access API which is experimental.
  // If the API is not supported, we fall back to auto downloading the file.
  // https://developer.mozilla.org/en-US/docs/Web/API/Window/showSaveFilePicker
  if (supported) {
    fileSave(file, {
      fileName: name,
    });
  } else {
    const element = document.createElement("a");
    element.href = URL.createObjectURL(file);
    element.download = name;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }
};
