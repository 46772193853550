import styled from '@emotion/styled';

export const PathWrapper = styled('div')(() => ({
  display: 'flex',
  columnGap: '1rem',
  width: '100%',
}));

export const ScrollView = styled('div')(() => ({
  flexGrow: 1,
  display: 'flex',
  whiteSpace: 'nowrap',
  overflowY: 'scroll',
  scrollbarWidth: 'none',
}));

export const ReferencesSpacer = styled('div')(() => ({
  display: 'flex',
  gap: '1rem 1rem',
  marginLeft: '0.15rem',
  marginRight: '0.15rem',
}));
