import styled from "@emotion/styled";

export const Styled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export const NoBorderCell = styled("td")`
  border: none !important;
`;
