import styled from "@emotion/styled";

export const Container = styled.div({
  display: "flex",
  width: "100vw",
  height: "100vh",
});

export const LeftColumn = styled.div(({ theme }) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  backgroundColor: theme.colors.gray[1],
  height: "100%",
}));

export const RightColumn = styled.div({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  paddingTop: "2rem",
  paddingLeft: "2rem",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
});

export const StepWrapper = styled.div({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: "3rem",
  height: "100%",
});
