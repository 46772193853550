import { ScrollArea, Text } from '@mantine/core';
import { CreateVariable } from '../create-variable';
import { useEntryContext } from '../../context';
import { Panel, Table, TableFooter, TableHeader } from './styles';
import { VariableRow } from '@/common';

export const EnvironmentPanel = () => {
  const variables = useEntryContext((s) => s.getVariableIds());
  return (
    <Panel>
      <Text c="dimmed">
        Environment variables are used to define common occuring values, so that
        they can be reused across multiple definitions inside node
        configurations.
      </Text>

      <ScrollArea scrollbarSize={2}>
        <Table horizontalSpacing="xl" verticalSpacing="xs">
          <TableHeader>
            <tr>
              <th>Name</th>
              <th>Value</th>
              <th>Action</th>
            </tr>
          </TableHeader>
          <tbody>
            {variables.map((id) => {
              return <VariableRow key={id} id={id} />;
            })}
          </tbody>
          <TableFooter />
        </Table>
      </ScrollArea>
      <CreateVariable />
    </Panel>
  );
};
