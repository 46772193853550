import { BaseNode } from '../node-base';
import { NodeProps } from 'reactflow';
import { useNavigate } from 'react-router-dom';
import { GraphOperation } from '@morph-mapper/types';
import { Add } from '@morph-mapper/ui';
import { useStore } from '@/store';
import { LogicBlockIndex } from '@morph-mapper/node-logic';

export const NodeSeed = (node: NodeProps) => {
  const variant = useStore(({ config: c }) => c.getVariant());
  const navigate = useNavigate();

  const handleNodeCreation = () => {
    navigate('nodes/new', {
      state: {
        operation: GraphOperation.Replace,
        nodeId: node.id,
      },
    });
  };

  return (
    <BaseNode
      type={node.type as LogicBlockIndex}
      postAction={<Add onClick={handleNodeCreation} />}
      variant={variant}
    />
  );
};
