import {
  Box,
  Button,
  Group,
  Modal,
  NumberInput,
  Radio,
  Select,
  TextInput,
  Textarea,
} from "@mantine/core";
import { useCreateTemplate } from "./use-create-template";
import { SchemaSource } from "@morph-mapper/types";
import { getKeys } from "@morph-mapper/utils";

export const CreateTemplateModal = () => {
  const {
    open,
    name,
    nameError,
    description,
    priority,
    category,
    type,
    source,
    variant,
    categories,
    handleName,
    handleDescription,
    handlePriority,
    handleCategory,
    handleCreateTemplate,
    handleCloseModal,
    getSchemaTypes,
    getSchemaVariants,
    handleTypeChange,
    handleVariantChange,
    handleSourceChange,
  } = useCreateTemplate();

  if (categories === undefined) return null;

  return (
    <Modal
      opened={open}
      onClose={handleCloseModal}
      title="New template"
      centered
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minWidth: 300,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            marginTop: "0.5rem",
            marginBottom: "2rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              minHeight: "80px",
            }}
          > 
          <TextInput
            label="Name"
            value={name}
            error={nameError}
            onChange={handleName}
            withAsterisk
            data-autofocus
          />
          </Box>

          <Radio.Group
            label="Template Type"
            withAsterisk
            onChange={handleTypeChange}
            value={type}
          >
            <Group mt="md">
              {getSchemaTypes().map((type) => {
                const label = type.charAt(0).toUpperCase() + type.slice(1);
                return <Radio key={type} value={type} label={label} />;
              })}
            </Group>
          </Radio.Group>

          {type && (
            <Radio.Group
              label="Template Variant"
              withAsterisk
              onChange={handleVariantChange}
              value={variant}
            >
              <Group mt="md">
                {getSchemaVariants(type).map((variant) => {
                  const label =
                    variant.charAt(0).toUpperCase() + variant.slice(1);
                  return <Radio key={variant} value={variant} label={label} />;
                })}
              </Group>
            </Radio.Group>
          )}
          {type && (
            <Radio.Group
              label="Input Source"
              withAsterisk
              onChange={handleSourceChange}
              value={source}
            >
              <Group mt="md">
                {getKeys(SchemaSource).map((source) => {
                  const label =
                    source.charAt(0).toUpperCase() + source.slice(1);
                  return <Radio key={source} value={SchemaSource[source]} label={label} />;
                })}
              </Group>
            </Radio.Group>
          )}

          <Box sx={{ display: "flex", gap: "1rem" }}>
            <Select
              label="Category"
              value={category}
              data={categories.map(({ name }) => name)}
              onChange={handleCategory}
              withAsterisk
            />

            <NumberInput
              label="Priority"
              value={priority}
              onChange={handlePriority}
              withAsterisk
            />
          </Box>

          <Textarea
            label="Description"
            value={description}
            onChange={handleDescription}
          />
        </Box>

        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <Button onClick={handleCreateTemplate} disabled={nameError !== "" || name === ""} >Create</Button>
        </Box>
      </Box>
    </Modal>
  );
};
