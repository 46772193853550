import {
  ReactFlow,
  Background,
  BackgroundVariant,
  Controls,
  MiniMap,
  NodeTypes,
} from 'reactflow';
import { EditorFlowWrapper } from './styles';
import 'reactflow/dist/style.css';
import { useGraphView } from './use-graph-view';
import { useGraphContext } from '../../context';
import { Header } from '../../molecules';
import { useGraphLayout } from './use-graph-layout';
import { getValues } from '@morph-mapper/utils';

export const GraphView = () => {
  /* Local State */
  const [{ nodes, edges }, onNodesChange, onEdgesChange] = useGraphContext(
    (s) => [s.get(), s.onNodesChange, s.onEdgesChange]
  );
  /* Hooks */
  const { nodeTypes, edgeTypes } = useGraphView();
  useGraphLayout();

  return (
    <>
      <Header />
      <EditorFlowWrapper>
        <ReactFlow
          nodes={getValues(nodes)}
          edges={getValues(edges)}
          nodeTypes={nodeTypes as NodeTypes}
          edgeTypes={edgeTypes}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          nodesDraggable={false}
          nodesConnectable={false}
          panOnScroll={true}
          selectionOnDrag={true}
          disableKeyboardA11y
          fitView
        >
          <Background variant={BackgroundVariant.Dots} gap={12} size={1} />
          <MiniMap />
          <Controls showInteractive={false} />
        </ReactFlow>
      </EditorFlowWrapper>
    </>
  );
};
