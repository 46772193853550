import { IoGrid } from "react-icons/io5";
import { Action, ActionProps } from "../action";
import { forwardRef } from "react";

export const Grid = forwardRef<HTMLButtonElement, ActionProps>(
  (props: ActionProps, ref) => {
    return (
      <Action
        {...props}
        ref={ref}
        Icon={IoGrid}
        variant="light"
        color="primary"
      />
    );
  }
);
