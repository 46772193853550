import { useRouteError } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Card, Text, Group } from '@mantine/core';
import { Content } from './styles';
import { FC, ReactElement, useEffect } from 'react';

/**
 * ErrorBoundaryProps
 *
 * @param message - Custom error message to display to the user.
 * @param buttons - An array of action buttons, allowing users to reload or navigate away from the error state.
 */
interface ErrorBoundaryProps {
  message?: string;
  buttons: ReactElement<HTMLButtonElement>[];
}

/**
 * ErrorBoundary Component
 *
 * A reusable error boundary for handling and displaying error states within specific route segments.
 * Captures errors with Sentry and provides custom actions for user recovery.
 */
export const ErrorBoundary: FC<ErrorBoundaryProps> = ({
  buttons,
  message = 'We are working on fixing the issue. In the meantime, we recommend reloading the workspace.',
}) => {
  const error = useRouteError() as Error;

  // Capture error with Sentry, this will log the error to the Sentry dashboard in production.
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <Content>
      <Card withBorder radius="sm" shadow="sm" w={400} padding="lg">
        <Text size="lg" weight={500} mb="md">
          Oops! Something went wrong.
        </Text>

        <Text size="sm" color="dimmed" mb="lg">
          {message}
        </Text>

        {process.env.NODE_ENV === 'development' && error?.message && (
          <Text size="xs" color="red">
            DEVELOPER NOTICE: {error.message}
          </Text>
        )}

        <Group position="apart" mt="md">
          {buttons}
        </Group>
      </Card>
    </Content>
  );
};
