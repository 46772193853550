import {
  createElement,
  createContext as reactCreateContext,
  useContext,
  useRef,
} from 'react';
import type { StoreApi } from 'zustand';
import { useStoreWithEqualityFn } from 'zustand/traditional';

type UseContextStore<Store extends StoreApi<unknown>> = {
  (): ExtractState<Store>;
  <U>(
    selector: (state: ExtractState<Store>) => U,
    equalityFn?: (a: U, b: U) => boolean
  ): U;
};

type ExtractState<Store> = Store extends { getState: () => infer T }
  ? T
  : never;

export const createContext = <
  State,
  InitProps,
  Store extends StoreApi<State> = StoreApi<State>
>(
  createStore: (initProps: InitProps) => Store
) => {
  const StoreContext = reactCreateContext<Store | undefined>(undefined);

  type ProviderProps = InitProps & {
    children: React.ReactNode;
  };

  const Provider: React.FC<ProviderProps> = ({ children, ...initProps }) => {
    const storeRef = useRef<Store>();
    return createElement(StoreContext.Provider, {
      value: (storeRef.current ||= createStore(initProps as InitProps)),
      children,
    });
  };

  const useStore = <StateSlice = ExtractState<Store>>(
    selector: (state: ExtractState<Store>) => StateSlice,
    equalityFn?: (a: StateSlice, b: StateSlice) => boolean
  ) => {
    const store = useContext(StoreContext);
    if (store) {
      return useStoreWithEqualityFn(store, selector, equalityFn);
    }
    throw new Error(
      'Seems like you have not used zustand provider as an ancestor.'
    );
  };

  return {
    Provider,
    useStore: useStore as UseContextStore<Store>,
  };
};
