import styled from '@emotion/styled';

interface StyledButtonProps {
  collision: boolean;
}

export const StyledButton = styled('div')<StyledButtonProps>(
  ({ theme, collision }) => ({
    display: collision ? 'flex' : 'none',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '10%',
    minWidth: 30,
    width: 30,
    color: theme.colors.blue[6],
    border: `1px solid ${theme.colors.blue[4]}`,
    cursor: 'pointer',
  })
);
