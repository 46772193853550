import { createLens } from "@dhmk/zustand-lens";
import { StateCreator } from "zustand";

export interface UIProps {
  configRefModalOpen: boolean;
  createRefModalOpen: boolean;

  setConfigRefModal: (open: boolean) => void;
  isConfigRefModalOpen: () => boolean;

  setCreateRefModal: (open: boolean) => void;
  isCreateRefModalOpen: () => boolean;
}

export interface UIState extends UIProps {}

export const uiState: () => StateCreator<
  { ui: UIState },
  [["zustand/immer", never]],
  [],
  UIState
> = () => (_set, _get) => {
  const [set, get] = createLens(_set, _get, ["ui"]);

  return {
    configRefModalOpen: false,
    createRefModalOpen: false,

    setConfigRefModal: (open) => {
      set((s) => {
        s.configRefModalOpen = open;
      });
    },
    isConfigRefModalOpen: () => {
      return get().configRefModalOpen;
    },

    setCreateRefModal: (open) => {
      set((s) => {
        s.createRefModalOpen = open;
      });
    },
    isCreateRefModalOpen: () => {
      return get().createRefModalOpen;
    },
  };
};
