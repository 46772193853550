import { useContext } from 'react';
import { useStore } from 'zustand';
import { MapEntryState } from '../../store';
import { MapEntryContext } from './context';
import { IMapEntry } from '@/store';

export const useMapEntryContext = <T>(
  selector: (state: MapEntryState & IMapEntry) => T,
  equalityFn?: (left: T, right: T) => boolean
): T => {
  const store = useContext(MapEntryContext);
  if (!store) throw new Error('Missing EntryContext.Provider in the entry');
  return useStore(store, selector, equalityFn);
};
