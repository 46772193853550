import { EntryItemType, EntryType } from '@morph-mapper/types';
import { useItemEntryContext } from '../../context';

const defaultTypes: EntryItemType[] = [
  EntryType.Boolean,
  EntryType.Simple,
  EntryType.Cell,
  EntryType.Graph,
];

/**
 * Hook which manages the logic for the type change menu section.
 */
export const useTypeChange = () => {
  const [allowedTypes, currentType, setType] = useItemEntryContext((s) => [
    s.getAllowedTypes(),
    s.getType(),
    s.setType,
  ]);

  // If the set of allowable types is empty, use the default types.
  const types = allowedTypes.length === 0 ? defaultTypes : allowedTypes;

  return {
    types,
    currentType,
    setType,
  };
};
