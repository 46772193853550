import { NodeProps } from "reactflow";
import _ from "lodash";
import dot from "dot-object";
import { GraphOperation } from "@morph-mapper/types";
import { useNavigate } from "react-router-dom";

export const useNodeGeneric = (node: NodeProps) => {
  const { dependencies } = node.data.graph;
  const navigate = useNavigate();

  const handleBlockConfig = () => {
    navigate(`nodes/${node.id}/config`);
  };

  const handleCreateNode = () => {
    navigate("nodes/new", {
      state: {
        operation: GraphOperation.Add,
        targetEdge: {
          target: node.id,
          targetHandle: "1",
        },
      },
    });
  };

  const dependencyArray = Object.keys(
    _.pickBy(
      dot.dot(dependencies),
      (value) => typeof value === "boolean" && value
    )
  );

  return { handleBlockConfig, handleCreateNode, dependencies: dependencyArray };
};
