import { z } from 'zod';
import {
  docStatusSchema,
  fileExtensionSchema,
  loadingDischargeSchema,
  mergingTypeSchema,
  orderSessionSchema,
  transportDirectionSchema,
  workloadSchema,
} from '../../shared';
import { Schema } from '../../../types';
import { EntryType, ReservedNodeType } from '@morph-mapper/types';
import { Input } from '@morph-mapper/node-inputs';

export const interModalTextImportSchema: Schema = {
  updateTemplateDiscardFields: [
    '_id',
    'id',
    'timestampedid',
    'hiddenreference',
    'dateofentry',
    'commissioner',
    'commissionerid',
    'clientidentifierraw',
    'clientidentifier',
  ],
  config: {
    _textConfig: {
      title: 'Text Settings',
      description: '',
      options: {
        messageBody: {
          type: z.boolean(),
          title: 'Message Body',
          description: 'Check if the file is a message body',
          input: Input.Checkbox,
          default: false,
        },
      },
      mutate: (options: any) => {
        const { messageBody } = options;

        if (messageBody) {
          return 'message-txt';
        }

        return 'txt';
      },
    },
    _updateConfig: {
      title: 'Update Template Settings',
      description: '',
      childConfigurations: [
        '_containerMergingConfig',
        '_containerSortDefault',
        '_containerFieldsConfig',
        '_skipDiffsConfig',
      ],
      options: {
        mergingType: {
          type: z.boolean(),
          title: 'Update Template',
          description: 'Check if this is an update to an existing template.',
          input: Input.Checkbox,
          default: '',
        },
      },
      mutate: (options: any) => {
        const { mergingType } = options;

        if (mergingType) {
          return 'extend';
        }
        return 'createIfNotExist';
      },
    },
    _containerMergingConfig: {
      title: '',
      description: '',
      options: {
        containerMerging: {
          type: z.string(),
          title: 'Container Merging',
          description:
            'Select whether single or all containers are being updated.',
          input: Input.SingleSelect,
          selection: [
            { label: 'Single', value: 'single' },
            { label: 'All Update', value: 'allUpdate' },
          ],
          default: 'allUpdate',
        },
      },
      conditions: {
        _updateConfig: {
          mergingType: {
            hasValue: true,
          },
        },
      },
      mutate: (options: any) => {
        const { containerMerging } = options;

        return containerMerging;
      },
    },
    _containerFieldsConfig: {
      title: '',
      description: '',
      options: {
        containerFields: {
          type: z.array(z.string()),
          title: 'Container Fields',
          description: 'Select zero or more container fields to be updated.',
          input: Input.MultiSelect,
          placeholder: 'Select container fields',
          selection: [
            { label: 'import', value: 'import' },
            { label: 'container', value: 'container' },
          ],
          default: [],
        },
      },
      conditions: {
        _updateConfig: {
          mergingType: {
            hasValue: true,
          },
        },
      },
      mutate: (options: any) => {
        const { containerFields } = options;

        return containerFields;
      },
    },
    _skipDiffsConfig: {
      title: '',
      description: '',
      options: {
        skipDiffs: {
          type: z.array(z.string()),
          title: 'Skip Fields',
          description: 'Select fields/items to be skipped.',
          input: Input.MultiSelect,
          placeholder: 'Select fields to ignore',
          selection: [
            { label: 'field 1', value: 'field 1' },
            { label: 'field2', value: 'field2' },
          ],
          default: [],
        },
      },
      conditions: {
        _updateConfig: {
          mergingType: {
            hasValue: true,
          },
        },
      },
      mutate: (options: any) => {
        const { skipDiffs } = options;

        if (skipDiffs) return { ignoreitems: skipDiffs };

        return undefined;
      },
    },
    _containerSortDefault: {
      title: '',
      description: '',
      hidden: true,
      options: {
        sort: {
          type: z.boolean(),
          title: 'Sort Confitional Default (Hidden)',
          description: '',
          input: Input.Checkbox,
          default: true,
        },
      },
      conditions: {
        _updateConfig: {
          mergingType: {
            hasValue: true,
          },
        },
      },
      mutate: (options: any) => {
        const { sort } = options;

        if (sort) return { dateofentry: -1 };
        return undefined;
      },
    },
    _autosubmitConfig: {
      title: 'Submission Settings',
      description: '',
      options: {
        autosubmit: {
          type: z.boolean(),
          title: 'Autosubmit Template',
          description:
            'Check if the template is ready and can be autosubmitted by default.',
          input: Input.Checkbox,
          default: false,
        },
      },
      mutate: (options: any) => {
        const { autosubmit } = options;

        if (autosubmit === false) {
          return undefined;
        }

        return {
          url: 'http://localhost:8080/InSight/TriggerReader',
          template: {
            semantics: 'product.ordermanagement.OrderAutoSubmitREST',
            selector: '$selector',
            stage: 'autosubmit',
          },
          skipIfSent: true,
        };
      },
    },
  },
  definition: {
    values: {
      type: {
        validation: fileExtensionSchema,
        type: EntryType.Simple,
        configKey: '_textConfig',
      },
      checking: {
        validation: z.boolean(),
        type: EntryType.Graph,
      },
      template: {
        values: {
          [ReservedNodeType.DeclareVariable]: {
            values: {
              type: {
                validation: z.literal('Import'),
                type: EntryType.Internal,
              },
              clientidentifier: {
                validation: z.string(),
                type: EntryType.Internal,
              },
            },
          },
          autosubmit: {
            validation: z.any(),
            type: EntryType.Internal,
            configKey: '_autosubmitConfig',
          },
          mergingType: {
            validation: mergingTypeSchema,
            type: EntryType.Internal,
            configKey: '_updateConfig',
          },
          containerMerging: {
            validation: z.string(),
            type: EntryType.Internal,
            configKey: '_containerMergingConfig',
          },
          sort: {
            validation: z.literal(JSON.stringify({ dateofentry: -1 })),
            type: EntryType.Internal,
            configKey: '_containerSortDefault',
          },
          containerFields: {
            validation: z.array(z.string()),
            type: EntryType.Internal,
            configKey: '_containerFieldsConfig',
          },
          skipDiff: {
            validation: z.literal(
              JSON.stringify({ ignoreitems: z.array(z.string()) })
            ),
            type: EntryType.Internal,
            configKey: '_skipDiffsConfig',
          },
          selector: {
            validation: z.boolean(),
            type: EntryType.Internal,
          },
          doc: {
            values: {
              [ReservedNodeType.DeclareVariable]: {
                values: {
                  status: {
                    validation: docStatusSchema,
                    type: EntryType.Simple,
                  },
                  clientreference: {
                    validation: z.string(),
                    type: EntryType.Graph,
                  },
                },
              },
              nlinkstate: {
                validation: z.literal('operational'),
                type: EntryType.Internal,
              },
              operatorid: {
                validation: z.number(),
                type: EntryType.Internal,
                prefill: ({ operatorId }) => operatorId,
              },
              workload: {
                validation: workloadSchema,
                type: EntryType.Simple,
              },
              templatetype: {
                validation: z.literal('order'),
                type: EntryType.Internal,
              },
              source: {
                validation: z.string(),
                type: EntryType.Simple,
              },
              _id: {
                validation: z.literal(
                  JSON.stringify([
                    ['order_', '$clientidentifier', '_', '$clientreference'],
                  ])
                ),
                type: EntryType.Internal,
              },
              id: {
                validation: z.literal(
                  JSON.stringify([
                    ['$clientidentifier', '_', '$clientreference'],
                  ])
                ),
                type: EntryType.Internal,
              },
              timestampedid: {
                validation: z.literal(
                  JSON.stringify([
                    [
                      '$clientidentifier',
                      '_',
                      '$clientreference',
                      '_',
                      '#currenttimestamp',
                    ],
                  ])
                ),
                type: EntryType.Internal,
              },
              hiddenreference: {
                validation: z.literal(
                  JSON.stringify([
                    ['$clientidentifier', '_', '$clientreference'],
                  ])
                ),
                type: EntryType.Internal,
              },
              status: {
                validation: z.literal('$status'),
                type: EntryType.Internal,
              },
              fileId: {
                validation: z.literal('$FILEID'),
                type: EntryType.Internal,
              },
              genericorigin: {
                validation: z.literal('$genericOrigin'),
                type: EntryType.Internal,
              },
              genericTemplateName: {
                validation: z.literal(
                  JSON.stringify([['$clientidentifier', ' ', '$status']])
                ),
                type: EntryType.Internal,
              },
              commissioner: {
                validation: z.literal('$clientidentifier'),
                type: EntryType.Internal,
              },
              commissionerid: {
                validation: z.literal('$clientidentifier'),
                type: EntryType.Internal,
              },
              dateofentry: {
                validation: z.literal('#$currentTimestamp'),
                type: EntryType.Internal,
              },
              bookingtyperaw: {
                validation: z.string(),
                type: EntryType.Graph,
              },
              type: {
                validation: z.literal('$type'),
                type: EntryType.Internal,
              },
              bookingType: {
                validation: z.string(),
                type: EntryType.Graph,
              },
              tarifftype: {
                validation: z.string(),
                type: EntryType.Graph,
              },
              transportdirection: {
                validation: transportDirectionSchema,
                type: EntryType.Internal,
              },
              ordersession: {
                validation: orderSessionSchema,
                type: EntryType.Internal,
              },
              bookdate: {
                validation: z.literal('#$currentTimestamp'),
                type: EntryType.Internal,
              },
              carriermerchant: {
                validation: z.enum(['C', 'M']),
                type: EntryType.Simple,
              },
              bookingreference: {
                validation: z.string(),
                type: EntryType.Graph,
              },
              clientreference: {
                validation: z.literal('$clientreference'),
                type: EntryType.Internal,
              },
              containercounter: {
                validation: z.literal(JSON.stringify({ $count: [] })),
                type: EntryType.Internal,
              },
              clientidentifierraw: {
                validation: z.string(),
                type: EntryType.Graph,
                prefill: ({ name }) => name,
              },
              clientidentifier: {
                validation: z.literal('$clientidentifier'),
                type: EntryType.Internal,
              },
              debtor: {
                validation: z.string(),
                type: EntryType.Internal,
              },
              debtorraw: {
                validation: z.string(),
                type: EntryType.Graph,
                prefill: ({ name }) => `Debtor ${name}`,
              },
              shippingcompanyraw: {
                validation: z.string(),
                type: EntryType.Graph,
              },
              containers: {
                outputType: 'array',
                values: {
                  [ReservedNodeType.DeclareVariable]: {
                    values: {
                      pickupterminalraw: {
                        validation: z.string(),
                        type: EntryType.Graph,
                      },
                      deliveryterminalraw: {
                        validation: z.string(),
                        type: EntryType.Graph,
                      },
                      seavesselraw: {
                        validation: z.string(),
                        type: EntryType.Graph,
                      },
                    },
                  },
                  invoicereference: {
                    validation: z.string(),
                    type: EntryType.Graph,
                  },
                  container: {
                    validation: z.string(),
                    type: EntryType.Graph,
                  },
                  weight: {
                    validation: z.number(),
                    type: EntryType.Graph,
                  },
                  unnumber: {
                    validation: z.array(z.string()),
                    type: EntryType.Graph,
                  },
                  sealnr1: {
                    validation: z.string(),
                    type: EntryType.Graph,
                  },
                  colli: {
                    validation: z.number(),
                    type: EntryType.Graph,
                  },
                  full: {
                    validation: z.boolean(),
                    type: EntryType.Graph,
                  },
                  cargodescription: {
                    validation: z.string(),
                    type: EntryType.Graph,
                  },
                  _containerid: {
                    validation: z.string(),
                    type: EntryType.Internal,
                  },
                  containertyperaw: {
                    validation: z.string(),
                    type: EntryType.Graph,
                  },
                  containercounter: {
                    validation: z.number(),
                    type: EntryType.Internal,
                  },
                  addresses: {
                    outputType: 'array',
                    values: {
                      _addressid: {
                        validation: z.string(),
                        type: EntryType.Internal,
                      },
                      loadingdischarge: {
                        validation: loadingDischargeSchema,
                        type: EntryType.Internal,
                      },
                      date: {
                        validation: z.number(),
                        type: EntryType.Internal,
                      },
                      reference: {
                        validation: z.string(),
                        type: EntryType.Graph,
                      },
                    },
                  },
                  billoflading: {
                    validation: z.string(),
                    type: EntryType.Graph,
                  },
                  pickupreference: {
                    validation: z.string(),
                    type: EntryType.Graph,
                  },
                  pickupdate: {
                    validation: z.number(),
                    type: EntryType.Internal,
                  },
                  latestpickupdate: {
                    validation: z.number(),
                    type: EntryType.Internal,
                  },
                  deliveryreference: {
                    validation: z.string(),
                    type: EntryType.Graph,
                  },
                  closingdate: {
                    validation: z.number(),
                    type: EntryType.Internal,
                  },
                  cargoopeningdate: {
                    validation: z.number(),
                    type: EntryType.Internal,
                  },
                  inlandterminalinbound: {
                    validation: z.string(),
                    type: EntryType.Graph,
                  },
                  inlandturninreference: {
                    validation: z.string(),
                    type: EntryType.Graph,
                  },
                  inlandterminaloutbound: {
                    validation: z.string(),
                    type: EntryType.Graph,
                  },
                  inlandturnoutreference: {
                    validation: z.string(),
                    type: EntryType.Graph,
                  },
                  addresscounter: {
                    validation: z.number(),
                    type: EntryType.Graph,
                  },
                  addressraw: {
                    validation: z.string(),
                    type: EntryType.Graph,
                  },
                  pickupterminalraw: {
                    validation: z.literal('$pickupterminalraw'),
                    type: EntryType.Internal,
                  },
                  pickupterminal: {
                    validation: z.literal(
                      JSON.stringify({
                        $mongoRequest: [
                          {
                            '$db.ordermanagement_conversion.findOne': {
                              key: 'pickupterminal',
                              commissionerid: '$clientidentifier',
                              crefcode: '$pickupterminalraw',
                            },
                            $result: {
                              $getVariable: 'orefcode',
                            },
                          },
                          'morphjs',
                          ['ordermanagement_conversion'],
                        ],
                      })
                    ),
                    type: EntryType.Internal,
                  },
                  deliveryterminalraw: {
                    validation: z.literal('$deliveryterminalraw'),
                    type: EntryType.Internal,
                  },
                  deliveryterminal: {
                    validation: z.literal(
                      JSON.stringify({
                        $mongoRequest: [
                          {
                            '$db.ordermanagement_conversion.findOne': {
                              key: 'deliveryterminal',
                              commissionerid: '$clientidentifier',
                              crefcode: '$deliveryterminalraw',
                            },
                            $result: {
                              $getVariable: 'orefcode',
                            },
                          },
                          'morphjs',
                          ['ordermanagement_conversion'],
                        ],
                      })
                    ),
                    type: EntryType.Internal,
                  },
                  seavesselraw: {
                    validation: z.literal('$seavesselraw'),
                    type: EntryType.Internal,
                  },
                  seavessel: {
                    validation: z.literal(
                      JSON.stringify({
                        $mongoRequest: [
                          {
                            '$db.ordermanagement_conversion.findOne': {
                              commissionerid: '$clientidentifier',
                              crefcode: '$seavesselraw',
                            },
                            $result: {
                              $getVariable: 'orefcode',
                            },
                          },
                          'morphjs',
                          ['ordermanagement_conversion'],
                        ],
                      })
                    ),
                    type: EntryType.Internal,
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};
