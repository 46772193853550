import { GraphOperation } from "@morph-mapper/types";
import {
  Location,
  useLocation,
  useNavigate,
  RelativeRoutingType,
} from "react-router-dom";

// Mutate original navigation interfaces to enforce state parameter existence
export interface NavigateOptions<T> {
  replace?: boolean;
  state: T;
  preventScrollReset?: boolean;
  relative?: RelativeRoutingType;
}

export interface NavigateFunction {
  (to: string): void;
  (delta: number): void;
}

// Generic interfaces to mutate specific location and navigation functions
interface ILocation<T> extends Location {
  state: T;
}
interface INavigation extends NavigateFunction {}

/*
 * Definition of hooks for route navigation with state
 */

export type GraphState =
  | {
      operation: GraphOperation.Insert;
      sourceEdge: {
        source: string;
        sourceHandle: string | null | undefined;
      };
      targetEdge: {
        id: string;
        data: any;
        target: string;
        targetHandle: string | null | undefined;
      };
    }
  | {
      operation: GraphOperation.Add;
      targetEdge: {
        target: string;
        targetHandle: string | null | undefined;
      };
    }
  | {
      operation: GraphOperation.Replace;
      nodeId: string;
    }
  | {
      operation: GraphOperation.Dependency;
      targetEdge: {
        target: string;
        targetHandle: string | null | undefined;
        data: any;
      };
    };

export const isGraphInsertLocation = (
  location: any
): location is Extract<GraphState, { operation: GraphOperation.Insert }> => {
  if (location === undefined) return false;
  return location.operation === GraphOperation.Insert;
};

export const isGraphAddLocation = (
  location: any
): location is Extract<GraphState, { operation: GraphOperation.Add }> => {
  if (location === undefined) return false;
  return location.operation === GraphOperation.Add;
};

export const isGraphReplaceLocation = (
  location: any
): location is Extract<GraphState, { operation: GraphOperation.Replace }> => {
  if (location === undefined) return false;
  return location.operation === GraphOperation.Replace;
};

export const isGraphAddDepLocation = (
  location: any
): location is Extract<
  GraphState,
  { operation: GraphOperation.Dependency }
> => {
  if (location === undefined) return false;
  return location.operation === GraphOperation.Dependency;
};

/**
 * Graph navigation and location hooks
 */
export interface GraphNavigation extends INavigation {
  (to: "nodes/new", options: NavigateOptions<GraphState>): void;
}
export interface GraphLocation extends ILocation<GraphState> {}

export const useGraphNavigation = (): GraphNavigation =>
  useNavigate() as GraphNavigation;
export const useGraphLocation = (): GraphLocation =>
  useLocation() as GraphLocation;
