import _ from 'lodash';
import dot from 'dot-object';
import { useStore } from '@/store';
import { getKeys, getValues } from '@morph-mapper/utils';
import {
  BlockRule,
  LogicBlockIndex,
  LogicBlockRecord,
  useLogicBlocks,
  useRules,
} from '@morph-mapper/node-logic';
import { ROOT_KEY } from '@/config';
import { Node, Edge } from '@/types';
import { parseNodeOptions } from '@/utils';

export const useLogicTree = () => {
  const [variant, nodeApi, IGraph] = useStore(({ config: c, graphs: g }) => [
    c.getVariant(),
    g.node,
    g.graph,
  ]);
  const { logicBlocks } = useLogicBlocks(variant);
  const { hasMultipleElements } = useRules(variant);

  // TODO: We need to calculate if the parent is the root node to find out if the current nodeId is in the root context
  // (the node's result will be attached to the corresponding entry key). We probably want to restructure the graph so that we don't need to calculate this.
  const findParentNode = (id: string, graphId: string): string | undefined => {
    const graph = IGraph(graphId);
    const edge = getValues(graph.getEdges())?.find(
      (edge) => edge.source === id
    );

    if (edge === undefined) {
      return undefined;
    }

    return edge.target;
  };

  /**
   * Finds all nodes which are connected to the current node. Used to traverse down the logic tree.
   *
   * @returns A tuple of two arrays. The first array contains all children of the current node.
   * The second array contains all dependencies of the current node.
   */
  const findConnectedNodes = (
    id: string,
    graphId: string
  ): [Node[], [Node, Edge][]] => {
    const graph = IGraph(graphId);
    // Find all edges which target the current node
    const edgesOnNode = getValues(graph.getEdges())?.filter((edge) => {
      return edge.target === id;
    });

    return getValues(graph.getNodes()).reduce(
      (acc, node) => {
        const [children, dependencies] = acc;
        const edge = edgesOnNode?.find((edge) => edge.source === node.id);

        if (edge === undefined) {
          return acc;
        }
        if (edge.data.type === 'dependency') {
          return [children, [...dependencies, [node, edge] as [Node, Edge]]];
        }

        return [[...children, node], dependencies];
      },
      [[], []] as [Node[], [Node, Edge][]]
    );
  };

  const renderChildren = (graphId: string, children: Node[]) => {
    return children.map((child) => {
      return logicTreeToTemplate(graphId, child.id);
    });
  };

  const parseOptions = (
    graphId: string,
    nodeId: string,
    dependencies: [Node, Edge][]
  ) => {
    const aggregatedTemplates: Record<string, any[]> = {};

    const dependencyNodes = dependencies.reduce((acc, [node, edge]) => {
      const template = logicTreeToTemplate(graphId, node.id);

      if (hasMultipleElements(node.type)) {
        let templateRefByType = aggregatedTemplates[node.type];
        if (templateRefByType === undefined) {
          templateRefByType = [];
        }
        templateRefByType.push(template);
      } else {
        dot.str(edge.data.property, template, acc);
      }

      return acc;
    }, {});

    getKeys(aggregatedTemplates).forEach((type) => {
      const templateByType = aggregatedTemplates[type];
      if (templateByType === undefined) {
        throw `Template by type ${type} is undefined`;
      }

      const mergedTemplates = templateByType.flat();
      dot.str(type, mergedTemplates, dependencyNodes);
    });

    const mappedOptions = parseNodeOptions<any>(
      nodeApi(graphId, nodeId).getOptions()
    );

    return _.merge({}, _.merge(mappedOptions, dependencyNodes));
  };

  const mutate = <
    K extends Record<LogicBlockIndex, any>,
    T extends LogicBlockRecord<K, Set<BlockRule>>
  >(
    graphId: string,
    nodeId: string,
    logicBlocks: T
  ) => {
    const type = nodeApi(graphId, nodeId).getType();
    const parent = findParentNode(nodeId, graphId);

    const [children, dependencies] = findConnectedNodes(nodeId, graphId);
    const options = parseOptions(graphId, nodeId, dependencies);
    const childTemplates: any[] = renderChildren(graphId, children);

    const { mutation } = logicBlocks[type];

    return mutation({
      tree: () => childTemplates,
      options,
      ctx: { isRoot: parent === ROOT_KEY },
    });
  };

  /**
   * Takes a node reference and traverses the logic graph
   * to parse the nodes into a template object.
   *
   * @param graph Contains a graph structure of nodes and edges.
   * @param node Reference of a location in the logic graph, used to traverse the graph.
   * @returns A template object which can be parsed by the template engine.
   */
  const logicTreeToTemplate = (graphId: string, nodeId?: string): any => {
    if (nodeId === undefined) {
      nodeId = ROOT_KEY;
    }

    return mutate(graphId, nodeId, logicBlocks);
  };

  return { logicTreeToTemplate };
};
