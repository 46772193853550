import { SideBarContent, SideBarWrapper } from './styles';
import { ConfigContent } from '../config-content';
import { Footer, Header } from '../../molecules';
import { useConfigView } from './use-config-view';
import { useNodeContext } from '../../context';
import { useStore } from '@/store';
import { useEffect } from 'react';

export const ConfigView = () => {
  const setIsSaveDisabled = useStore(({ config: c }) => c.setIsSaveDisabled);

  const type = useNodeContext((s) => s.getType());
  const { selectContent } = useConfigView();

  // Disable save in configuration view
  useEffect(() => {
    setIsSaveDisabled(true);

    // Re-enable saving when the component unmounts
    return () => {
      setIsSaveDisabled(false);
    };
  }, [setIsSaveDisabled]);

  return (
    <SideBarWrapper>
      <Header />
      <SideBarContent>
        <ConfigContent options={selectContent(type)} />
      </SideBarContent>
      <Footer />
    </SideBarWrapper>
  );
};
