import { Table } from '@mantine/core';
import { useTreeContext } from '../../context';
import { ProviderSelector } from '../provider-selector';
import { StyledRow, StyledTitle } from './styles';

export const ListTree = () => {
  const childrenIds = useTreeContext((s) => s.getChildrenIds());

  return (
    <Table highlightOnHover sx={{ tableLayout: 'fixed' }}>
      <thead>
        <StyledRow>
          <th style={{ width: '30%' }}>
            <StyledTitle>Field</StyledTitle>
          </th>
          <th>
            <StyledTitle>Value</StyledTitle>
          </th>
          <th style={{ width: 160 }}>
            <StyledTitle>Actions</StyledTitle>
          </th>
        </StyledRow>
      </thead>
      <tbody>
        {childrenIds.map((entryId) => {
          return <ProviderSelector key={entryId} entryId={entryId} />;
        })}
      </tbody>
    </Table>
  );
};
