import { useEffect, useState } from 'react';
import { useStore } from '../../context';

export const useXmlFile = () => {
  const file = useStore(({ file: f }) => f.getFile());
  const [xml, setXml] = useState<string | null>(null);

  useEffect(() => {
    if (!file) return;
    
    const parseFileContent = async () => {
      const text = await file.text();
      setXml(text);
    };

    parseFileContent();
  }, [xml]);

  return { xml };
};
