import { useRef } from "react";
import { ParameterRowContext } from "./context";
import { ParameterRowStore, createParameterRowStore } from "../../store";
import { OptionEntry } from "@morph-mapper/types";

interface ParameterRowProviderProps extends React.PropsWithChildren {
  name: string;
  value: OptionEntry<any>;
  updateParameter: (name: string, value: OptionEntry<any>) => void;
}

export const ParameterRowProvider = ({
  name,
  value,
  updateParameter,
  children,
}: ParameterRowProviderProps) => {
  const storeRef = useRef<ParameterRowStore>();
  if (!storeRef.current) {
    storeRef.current = createParameterRowStore({
      name,
      value,
      updateParameter,
    });
  }

  return (
    <ParameterRowContext.Provider value={storeRef.current}>
      {children}
    </ParameterRowContext.Provider>
  );
};
