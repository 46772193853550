import { Menu, useMantineTheme } from '@mantine/core';
import { useTypeChange } from './use-type-change';
import { match, P } from 'ts-pattern';
import { IoMdRadioButtonOff, IoMdRadioButtonOn } from 'react-icons/io';

/**
 * Molecule which displays a menu section for changing the type of an entry.
 */
export const TypeChangeMenuSection = () => {
  const theme = useMantineTheme();
  const { types, currentType, setType } = useTypeChange();

  return (
    match(types)
      // We do not show the type change menu if only one type is allowed,
      // which should be the current type.
      .with([P._], () => null)
      // Generate a menu item for each allowed type.
      .with(P.array(P.any), (types) => (
        <>
          <Menu.Label>Type</Menu.Label>

          {types.map((t) => (
            <Menu.Item
              icon={
                currentType === t ? (
                  <IoMdRadioButtonOn color={theme.colors.blue[7]} />
                ) : (
                  <IoMdRadioButtonOff color={theme.colors.gray[6]} />
                )
              }
              onClick={() => setType(t)}
            >
              {t.charAt(0).toUpperCase() + t.slice(1)}
            </Menu.Item>
          ))}
        </>
      ))
      .exhaustive()
  );
};
