import { useEffect, useState } from 'react';
import {
  useCategoriesOfOperatorIdByDomain,
  useCreateTemplateSave,
  useCreateTemplateRef,
  useTemplateSave,
  useTemplateRefsOfOperatorId,
} from '@/services';
import { useSetupStore } from '../../store';
import { useLoadState, useNotify, useSchemaBuilder } from '@/hooks';
import { useStore } from '@/store';
import { SchemaType, useSchema } from '@morph-mapper/schemas';
import { SchemaSource, type SchemaVariant } from '@morph-mapper/types';
import { TreeNode, PreConfiguration } from '@/types';
import {
  getEntries,
  getKeys,
  getValues,
  isNotUndefined,
} from '@morph-mapper/utils';

export const useCreateTemplate = () => {
  const [setId, setPath, definedCustomSchemes] = useStore(({ config: c }) => [c.setId, c.setPath, c.definedCustomSchemes]);
  const [operatorId, domain, open, setOpen, step, setStep] = useSetupStore(
    (s) => [
      s.getOperatorId(),
      s.getDomain(),
      s.isCreateModalOpen,
      s.setIsCreateModalOpen,
      s.currentStep,
      s.setCurrentStep,
    ]
  );

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [priority, setPriority] = useState(0);
  const [category, setCategory] = useState('default');
  const [type, setType] = useState<SchemaType>();
  const [variant, setVariant] = useState<SchemaVariant>();
  const [source, setSource] = useState<SchemaSource>();
  const [active, setActive] = useState(false);
  const [templatePath, setTemplatePath] = useState(null);
  const [nameError, setNameError] = useState('');

  const { error, success } = useNotify();

  /* Queries */
  const { data: templates } = useTemplateRefsOfOperatorId(operatorId);
  const { data: categories } = useCategoriesOfOperatorIdByDomain(
    operatorId,
    domain
  );
  const {
    mutate: createTemplateRef,
    isSuccess,
    data: templateData,
  } = useCreateTemplateRef();
  const {
    mutate: createTemplateSave,
    isSuccess: saveSuccess,
    data: savedTemplateId,
  } = useCreateTemplateSave();

  const { data: templateToLoad } = useTemplateSave(templatePath, active);
  const { load } = useLoadState(savedTemplateId);
  const { getSchemaTypes, getSchemaVariants, getSchema } = useSchema(domain, definedCustomSchemes);
  const { buildAppState } = useSchemaBuilder();

  useEffect(() => {
    if (!active) return;
    if (templatePath === null || templateToLoad === undefined) return;

    // Set refId and path in the global store
    setPath(templatePath);
    load(templateToLoad);

    setStep(step + 1);
  }, [active, templatePath, templateToLoad]);

  useEffect(() => {
    if (name === '') return;
    checkNameConflict();
  }, [name, category]);

  useEffect(() => {
    if (!saveSuccess) return;

    setTemplatePath(savedTemplateId);
    setActive(true);

    success('Template created');

    setName('');
    setDescription('');
    setPriority(0);
    setCategory('');
    setType(undefined);
    setVariant(undefined);
  }, [saveSuccess]);

  useEffect(() => {
    if (!isSuccess) return;

    const { id } = templateData;
    const template = buildTemplate(id);

    if (template === undefined) {
      error('Could not create template.');
      return;
    }

    createTemplateSave(template);
    setId(id);
    setOpen(false);
  }, [isSuccess]);

  useEffect(() => {
    const [type, ..._] = getSchemaTypes();
    if (type === undefined) {
      error('No types found for selected domain');
      return;
    }

    setType(type);
  }, []);

  useEffect(() => {
    if (type === undefined) return;

    const [variant, ..._] = getSchemaVariants(type);

    if (variant === undefined) {
      error('No variants found for selected type');
      return;
    }

    setVariant(variant);
  }, [type, domain, operatorId]);

  useEffect(() => {
    const schemaSources = getKeys(SchemaSource);
    if (schemaSources.length > 0) {
      setSource(SchemaSource[schemaSources[0]]);
    } else {
      error('No input sources found');
    }
  }, []);

  const checkNameConflict = async () => {
    if (templates === undefined) return;
    const isNameDuplicate = templates.find(
      (template) =>
        template.operatorId === operatorId &&
        template.domain === domain &&
        template.category === category &&
        template.name === name &&
        template.deleted !== true
    );
    if (isNameDuplicate) {
      setNameError(
        'A template with this name already exists in the selected category'
      );
    } else {
      setNameError('');
    }
  };

  // TODO: move inside appropriate hook
  const findDiscardableIds = (
    type: SchemaType,
    variant: SchemaVariant,
    entries: Record<string, TreeNode>
  ) => {
    const schema = getSchema(type, variant);

    return schema.updateTemplateDiscardFields
      .map((fieldName) => {
        return getEntries(entries)
          .map(([id, entry]) => {
            if (entry.key === fieldName) {
              return id;
            }
          })
          .filter(isNotUndefined);
      })
      .flat();
  };
  // TODO: move inside appropriate hook
  const validateConfiguration = (
    preconfig: PreConfiguration,
    type: SchemaType,
    variant: SchemaVariant
  ) => {
    const { config } = getSchema(type, variant);
    const availableKeys = getKeys(config || {});

    getValues(preconfig).forEach(({ key }) => {
      if (!availableKeys.includes(key)) {
        throw `Key ${key} is not available in the schema configuration.`;
      }
    });
  };

  const buildTemplate = (id: string) => {
    if (type === undefined || variant === undefined) {
      error('No type or variant selected');
      return;
    }

    const schema = getSchema(type, variant);
    if (schema === undefined) {
      error('Schema not found');
      return;
    }
    const { entries, preconfiguration } = buildAppState(schema, {
      name,
      operatorId,
    });
    validateConfiguration(preconfiguration, type, variant);
    const updateDiscardIds = findDiscardableIds(type, variant, entries);

    return {
      refId: id,
      operatorId,
      name,
      domain,
      type,
      variant,
      graphs: {},
      entries,
      config: {
        preconfiguration,
        updateDiscardIds,
      },
    };
  };

  const handleName = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '') setNameError('');
    setName(e.target.value);
  };

  const handleDescription = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value);
  };

  const handlePriority = (value: number | '') => {
    if (value === '') {
      setPriority(0);
      return;
    }

    setPriority(value);
  };

  const handleCategory = (value: string | null) => {
    if (value === null) return;
    setCategory(value);
  };

  const handleVariantChange = (variant: SchemaVariant) => {
    setVariant(variant);
  };

  const handleSourceChange = (source: SchemaSource) => {
    setSource(source);
  };

  const handleTypeChange = (type: SchemaType) => {
    setType(type);
  };

  const handleCreateTemplate = () => {
    createTemplateRef({
      operatorId,
      deployPath: null,
      name,
      templatetype: 'MorphMapper',
      domain,
      category,
      enabled: false,
      priority,
      inputsource: source || SchemaSource.Email,
    });
  };

  const handleCloseModal = () => {
    setNameError('');
    setOpen(false);
  };

  return {
    open,
    name,
    nameError,
    description,
    priority,
    category,
    type,
    source,
    variant,
    categories,
    handleName,
    handleDescription,
    handlePriority,
    handleCategory,
    handleCreateTemplate,
    handleCloseModal,
    getSchemaTypes,
    getSchemaVariants,
    handleTypeChange,
    handleVariantChange,
    handleSourceChange,
  };
};
