import { ConfigInput, ConfigLabel } from "@morph-mapper/ui";
import { SectionWrapper } from "./styles";

interface RowLayoutProps {
  title: string;
  description: string;
  children: React.ReactNode;
}

export const RowLayout = ({ children, ...props }: RowLayoutProps) => {
  return (
    <SectionWrapper>
      <ConfigLabel {...props} />
      <ConfigInput>{children}</ConfigInput>
    </SectionWrapper>
  );
};
