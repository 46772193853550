import { SearchTarget } from '@/hooks';
import { Badge, Group, Text } from '@mantine/core';
import React from 'react';
import { forwardRef } from 'react';

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  label: string;
  source: SearchTarget;
}

export const VariableItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ label, source, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        <div>
          <Text size="sm">{label}</Text>

          <Badge
            size="xs"
            radius="xs"
            color={source === SearchTarget.Data ? 'orange' : 'cyan'}
          >
            <Text size="xxs" opacity={0.65}>
              {source}
            </Text>
          </Badge>
        </div>
      </Group>
    </div>
  )
);
