import { useEffect, useState } from 'react';
import { useStore } from '../../context';

export const useText = () => {
  const file = useStore(({ file: f }) => f.getFile());
  /* Local State */
  const [text, setText] = useState<string>('');
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(1);
  const [linesPerPage] = useState(50);

  /* Effects */
  useEffect(() => {
    readFile();
  }, [file, linesPerPage]);

  /**
   * Handlers
   **/
  const readFile = async () => {
    if (!file) {
      console.error("File is null. Cannot read file.");
      return;
    }
  
    const fileText = await file.text();
    setText(fileText);
    setNumPages(Math.ceil(fileText.split('\n').length / linesPerPage));
  };

  const getPageText = () => {
    const lines = text.split('\n');
    const start = (pageNumber - 1) * linesPerPage;
    const end = start + linesPerPage;
    return lines.slice(start, end).join('\n');
  };

  const previousPage = () => {
    setPageNumber((prevPageNumber) => Math.max(prevPageNumber - 1, 1));
  };

  const nextPage = () => {
    setPageNumber((prevPageNumber) => Math.min(prevPageNumber + 1, numPages));
  };

  return {
    pageNumber,
    numPages,
    getPageText,
    previousPage,
    nextPage,
  };
};
