import styled from '@emotion/styled';
import {
  Accordion,
  Box,
  BoxProps,
  createPolymorphicComponent,
} from '@mantine/core';

const _ListContent = styled(Box)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 0px;
  overflow: auto;
`;

export const ListContent = createPolymorphicComponent<'div', BoxProps>(
  _ListContent
);

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  '& .mantine-Accordion-item': {
    '&:not(:last-of-type)': {
      borderTop: `1px solid ${theme.colors.gray[4]}`,
    },
    borderBottom: `1px solid ${theme.colors.gray[4]}`,
  },
  '& .mantine-Accordion-control': {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left',
    fontSize: '0.95rem',
    width: '100%',
    border: 0,
    paddingTop: '0.8rem',
    paddingBottom: '0.8rem',
    paddingLeft: '1rem',
    gap: '1rem',
    backgroundColor: theme.colors.gray[0],
    '&[data-active]': {
      borderBottom: `1px solid ${theme.colors.gray[4]}`,
    },
    '& .mantine-Accordion-chevron': {
      '&[data-rotate]': {
        transform: 'rotate(180deg)',
      },
    },
  },
  '& .mantine-Accordion-label': {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: '1rem',
  },
}));
