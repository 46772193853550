import { match } from 'ts-pattern';
import { useStore } from '../../context';
import { Segment } from '../segment';

interface SegmentListProps {
  firstChildRef: React.RefObject<HTMLButtonElement>;
  lastChildRef: React.RefObject<HTMLButtonElement>;
}

export const SegmentList = ({
  firstChildRef,
  lastChildRef,
}: SegmentListProps) => {
  const pathMode = useStore(({ path: p }) => p.getPathMode());
  const referenceIds = useStore(({ path: p }) => p.getReferenceIds(pathMode));

  return referenceIds.map((id, index) =>
    match(index)
      .with(0, () => <Segment key={id} id={id} ref={firstChildRef} />)
      .with(referenceIds.length - 1, () => (
        <Segment key={id} id={id} ref={lastChildRef} />
      ))
      .otherwise(() => <Segment key={id} id={id} />)
  );
};
