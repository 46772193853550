import { ComputedCell, ConfirmCell } from '@morph-mapper/ui';
import { StyledItemRow } from './styles';
import { useItemEntryContext } from '../../context';
import { ActionCell } from '../action-cell';
import { Box, Table } from '@mantine/core';

interface ItemRowProps {
  editView?: React.ReactNode;
}

export const ItemRow = ({ editView }: ItemRowProps) => {
  const [name, computed, isConfirm, isEditing, cancelDelete, confirmDelete] =
    useItemEntryContext((s) => [
      s.getName(),
      s.getComputed(),
      s.isConfirm,
      s.isEditing,
      s.cancelDelete,
      s.confirmDelete,
    ]);

  const pickCell = () => {
    if (isConfirm)
      return (
        <ConfirmCell handleAccept={confirmDelete} handleDeny={cancelDelete} />
      );
    return <ComputedCell value={computed} />;
  };

  return (
    <>
      <StyledItemRow>
        <td style={{ paddingRight: '3rem' }}>{name}</td>
        <td>{pickCell()}</td>
        <td style={{ whiteSpace: 'nowrap' }}>
          <ActionCell />
        </td>
      </StyledItemRow>
      {isEditing && (
        <tr>
          <td colSpan={3} style={{ border: 'none', padding: 0 }}>
            <Box
              sx={(theme) => ({
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: theme.colors.gray[1],
                paddingTop: theme.spacing.md,
                paddingBottom: theme.spacing.md,
                paddingLeft: theme.spacing.xl,
                paddingRight: theme.spacing.xl,
                borderTop: `1px solid ${theme.colors.gray[3]}`,
              })}
            >
              <Table width={'100%'}>{editView}</Table>
            </Box>
          </td>
        </tr>
      )}
    </>
  );
};
