import { Input } from "@mantine/core";
import { Layout, StyledBadge } from "./styles";

interface OperatorDisplayProps {
  label: string;
  value: string;
  operation: string;
}

/**
 * Renders a reference with a badge that shows the currently active operation.
 */
export const OperatorDisplay = ({
  label,
  value,
  operation,
}: OperatorDisplayProps) => {
  return (
    <Input.Wrapper label={label}>
      <Layout>
        <Input value={value} readOnly sx={{ flexGrow: 1 }} />
        <StyledBadge color="gray" radius="sm" size="lg">
          {operation}
        </StyledBadge>
      </Layout>
    </Input.Wrapper>
  );
};
