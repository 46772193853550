import { Table, TableWrapper } from "./styles";
import { CreateParameter } from "../create-parameter";
import { ParameterRow } from "../row";
import { ParameterRowProvider } from "../context";
import { OptionEntry } from "@morph-mapper/types";

interface ParameterTableProps {
  value: Record<string, OptionEntry<any>>;
  handleChange: (value: Record<string, OptionEntry<any>>) => void;
}

export const ParameterTable = ({
  value: values,
  handleChange,
}: ParameterTableProps) => {
  const updateParameter = (name: string, value: OptionEntry<any>) => {
    handleChange({ ...values, [name]: value });
  };

  return (
    <TableWrapper>
      <Table horizontalSpacing="xl" verticalSpacing="xs">
        <thead>
          <tr>
            <th>Name</th>
            <th>Value</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(values).map(([name, value]) => {
            return (
              <ParameterRowProvider
                name={name}
                key={name}
                value={value}
                updateParameter={updateParameter}
              >
                <ParameterRow />
              </ParameterRowProvider>
            );
          })}
        </tbody>
      </Table>
      <CreateParameter value={values} handleChange={handleChange} />
    </TableWrapper>
  );
};
