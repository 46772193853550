import { GraphEdge, typeConnectorRecord } from '../../types';

export const STRUCTURE_CATEGORIES = {
  core: 'Core',
  iterator: 'Iterators',
} as const;

export const LOGIC_CATEGORIES = {
  core: 'Core',
  transformer: 'Transformers',
  logic: 'Logical Operators',
  math: 'Mathematical Operators',
  request: 'Requests',
  table: 'Tables',
  iterator: 'Iterators',
  case: 'Case Definition',
} as const;

export const LOGIC_CONNECTORS = typeConnectorRecord({
  default: {
    edge: GraphEdge.Add,
  },
});
