import { EntryType, ReservedNodeType } from '@morph-mapper/types';
import { Schema } from '../../../types';
import { z } from 'zod';
import { Input } from '@morph-mapper/node-inputs';
import { fileExtensionSchema } from '../../shared';

export const easyeomTextInvoiceSchema: Schema = {
  updateTemplateDiscardFields: [],
  config: {
    _textConfig: {
      title: 'Text Settings',
      description: '',
      options: {
        messageBody: {
          type: z.boolean(),
          title: 'Message Body',
          description: 'Check if the file is a message body',
          input: Input.Checkbox,
          default: false,
        },
      },
      mutate: (options: any) => {
        const { messageBody } = options;

        if (messageBody) {
          return 'message-txt';
        }

        return 'txt';
      },
    },
    _autosubmitConfig: {
      title: 'Submission Settings',
      description: '',
      options: {
        autosubmit: {
          type: z.boolean(),
          title: 'Autosubmit Template',
          description:
            'Check if the template is ready and can be autosubmitted by default.',
          input: Input.Checkbox,
          default: false,
        },
      },
      mutate: (options: any) => {
        const { autosubmit } = options;

        if (autosubmit === false) {
          return undefined;
        }

        return {
          url: 'http://localhost:8080/InSight/TriggerReader',
          template: {
            semantics: 'product.ordermanagement.OrderAutoSubmitREST',
            selector: '$selector',
            stage: 'autosubmit',
          },
          skipIfSent: true,
        };
      },
    },
  },
  definition: {
    values: {
      type: {
        validation: fileExtensionSchema,
        type: EntryType.Simple,
        configKey: '_textConfig',
      },
      checking: {
        validation: z.boolean(),
        type: EntryType.Graph,
      },
      template: {
        values: {
          [ReservedNodeType.DeclareVariable]: {
            values: {
              operatorid: {
                validation: z.number(),
                type: EntryType.Internal,
                prefill: ({ operatorId }) => operatorId,
              },
              vendor: {
                validation: z.string(),
                displayName: 'Vendor on Text',
                type: EntryType.Graph,
                prefill: ({ name }) => `${name}`,
              },
              logo: {
                validation: z.string(),
                displayName: 'URL path to logo of service by vendor',
                type: EntryType.Graph,
              },
              logopath: {
                validation: z.literal(
                  JSON.stringify([
                    [
                      '<img src="',
                      '$logo',
                      '" style="margin-right: -60px;" width="60"/>',
                    ],
                  ])
                ),
                type: EntryType.Internal,
              },
              company: {
                validation: z.string(),
                displayName: 'Company',
                type: EntryType.Graph,
              },
              companyName: {
                validation: z.literal(
                  JSON.stringify({
                    $mongoRequest: [
                      {
                        '$db.companiesconversion.findOne': {
                          operatorid: '$operatorid',
                          key: 'company',
                          crefcode: '$company',
                        },
                        $result: {
                          $getVariable: 'orefcode',
                        },
                      },
                      'morphjs',
                      ['companiesconversion'],
                    ],
                  })
                ),
                type: EntryType.Internal,
              },
              companyWithoutSpaces: {
                validation: z.literal(
                  JSON.stringify([
                    [
                      {
                        '$utils.regexp.replace': ['$company', [' ', 'gi'], ''],
                      },
                    ],
                  ])
                ),
                type: EntryType.Internal,
              },
              service: {
                validation: z.string(),
                displayName: 'Service',
                type: EntryType.Graph,
              },
              uniqueid: {
                validation: z.string(),
                displayName: 'Id (or date)',
                type: EntryType.Graph,
              },
              priceNumber: {
                validation: z.number(),
                displayName: 'Prijs',
                type: EntryType.Graph,
              },
              price: {
                validation: z.literal(
                  JSON.stringify({
                    '$math.parseFloat': '$priceNumber',
                  })
                ),
                type: EntryType.Internal,
              },
              costNumber: {
                validation: z.number(),
                displayName: 'Kostprijs',
                type: EntryType.Graph,
              },
              cost: {
                validation: z.literal(
                  JSON.stringify({
                    '$math.parseFloat': '$costNumber',
                  })
                ),
                type: EntryType.Internal,
              },
              quantity: {
                validation: z.number(),
                displayName: 'Quantity',
                type: EntryType.Graph,
              },
              selector: {
                validation: z.literal(
                  JSON.stringify({
                    [ReservedNodeType.DeclareVariable]: {
                      PARSE_SETTINGS: {
                        noResultIfUndefined: true,
                        noDollarKeys: false,
                        noDollarStrings: true,
                        templateKeys: true,
                      },
                    },
                    companyNameraw: '$company',
                  })
                ),
                type: EntryType.Internal,
              },
            },
          },
          autosubmit: {
            validation: z.any(),
            type: EntryType.Internal,
            configKey: '_autosubmitConfig',
          },
          mergingType: {
            validation: z.literal('extend'),
            type: EntryType.Internal,
          },
          containerMerging: {
            validation: z.literal('single'),
            type: EntryType.Internal,
          },
          selector: {
            validation: z.literal('$selector'),
            type: EntryType.Internal,
          },
          sort: {
            validation: z.literal(
              JSON.stringify({
                dateofentry: -1,
              })
            ),
            type: EntryType.Internal,
          },
          contractFields: {
            validation: z.literal(
              JSON.stringify(['contractName', 'contractServices.services.name'])
            ),
            type: EntryType.Internal,
          },
          doc: {
            values: {
              operatorid: {
                validation: z.literal('$operatorid'),
                type: EntryType.Internal,
              },
              templatetype: {
                validation: z.literal('company'),
                type: EntryType.Internal,
              },
              source: {
                validation: z.literal('$vendor'),
                type: EntryType.Internal,
              },
              _id: {
                validation: z.literal(
                  JSON.stringify([
                    [
                      '$operatorid',
                      '_',
                      '$vendor',
                      '_',
                      '$service',
                      '_',
                      '$company',
                      '_',
                      '$uniqueid',
                    ],
                  ])
                ),
                type: EntryType.Internal,
              },
              id: {
                validation: z.literal(
                  JSON.stringify({
                    $if: '$companyName',
                    $then: {
                      $mongoRequest: [
                        {
                          '$db.companies.findOne': {
                            operatorid: '$operatorid',
                            companyName: {
                              $mongoRequest: [
                                {
                                  '$db.companiesconversion.findOne': {
                                    operatorid: '$operatorid',
                                    key: 'company',
                                    crefcode: '$company',
                                  },
                                  $result: {
                                    $getVariable: 'orefcode',
                                  },
                                },
                                'morphjs',
                                ['companiesconversion'],
                              ],
                            },
                          },
                          $result: {
                            $getVariable: 'id',
                          },
                        },
                        'morphjs',
                        ['companies'],
                      ],
                    },
                    $else: '$companyWithoutSpaces',
                  })
                ),
                type: EntryType.Internal,
              },
              companyCN: {
                validation: z.literal('$companyWithoutSpaces'),
                type: EntryType.Internal,
              },
              companyNameraw: {
                validation: z.literal('$company'),
                type: EntryType.Internal,
              },
              companyName: {
                validation: z.literal('$companyName'),
                type: EntryType.Internal,
              },
              timestamp: {
                validation: z.literal(JSON.stringify({ '$time.stamp': [] })),
                type: EntryType.Internal,
              },
              contracts: {
                outputType: 'array',
                values: {
                  contractNameraw: {
                    validation: z.literal(
                      JSON.stringify([['$service', '_', '$company']])
                    ),
                    type: EntryType.Internal,
                  },
                  contractName: {
                    validation: z.literal(
                      JSON.stringify({
                        $mongoRequest: [
                          {
                            '$db.companiesconversion.findOne': {
                              companyCN: '$companyWithoutSpaces',
                              key: 'contract',
                              crefcode: [['$service', ' - ', '$company']],
                            },
                            $result: {
                              $getVariable: 'orefcode',
                            },
                          },
                          'morphjs',
                          ['companiesconversion'],
                        ],
                      })
                    ),
                    type: EntryType.Internal,
                  },
                  contractServices: {
                    outputType: 'array',
                    values: {
                      services: {
                        outputType: 'array',
                        values: {
                          nameraw: {
                            validation: z.literal('$service'),
                            type: EntryType.Internal,
                          },
                          logo: {
                            validation: z.literal('$logopath'),
                            type: EntryType.Internal,
                          },
                          name: {
                            validation: z.literal(
                              JSON.stringify({
                                $mongoRequest: [
                                  {
                                    '$db.companiesconversion.findOne': {
                                      key: 'service',
                                      crefcode: '$service',
                                    },
                                    $result: {
                                      $getVariable: 'orefcode',
                                    },
                                  },
                                  'morphjs',
                                  ['companiesconversion'],
                                ],
                              })
                            ),
                            type: EntryType.Internal,
                          },
                          activeunit: {
                            values: {
                              [ReservedNodeType.DeclareVariable]: {
                                values: {
                                  epochFstMonth: {
                                    validation: z.literal(
                                      JSON.stringify({
                                        '$time.stamp': [
                                          [
                                            [
                                              '01-',
                                              {
                                                '$time.format': [
                                                  {
                                                    '$time.stamp': [],
                                                  },
                                                  'MM-YYYY',
                                                ],
                                              },
                                            ],
                                          ],
                                          'DD-MM-YYYY',
                                        ],
                                      })
                                    ),
                                    type: EntryType.Graph,
                                  },
                                  fstMonth: {
                                    validation: z.literal(
                                      JSON.stringify({
                                        '$time.format': [
                                          '$epochFstMonth',
                                          'YYYY-MM-DD',
                                        ],
                                      })
                                    ),
                                    type: EntryType.Graph,
                                  },
                                },
                              },
                              timestamp: {
                                validation: z.literal(
                                  JSON.stringify({ '$time.stamp': [] })
                                ),
                                type: EntryType.Internal,
                              },
                              vendorraw: {
                                validation: z.literal('$vendor'),
                                type: EntryType.Internal,
                              },
                              effectiveDateReadable: {
                                validation: z.literal(
                                  JSON.stringify([
                                    ['$fstMonth', 'T00:00:00.000Z'],
                                  ])
                                ),
                                type: EntryType.Internal,
                              },
                              effectiveDate: {
                                validation: z.literal('$epochFstMonth'),
                                type: EntryType.Internal,
                              },
                              unitCost: {
                                validation: z.literal('$cost'),
                                type: EntryType.Internal,
                              },
                              unitPrice: {
                                validation: z.literal('$price'),
                                type: EntryType.Internal,
                              },
                              unitCount: {
                                validation: z.literal('$quantity'),
                                type: EntryType.Internal,
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};
