import { SchemaVariant } from '@morph-mapper/types';
import { getEntries } from '@morph-mapper/utils';
import { useXmlView } from 'react-interactive-xml-viewer';
import { selectorConfig, operatorConfig } from '../../config';
import { useStore } from '../../context';
import { XmlExtractor, XmlOperator, XmlSelector } from '../../types';
import { P, match } from 'ts-pattern';

export const useXmlModal = () => {
  const id = useStore(({ path: p }) => p.getActiveReference());
  const [{ next }, setExtractorById, setOperationById] = useStore(
    ({ path: p, ui }) => [
      p.getReference(id),
      p.setExtractor,
      p.setOperation,
      ui.isConfigRefModalOpen(),
      ui.setConfigRefModal,
    ]
  );
  const { getNode } = useXmlView();

  const setExtractor = (value: XmlExtractor | null) => {
    if (value === null) return;

    setExtractorById(id, value);

    match(value)
      .with(XmlSelector.Array, () => setOperation(`0`))
      .with(XmlSelector.Base, () => setOperation(null))
      .with(XmlOperator.Array, () => setOperation('@array'))
      .with(XmlOperator.Value, () => setOperation('@'))
      .with(XmlOperator.Attribute, () => setOperation('_attrs'))
      .exhaustive();
  };

  const setOperation = (value: string | number | null) => {
    if (value === '') return;

    setOperationById(id, value);
  };

  const handleSetAttribute = (value: string | null) => {
    if (value === null) return;

    // abstract formatting
    setOperation(`_attrs.${value}`);
  };

  /**
   * Returns the available extractors for the current reference,
   * based on predefined rules.
   */
  const getExtractors = () => {
    const selectors = selectorConfig[SchemaVariant.Xml];
    const operators = operatorConfig[SchemaVariant.Xml];
    const extractors: {
      label: string;
      value: XmlSelector | XmlOperator;
      group: string;
    }[] = [];

    getEntries(selectors).forEach(([type, { displayName }]) => {
      extractors.push({
        value: type,
        label: displayName,
        group: 'Selectors',
      });
    });

    getEntries(operators).forEach(([type, { displayName }]) => {
      let isAvailable = true;

      //rules in separate hook per variant: checkOperatorRules(type), checkSelectorRules(type)
      match([type, isAvailable]).with([P._, true], () => {
        isAvailable = next === null;
      });

      match([type, isAvailable]).with([XmlOperator.Attribute, true], () => {
        isAvailable = getAttributes().length > 0;
      });

      if (!isAvailable) return;

      extractors.push({
        value: type,
        label: displayName,
        group: 'Operators',
      });
    });

    return extractors;
  };

  const getAttributes = () => {
    return getNode(id)
      .getAttributes()
      .map((attr) => ({
        label: attr.getKey(),
        value: attr.getKey(),
      }));
  };

  return {
    setExtractor,
    setOperation,
    handleSetAttribute,
    getExtractors,
    getAttributes,
  };
};
