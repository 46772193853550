import { CustomSchemes } from "@/types";
import api from "./api";

export const fetchDomainsbyOperatorId = async (id: string): Promise<string[]> => {
  const { data } = await api.get(`/operators/${id}`);
  return data;
};

export const fetchSchemesbyOperatorId = async (id: string): Promise<CustomSchemes> => {
  const { data } = await api.get(`/operators/schemes/${id}`);
  return data || {};
};
