import { RiCheckboxMultipleBlankLine } from 'react-icons/ri';
import { AiOutlineGroup } from 'react-icons/ai';
import { HiOutlineVariable } from 'react-icons/hi';
import { typeStructureBlockRecord } from '../../types';
import { MdDataArray, MdMoveUp } from 'react-icons/md';

const STRUCTURE_BLOCKS_INTERNAL = typeStructureBlockRecord({
  unwrap: {
    title: 'Unwrap',
    description: 'Moves children up while ignoring the mapping key.',
    category: 'internal',
    type: 'unwrap',
    icon: MdMoveUp,
    render: () => {
      return [[], {}];
    },
  },
});

const STRUCTURE_BLOCKS_CORE = typeStructureBlockRecord({
  group: {
    title: 'Group',
    description: 'Group the descendant values by the given key.',
    category: 'core',
    type: 'group',
    icon: AiOutlineGroup,
    render: (key: string) => {
      return [[key], { [key]: {} }];
    },
  },
  array: {
    title: 'Array Group',
    description: 'Group the descendant values by the given key into an array.',
    category: 'core',
    type: 'array',
    icon: MdDataArray,
    render: (key: string) => {
      return [[key, '0'], { [key]: [{}] }];
    },
  },
});

const STRUCTURE_BLOCKS_ITERATOR = typeStructureBlockRecord({
  forEach: {
    title: 'For Each',
    description:
      'Fills the descendant values for each item in the given array.',
    category: 'iterator',
    type: 'forEach',
    icon: RiCheckboxMultipleBlankLine,
    render: () => {
      return [['$Array.forEach'], { '$Array.forEach': {} }];
    },
  },
  data: {
    title: 'Data Object',
    description:
      'Use this block to create a data object, this data object can then be used by other iterator structure blocks.',
    category: 'iterator',
    type: 'data',
    icon: HiOutlineVariable,
    render: () => {
      return [['$data'], { $data: {} }];
    },
  },
});

export const STRUCTURE_BLOCKS = {
  ...STRUCTURE_BLOCKS_INTERNAL,
  ...STRUCTURE_BLOCKS_CORE,
  ...STRUCTURE_BLOCKS_ITERATOR,
};
