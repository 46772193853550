import { useMapEntryContext, useTreeContext } from "../../context";
import { useNavigate } from "react-router-dom";

export const useMapRow = () => {
  /* Context State */
  const moveIn = useTreeContext((s) => s.moveIn);
  const id = useMapEntryContext((s) => s.getId());
  /* Hooks */
  const navigate = useNavigate();

  /**
   * Handlers
   **/
  const handleConfigureEntry = () => {
    navigate(`${id}/context`);
  };

  const handleFocusChange = () => {
    moveIn(id);
  };

  return {
    handleConfigureEntry,
    handleFocusChange,
  };
};
