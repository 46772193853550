import styled from "@emotion/styled";
import { Box, BoxProps, createPolymorphicComponent } from "@mantine/core";

export const _StyledHeader = styled(Box)`
  display: flex;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  min-height: 60px;
  height: 60px;
`;

export const StyledHeader = createPolymorphicComponent<"div", BoxProps>(
  _StyledHeader
);
