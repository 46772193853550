import { Handle, Position } from 'reactflow';
import { getEntries } from '@morph-mapper/utils';
import { type SchemaVariant } from '@morph-mapper/types';
import {
  DIRECTION,
  LogicBlockIndex,
  useLogicBlocks,
} from '@morph-mapper/node-logic';

export const useBaseNode = (type: LogicBlockIndex, variant: SchemaVariant) => {
  const { logicBlocks } = useLogicBlocks(variant);

  const getTemplateHandles = () => {
    return getEntries(logicBlocks[type].handles).map(([direction, handles]) => {
      return handles.map((handle) => {
        return (
          <Handle
            id={handle.id}
            key={handle.id}
            type={direction as DIRECTION}
            position={
              direction === DIRECTION.SOURCE ? Position.Right : Position.Left
            }
          />
        );
      });
    });
  };

  return { getTemplateHandles };
};
