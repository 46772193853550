import { useContext, useLogicTree } from '@/hooks';
import { useEntryContext, useNodeContext } from '../../context';

export const useConfigRow = (optionName: string) => {
  const [entryId, setValue] = useEntryContext((s) => [
    s.getId(),
    s.setTemplateValue,
  ]);
  const { findVariables } = useContext(entryId);
  const [setOptionValue, setDependency, graphId, optionDefault, currentOptionValue] = useNodeContext((s) => [
    s.setOptionValue,
    s.setDependency,
    s.graphId,
    s.getOptionDefault(optionName),
    s.getOptionValue(optionName),
  ]);
  const { logicTreeToTemplate } = useLogicTree();

  // We set the updated value in the global store when the graph changes
  // so the dependencies are updated, required for correct rendering.
  // TODO: This is a workaround, we should find a better solution.
  const updateValueOnGraphChange = () => {
    const template = logicTreeToTemplate(graphId);
    setValue(template);
  };

  const handleChange = (value: any) => {
    setOptionValue(optionName, value);

    updateValueOnGraphChange();
  };

  const handleDependency = () => {
    setDependency(optionName);

    updateValueOnGraphChange();
  };

  const getAvailableVariables = () => {
    return findVariables();
  };

  const setToDefault = () => {
    if (currentOptionValue !== optionDefault) {
      setOptionValue(optionName, optionDefault);
      updateValueOnGraphChange();
    }
  };

  return {
    handleChange,
    handleDependency,
    availableVariables: getAvailableVariables(),
    setToDefault,
  };
};
