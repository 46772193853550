import styled from '@emotion/styled';
import { Box, BoxProps, createPolymorphicComponent } from '@mantine/core';

const _Content = styled(Box)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 0px;
  overflow: auto;
  align-items: center;
  justify-content: center;
`;

export const Content = createPolymorphicComponent<'div', BoxProps>(_Content);
