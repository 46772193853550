import styled from "@emotion/styled";

export const ContentWrapper = styled.div(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  backgroundColor: theme.colors.gray[2],
  paddingTop: "1rem",
  paddingBottom: "1rem",
  borderRadius: theme.radius.sm,
  ":hover": {
    cursor: "pointer",
    backgroundColor: theme.colors.gray[3],
  },
}));
