import { keepPreviousData, skipToken, useQuery } from '@tanstack/react-query';
import {
  fetchParsedPdf,
  fetchParsedXls,
  fetchResultText,
  fetchResultXls,
  fetchResultTextEntry,
  fetchResultXlsEntry,
  parseContentByTemplatePosition,
  ParsedPositionResult,
  fetchResultXml,
  fetchResultXmlEntry,
  fetchParsedXml,
  fetchParsedTxt,
  fetchParsedEml,
  fetchResultJson,
  fetchResultJsonEntry,
} from './crud';
import { useStore } from '@/store';
import { SchemaVariant } from '@morph-mapper/types';
import { useRenderTemplate } from '@/hooks';
import { findAllOccurrences } from '@morph-mapper/utils';

/**
 * Query wrapper for parsing a partially rendered template code with
 * the text content of the current loaded example file, based on a pdf-like structure.
 */
const useTextEntryResult = (
  template: any,
  content: string,
  select?: (data: any) => any
) => {
  return useQuery({
    queryKey: ['templating-entry-text-result', template, content],
    queryFn: template
      ? () => fetchResultTextEntry(template, content)
      : skipToken,
    select,
  });
};

/**
 * Query wrapper for parsing a partially rendered template code with
 * the text content of the current loaded example file, based on a xls-like structure.
 */
const useXlsEntryResult = (
  template: any,
  content: string,
  select?: (data: any) => any
) => {
  return useQuery({
    queryKey: ['templating-entry-xls-result', template, content],
    queryFn: template
      ? () => fetchResultXlsEntry(template, content)
      : skipToken,
    select,
  });
};

/**
 * Query wrapper for parsing a partially rendered template code with
 * the text content of the current loaded example file, based on a xml-like structure.
 */
const useXmlEntryResult = (
  template: any,
  content: string,
  select?: (data: any) => any
) => {
  return useQuery({
    queryKey: ['templating-entry-xml-result', template, content],
    queryFn: template
      ? () => fetchResultXmlEntry(template, content)
      : skipToken,
    select,
  });
};

/**
 * Query wrapper for parsing a partially rendered template code with
 * the text content of the current loaded example file, based on a json-like structure.
 */
const useJsonEntryResult = (
  template: any,
  content: string,
  select?: (data: any) => any
) => {
  return useQuery({
    queryKey: ['templating-entry-json-result', template, content],
    queryFn: template
      ? () => fetchResultJsonEntry(template, content)
      : skipToken,
    select,
  });
};

/**
 * Query wrapper for parsing the complete rendered template code with
 * the text content of the current loaded example file, based on a pdf-like structure.
 */
const useTextResult = (template: any, content: string) => {
  return useQuery({
    queryKey: ['templating-template-text-result', template, content],
    queryFn: template ? () => fetchResultText(template, content) : skipToken,
  });
};

/**
 * Query wrapper for parsing the complete rendered template code with
 * the text content of the current loaded example file, based on a xls-like structure.
 */
const useXlsResult = (template: any, content: string) => {
  return useQuery({
    queryKey: ['templating-template-xls-result', template, content],
    queryFn: template ? () => fetchResultXls(template, content) : skipToken,
  });
};

/**
 * Query wrapper for parsing the complete rendered template code with
 * the text content of the current loaded example file, based on a xml-like structure.
 */
const useXmlResult = (template: any, content: string) => {
  return useQuery({
    queryKey: ['templating-template-xml-result', template, content],
    queryFn: template ? () => fetchResultXml(template, content) : skipToken,
  });
};

/**
 * Query wrapper for parsing the complete rendered template code with
 * the text content of the current loaded example file, based on a json-like structure.
 */
const useJsonResult = (template: any, content: string) => {
  return useQuery({
    queryKey: ['templating-template-json-result', template, content],
    queryFn: template ? () => fetchResultJson(template, content) : skipToken,
  });
};

/**
 * Hook selector for parsing partially rendered template code with
 * the text content of the current loaded example file. Selection is based
 * on the current variant.
 */
export const useEntryParser = (entryId: string, nodeId?: string) => {
  const [variant, textContent] = useStore(({ config: c }) => [
    c.getVariant(),
    c.getTextContent(),
  ]);
  const { renderEntryWithContext } = useRenderTemplate();

  const parseData = (data: any) => {
    return findAllOccurrences(data, `__reserved__key__${entryId}`);
  };

  return {
    [SchemaVariant.Pdf]: useTextEntryResult,
    [SchemaVariant.Table]: useXlsEntryResult,
    [SchemaVariant.Xml]: useXmlEntryResult,
    [SchemaVariant.Text]: useTextEntryResult,
    [SchemaVariant.Email]: useTextEntryResult,
    [SchemaVariant.Json]: useJsonEntryResult,
  }[variant](renderEntryWithContext(entryId, nodeId), textContent, parseData);
};

export const useDirtyEntry = (entryIds: string[]) => {
  const [variant, textContent] = useStore(({ config: c}) => [
    c.getVariant(),
    c.getTextContent(),
  ]);

  const { renderEntryWithContext } = useRenderTemplate();

  const obj = entryIds.reduce((acc, entryId) => {
    acc[entryId] = renderEntryWithContext(entryId);
    return acc;
  }, {} as Record<string, any>);

  const parseData = (data: Record<string, { template: any }>[]) => {
    return entryIds.reduce((acc, entryId) => {
      return {
        ...acc,
        [entryId]: findAllOccurrences(
          data,
          // TODO: Typed results so we can distinguish between different targets.
          // Probably requires rerouting through the current express API.
          //data[0][entryId],
          `__reserved__key__${entryId}`
        ),
      };
    }, {} as Record<string, any>);
  };

  const shouldQuery = entryIds.length > 0;

  return {
    [SchemaVariant.Pdf]: useTextEntryResult,
    [SchemaVariant.Table]: useXlsEntryResult,
    [SchemaVariant.Xml]: useXmlEntryResult,
    [SchemaVariant.Text]: useTextEntryResult,
    [SchemaVariant.Email]: useTextEntryResult,
    [SchemaVariant.Json]: useTextEntryResult,
  }[variant](shouldQuery ? obj : undefined, textContent, parseData);
};

/**
 * Hook selector for parsing the complete rendered template code with
 * the text content of the current loaded example file. Selection is based
 * on the current variant.
 */
export const useTemplateParser = (template: any) => {
  const [variant, textContent] = useStore(({ config: c }) => [
    c.getVariant(),
    c.getTextContent(),
  ]);

  return {
    [SchemaVariant.Pdf]: useTextResult,
    [SchemaVariant.Table]: useXlsResult,
    [SchemaVariant.Xml]: useXmlResult,
    [SchemaVariant.Text]: useTextResult,
    [SchemaVariant.Email]: useTextResult,
    [SchemaVariant.Json]: useJsonResult,
  }[variant](template, textContent);
};

/**
 * Query wrapper for parsing pdf-like files to text.
 */
const usePdfToText = (file: File | undefined) => {
  return useQuery({
    queryKey: ['pdf-to-text', file],
    queryFn: file ? () => fetchParsedPdf(file) : skipToken,
    placeholderData: keepPreviousData,
  });
};

/**
 * Query wrapper for parsing xls-like files to text.
 */
const useXlsToText = (file: File | undefined) => {
  return useQuery({
    queryKey: ['xls-to-text', file],
    queryFn: file ? () => fetchParsedXls(file) : skipToken,
    placeholderData: keepPreviousData,
  });
};

/**
 * Query wrapper for parsing xml-like files to text.
 */
const useXmlToText = (file: File | undefined) => {
  return useQuery({
    queryKey: ['xml-to-text', file],
    queryFn: file ? () => fetchParsedXml(file) : skipToken,
    placeholderData: keepPreviousData,
  });
};

const useTxtToText = (file: File | undefined) => {
  return useQuery({
    queryKey: ['txt-to-text', file],
    queryFn: file ? () => fetchParsedTxt(file) : skipToken,
    placeholderData: keepPreviousData,
  });
};

const useEmlToText = (file: File | undefined) => {
  return useQuery({
    queryKey: ['eml-to-text', file],
    queryFn: file ? () => fetchParsedEml(file) : skipToken,
    placeholderData: keepPreviousData,
    select: (data) => data.text,
  });
};

/**
 * Hook selector for parsing the text content of the current loaded example file.
 * Selection is based on the current variant.
 */
export const useFileToTextParser = (file: File | undefined) => {
  const variant = useStore(({ config: c }) => c.getVariant());

  return {
    [SchemaVariant.Pdf]: usePdfToText,
    [SchemaVariant.Table]: useXlsToText,
    [SchemaVariant.Xml]: useXmlToText,
    [SchemaVariant.Text]: useTxtToText,
    [SchemaVariant.Email]: useEmlToText,
    [SchemaVariant.Json]: useTxtToText,
  }[variant](file);
};

export const usePdfPositionData = (
  templates: [string, any][],
  content: string
) => {
  return useQuery<[string, ParsedPositionResult][]>({
    queryKey: ['template-parser-pdf-position', templates, content],
    queryFn: () =>
      Promise.all(
        templates.map(async ([nodeId, template]) => [
          nodeId,
          await parseContentByTemplatePosition(template, content),
        ])
      ),
    placeholderData: keepPreviousData,
  });
};

// export const usePositionData = (
//   templates: [string, any][],
//   entryId: string
// ) => {
//   const [variant, textContent] = useStore(({ config: c }) => [
//     c.getVariant(),
//     c.getTextContent(),
//   ]);

//   const { wrapVariable, getPosition } = useContextVariables(entryId);

//   switch (variant) {
//     case SchemaVariant.Default:
//       return usePdfPositionData(
//         templates.map(([nodeId, template]) => [
//           nodeId,
//           wrapVariable(getPosition({ entry: template })),
//         ]),
//         textContent
//       );
//     case SchemaVariant.Table:
//     default:
//       throw new Error('[Templating:useFileToTextParser] No parser available');
//   }
// };
