import { BaseFooter } from "@morph-mapper/ui";
import { Text, TextInput } from "@mantine/core";
import { MdOutlineOutput } from "react-icons/md";
import { useFooter } from "./use-footer";

export const Footer = () => {
  const { output } = useFooter();

  return (
    <BaseFooter>
      <Text>Node Output</Text>
      <TextInput
        disabled
        value={output}
        icon={<MdOutlineOutput />}
        style={{
          flex: 1,
          marginLeft: "1.5rem",
        }}
        styles={{
          input: {
            ":disabled": {
              fontWeight: 500,
              color: "black",
            },
          },
        }}
      />
    </BaseFooter>
  );
};
