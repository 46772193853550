import { Badge, BaseHeader, Move } from '@morph-mapper/ui';
import { StyledGroup, StyledNavigation } from './styles';
import { useEntryContext } from '../../context';
import { useNavigate } from 'react-router-dom';

export const Header = () => {
  const name = useEntryContext((s) => s.getName());
  const navigate = useNavigate();

  // This handler is responsible for exiting the graph view, as
  // the graph view can originate from multiple different contexts we
  // cannot route to an absolute path.
  const handleFlowExit = () => {
    navigate(-1);
  };

  return (
    <BaseHeader>
      <StyledGroup>
        <StyledNavigation>
          <Move into={false} onClick={handleFlowExit} />
        </StyledNavigation>
        <Badge>{name}</Badge>
      </StyledGroup>
    </BaseHeader>
  );
};
