import { Button, Modal, NumberInput, Select, TextInput } from '@mantine/core';
import { useStore } from '../../context';
import { JsonSelector } from '../../types';
import { match } from 'ts-pattern';
import { Body, FormLayout, Footer } from './styles';
import { ArraySelectorDisplay } from '../../atoms';
import { useJsonModal } from './use-json-modal';

export const JsonModal = () => {
  const id = useStore(({ path: p }) => p.getActiveReference());
  const [{ type, value: refValue, operation }, isModalOpen, setModal] =
    useStore(({ path: p, ui }) => [
      p.getReference(id),
      ui.isConfigRefModalOpen(),
      ui.setConfigRefModal,
    ]);
  const { setExtractor, setOperation, getExtractors } = useJsonModal();

  if (refValue === null) return null;

  return (
    <Modal
      opened={isModalOpen}
      onClose={() => setModal(false)}
      title={'Edit reference'}
      centered
    >
      <Body>
        <FormLayout>
          {match(type)
            .with(JsonSelector.Array, () => (
              <ArraySelectorDisplay
                label="Current reference"
                value={refValue}
                count={operation || ''}
              />
            ))
            .otherwise(() => {
              return (
                <TextInput
                  label="Current reference"
                  value={refValue}
                  disabled
                />
              );
            })}

          <Select
            label="Select type of reference"
            value={type}
            onChange={setExtractor}
            data={getExtractors()}
            withinPortal
          />

          {match(type)
            .with(JsonSelector.Base, () => null)
            .with(JsonSelector.Array, () => {
              let value = operation;

              if (value === null) {
                setOperation(0);
                return null;
              }

              if (typeof value === 'string') {
                value = parseInt(value);
              }

              return (
                <NumberInput
                  label="Select array index"
                  defaultValue={0}
                  value={value}
                  onChange={setOperation}
                />
              );
            })
            .otherwise(() => null)}
        </FormLayout>
        <Footer>
          <Button onClick={() => setModal(false)}>Close</Button>
        </Footer>
      </Body>
    </Modal>
  );
};
