import { Input, Modal, Select } from '@mantine/core';
import { useStore } from '../../context';
import { useXmlView } from 'react-interactive-xml-viewer';
import { PathMode, XmlSelector } from '../../types';
import { Body, FormLayout } from './styles';
import { assertDefined } from '@morph-mapper/utils';

export const XmlNodeCreate = () => {
  const [
    pathIds,
    createReference,
    setActiveReference,
    isModalOpen,
    setCreateModal,
    setConfigRefModal,
  ] = useStore(({ file: f, path: p, ui }) => [
    p.getReferenceIds(PathMode.Absolute),
    p.createReference,
    p.setActiveReference,
    ui.isCreateRefModalOpen(),
    ui.setCreateRefModal,
    ui.setConfigRefModal,
  ]);
  const { getNode } = useXmlView();

  const getAvailableReferences = () => {
    const refId = pathIds[pathIds.length - 1];
    assertDefined(refId);

    return getNode(refId)
      .getChildren()
      .map((node) => ({
        value: node.getId(),
        label: node.getName(),
      }));
  };

  const handleCreateReference = (id: string) => {
    const node = getNode(id);

    const tagRef = {
      type: XmlSelector.Base,
      value: node.getName(),
      operation: null,
    };

    createReference(tagRef, id);
    setActiveReference(id);

    setCreateModal(false);
    setConfigRefModal(true);
  };

  return (
    <Modal
      opened={isModalOpen}
      onClose={() => setCreateModal(false)}
      title="Add reference to path"
      centered
    >
      <Body>
        <FormLayout>
          <Input.Wrapper label="Reference">
            <Select
              data={getAvailableReferences()}
              onChange={handleCreateReference}
              withinPortal
            />
          </Input.Wrapper>
        </FormLayout>
      </Body>
    </Modal>
  );
};
