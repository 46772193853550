import styled from "@emotion/styled";
import { FileInput } from "@mantine/core";

export const Container = styled.div({
  display: "flex",
  height: "100%",
  flexDirection: "column",
  maxWidth: 700,
  gap: "2rem",
});

export const ContentWrapper = styled.div({
  display: "flex",
  height: "100%",
  flexDirection: "column",
  gap: "1rem",
});

export const InputWrapper = styled.div({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  width: "100%",
});

export const StyledFileInput = styled(FileInput)(({ theme }) => ({
  width: "100%",
}));
