import { type SchemaVariant } from '@morph-mapper/types';

/**
 * Operator definitions for different schema variants.
 *
 * The operator determines how the value of a node is extracted from the file,
 * operators make use of the file structure.
 *
 * The implementation of the operator can be found in the config/operators.ts file.
 */
export enum XmlOperator {
  Value = 'operator_value',
  Attribute = 'operator_attribute',
  Array = 'operator_array',
}

export enum TableOperator {}

export enum TextOperator {}

export type Operator = XmlOperator | TableOperator | TextOperator;

/**
 *
 * Configuration types for operators.
 *
 */

export type OperatorConfig = {
  displayName: string;
  value: string;
};

export type OperatorMap = {
  [SchemaVariant.Pdf]: TextOperator;
  [SchemaVariant.Table]: TableOperator;
  [SchemaVariant.Xml]: XmlOperator;
  [SchemaVariant.Text]: TextOperator;
  [SchemaVariant.Email]: TextOperator;
  [SchemaVariant.Json]: TextOperator;
};

export type OperatorConfigRecord = {
  [K in SchemaVariant]: Record<OperatorMap[K], OperatorConfig>;
};
