import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';
import { Direction } from '../../types';
import { StyledButton } from './styles';

interface ShiftButtonProps {
  direction: Direction;
  collision: boolean;
  handleShift: (direction: Direction) => void;
}

export const ShiftButton = ({
  direction,
  collision,
  handleShift,
}: ShiftButtonProps) => {
  return (
    <StyledButton
      collision={collision}
      onClick={() => handleShift(Direction.Left)}
    >
      {direction === Direction.Left ? (
        <RiArrowLeftSLine />
      ) : (
        <RiArrowRightSLine />
      )}
    </StyledButton>
  );
};
