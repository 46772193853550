import { Accordion, Text } from '@mantine/core';
import {
  SideBarContent,
  SideBarWrapper,
  StyledAccordion,
  StyledList,
} from './styles';
import { BlockItem, Header } from '../../molecules';
import { useStore } from '@/store';
import { useStructureBlocks } from '@morph-mapper/node-logic';
import { getEntries } from '@morph-mapper/utils';
// TODO: put import behind interface
import { STRUCTURE_CATEGORIES } from '@morph-mapper/node-logic';

export const SelectorView = () => {
  const variant = useStore(({ config: c }) => c.getVariant());
  const { structureBlocksByCategory } = useStructureBlocks(variant);

  return (
    <SideBarWrapper>
      <Header />
      <SideBarContent>
        <Text c="dimmed">
          Components are logic blocks which can be chained, split and combined.
          Use a combination of these blocks to extract the required values from
          your file.
        </Text>
        <StyledAccordion variant="separated" defaultValue="core">
          {getEntries(STRUCTURE_CATEGORIES).map(([category, title]) => (
            <Accordion.Item value={category}>
              <Accordion.Control>{title}</Accordion.Control>
              <Accordion.Panel>
                <StyledList>
                  {/* TODO: convert to utils function: getValues */}
                  {Object.values(structureBlocksByCategory[category]).map(
                    ({ type }) => (
                      <BlockItem type={type} key={type} />
                    )
                  )}
                </StyledList>
              </Accordion.Panel>
            </Accordion.Item>
          ))}
        </StyledAccordion>
      </SideBarContent>
    </SideBarWrapper>
  );
};
