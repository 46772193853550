import { Menu, Portal } from '@mantine/core';
import { Options } from '@morph-mapper/ui';
import { FiTrash2 } from 'react-icons/fi';
import { HiOutlineDuplicate } from 'react-icons/hi';
import { useDeleteTemplateRef } from '@/services';
import { useSetupStore } from '../../store';
import { TbSettings } from 'react-icons/tb';

interface TemplateItemMenuProps {
  id: string;
}

export const TemplateItemMenu = ({ id }: TemplateItemMenuProps) => {
  const [setOpenEdit, setOpenDuplicate, setModalId] = useSetupStore((s) => [
    s.setIsEditModalOpen,
    s.setIsDuplicateModalOpen,
    s.setModalId,
  ]);

  const { mutate: deleteTemplate } = useDeleteTemplateRef();

  const handleEdit = () => {
    setModalId(id);
    setOpenEdit(true);
  };

  const handleDuplicate = () => {
    setModalId(id);
    setOpenDuplicate(true);
  };

  const handleDelete = () => {
    deleteTemplate(id);
  };

  return (
    <>
      <Menu
        trigger="hover"
        openDelay={200}
        closeDelay={400}
        position="right-start"
      >
        <Menu.Target>
          <Options variant="default" />
        </Menu.Target>
        <Portal>
          <Menu.Dropdown>
            <Menu.Item icon={<TbSettings />} onClick={handleEdit}>
              Configure
            </Menu.Item>
            <Menu.Item icon={<HiOutlineDuplicate />} onClick={handleDuplicate}>
              Duplicate
            </Menu.Item>
            <Menu.Item icon={<FiTrash2 />} color={'red'} onClick={handleDelete}>
              Delete Template
            </Menu.Item>
          </Menu.Dropdown>
        </Portal>
      </Menu>
    </>
  );
};
