import { Center, Checkbox, Text, Title } from '@mantine/core';
import { useDomainItem } from './use-domain-item';
import { StyledListItem, TextWrapper } from './styles';
import { useSchema } from '@morph-mapper/schemas';
import { useStore } from '@/store';

interface DomainItemProps {
  domain: string;
}

export const DomainItem = ({ domain }: DomainItemProps) => {
  const [definedCustomSchemes] = useStore(({ config: c }) => [c.definedCustomSchemes]);
  
  const { isSelected, handleSelect } = useDomainItem(domain);
  const { getSchemaDomainConfig } = useSchema(domain, definedCustomSchemes);

  const { displayName, description } = getSchemaDomainConfig();

  return (
    <StyledListItem onClick={handleSelect}>
      <Center>
        <Checkbox checked={isSelected} readOnly />
      </Center>

      <TextWrapper>
        <Title order={4} fw={500}>
          {displayName}
        </Title>
        <Text>{description}</Text>
      </TextWrapper>
    </StyledListItem>
  );
};
