import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { createUISlice, UISlice } from './ui';
import { ConfigSlice, createConfigSlice } from './config';
import {
  createEntrySlice,
  EntrySlice,
  ICellEntry,
  IEntry,
  IGraphEntry,
  IInternalEntry,
  IMapEntry,
  IMapChildren,
  ISimpleEntry,
  IBooleanEntry,
} from './entries';
import { createGraphsSlice, GraphsSlice, IGraph, INode } from './graphs';

/**
 * Helper interfaces for the global application store.
 */
export type {
  IEntry,
  IBooleanEntry,
  ISimpleEntry,
  ICellEntry,
  IGraphEntry,
  IInternalEntry,
  IMapEntry,
  IMapChildren,
  IGraph,
  INode,
};
/**
 * The global application store interface.
 */
export type StoreSlice = { ui: UISlice } & { config: ConfigSlice } & {
  entries: EntrySlice;
} & {
  graphs: GraphsSlice;
};

/**
 * Initialisation of the global application store,
 * which combines multiple slices separated by topic.
 * Slices are merged using namespaces.
 *
 * @returns {StoreSlice} The global application store
 */
export const useStore = create<StoreSlice>()(
  immer((...a) => ({
    ui: createUISlice(...a),
    config: createConfigSlice(...a),
    graphs: createGraphsSlice(...a),
    entries: createEntrySlice(...a),
  }))
);
