import { LuClipboardPaste } from "react-icons/lu";
import { Action, ActionProps } from "../action";
import { forwardRef } from "react";

export const Clipboard = forwardRef<HTMLButtonElement, ActionProps>(
  (props: ActionProps, ref) => {
    return (
      <Action {...props} ref={ref} Icon={LuClipboardPaste} variant="default" />
    );
  }
);
