import { useFileInteraction } from '@morph-mapper/file-viewer';
import _ from 'lodash';
import { useEffect } from 'react';
import { useContext } from '@/hooks';

interface UseRelativePathProps {
  entryId: string;
  graphId?: string;
  nodeId?: string;
}

export const useRelativePath = ({ entryId }: UseRelativePathProps) => {
  const { setRelativePaths } = useFileInteraction();
  const { findRelativePaths } = useContext(entryId);

  useEffect(() => {
    const paths = findRelativePaths();
    setRelativePaths(paths);

    return () => {
      setRelativePaths([]);
    };
  }, []);
};
