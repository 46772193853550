import styled from "@emotion/styled";
import { Table as MantineTable } from "@mantine/core";

export const Panel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
`;

export const TableHeader = styled.thead(({ theme }) => ({
  background: "white",
  position: "sticky",
  top: 0,
  zIndex: 10,

  "::after": {
    borderTop: `1px solid ${theme.colors.gray[3]}`,
    borderBottom: `1px solid ${theme.colors.gray[3]}`,
    content: "''",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
}));

export const TableFooter = styled.tfoot(({ theme }) => ({
  position: "sticky",
  bottom: 0,

  "::after": {
    borderBottom: `1px solid ${theme.colors.gray[3]}`,
    content: "''",
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
}));

export const Table = styled(MantineTable)(({ theme }) => ({
  borderLeft: `1px solid ${theme.colors.gray[3]}`,
  borderRight: `1px solid ${theme.colors.gray[3]}`,
  backgroundColor: theme.colors.gray[0],
}));
