import styled from "@emotion/styled";

export const ListItem = styled.div(({ theme }) => ({
  display: "flex",
  alignContent: "center",
  padding: "1rem",
  borderBottom: `1px solid ${theme.colors.gray[3]}`,
  gap: "1rem",
  ":hover": {
    backgroundColor: theme.colors.gray[0],
    cursor: "pointer",
  },
}));

export const TextWrapper = styled.div({
  display: "flex",
  flexDirection: "column",
});

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing.sm,
  backgroundColor: 'white', 
  border: `1px solid ${theme.colors.gray[2]}`,
  borderRadius: theme.radius.sm, 
  cursor: 'pointer', 
  marginBottom: theme.spacing.xs, 
  ':hover': {
    borderColor: theme.colors.gray[3], 
  },
}));