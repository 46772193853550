import styled from "@emotion/styled";

export const Badge = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.gray[4]};
  width: fit-content;
  padding: 0.1em 0.5em;
  border-radius: 0.25em;
  background-color: ${({ theme }) => theme.colors.gray[0]};
`;

export const ListItem = styled.div(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
}));

export const TextWrapper = styled.div(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  padding: "0.3rem 0.3rem",
  borderRadius: theme.radius.sm,
  border: `1px solid ${theme.colors.gray[4]}`,
  background: theme.colors.gray[2],
  gap: "0.5rem",
}));
