import { useContext } from 'react';
import { useStore } from 'zustand';
import {
  BooleanEntryState,
  CellEntryState,
  GraphEntryState,
  SimpleEntryState,
} from '../store';
import { BooleanEntryContext } from './boolean-entry/context';
import { SimpleEntryContext } from './simple-entry/context';
import { CellEntryContext } from './cell-entry/context';
import { GraphEntryContext } from './graph-entry/context';
import { IBooleanEntry, ICellEntry, IGraphEntry, ISimpleEntry } from '@/store';

export const useItemEntryContext = <T>(
  selector: (
    state:
      | (BooleanEntryState & IBooleanEntry)
      | (SimpleEntryState & ISimpleEntry)
      | (CellEntryState & ICellEntry)
      | (GraphEntryState & IGraphEntry)
  ) => T,
  equalityFn?: (left: T, right: T) => boolean
): T => {
  const booleanStore = useContext(BooleanEntryContext);
  const simpleStore = useContext(SimpleEntryContext);
  const cellStore = useContext(CellEntryContext);
  const graphStore = useContext(GraphEntryContext);
  const store = booleanStore || simpleStore || cellStore || graphStore;

  if (!store)
    throw new Error(
      'Missing correct  <Item>EntryContext.Provider in the entry'
    );
  return useStore(store, selector, equalityFn);
};

export const useInlineEntryContext = <T>(
  selector: (
    state:
      | (BooleanEntryState & IBooleanEntry)
      | (SimpleEntryState & ISimpleEntry)
      | (CellEntryState & ICellEntry)
  ) => T,
  equalityFn?: (left: T, right: T) => boolean
): T => {
  const booleanStore = useContext(BooleanEntryContext);
  const simpleStore = useContext(SimpleEntryContext);
  const cellStore = useContext(CellEntryContext);
  const store = booleanStore || simpleStore || cellStore;

  if (!store)
    throw new Error(
      'Missing correct <Inline-Item>EntryContext.Provider in the entry'
    );
  return useStore(store, selector, equalityFn);
};
