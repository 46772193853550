import { createStore } from 'zustand';
import { TreeItem, isTreeItem } from '@/types';
import { immer } from 'zustand/middleware/immer';
import { z } from 'zod';
import { StoreSlice, IMapEntry } from '@/store';
import { EntryType } from '@morph-mapper/types';
import { ReservedNodeType } from '@morph-mapper/types';

export interface EntryProps {
  id: string;
}

export type InitEntryProps = EntryProps;

export interface EntryState extends EntryProps {
  getId: () => string;
  getName: () => string | undefined;
  getIteratorId: () => string | undefined;
  hasIterator: () => boolean;
  getVariableIds: () => string[];
  getVariable: (id: string) => TreeItem;
  createVariable: (name: string) => void;
  deleteVariable: (id: string) => void;
}

export type EntryStore = ReturnType<typeof createEntryStore>;

export const createEntryStore = (
  global: StoreSlice,
  initProps: InitEntryProps
) => {
  return createStore<EntryState & StoreSlice & IMapEntry>()(
    immer((set, get) => {
      return {
        ...global,
        // TODO: we probably want to differentiate between a map and item,
        // as we can now call functions which crash the application
        ...global.entries.entry(initProps.id, EntryType.Map),
        ...initProps,

        getIteratorId: () => {
          return get().children().getId(ReservedNodeType.Iterator);
        },
        hasIterator: () => {
          return !!get().children().getId(ReservedNodeType.Iterator);
        },
        getVariableIds: () => {
          const declareEntryId = get()
            .children()
            .getId(ReservedNodeType.DeclareVariable);
          if (!declareEntryId) return [];

          return get()
            .entries.children(declareEntryId!)
            .getIds()
            .filter((id) => get().entries.getType(id) !== EntryType.Internal);
        },
        createVariable: (name) => {
          let declareEntryId = get()
            .children()
            .getId(ReservedNodeType.DeclareVariable);

          if (!declareEntryId) {
            declareEntryId = get().entries.createMap({
              key: ReservedNodeType.DeclareVariable,
              name: ReservedNodeType.DeclareVariable,
              parentId: get().id,
              outputType: 'group',
            });
            get().linkChild(declareEntryId);
          }

          const id = get().entries.createItem(
            {
              key: name,
              name,
              parentId: declareEntryId,
              validation: z.any(),
            },
            EntryType.Simple
          );

          get().entries.children(declareEntryId).set(id, name);
        },
        getVariable: (id) => {
          const variableEntry = get().entries.getById(id);
          if (!isTreeItem(variableEntry)) throw 'Incorrect type';

          return variableEntry;
        },
        deleteVariable: (id) => {
          const declareEntryId = get()
            .children()
            .getId(ReservedNodeType.DeclareVariable);
          if (!declareEntryId) throw new Error('Cannot delete variable');

          get().entries.delete(id);
        },
      };
    })
  );
};
