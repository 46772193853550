import {
  PrefillContext,
  Schema,
  SchemaEntry,
  SchemaMap,
  isSchemaEntry,
  isSchemaMap,
} from '@morph-mapper/schemas';
import { generateUniqueId, getKeys } from '@morph-mapper/utils';
import { PreConfiguration, TreeNode, isTreeMap } from '@/types';
import {
  createUnregisteredItem,
  createUnregisteredMap,
  createDependencyScanner,
  ScanMode,
} from '@/utils';
import _ from 'lodash';
import Queue from 'queue-fifo';
import { P, match } from 'ts-pattern';
import { ROOT_KEY } from '@/config';

export const buildEntries = ({ definition }: Schema, ctx: PrefillContext) => {
  const entries: Record<string, TreeNode> = {};
  const preconfiguration: PreConfiguration = {};

  const queue = new Queue<{ pointer: string[]; parentId: string }>();

  const setPreconfiguration = (key: string, id: string) => {
    preconfiguration[id] = { key, options: {} };
  };

  const deriveObjectReference = (pointer: string[]) => {
    const reference: string[] = [];

    pointer.forEach((key) => {
      reference.push(key, 'values');
    });

    reference.pop();

    return reference;
  };

  const keyExists = (id: string) => {
    return id in entries;
  };

  const initializeRoot = () => {
    entries[ROOT_KEY] = {
      id: ROOT_KEY,
      ...createUnregisteredMap({
        key: ROOT_KEY,
        name: ROOT_KEY,
        parentId: undefined,
        outputType: 'unwrap',
      }),
    };
  };

  const enqueueInitialKeys = () => {
    getKeys(definition.values).forEach((key) => {
      queue.enqueue({ pointer: [key.toString()], parentId: ROOT_KEY });
    });
  };

  const linkEntries = (parentId: string, childId: string) => {
    const parent = entries[parentId];
    const child = entries[childId];

    if (!parent || !child) {
      throw new Error('Invalid parent or child id');
    }
    if (!isTreeMap(parent)) {
      throw new Error('Invalid parent type');
    }

    parent.children.forwardMap[childId] = child.key;
    parent.children.reverseMap[child.key] = childId;
  };

  const processSchemaMap = (
    entry: SchemaMap,
    { pointer, parentId }: { pointer: string[]; parentId: string }
  ) => {
    const { displayName, outputType, configKey } = entry;
    const key = pointer.at(-1);
    const id = generateUniqueId(keyExists);

    if (key === undefined) {
      throw new Error('Key is undefined');
    }

    if (configKey) {
      setPreconfiguration(configKey, id);
    }

    const name = displayName ?? key;
    const output = outputType ?? 'group';
    entries[id] = {
      id,
      ...createUnregisteredMap({ key, name, outputType: output, parentId }),
    };

    getKeys(entry.values).forEach((key) => {
      queue.enqueue({
        pointer: [...pointer, key.toString()],
        parentId: id,
      });
    });

    linkEntries(parentId, id);
  };

  const processSchemaEntry = (
    entry: SchemaEntry,
    { pointer, parentId }: { pointer: string[]; parentId: string }
  ) => {
    const { type, prefill, displayName, validation, configKey, allowedTypes } =
      entry;
    const key = pointer.at(-1);
    const value = prefill?.(ctx);
    const id = generateUniqueId(keyExists);

    if (key === undefined) {
      throw new Error('Key is undefined');
    }

    if (configKey) {
      setPreconfiguration(configKey, id);
    }

    entries[id] = {
      id,
      ...createUnregisteredItem(type, {
        key,
        value,
        name: displayName ?? key,
        parentId,
        validation,
        allowedTypes,
      }),
    };

    linkEntries(parentId, id);
  };

  const processQueue = () => {
    while (!queue.isEmpty()) {
      const queueItem = queue.dequeue()!;
      const { pointer } = queueItem;
      const entry = _.get(definition.values, deriveObjectReference(pointer));

      match(entry)
        .with(P.when(isSchemaMap), (entry) =>
          processSchemaMap(entry, queueItem)
        )
        .with(P.when(isSchemaEntry), (entry) =>
          processSchemaEntry(entry, queueItem)
        )
        .otherwise(() => {
          throw 'Encountered invalid schema value type.';
        });
    }
  };

  initializeRoot();
  enqueueInitialKeys();
  processQueue();

  return { entries, preconfiguration };
};

export const buildAppState = (schema: Schema, ctx: PrefillContext) => {
  const state = buildEntries(schema, ctx);

  const entries = state.entries;
  const preconfiguration = state.preconfiguration;

  const { scanDependencies } = createDependencyScanner(entries);
  const dependencies = scanDependencies(ScanMode.All);

  dependencies.forEach((dependencies, id) => {
    entries[id].dependencies = dependencies;
  });

  return { entries, preconfiguration };
};

export const useSchemaBuilder = () => {
  return {
    buildAppState,
  };
};
