import { useContext } from 'react';
import { useStore } from 'zustand';
import { SimpleEntryState } from '../../store';
import { SimpleEntryContext } from './context';
import { ISimpleEntry } from '@/store';

export const useSimpleEntryContext = <T>(
  selector: (state: SimpleEntryState & ISimpleEntry) => T,
  equalityFn?: (left: T, right: T) => boolean
): T => {
  const store = useContext(SimpleEntryContext);
  if (!store) throw new Error('Missing EntryContext.Provider in the entry');
  return useStore(store, selector, equalityFn);
};
