import { createLens, Setter, Getter } from '@dhmk/zustand-lens';
import type { Draft } from 'immer';
import { TreeMap } from '@/types';
import { EntrySlice } from '.';
import { EntryType } from '@morph-mapper/types';

export interface IMapChildren {
  set: (childId: string, value: string) => void;
  getName: (id: string) => string | undefined;
  getId: (name: string) => string | undefined;
  deleteById: (id: string) => void;
  deleteByName: (name: string) => void;
  getIds: () => string[];
  getNames: () => string[];
  getEntries: () => [string, string][];
}

export const createMapChildrenAPI = (
  _set: Setter<Draft<EntrySlice>>,
  _get: Getter<Draft<EntrySlice>>,
  id: string
) => {
  if (_get().getType(id) !== EntryType.Map)
    throw new Error('[IMapChildren]: Invalid entry type');

  const [set, get] = createLens(_set, _get, ['entries', id, 'children']) as [
    Setter<Draft<TreeMap['children']>>,
    Getter<Draft<TreeMap['children']>>
  ];

  return {
    set: (childId: string, value: string) => {
      set((s) => {
        s.forwardMap[childId] = value;
        s.reverseMap[value] = childId;
      });
    },
    getName: (id: string) => {
      return get().forwardMap[id];
    },
    getId: (name: string) => {
      return get().reverseMap[name];
    },
    deleteById: (id: string) => {
      const name = get().forwardMap[id];

      if (name === undefined) {
        throw new Error(`[IMapChildren]: Child with id ${id} does not exist`);
      }

      set((s) => {
        delete s.forwardMap[id];
        delete s.reverseMap[name];
      });
    },
    deleteByName: (name: string) => {
      const id = get().reverseMap[name];

      if (id === undefined) {
        throw new Error(
          `[IMapChildren]: Child with name ${name} does not exist`
        );
      }

      set((s) => {
        delete s.forwardMap[id];
        delete s.reverseMap[name];
      });
    },
    getIds: () => {
      return Object.keys(get().forwardMap);
    },
    getNames: () => {
      return Object.keys(get().reverseMap);
    },
    getEntries: () => {
      return Object.entries(get().forwardMap);
    },
  };
};
