import { useLayoutEffect, useRef } from 'react';
import { useListOverflow } from '../../hooks';
import { Direction } from '../../types/ui';

/**
 * Handles scrolling and interactive UI elements for the path references.
 */
export const useDynamicUI = (referenceIds: string[]) => {
  const parentRef = useRef<HTMLDivElement>(null);
  const firstChildRef = useRef<HTMLButtonElement>(null);
  const lastChildRef = useRef<HTMLButtonElement>(null);
  const { collidedLeft, collidedRight, checkCollisions } = useListOverflow({
    parentRef,
    firstChildRef,
    lastChildRef,
  });

  useLayoutEffect(() => {
    checkCollisions();
  }, [referenceIds]);

  /**
   * Shifts either to the start or end of the path, depending on the direction.
   */
  const handleShift = (direction: Direction) => {
    const parent = parentRef.current;

    if (parent === null) {
      return;
    }

    if (direction === Direction.Left) {
      parent.scrollTo({ left: 0, behavior: 'smooth' });
    } else {
      parent.scrollTo({ left: parent.scrollWidth, behavior: 'smooth' });
    }

    parent.addEventListener('scrollend', checkCollisions, { once: true });
  };

  /**
   * Handles the scrolling event when the user scrolls on or around the references.
   */
  const handleScroll = (e: React.WheelEvent<HTMLDivElement>) => {
    if (e.deltaY > 0) {
      e.currentTarget.scrollLeft += 30;
    } else {
      e.currentTarget.scrollLeft -= 30;
    }

    checkCollisions();
  };

  return {
    parentRef,
    firstChildRef,
    lastChildRef,
    collidedLeft,
    collidedRight,
    handleShift,
    handleScroll,
  };
};
