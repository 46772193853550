import { useMantineTheme } from "@mantine/core";
import { FiX } from "react-icons/fi";
import { Action, ActionProps } from "../action";

export const Cancel = (props: ActionProps) => {
  const { colors } = useMantineTheme();

  return (
    <Action
      Icon={FiX}
      color={"red"}
      variant="outline"
      borderColor={`1px solid ${colors.red[3]}`}
      {...props}
    />
  );
};
