import { SectionDescription, SectionTitle, StyledStack } from "./styles";

interface SectionLayoutProps {
  title: string;
  description?: string;
  children: React.ReactNode[] | React.ReactNode;
}

export const SectionLayout = ({
  title,
  description,
  children,
}: SectionLayoutProps) => {
  return (
    <StyledStack>
      <SectionTitle order={4}>{title}</SectionTitle>
      {description !== undefined && (
        <SectionDescription>{description}</SectionDescription>
      )}
      {children}
    </StyledStack>
  );
};
