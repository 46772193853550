import styled from "@emotion/styled";
import { Accordion, Box } from "@mantine/core";

export const StyledAccordionControl = styled(Accordion.Control)({
  fontWeight: "bold",
  color: "black",
});

export const RenameFormLayout = styled.div(({ theme }) => ({
  flex: 1,
  paddingLeft: theme.spacing.md,
  paddingRight: theme.spacing.md,
  paddingTop: theme.spacing.xs,
  paddingBottom: theme.spacing.xs,
}));
