import {
  EdgeProps as FlowEdgeProps,
  NodeProps as FlowNodeProps,
} from "reactflow";

// Rewrite props type to distinguish between different edges and enforce type property
export type EdgeProps = FlowEdgeProps & {
  data: {
    type: "template" | "dependency";
  };
};

export type NodeProps<K> = FlowNodeProps & {
  type: K;
};

export enum GraphNode {
  Generic = "generic",
  Output = "output",
  Seed = "seed",
}

export enum GraphEdge {
  Add = "add",
}
