import { Document, Page } from "react-pdf";
import { Group } from "@mantine/core";
import { useElementSize } from "@mantine/hooks";
import { usePdf } from "./use-pdf";
import { Move } from "@morph-mapper/ui";
import { useStore } from "../../context";
import { useTextSelect } from "../../hooks";
import { Footer } from "../../molecules";
import { FileViewContent, FileViewHeader, FileViewWrapper } from "./styles";

import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

export const PdfView = () => {
  const file = useStore(({ file: f }) => f.getFile());
  const { ref, width } = useElementSize();
  const {
    numPages,
    pageNumber,
    onDocumentLoadSuccess,
    previousPage,
    nextPage,
  } = usePdf();

  useTextSelect();

  return (
    <FileViewWrapper>
      <FileViewHeader>
        <Group>
          <Move
            into={false}
            onClick={previousPage}
            disabled={pageNumber <= 1}
          />
          {pageNumber} / {numPages}
          <Move onClick={nextPage} disabled={pageNumber >= numPages} />
        </Group>
      </FileViewHeader>
      <FileViewContent ref={ref}>
        <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
          <Page width={width} pageNumber={pageNumber} />
        </Document>
      </FileViewContent>
      <Footer />
    </FileViewWrapper>
  );
};
