import { Text } from '@mantine/core';
import { AccordionItem, Header } from '../../molecules';
import { SideBarContent, SideBarWrapper, StyledAccordion } from './styles';
import { getKeys, omit } from '@morph-mapper/utils';
// TODO: Put import behind interface
import { LOGIC_CATEGORIES } from '@morph-mapper/node-logic';

export const SelectorView = (state: any) => {
  //TODO: generalize this: with a rule for single block selection?
  const singleBlockSeletion =
    state?.state?.targetEdge?.targetHandle === 'caseDefinition';
  const filtered_logic_categories = omit(LOGIC_CATEGORIES, 'case');

  // Only 'Case Definition' block should be an option on the sidebar when defining switch cases
  return (
    <SideBarWrapper>
      <Header />
      <SideBarContent>
        {singleBlockSeletion ? (
          <>
            <Text color="dimmed">
              Define case options for switch statements through this interface.
              Each case represents a possible condition and the corresponding
              action or value.
            </Text>
            <StyledAccordion variant="separated" defaultValue="case">
              <AccordionItem key={'case'} category={'case'} />
            </StyledAccordion>
          </>
        ) : (
          <>
            <Text color="dimmed">
              Components are logic blocks which can be chained, split, and
              combined. Use a combination of these blocks to extract the
              required values from your file.
            </Text>
            <StyledAccordion variant="separated" defaultValue="core">
              {getKeys(filtered_logic_categories).map((category) => (
                <AccordionItem key={category} category={category} />
              ))}
            </StyledAccordion>
          </>
        )}
      </SideBarContent>
    </SideBarWrapper>
  );
};
