import { useSetupStore } from '../../store';
import { useEffect, useState } from 'react';
import { useStore } from '@/store';
import { useLoadState, useNotify } from '@/hooks';
import { useTemplateSave, useUpdateTemplateRef } from '@/services';
import { useNavigate } from 'react-router-dom';

export const useManageTemplate = (
  id: string,
  path: string | null,
  deployPath: string | null,
  enabled: boolean
) => {
  const [step, setStep] = useSetupStore((s) => [
    s.currentStep,
    s.setCurrentStep,
  ]);
  const [setId, setPath, setIsDeployOnly, isDeployOnly, setTextContent] =
    useStore(({ config: c }) => [
      c.setId,
      c.setPath,
      c.setIsDeployOnly,
      c.getIsDeployOnly(),
      c.setTextContent,
    ]);
  const { mutate: updateTemplate } = useUpdateTemplateRef();
  const [active, setActive] = useState(false);
  const [templatePath, setTemplatePath] = useState<string | null>(path);
  const { data: template } = useTemplateSave(templatePath, active);
  const { load } = useLoadState(id);
  const { error } = useNotify();
  const navigate = useNavigate();

  useEffect(() => {
    if (template === undefined) return;
    if (!active) return;
    if (templatePath === null) return;
    if (isDeployOnly) return;
    // Set the path in the global store
    setPath(templatePath);
    setId(id);
    load(template);
    setStep(step + 1);
  }, [template, active, path, isDeployOnly]);

  useEffect(() => {
    if (template === undefined) return;
    if (templatePath === null) return;
    if (!isDeployOnly) return;

    setTextContent(template);
    setPath(templatePath);
    navigate('editor');
  }, [template, isDeployOnly]);

  const handleEnable = () => {
    updateTemplate({
      id,
      template: {
        enabled: !enabled,
      },
    });
  };

  const handleSelect = () => {
    if (path === null) {
      error('No template has been configured for this reference.');
      return;
    }

    if (deployPath && path === undefined) {
      setIsDeployOnly(true);
      setTemplatePath(deployPath);
    }

    setActive(true);
  };

  return {
    handleEnable,
    handleSelect,
  };
};
