import { useParams } from 'react-router-dom';
import { SelectorView } from './organisms';
import { EntryProvider } from './context';

export const StructureSelector = () => {
  const { entryId } = useParams();
  if (!entryId) throw new Error('No active entry');

  return (
    <EntryProvider id={entryId}>
      <SelectorView />
    </EntryProvider>
  );
};

export default StructureSelector;
