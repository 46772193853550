import type { JSX } from 'react';
import {
  Checkbox,
  LiteralView,
  MultiSelectCreatable,
  NumberInput,
  RadioSelector,
  SingleSelect,
  TextInput,
  TextSelector,
  ParameterTable,
  MultiSelect,
} from './inputs';
import { type InputProps, Input } from './types';

export { Input } from './types';
export const Inputs: Record<Input, (props: InputProps<any>) => JSX.Element> = {
  [Input.Text]: TextInput,
  [Input.Number]: NumberInput,
  [Input.Checkbox]: Checkbox,
  [Input.TextInput]: TextInput,
  [Input.MultiSelectCreatable]: MultiSelectCreatable,
  [Input.TextSelector]: TextSelector,
  [Input.RadioSelector]: RadioSelector,
  [Input.SingleSelect]: SingleSelect,
  [Input.Literal]: LiteralView,
  [Input.ParameterTable]: ParameterTable,
  [Input.MultiSelect]: MultiSelect,
};
