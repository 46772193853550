import styled from "@emotion/styled";

export const ItemSettingsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 1;
  gap: 1rem;
`;

export const TextWrapper = styled.div({
  display: "flex",
  flexDirection: "column",
});

type ListItemProps = {
  isSelected?: boolean;
};

export const ListItem = styled.tr<ListItemProps>(({ theme, isSelected }) => ({
  backgroundColor: isSelected ? theme.colors.blue[2] : "white",
  ":hover": {
    backgroundColor: theme.colors.blue[0],
    cursor: "pointer",
  },
}));
