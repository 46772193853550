import styled from '@emotion/styled';

export const StyledComponent = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing.sm,
  border: `1px solid ${theme.colors.gray[1]}`,
  ':hover': {
    backgroundColor: theme.colors.gray[0],
  },
}));
