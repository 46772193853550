import styled from "@emotion/styled";
import { Box, BoxProps, createPolymorphicComponent } from "@mantine/core";

const _EditorFlowWrapper = styled(Box)`
  width: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

export const EditorFlowWrapper = createPolymorphicComponent<"div", BoxProps>(
  _EditorFlowWrapper
);
