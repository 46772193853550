import { Text, Group, Radio } from '@mantine/core';
import { InfoTooltip } from '@morph-mapper/ui';
import { PathMode } from '../../types';
import { useStore } from '../../context';
import { capitalizeWord, getValues } from '@morph-mapper/utils';
import { MenuBar } from './styles';
import { useRelativePaths } from '../../hooks';

export const RelativePathMenuBar = () => {
  const [pathMode, relativePaths] = useStore(({ path: p }) => [
    p.getPathMode(),
    p.getRelativePaths(),
  ]);
  const { pathModeIsAvailable, setPathMode } = useRelativePaths();

  // We did not find an available relative path, so the menu is disabled.
  if (relativePaths.length === 0) return null;

  return (
    <MenuBar>
      <Group position="apart">
        <Group>
          <Text>Path resolution mode</Text>
          <InfoTooltip label="Switch between paths from the root of the document, and predefined paths." />
        </Group>

        <Group>
          {getValues(PathMode).map((mode) => (
            <Radio
              key={mode}
              label={capitalizeWord(mode)}
              checked={pathMode === mode}
              onChange={() => setPathMode(mode)}
              disabled={!pathModeIsAvailable(mode)}
            />
          ))}
        </Group>
      </Group>
    </MenuBar>
  );
};
