import { useContext } from 'react';
import { useStore } from 'zustand';
import { GraphState } from '../../store';
import { GraphContext } from './context';
import { IGraph } from '@/store';

export const useGraphContext = <T>(
  selector: (state: GraphState & IGraph) => T,
  equalityFn?: (left: T, right: T) => boolean
): T => {
  const store = useContext(GraphContext);
  if (!store) throw new Error('Missing GraphContext.Provider in the graph');
  return useStore(store, selector, equalityFn);
};
