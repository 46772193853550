import { createStore } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { StoreSlice, IEntry } from '@/store';
import { NavigateFunction } from 'react-router-dom';
import { EntryItemType, EntryType } from '@morph-mapper/types';

export interface RowProps {
  id: string;
  // state for entry in-row editing
  tempValue: any;

  // UI state for entry in-row editing
  isConfirm: boolean;
  isEditing: boolean;
}

export type InitRowProps = Pick<RowProps, 'id'>;

export interface RowState extends RowProps {
  // Routing
  navigate: NavigateFunction;

  // variable in row operations
  getType: () => EntryType;
  setType: (type: EntryItemType) => void;
  getComputed: () => any;
  setTempValue: (tempValue: any) => void;
  setConfirm: (toggle: boolean) => void;
  setEditing: (toggle: boolean) => void;

  cancelClear: () => void;
  confirmClear: () => void;
  cancelEdit: () => void;
  confirmEdit: () => void;
  onEdit: () => void;
  onClear: () => void;
  canClear: () => boolean;
  remove: () => void;
}

export type InitRowState = Pick<RowState, 'navigate'>;

export type RowStore = ReturnType<typeof createRowStore>;

export const createRowStore = (
  global: StoreSlice,
  initProps: InitRowProps & InitRowState
) => {
  const DEFAULT_VALUES: Omit<RowProps, keyof InitRowProps> = {
    tempValue: undefined,
    isConfirm: false,
    isEditing: false,
  };

  return createStore<RowState & StoreSlice & IEntry>()(
    immer((set, get) => ({
      ...global,
      ...global.entries.entry(initProps.id),
      ...DEFAULT_VALUES,
      ...initProps,

      setTempValue: (tempValue) =>
        set((s) => {
          s.tempValue = tempValue;
        }),
      setConfirm: (toggle) => {
        set((s) => {
          s.isConfirm = toggle;
        });
      },
      setEditing: (toggle) => {
        set((s) => {
          s.isEditing = toggle;
        });
      },
      cancelClear: () => {
        set((s) => {
          s.isConfirm = false;
        });
      },
      confirmClear: () => {
        get().clear();

        set((s) => {
          s.isConfirm = false;
          s.isEditing = false;
        });
      },
      cancelEdit: () => {
        set((s) => {
          s.tempValue = undefined;
          s.isConfirm = false;
          s.isEditing = false;
        });
      },
      confirmEdit: () => {
        get().setTemplateValue(get().tempValue);

        set((s) => {
          s.tempValue = undefined;
          s.isConfirm = false;
          s.isEditing = false;
        });
      },
      onEdit: () => {
        set((s) => {
          switch (s.getType()) {
            case EntryType.Graph:
              get().navigate(`/entries/${s.id}/graph`);
              break;
            default:
              s.isEditing = true;
          }
        });
      },
      onClear: () => {
        set((s) => {
          s.isConfirm = true;
          s.isEditing = false;
        });
      },
      canClear: () => {
        return get().hasValue();
      },
      remove: () => {
        get().delete();
      },
    }))
  );
};
