import axios from 'axios';
import { API_UI_TEST_BASE_URL } from '@/config';

export default axios.create({
  baseURL: API_UI_TEST_BASE_URL,
  timeout: 5000,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
  },
});
