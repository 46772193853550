import { useContext } from 'react';
import { useStore } from 'zustand';
import { EntryState } from '../../store';
import { EntryContext } from './context';
import { StoreSlice, IGraphEntry } from '@/store';

export const useEntryContext = <T>(
  selector: (state: EntryState & StoreSlice & IGraphEntry) => T,
  equalityFn?: (left: T, right: T) => boolean
): T => {
  const store = useContext(EntryContext);
  if (!store) throw new Error('Missing EntryContext.Provider in the entry');
  return useStore(store, selector, equalityFn);
};
