import { StateCreator } from 'zustand';
import { createLens } from '@dhmk/zustand-lens';
import { Node, Edge, LogicGraph } from '@/types';
import { nanoid } from 'nanoid';
import {
  initialEdges,
  initialNodes,
} from '../../components/graph-editor/config';
import { IGraph, createGraphAPI } from './graph';
import { INode, createNodeAPI } from './node';

export type { IGraph, INode };

export interface GraphsSlice {
  graphs: Record<string, LogicGraph>;

  // Helpers
  graph: (id: string) => IGraph;
  node: (graphId: string, nodeId: string) => INode;

  // Operators on a single graph
  init: (
    nodes?: Record<string, Node>,
    edges?: Record<string, Edge>,
    id?: string
  ) => string;
  get: (id: string) => LogicGraph;
  getId: (id: string) => string;
  getNodeIds: (id: string) => string[];
  getNodes: (id: string) => Record<string, Node>;
  getEdges: (id: string) => Record<string, Edge>;
  setNodes: (id: string, nodes: Record<string, Node>) => void;
  setEdges: (id: string, edges: Record<string, Edge>) => void;
  delete: (id: string) => void;

  // Operators on multiple graphs
  hasGraphs: () => boolean;
  setGraphs: (graphs: Record<string, LogicGraph>) => void;

  getNode: (graphId: string, nodeId: string) => Node;
}

export const createGraphsSlice: StateCreator<
  { graphs: GraphsSlice },
  [['zustand/immer', never]],
  [],
  GraphsSlice
> = (_set, _get) => {
  // Slices make use of a namespace, make current slice local namespace.
  const [set, get] = createLens(_set, _get, ['graphs']);

  return {
    graphs: {},
    //TODO: split structure from data
    // nodes: {},
    // edges: {},

    graph: (id) => createGraphAPI(set, get, id),
    node: (graphId, nodeId) => createNodeAPI(set, get, graphId, nodeId),

    init: (nodes, edges, id) => {
      const graph = {
        id: id ?? nanoid(10),
        nodes: nodes ?? initialNodes,
        edges: edges ?? initialEdges,
      };

      set((s) => {
        s.graphs[graph.id] = graph;
      });

      return graph.id;
    },
    get: (id) => {
      const graph = get().graphs[id];
      if (!graph) {
        throw new Error('Graph does not exist');
      }
      return graph;
    },
    getId: (id) => {
      const graph = get().graphs[id];
      if (!graph) {
        throw new Error('Graph does not exist');
      }
      return graph.id;
    },
    getNodeIds: (id) => {
      const graph = get().graphs[id];
      if (!graph) {
        throw new Error('Graph does not exist');
      }
      return Object.keys(graph.nodes);
    },
    getNodes: (id) => {
      const graph = get().graphs[id];
      if (!graph) {
        throw new Error('Graph does not exist');
      }
      return graph.nodes;
    },
    getEdges: (id) => {
      const graph = get().graphs[id];
      if (!graph) {
        throw new Error('Graph does not exist');
      }
      return graph.edges;
    },
    setNodes: (id, nodes) => {
      set((s) => {
        const graph = s.graphs[id];
        if (!graph) {
          throw new Error('Graph does not exist');
        }
        graph.nodes = nodes;
      });
    },
    setEdges: (id, edges) => {
      set((s) => {
        const graph = s.graphs[id];
        if (!graph) {
          throw new Error('Graph does not exist');
        }
        graph.edges = edges;
      });
    },
    delete: (id) => {
      set((s) => {
        if (!s.graphs[id]) {
          throw new Error('Graph does not exist');
        }
        delete s.graphs[id];
      });
    },

    hasGraphs: () => {
      return Object.keys(get().graphs).length > 0;
    },
    setGraphs: (graphs) => {
      set((s) => {
        s.graphs = graphs;
      });
    },
    getNode: (graphId, nodeId) => {
      const graph = get().graphs[graphId];
      if (!graph) {
        throw new Error('Graph does not exist');
      }

      const node = graph.nodes[nodeId];

      if (!node) {
        throw new Error('Node does not exist');
      }

      return node;
    },
  };
};
