import styled from '@emotion/styled';
import { Box, BoxProps, createPolymorphicComponent } from '@mantine/core';

export const RawViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
`;

const _RawViewContent = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  height: 100%;
  overflow: hidden;
`;

export const RawViewContent = createPolymorphicComponent<'div', BoxProps>(
  _RawViewContent
);

export const LineNumbers = styled.div`
  padding: 8px;
  font-family: monospace;
  font-size: 14px;
  text-align: right;
  user-select: none;
  background-color: #f7f7f7;
  border-right: 1px solid #ddd;
  line-height: 1.5em;
  overflow-y: auto;
  height: 100%;
  width: 40px;
`;

export const TextDisplay = styled.div`
  padding: 8px;
  font-family: monospace;
  font-size: 14px;
  line-height: 1.5em;
  overflow: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
  flex: 1;
  height: 100%;
`;
