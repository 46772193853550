import _ from 'lodash';
import { nanoid } from 'nanoid';
import { z } from 'zod';
import { StateCreator } from 'zustand';
import { createLens } from '@dhmk/zustand-lens';
import {
  CreateEntryItem,
  CreateMapItem,
  DirtyEntries,
  DirtyType,
  TreeNode,
  isTreeGraphItem,
  isTreeItem,
  isTreeMap,
} from '@/types';
import { IMapChildren, createMapChildrenAPI } from './children';
import {
  EntryReturnType,
  ICellEntry,
  IEntry,
  IGraphEntry,
  IInternalEntry,
  IMapEntry,
  ISimpleEntry,
  IBooleanEntry,
  createEntryAPI,
} from './entry';
import { StoreSlice } from '@/store';
import { EntryItemType, EntryType } from '@morph-mapper/types';
import { StructureBlockIndex } from '@morph-mapper/node-logic';
import {
  createDependencyScanner,
  createUnregisteredItem,
  createUnregisteredMap,
  ScanMode,
} from '@/utils';
import type { Draft } from 'immer';
import { match } from 'ts-pattern';

export type {
  IEntry,
  IBooleanEntry,
  ISimpleEntry,
  ICellEntry,
  IGraphEntry,
  IInternalEntry,
  IMapEntry,
  IMapChildren,
};

export interface EntrySlice {
  rootId: string | undefined;
  entries: Record<string, TreeNode>;
  dirtyEntries: DirtyEntries;

  // Helpers
  children: (id: string) => IMapChildren;
  entry: <T extends EntryType>(id: string, type?: T) => EntryReturnType<T>;
  // TODO: maybe we want to make all usage in the application of type IEntry
  // instead of having access to the entry object directly
  getParent: (id: string) => IMapEntry | undefined;

  // Operators on a single entry
  createItem: (
    partial: CreateEntryItem,
    type: EntryItemType,
    prefill?: string
  ) => string;
  createMap: (partial: CreateMapItem) => string;
  getById: (id: string) => TreeNode;
  getByKey: (name: string) => IEntry[];
  getRootId: () => string;
  clear: (id: string) => void;

  addDirty: (id: string, type: DirtyType) => void;
  getAllDirty: (type: DirtyType) => string[];
  clearDirty: (type: DirtyType) => void;

  linkChild: (parentId: string, childId: string) => void;
  linkGraph: (entryId: string, graphId: string) => void;
  getParentId: (id: string) => string | undefined;
  getAllowedTypes: (id: string) => EntryItemType[];
  getType: (id: string) => EntryType;
  setType: (id: string, type: EntryItemType) => void;
  getKey: (id: string) => string;
  getName: (id: string) => string | undefined;
  getComputed: (id: string) => any;
  setComputed: (id: string, computed: any) => void;
  getValidation: (id: string) => z.ZodType<any, any, any> | undefined;
  getOutputType: (id: string) => StructureBlockIndex;
  setOutputType: (id: string, outputType: StructureBlockIndex) => void;
  getDependsOn: (id: string) => string[];
  getRequiredBy: (id: string) => string[];
  getPath: (id: string) => string[];

  getUnderlyingValue: (id: string) => any;
  setUnderlyingValue: (id: string, value: any) => void;

  getTemplateValue: (id: string) => any;
  setTemplateValue: (id: string, value: any) => void;

  hasValue: (id: string) => boolean;
  delete: (id: string) => void;

  // Operators on multiple entries
  hasEntries: () => boolean;
  setEntries: (entries: Record<string, TreeNode>) => void;
}

export const createEntrySlice: StateCreator<
  StoreSlice,
  [['zustand/immer', never]],
  [],
  EntrySlice
> = (_set, _get) => {
  // Slices make use of a namespace, make current slice local namespace.
  const [set, get] = createLens(_set, _get, ['entries']);

  const _getEntrySafe = <T extends EntryType>(id: string, type?: T) => {
    const entry = get().entries[id];
    if (!entry) {
      throw `[EntrySlice]: Entry ${id} does not exist`;
    }
    if (!type) {
      return entry;
    }
    if (entry.type !== type) {
      throw `[EntrySlice]: Entry ${id} is of type ${entry.type}, expected ${type}`;
    }

    return entry;
  };

  const _setEntrySafe = <T extends EntryType>(
    s: Draft<EntrySlice>,
    id: string,
    type?: T
  ) => {
    const entry = s.entries[id];
    if (!entry) {
      throw `[EntrySlice]: Entry ${id} does not exist`;
    }
    if (!type) {
      return entry;
    }
    if (entry.type !== type) {
      throw `[EntrySlice]: Entry ${id} is of type ${entry.type}, expected ${type}`;
    }

    return entry;
  };

  return {
    rootId: undefined,
    entries: {},
    dirtyEntries: {
      [DirtyType.Underlying]: [],
      [DirtyType.Template]: [],
    },

    children: (id) => createMapChildrenAPI(set, get, id),
    entry: (id, type) => createEntryAPI(set, get, id, type),

    getParent: (id) => {
      const entry = _getEntrySafe(id);
      const parentId = entry.parentId;
      if (!parentId) {
        return undefined;
      }

      return get().entry(parentId, EntryType.Map);
    },

    addDirty: (id, type) => {
      set((s) => {
        if (s.entries[id].type === EntryType.Map) return;
        s.dirtyEntries[type].push(id);
      });
    },
    getAllDirty: (type) => {
      const dirty = get().dirtyEntries[type];

      return dirty;
    },
    clearDirty: (type) => {
      set((s) => {
        s.dirtyEntries[type] = [];
      });
    },
    createItem: (partial, type, prefill) => {
      let id = nanoid(10);

      set((s) => {
        while (s.entries[id]) {
          id = nanoid(10);
        }

        const entry = {
          id,
          key: partial.key,
          name: partial.name ?? partial.key,
          value: prefill,
          validation: partial.validation ?? z.any(),
          parentId: partial.parentId,
          allowedTypes: partial.allowedTypes,
        };

        s.entries[id] = { id, ...createUnregisteredItem(type, entry) };
      });

      return id;
    },
    createMap: (partial) => {
      let id = nanoid(10);

      set((s) => {
        while (s.entries[id]) {
          id = nanoid(10);
        }

        s.entries[id] = {
          id,
          ...createUnregisteredMap(partial),
        };
      });

      return id;
    },
    getById: (id) => {
      return _getEntrySafe(id);
    },
    getByKey: (name) => {
      const entries = Object.values(get().entries).filter(
        (entry) => entry.key === name
      );
      if (entries.length === 0) {
        throw new Error(`[EntrySlice]: Entry ${name} does not exist`);
      }
      return entries.map((entry) => get().entry(entry.id));
    },
    getRootId: () => {
      //TODO: optimize
      const rootId = get().rootId;
      if (rootId) return rootId;

      const root = Object.values(get().entries).find(
        (entry) => entry.name === 'root'
      );
      if (!root) {
        throw new Error('[EntrySlice]: Root entry not found');
      }

      return root.id;
    },
    linkChild: (parentId, childId) => {
      const parent = _getEntrySafe(parentId);
      if (!isTreeMap(parent)) {
        throw new Error('[EntrySlice]: Invalid parent entry');
      }
      const child = _getEntrySafe(childId);

      get().children(parentId).set(childId, child.key);
    },
    linkGraph: (entryId, graphId) => {
      set((s) => {
        const entry = _setEntrySafe(s, entryId);
        if (!isTreeGraphItem(entry)) {
          throw new Error('[EntrySlice]: Invalid entry type');
        }

        entry.graphId = graphId;
      });
    },
    getParentId: (id) => {
      return _getEntrySafe(id).parentId;
    },
    getAllowedTypes: (id) => {
      return _getEntrySafe(id).allowedTypes ?? [];
    },
    getType: (id) => {
      return _getEntrySafe(id).type;
    },
    setType: (id, type) => {
      set((s) => {
        const entry = _getEntrySafe(id);
        const { name, parentId, validation, key, allowedTypes } = entry;
        if (!parentId || !validation) {
          throw new Error('[EntrySlice]: Cannot change entry type');
        }

        const partial = { name, parentId, validation, key, allowedTypes };

        s.entries[id] = { id, ...createUnregisteredItem(type, partial) };
      });
    },
    getKey: (id) => {
      return _getEntrySafe(id).key;
    },
    getName: (id) => {
      // TODO: should be made safe
      const entry = get().entries[id];
      if (!entry) {
        return undefined;
      }

      return entry.name;
    },
    getComputed: (id) => {
      const entry = _getEntrySafe(id);
      if (!isTreeItem(entry))
        throw new Error('[EntrySlice]: Invalid entry type');

      return entry.computed;
    },
    setComputed: (id, computed) => {
      set((s) => {
        const entry = _setEntrySafe(s, id);
        if (!isTreeItem(entry))
          throw new Error('[EntrySlice]: Invalid entry type');

        entry.computed = computed;
      });
    },
    getValidation: (id) => {
      const entry = _getEntrySafe(id);
      if (!isTreeItem(entry))
        throw new Error('[EntrySlice]: Invalid entry type');

      return entry.validation;
    },
    getOutputType: (id) => {
      const entry = _getEntrySafe(id);
      if (!isTreeMap(entry))
        throw new Error('[EntrySlice]: Invalid entry type (output)');

      return entry.outputType;
    },
    setOutputType: (id, outputType) => {
      set((s) => {
        const entry = _setEntrySafe(s, id);
        if (!isTreeMap(entry))
          throw new Error('[EntrySlice]: Invalid entry type');

        entry.outputType = outputType;
      });
    },
    getDependsOn: (id) => {
      const entry = _getEntrySafe(id);
      return Array.from(entry.dependencies.dependsOn);
    },
    getRequiredBy: (id) => {
      const entry = _getEntrySafe(id);
      return Array.from(entry.dependencies.requiredBy);
    },
    getPath: (id) => {
      const entry = _getEntrySafe(id);
      const path = [entry.id];
      let parentId = entry.parentId;

      while (parentId !== undefined) {
        path.push(parentId);

        const parent = _getEntrySafe(parentId);
        parentId = parent.parentId;
      }

      return path.reverse();
    },
    setUnderlyingValue: (id, value) => {
      set((s) => {
        const entry = _setEntrySafe(s, id);
        if (!isTreeItem(entry))
          throw new Error('[EntrySlice]: Invalid entry type');

        // For simple and internal entries the value is the same as the underlying value
        // simply set them anyway here so the listener for template values is triggered.
        match(entry)
          .with({ type: EntryType.Boolean }, (e) => {
            e.value = value;
          })
          .with({ type: EntryType.Simple }, (e) => {
            e.value = value;
          })
          .with({ type: EntryType.Cell }, (e) => {
            e.column = value;
          })
          .with({ type: EntryType.Graph }, (e) => {
            e.graphId = value;
          })
          .with({ type: EntryType.Internal }, (e) => {
            e.value = value;
          })
          .exhaustive();

        s.dirtyEntries[DirtyType.Underlying].push(id);
      });
    },
    getUnderlyingValue: (id) => {
      const entry = _getEntrySafe(id);
      if (!isTreeItem(entry))
        throw new Error('[EntrySlice]: Invalid entry type');

      return match(entry)
        .with({ type: EntryType.Boolean }, (e) => e.value)
        .with({ type: EntryType.Simple }, (e) => e.value)
        .with({ type: EntryType.Cell }, (e) => e.column)
        .with({ type: EntryType.Graph }, (e) => e.graphId)
        .with({ type: EntryType.Internal }, (e) => e.value)
        .exhaustive();
    },
    getTemplateValue: (id) => {
      const entry = _getEntrySafe(id);
      if (!isTreeItem(entry))
        throw new Error('[EntrySlice]: Invalid entry type');
      return entry.value;
    },
    setTemplateValue: (id, value) => {
      set((s) => {
        const entry = _setEntrySafe(s, id);
        if (!isTreeItem(entry))
          throw new Error('[EntrySlice]: Invalid entry type');

        entry.value = value;

        const { scanDependencies } = createDependencyScanner(s.entries);
        const dependencies = scanDependencies(ScanMode.Singular, id).get(id);

        // Update dependencies
        if (!dependencies) {
          throw new Error('[EntrySlice]: Dependencies not found');
        }
        entry.dependencies = dependencies;

        if (value === undefined) {
          entry.computed = undefined;
          return;
        }
        if (!s.dirtyEntries[DirtyType.Template].includes(id)) {
          s.dirtyEntries[DirtyType.Template].push(id);
        }
      });
      const entry = _getEntrySafe(id);
      if (!isTreeItem(entry))
        throw new Error('[EntrySlice]: Invalid entry type');
    },
    hasValue: (id) => {
      const entry = _getEntrySafe(id);
      if (!isTreeItem(entry))
        throw new Error('[EntrySlice]: Invalid entry type');

      return match(entry)
        .with({ type: EntryType.Boolean }, (e) => e.value !== undefined)
        .with({ type: EntryType.Simple }, (e) => e.value !== undefined)
        .with({ type: EntryType.Cell }, (e) => e.column !== undefined)
        .with({ type: EntryType.Graph }, (e) => e.graphId !== undefined)
        .with({ type: EntryType.Internal }, (e) => e.value !== undefined)
        .exhaustive();
    },
    clear: (id) => {
      set((s) => {
        const entry = _setEntrySafe(s, id);
        if (!isTreeItem(entry))
          throw new Error('[EntrySlice]: Invalid entry type');

        entry.computed = undefined;
        entry.value = undefined;

        match(entry)
          .with({ type: EntryType.Cell }, (e) => {
            e.column = undefined;
          })
          .with({ type: EntryType.Graph }, (e) => {
            if (e.graphId) _get().graphs.delete(e.graphId);
            e.graphId = undefined;
          })
          .otherwise(() => null);
      });
    },
    delete: (id) => {
      const entry = _getEntrySafe(id);
      // Recursively delete children
      if (entry.type === EntryType.Map) {
        get()
          .children(entry.id)
          .getIds()
          .forEach((childId) => {
            get().delete(childId);
          });
      }

      // Unlink from parent
      if (entry.parentId) {
        get().children(entry.parentId).deleteById(entry.id);
      }

      // Unlink from graph
      if (entry.type === EntryType.Graph && entry.graphId) {
        _get().graphs.delete(entry.graphId);
      }

      set((s) => {
        delete s.entries[id];
      });
    },

    hasEntries: () => {
      return Object.keys(get().entries).length > 0;
    },
    setEntries: (entries) => {
      set((s) => {
        s.entries = entries;
      });
    },
  };
};
