import { useRef } from 'react';
import { CellEntryContext } from './context';
import { useStore } from '@/store';
import { CellEntryStore, createCellEntryStore } from '../../store';
import { useNavigate } from 'react-router-dom';

interface CellEntryProviderProps extends React.PropsWithChildren {
  id: string;
}

export const CellEntryProvider = ({ children, id }: CellEntryProviderProps) => {
  const navigate = useNavigate();

  const globalStore = useStore();
  const storeRef = useRef<CellEntryStore>();

  if (!storeRef.current) {
    storeRef.current = createCellEntryStore(globalStore, {
      id,
      navigate,
    });
  }

  return (
    <CellEntryContext.Provider value={storeRef.current}>
      {children}
    </CellEntryContext.Provider>
  );
};
