import {
  Group,
  List,
  Stack,
  ThemeIcon,
  Title,
  Text,
  ActionIcon,
} from '@mantine/core';
import { FiChevronRight } from 'react-icons/fi';
import { StyledComponent } from './styles';
import { useBlockItem } from './use-block-item';
import { LogicBlockIndex } from '@morph-mapper/node-logic';
import { useLogicBlocks } from '@morph-mapper/node-logic';
import { useStore } from '@/store';

interface BlockItemProps {
  type: LogicBlockIndex;
}

export const BlockItem = ({ type }: BlockItemProps) => {
  const variant = useStore(({ config: c }) => c.getVariant());
  const { logicBlocks } = useLogicBlocks(variant);
  const { icon: Icon, title, description } = logicBlocks[type];
  const { handleCreate } = useBlockItem(type);

  return (
    <List.Item onClick={handleCreate}>
      <StyledComponent>
        <Group>
          <ThemeIcon size="xl">
            <Icon />
          </ThemeIcon>
          <Stack spacing={5}>
            <Title order={6}>{title}</Title>
            <Text size="xs" c="dimmed">
              {description}
            </Text>
          </Stack>
        </Group>
        <ActionIcon>
          <FiChevronRight />
        </ActionIcon>
      </StyledComponent>
    </List.Item>
  );
};
