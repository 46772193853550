import { useEffect, useRef } from 'react';
import {
  RawViewWrapper,
  RawViewContent,
  LineNumbers,
  TextDisplay,
} from './styles';

interface RawViewProps {
  rawText: string;
  setRawText: (value: string) => void;
}

export const RawView = ({ rawText, setRawText }: RawViewProps) => {
  const lineNumbers = rawText.split(/\r?\n/).map((_, index) => index + 1);

  // Scrolling synchronization
  const textDisplayRef = useRef<HTMLDivElement>(null);
  const lineNumbersRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    if (lineNumbersRef.current && textDisplayRef.current) {
      lineNumbersRef.current.scrollTop = textDisplayRef.current.scrollTop;
    }
  };

  const handleInput = (e: React.FormEvent<HTMLDivElement>) => {
    setRawText(e.currentTarget.textContent || '');
  };

  // Setting initial contentEditable value without re-rendering
  useEffect(() => {
    if (
      textDisplayRef.current &&
      textDisplayRef.current.textContent !== rawText
    ) {
      textDisplayRef.current.textContent = rawText;
    }
  }, [rawText]);

  return (
    <RawViewWrapper>
      <RawViewContent>
        <LineNumbers ref={lineNumbersRef}>
          {lineNumbers.map((line) => (
            <div key={line}>{line}</div>
          ))}
        </LineNumbers>

        <TextDisplay
          ref={textDisplayRef}
          contentEditable
          suppressContentEditableWarning
          onInput={handleInput}
          onScroll={handleScroll}
        />
      </RawViewContent>
    </RawViewWrapper>
  );
};
