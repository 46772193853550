export const isNotUndefined = <T>(value: T | undefined): value is T => {
  return value !== undefined;
};

export const isNotNull = <T>(value: T | null): value is T => {
  return value !== null;
};

export function assertNotEmpty<T>(
  arr: T[],
  err?: string
): asserts arr is [T, ...T[]] {
  if (arr.length === 0) {
    throw err || 'Array is empty';
  }
}

export function assertDefined<T>(
  value: T | undefined,
  err?: string
): asserts value is T {
  if (value === undefined) {
    throw err || 'Value is undefined';
  }
}

export function hasProperty<T extends object, K extends PropertyKey>(
  obj: T,
  key: K
): obj is T & Record<K, unknown> {
  return key in obj;
}
