import styled from "@emotion/styled";

export const SideBarWrapper = styled.div(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "100%",
  backgroundColor: theme.colors.gray[1],
}));

export const SideBarContent = styled.div`
  flex: 1;
  width: 100%;
  overflow-y: auto;
  padding: 2rem;
`;
