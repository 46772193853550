import { Box, Menu, Portal, useMantineTheme } from '@mantine/core';
import { Options } from '@morph-mapper/ui';
import { IoMdRadioButtonOff, IoMdRadioButtonOn } from 'react-icons/io';
import { useRowContext } from '../../context';
import { FiTrash2 } from 'react-icons/fi';
import { EntryType } from '@morph-mapper/types';

export const RowMenu = () => {
  const theme = useMantineTheme();
  const [type, setType, remove] = useRowContext((s) => [
    s.getType(),
    s.setType,
    s.remove,
  ]);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Menu shadow="md" width={200} position="bottom-end">
        <Menu.Target>
          <Options />
        </Menu.Target>
        <Portal>
          <Menu.Dropdown sx={{ zIndex: 1000 }}>
            <Menu.Label>Configure variable</Menu.Label>
            <Menu.Item
              icon={
                type === EntryType.Boolean ? (
                  <IoMdRadioButtonOn color={theme.colors.blue[7]} />
                ) : (
                  <IoMdRadioButtonOff color={theme.colors.gray[6]} />
                )
              }
              onClick={() => setType(EntryType.Boolean)}
            >
              Boolean
            </Menu.Item>
            <Menu.Item
              icon={
                type === EntryType.Simple ? (
                  <IoMdRadioButtonOn color={theme.colors.blue[7]} />
                ) : (
                  <IoMdRadioButtonOff color={theme.colors.gray[6]} />
                )
              }
              onClick={() => setType(EntryType.Simple)}
            >
              Literal
            </Menu.Item>
            <Menu.Item
              icon={
                type === EntryType.Graph ? (
                  <IoMdRadioButtonOn color={theme.colors.blue[7]} />
                ) : (
                  <IoMdRadioButtonOff color={theme.colors.gray[6]} />
                )
              }
              onClick={() => setType(EntryType.Graph)}
            >
              Graph
            </Menu.Item>
            <Menu.Label>Operations</Menu.Label>
            <Menu.Item
              onClick={remove}
              icon={<FiTrash2 color={theme.colors.gray[6]} />}
            >
              Delete Variable
            </Menu.Item>
          </Menu.Dropdown>
        </Portal>
      </Menu>
    </Box>
  );
};
