import { Box, Menu, useMantineTheme } from '@mantine/core';
import { Options } from '@morph-mapper/ui';
import { TbSquareCheckFilled } from 'react-icons/tb';
import { useParameterRowContext } from '../context';
import { OptionType } from '@morph-mapper/types';

export const ParameterMenu = () => {
  const theme = useMantineTheme();
  const [type, setType] = useParameterRowContext((s) => [
    s.getType(),
    s.setType,
  ]);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Menu shadow="md" width={200}>
        <Menu.Target>
          <Options />
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Label>Configure parameter</Menu.Label>
          <Menu.Item
            icon={
              type === OptionType.UserDefined && (
                <TbSquareCheckFilled color={theme.colors.blue[7]} size={15} />
              )
            }
            onClick={() => setType(OptionType.UserDefined)}
          >
            Self defined
          </Menu.Item>
          <Menu.Item
            icon={
              type === OptionType.Reference && (
                <TbSquareCheckFilled color={theme.colors.blue[7]} size={15} />
              )
            }
            onClick={() => setType(OptionType.Reference)}
          >
            Variable Reference
          </Menu.Item>
          <Menu.Item
            icon={
              type === OptionType.Dependency && (
                <TbSquareCheckFilled color={theme.colors.blue[7]} size={15} />
              )
            }
            onClick={() => setType(OptionType.Dependency)}
          >
            Graph dependenccy
          </Menu.Item>
          <Menu.Label>Operations</Menu.Label>
          <Menu.Item>Delete parameter</Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </Box>
  );
};
