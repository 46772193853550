import styled from "@emotion/styled";

export const StyledComponent = styled.div(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: theme.spacing.sm,
  border: `1px solid ${theme.colors.gray[3]}`,
  borderRadius: theme.radius.sm,
  backgroundColor: "white",
  ":hover": {
    backgroundColor: theme.colors.gray[0],
  },
}));
