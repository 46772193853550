import { IconType } from 'react-icons';
import { STRUCTURE_BLOCKS_RULESET, STRUCTURE_CATEGORIES } from '../logic';

export type StructureBlockConfig<K> = {
  title: string;
  description: string;
  category: keyof typeof STRUCTURE_CATEGORIES | 'internal';
  type: K;
  icon: IconType;
  // TODO: make the given parameters dynamic
  /* The render function wraps descendant tree nodes in dynamic rendered template code,
   * the return value is a tuple of a pointer array and a template object.
   *The elements in the pointer array represent the path to the descendant tree nodes.
   */
  render: (key: string) => [pointer: string[], template: any];
};

export type StructureBlockRecord<T extends Record<keyof T, any>> = {
  [K in keyof T]: StructureBlockConfig<K>;
};

export const typeStructureBlockRecord = <T extends Record<keyof T, any>>(
  map: StructureBlockRecord<T>
) => map;

export type StructureBlockIndex =
  keyof (typeof STRUCTURE_BLOCKS_RULESET)[keyof typeof STRUCTURE_BLOCKS_RULESET];
