import styled from "@emotion/styled";
import { Box, BoxProps, createPolymorphicComponent } from "@mantine/core";
import { BaseHeader } from "@morph-mapper/ui";

export const XmlViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const _XmlViewContent = styled(Box)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 0px;
  overflow: auto;
`;

export const XmlViewContent = createPolymorphicComponent<"div", BoxProps>(
  _XmlViewContent
);

export const XmlViewHeader = styled(BaseHeader)`
  justify-content: center;
`;
