import { Connect, ConfigInput, ConfigLabel } from '@morph-mapper/ui';
import { SectionContainer, SectionWrapper } from './styles';
import { Group, Select } from '@mantine/core';
import { PiBracketsCurlyBold } from 'react-icons/pi';
import { useConfigRow } from './use-config-row';
import { useNodeContext } from '../../context';
import { isBoolean } from '@/types';
import { OptionConditions } from '@morph-mapper/node-logic';
import { OptionType } from '@morph-mapper/types';
import { OptionMenu } from '../../molecules/option-menu';
import { type Input, Inputs } from '@morph-mapper/node-inputs';
import { VariableItem } from '../../molecules';
import { getEntries } from '@morph-mapper/utils';

interface ConfigRowProps {
  title: string;
  description: string;
  optionName: string;
  conditions: OptionConditions;
  input: Input;
}

export const ConfigRow = ({
  optionName,
  conditions,
  input,
  ...props
}: ConfigRowProps) => {
  const [optionValue, optionType, dependencies, selection, getOptionValue] =
    useNodeContext((s) => [
      s.getOptionValue(optionName),
      s.getOptionType(optionName),
      s.getDependencies(),
      s.getOptionSelection(optionName),
      s.getOptionValue,
    ]);
  const { handleChange, handleDependency, availableVariables, setToDefault } =
    useConfigRow(optionName);

  const Input = Inputs[input];

  /**
   * This function checks the preconditions for rendering an option to the UI.
   *
   * @param conditions contains fields of preconditions to check (not entire object)
   * @returns boolean value indicating if the preconditions hold
   */
  const validPreConditions = () => {
    const { hasValue, isHidden } = conditions;

    if (isHidden) return false;

    if (hasValue) {
      const result = getEntries(hasValue).every(([key, value]) => {
        const nodeOptionValue = getOptionValue(key);
        return nodeOptionValue === value;
      });

      if (!result) {
        setToDefault();
        return false;
      }
    }
    return true;
  };

  if (!validPreConditions()) return null;

  if (conditions.isFullWidth) {
    return (
      <SectionWrapper>
        <SectionContainer>
          <Group sx={{ justifyContent: 'space-between' }}>
            <ConfigLabel {...props} fullWidth />
            <Group>
              {optionType === OptionType.Reference && (
                <Select
                  itemComponent={VariableItem}
                  onChange={handleChange}
                  searchable
                  placeholder="Select variable"
                  nothingFound="No variable found"
                  icon={<PiBracketsCurlyBold />}
                  data={availableVariables}
                />
              )}
              <OptionMenu name={optionName} />
            </Group>
          </Group>
          {optionType === OptionType.UserDefined && (
            <Input
              value={optionValue}
              handleChange={handleChange}
              selection={selection}
            />
          )}
        </SectionContainer>
      </SectionWrapper>
    );
  }

  // Dependencies can be nested in the case of parameter tables,
  // these are not rendered using a config row of this type. Return an error when trying.
  const optionDependencies = dependencies[optionName];
  if (optionDependencies !== undefined && !isBoolean(optionDependencies)) {
    throw 'Dependency cannot be rendered using this component if it is nested.';
  }

  return (
    <SectionWrapper>
      <ConfigLabel {...props} />
      <Group>
        {optionType === OptionType.Dependency && (
          <ConfigInput>
            <Connect onClick={handleDependency} disabled={optionDependencies} />
          </ConfigInput>
        )}
        {optionType === OptionType.UserDefined && (
          <ConfigInput>
            <Input
              value={optionValue}
              handleChange={handleChange}
              selection={selection}
            />
          </ConfigInput>
        )}
        {optionType === OptionType.Reference && (
          <Select
            sx={{ width: 300 }}
            itemComponent={VariableItem}
            value={optionValue}
            onChange={handleChange}
            searchable
            placeholder="Select variable"
            nothingFound="No variable found"
            icon={<PiBracketsCurlyBold />}
            data={availableVariables}
          />
        )}
        {!conditions.isStatic && <OptionMenu name={optionName} />}
      </Group>
    </SectionWrapper>
  );
};
