import { Box, TextInput } from '@mantine/core';
import { useTextSelector } from './use-text-selector';
import type { InputProps } from '../../types';
import { Clipboard, Reset } from '@morph-mapper/ui';

export const TextSelector = ({ value, handleChange }: InputProps<string>) => {
  const { handleSetPath, handleClearPath, handleInputChange } =
    useTextSelector(handleChange);

  const inputValue = value ?? '';

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Clipboard size={'lg'} mr={'md'} onClick={handleSetPath} />
      {inputValue !== '' && (
        <Reset
          size={'lg'}
          mr={'md'}
          onClick={handleClearPath}
          variant="default"
        />
      )}
      <TextInput
        value={inputValue}
        onChange={handleInputChange}
        sx={{ flexGrow: 1 }}
      />
    </Box>
  );
};
