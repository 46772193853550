import { Button, Menu, useMantineTheme } from '@mantine/core';
import { PiDotsSixVerticalBold, PiFunction } from 'react-icons/pi';
import { RiBracketsFill } from 'react-icons/ri';
import { FiTrash2 } from 'react-icons/fi';
import { XmlOperator, XmlSelector } from '../../types';
import { TbSettings } from 'react-icons/tb';
import { match } from 'ts-pattern';
import { forwardRef } from 'react';
import { useStore } from '../../context';

interface SegmentProps {
  id: string;
}

export const Segment = forwardRef<HTMLButtonElement, SegmentProps>(
  ({ id }, ref) => {
    const [{ type, value }, removeReference, setActiveReference, setModal] =
      useStore(({ path: p, ui }) => [
        p.getReference(id),
        p.removeReference,
        p.setActiveReference,
        ui.setConfigRefModal,
      ]);
    const theme = useMantineTheme();

    // TODO: generalize
    const renderLeftIcon = () =>
      match(type)
        .with(XmlSelector.Array, () => <RiBracketsFill />)
        .with(
          XmlOperator.Array,
          XmlOperator.Value,
          XmlOperator.Attribute,
          () => <PiFunction />
        )
        .otherwise(() => null);

    const handleConfigure = () => {
      setActiveReference(id);
      setModal(true);
    };

    const handleDelete = () => {
      removeReference(id);
    };

    return (
      <Menu trigger="hover" shadow="sm" withArrow closeDelay={30} withinPortal>
        <Menu.Target>
          <Button
            ref={ref}
            sx={{ display: 'inline-block' }}
            variant="default"
            leftIcon={renderLeftIcon()}
            rightIcon={<PiDotsSixVerticalBold />}
          >
            {value}
          </Button>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item
            icon={<FiTrash2 color={theme.colors.gray[6]} />}
            onClick={handleDelete}
          >
            Delete
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item icon={<TbSettings />} onClick={handleConfigure}>
            Configure
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    );
  }
);
