import { SchemaVariant } from '@morph-mapper/types';
import { JsonSelector, SelectorConfigRecord, XmlSelector } from '../types';

export const selectorConfig: SelectorConfigRecord = {
  [SchemaVariant.Pdf]: {},
  [SchemaVariant.Table]: {},
  [SchemaVariant.Xml]: {
    [XmlSelector.Base]: {
      displayName: 'Base',
    },
    [XmlSelector.Array]: {
      displayName: 'Array',
    },
  },
  [SchemaVariant.Text]: {},
  [SchemaVariant.Email]: {},
  [SchemaVariant.Json]: {
    [JsonSelector.Base]: {
      displayName: 'Base',
    },
    [JsonSelector.Array]: {
      displayName: 'Array',
    },
  },
};
