import { nanoid } from 'nanoid';
import { useStore } from '../../context';
import { JsonSelector, Reference } from '../../types';

/**
 * Custom hook for creating reference paths in the JSON viewer.
 */
export const useJsonPath = () => {
  const setPath = useStore(({ path: p }) => p.setAbsolutePath);

  const createPath = (keys: (string | number)[]) => {
    const ids = [];
    const references: Omit<Reference, 'next' | 'prev'>[] = [];

    for (let i = 0; i < keys.length; i++) {
      // If the key is a number, it is an array index.
      // We need to update the last reference to be an array reference.
      if (typeof keys[i] === 'number') {
        references[references.length - 1] = {
          ...references[references.length - 1],
          type: JsonSelector.Array,
          operation: keys[i],
        };

        continue;
      }

      references.push({
        type: JsonSelector.Base,
        value: keys[i].toString(),
        operation: null,
      });

      ids.push(nanoid());
    }

    setPath(references, ids);
  };

  return {
    createPath,
  };
};
