import {
  INode,
  IAttribute,
  IValue,
  InteractFn,
} from 'react-interactive-xml-viewer';
import { useStore } from '../../context';
import { Reference, XmlOperator, XmlSelector } from '../../types';

/**
 * Custom hook for creating reference paths in the XML viewer.
 */
export const useXmlPath = () => {
  const setPath = useStore(({ path: p }) => p.setAbsolutePath);

  /**
   * Creates a reference path when the user has clicked on a tag.
   */
  const createTagPath: InteractFn<INode> = (e, node) => {
    const ids = [];
    const references: Omit<Reference, 'next' | 'prev'>[] = [];

    let parent = node.getParent();
    while (parent !== null) {
      references.push({
        type: XmlSelector.Base,
        value: parent.getName(),
        operation: null,
      });
      ids.push(parent.getId());

      parent = parent.getParent();
    }

    const tagRef = {
      type: XmlSelector.Base,
      value: node.getName(),
      operation: null,
    };

    setPath(
      references.reverse().concat(tagRef),
      ids.reverse().concat(node.getId())
    );
  };

  /**
   * Creates a reference path when the user has clicked on an attribute.
   */
  const createAttributePath: InteractFn<IAttribute> = (e, attr) => {
    // We stop propagation otherwise the tag path will override the attribute path.
    e.stopPropagation();

    const ids = [];
    const references: Omit<Reference, 'next' | 'prev'>[] = [];

    const node = attr.getNode();
    const attrRef = {
      type: XmlOperator.Attribute,
      value: node.getName(),
      operation: `_attrs.${attr.getKey()}`,
    };

    let parent = node.getParent();
    while (parent !== null) {
      references.push({
        type: XmlSelector.Base,
        value: parent.getName(),
        operation: null,
      });
      ids.push(parent.getId());

      parent = parent.getParent();
    }

    setPath(
      [...references.reverse(), attrRef],
      [...ids.reverse(), node.getId()]
    );
  };

  /**
   * Creates a reference path when the user has clicked on a value between tags.
   */
  const createValuePath: InteractFn<IValue> = (e, value) => {
    const ids = [];
    const references: Omit<Reference, 'next' | 'prev'>[] = [];

    const node: INode | null = value.getNode();
    const valueRef = {
      type: XmlOperator.Value,
      value: node.getName(),
      operation: '@',
    };

    let parent = node.getParent();
    while (parent !== null) {
      references.push({
        type: XmlSelector.Base,
        value: parent.getName(),
        operation: null,
      });
      ids.push(parent.getId());

      parent = parent.getParent();
    }

    setPath(
      [...references.reverse(), valueRef],
      [...ids.reverse(), node.getId()]
    );
  };

  return {
    createTagPath,
    createAttributePath,
    createValuePath,
  };
};
