import { useStore } from '@/store';
import Queue from 'queue-fifo';

export const useDependencies = () => {
  const entry = useStore(({ entries: e }) => e.entry);

  const retrieveDependencies = (entryId: string) => {
    const queue = new Queue<string>();
    queue.enqueue(entryId);

    const found = new Set<string>();

    while (!queue.isEmpty()) {
      const entryId = queue.dequeue()!;

      entry(entryId)
        .getDependsOn()
        .forEach((dependencyId) => {
          if (found.has(dependencyId)) {
            return;
          }

          found.add(dependencyId);
          queue.enqueue(dependencyId);
        });
    }

    return Array.from(found);
  };

  return {
    retrieveDependencies,
  };
};
