import { MultiSelect as MantineMultiSelect } from '@mantine/core';
import { InputProps } from '../../types';

export const MultiSelect = ({
  value,
  handleChange,
  selection,
  placeholder = 'Select multiple values',
}: InputProps<string[]>) => {
  return (
    <MantineMultiSelect
      searchable
      creatable
      clearable
      data={selection}
      value={value ?? []}
      onChange={handleChange}
      placeholder={placeholder}
    />
  );
};
