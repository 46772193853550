import styled from "@emotion/styled";

export const StyledVariableRow = styled("tr")`
  height: 60px;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
`;
