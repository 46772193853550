import { SchemaVariant } from '@morph-mapper/types';
import { SchemaType } from '../../types';
import {
  interModalDefaultExportSchema,
  interModalEmailExportSchema,
  interModalTableExportSchema,
  interModalTextExportSchema,
} from './export';
import {
  interModalTableImportSchema,
  interModalDefaultImportSchema,
  interModalEmailImportSchema,
  interModalTextImportSchema,
} from './import';

export const types = {
  [SchemaType.Export]: {
    [SchemaVariant.Pdf]: interModalDefaultExportSchema,
    [SchemaVariant.Table]: interModalTableExportSchema,
    // TODO: add xml export schema
    [SchemaVariant.Xml]: interModalTableExportSchema,
    [SchemaVariant.Email]: interModalEmailExportSchema,
    [SchemaVariant.Text]: interModalTextExportSchema,
  },
  [SchemaType.Import]: {
    [SchemaVariant.Pdf]: interModalDefaultImportSchema,
    [SchemaVariant.Table]: interModalTableImportSchema,
    // TODO: add xml import schema
    [SchemaVariant.Xml]: interModalTableImportSchema,
    [SchemaVariant.Email]: interModalEmailImportSchema,
    [SchemaVariant.Text]: interModalTextImportSchema,
  },
};
