import styled from "@emotion/styled";
import { Box, BoxProps, createPolymorphicComponent } from "@mantine/core";
import { BaseHeader } from "@morph-mapper/ui";

export const FileViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const FileViewHeader = styled(BaseHeader)`
  justify-content: center;
`;

const _FileViewContent = styled(Box)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 0px;
  overflow: auto;
`;

export const FileViewContent = createPolymorphicComponent<"div", BoxProps>(
  _FileViewContent
);
