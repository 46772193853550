import { Cancel, Complete } from "@morph-mapper/ui";
import { Text, Group, useMantineTheme, TextInput } from "@mantine/core";
import { useCreateParameter } from "./use-create-parameter";
import { ContentWrapper } from "./styles";
import { OptionEntry } from "@morph-mapper/types";

interface CreateParameterProps {
  value: Record<string, OptionEntry<any>>;
  handleChange: (value: Record<string, OptionEntry<any>>) => void;
}

export const CreateParameter = (props: CreateParameterProps) => {
  const theme = useMantineTheme();
  const { edit, name, handleNew, handleSetName, handleCreate, handleCancel } =
    useCreateParameter(props);

  if (edit) {
    return (
      <ContentWrapper>
        <Group>
          <TextInput
            value={name}
            onChange={handleSetName}
            placeholder="Parameter name"
          />
          <Complete onClick={handleCreate} />
          <Cancel onClick={handleCancel} />
        </Group>
      </ContentWrapper>
    );
  }

  return (
    <ContentWrapper onClick={handleNew}>
      <Group>
        <Text color={theme.colors.gray[6]}>Add new parameter</Text>
      </Group>
    </ContentWrapper>
  );
};
