import { useLocation, useParams } from 'react-router-dom';
import { GraphProvider } from '../../context';
import { SelectorView } from '../../organisms';
import { useStore } from '@/store';

export const NodeCreator = () => {
  const { graphId } = useParams();
  const location = useLocation();
  const state = location.state;

  if (!graphId) throw new Error('No active graph');

  const getGraph = useStore(({ graphs: g }) => g.get);
  const graph = getGraph(graphId);

  return (
    <GraphProvider {...graph}>
      <SelectorView state={state} />
    </GraphProvider>
  );
};
