import { z } from 'zod';

export enum SchemaVariant {
  Pdf = 'pdf',
  Table = 'xls/csv',
  Xml = 'xml',
  Text = 'text',
  Email = 'email',
  Json = 'json',
}

export enum SchemaSource {
  Email = 'email',
  File = 'file',
  Http = 'http',
}

export enum EntryType {
  Boolean = 'boolean',
  Simple = 'simple',
  Graph = 'graph',
  Internal = 'internal',
  Cell = 'cell',
  Map = 'map',
}

export enum ReservedNodeType {
  Data = '$data',
  DeclareVariable = '$declareVariable',
  Iterator = '#iterator',
}

// The structure stores: [key, mappedReturnObject, wrapVariable]
export const reservedWrapVariables = new Map<string, [object, string]>([
  ['$xlsColumn', [{ '$data.val': [] }, '$genericXls.findVal']],
]);

export type EntryItemType = Exclude<EntryType, EntryType.Map>;

export const isZodEnum = (schema: any): schema is z.ZodEnum<any> => {
  if (!schema) return false;
  return schema instanceof z.ZodEnum;
};
