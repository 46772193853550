import { SchemaVariant } from '@morph-mapper/types';
import { OperatorConfigRecord, XmlOperator } from '../types';

export const operatorConfig: OperatorConfigRecord = {
  [SchemaVariant.Pdf]: {},
  [SchemaVariant.Table]: {},
  [SchemaVariant.Xml]: {
    [XmlOperator.Value]: {
      displayName: 'Value',
      value: '@',
    },
    [XmlOperator.Attribute]: {
      displayName: 'Attribute',
      value: '_attrs',
    },
    [XmlOperator.Array]: {
      displayName: 'Array',
      value: '@array',
    },
  },
  [SchemaVariant.Text]: {},
  [SchemaVariant.Email]: {},
  [SchemaVariant.Json]: {},
};
