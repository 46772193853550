import { Title } from '@mantine/core';
import { FileDrop } from '../../molecules';
import { useFileSelect } from './use-file-select';
import {
  Container,
  ContentWrapper,
  InputWrapper,
  StyledFileInput,
} from './styles';

export interface FileSelectProps {
  children: (progress: () => void, disabled?: boolean) => JSX.Element;
}

export const FileSelect = ({ children }: FileSelectProps) => {
  const {
    file,
    setFile,
    progressSetup,
    disableNext,
    getAcceptedFileExtensions,
    getAcceptedFileExtensionsDisplay,
  } = useFileSelect();

  return (
    <>
      <Container>
        <ContentWrapper>
          <Title order={3} fw={500}>
            Select an example file for parsing
          </Title>

          <InputWrapper>
            {file ? (
              <StyledFileInput
                label="Selected file"
                accept={getAcceptedFileExtensions().join()}
                value={file}
                withAsterisk
              />
            ) : (
              <FileDrop
                fileExtenstions={getAcceptedFileExtensions()}
                description={`Only ${getAcceptedFileExtensionsDisplay().join(
                  ', '
                )} files are supported.`}
                load={setFile}
              />
            )}
          </InputWrapper>
        </ContentWrapper>
      </Container>

      {children(progressSetup, disableNext)}
    </>
  );
};
