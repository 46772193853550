import { LayoutDirection } from '@/types';
import { StateCreator } from 'zustand';
import { createLens } from '@dhmk/zustand-lens';

/**
 * Slice interface for UI state.
 */
export interface UISlice {
  /**
   * Indicates the layout direction of the shown panels.
   */
  layoutDirection: LayoutDirection;

  /**
   * Sets the layout direction.
   */
  setLayoutDirection: (layoutDirection: LayoutDirection) => void;
}

/**
 * Slice for UI state.
 */
export const createUISlice: StateCreator<
  { ui: UISlice },
  [['zustand/immer', never]],
  [],
  UISlice
> = (_set, _get) => {
  // Slices make use of a namespace, make current slice local namespace.
  const [set, get] = createLens(_set, _get, ['ui']);

  return {
    layoutDirection: LayoutDirection.Row,

    setLayoutDirection: (layoutDirection) => {
      set(() => ({ layoutDirection }));
    },
  };
};
