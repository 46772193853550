import { Input } from "@mantine/core";
import { Layout, StyledBadge } from "./styles";

interface ArraySelectorDisplayProps {
  label: string;
  value: string;
  count: string | number;
}

/**
 * Renders a reference with a badge that shows the currently selected occurence.
 */
export const ArraySelectorDisplay = ({
  label,
  value,
  count,
}: ArraySelectorDisplayProps) => {
  return (
    <Input.Wrapper label={label}>
      <Layout>
        <Input value={value} readOnly sx={{ flexGrow: 1 }} />
        <StyledBadge color="gray" variant="dot" radius="sm" size="lg">
          {count}
        </StyledBadge>
      </Layout>
    </Input.Wrapper>
  );
};
