import { Accordion } from '@mantine/core';
import { BlockItem } from '../block-item';
import { StyledList } from './styles';
import { useStore } from '@/store';
// TODO: Import should be behind interface?
import {} from '@morph-mapper/node-logic';
import { useLogicBlocks, LOGIC_CATEGORIES } from '@morph-mapper/node-logic';

interface SidebarItemProps {
  category: keyof typeof LOGIC_CATEGORIES;
}

export const AccordionItem = ({ category }: SidebarItemProps) => {
  const variant = useStore(({ config: c }) => c.getVariant());
  const { logicBlocksByCategory } = useLogicBlocks(variant);
  const title = LOGIC_CATEGORIES[category];

  return (
    <Accordion.Item value={category}>
      <Accordion.Control>{title}</Accordion.Control>
      <Accordion.Panel>
        <StyledList>
          {Object.values(logicBlocksByCategory[category]).map(({ type }) => (
            <BlockItem type={type} key={type} />
          ))}
        </StyledList>
      </Accordion.Panel>
    </Accordion.Item>
  );
};
