import { SideBarContent, SideBarWrapper } from './styles';
import { HeaderConfigDef } from './header';
import { SectionLayout } from '@/common';
import { useSchemaConfig, useSchemaEditor } from '@/hooks';
import { ConfigurationOption } from './configuration-option';
import { DocumentationModal } from '../documentation-modal';

export const SchemaEditor = () => {
  const { getIdsForConfiguration, getKeyById, getConfigurationByKey } =
    useSchemaConfig();
  const { isValidConfiguration } = useSchemaEditor();

  return (
    <SideBarWrapper>
      <HeaderConfigDef />
      <SideBarContent>
        {getIdsForConfiguration()
          .filter(isValidConfiguration)
          .map((id) => {
            const key = getKeyById(id);
            const { title, description, hidden } = getConfigurationByKey(key);

            if (hidden) return null;

            if (title) {
              return (
                <SectionLayout
                  title={title}
                  description={description}
                  key={key}
                >
                  <ConfigurationOption id={id} />
                </SectionLayout>
              );
            }

            return <ConfigurationOption id={id} key={key} />;
          })}
      </SideBarContent>
      <DocumentationModal />
    </SideBarWrapper>
  );
};
