import styled from "@emotion/styled";

export const IconWrapper = styled.div(({ theme }) => ({
  width: 17,
  height: 17,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  background: theme.colors.gray[3],
  borderRadius: "50%",
}));
