import { ActionCell } from '../action-cell';
import { useRowContext } from '../../context';
import { ComputedCell, ConfirmCell, EditCell } from '@morph-mapper/ui';
import { RowMenu } from '../row-menu';
import { ActionsWrapper, StyledVariableRow } from './styles';

export const Row = () => {
  const [
    name,
    tempValue,
    computed,
    isConfirm,
    confirmClear,
    cancelClear,
    isEditing,
    setTempValue,
    validation,
  ] = useRowContext((s) => [
    s.getName(),
    s.tempValue,
    s.getComputed(),
    s.isConfirm,
    s.confirmClear,
    s.cancelClear,
    s.isEditing,
    s.setTempValue,
    s.getValidation(),
  ]);

  const pickCell = () => {
    if (isConfirm)
      return (
        <ConfirmCell handleAccept={confirmClear} handleDeny={cancelClear} />
      );
    if (isEditing)
      return (
        <EditCell
          handleChange={setTempValue}
          value={tempValue}
          validation={validation}
        />
      );
    return <ComputedCell value={computed} />;
  };

  return (
    <StyledVariableRow>
      <td>{name}</td>
      <td>{pickCell()}</td>
      <td>
        <ActionsWrapper>
          <ActionCell />
          <RowMenu />
        </ActionsWrapper>
      </td>
    </StyledVariableRow>
  );
};
