import { useUpdateDeployTemplateSave } from '@/services';
import { useStore } from '@/store';
import { useEffect } from 'react';
import { useNotify, useRenderTemplate } from '@/hooks';

export const useDeployTemplate = () => {
  /* Global state */
  const [operatorId, id, name, domain, type, variant] = useStore(
    ({ config: c }) => [
      c.getOperatorId(),
      c.getId(),
      c.getName(),
      c.getDomain(),
      c.getType(),
      c.getVariant(),
    ]
  );
  /* Hooks */
  const { renderTemplate } = useRenderTemplate();

  /* Queries */
  const {
    mutate: deployTemplateSave,
    isSuccess: deploySuccess,
    isError: deployError,
  } = useUpdateDeployTemplateSave();

  const { success, error } = useNotify();

  // TODO: maybe we want to make this available within the queries as a middleware instead.
  useEffect(() => {
    if (deploySuccess) {
      success('Template has been deployed.');
    }
    if (deployError) {
      error('Failed to deploy template.');
    }
  }, [deploySuccess, deployError]);

  /**
   * Handlers
   */
  const deployTemplate = () => {
    const template = renderTemplate();
    const file = JSON.stringify(template, null, 4);

    deployTemplateSave({
      refId: id,
      operatorId,
      name,
      domain,
      type,
      variant,
      file,
    });
  };

  return { deployTemplate };
};
