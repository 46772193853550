import styled from '@emotion/styled';
import { LayoutDirection } from '@/types';

export const AppWrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

interface WorkspaceWrapperProps {
  direction: LayoutDirection;
}

export const WorkspaceWrapper = styled.div<WorkspaceWrapperProps>(
  ({ direction }) => ({
    height: '100%',
    display: 'flex',
    width: '100%',
    flexDirection: direction,
  })
);

interface WorkspaceProps {
  width?: number;
}

export const Workspace = styled.div<WorkspaceProps>(({ theme, width }) => ({
  position: 'relative',
  display: 'flex',
  flexGrow: 1,
  flexDirection: 'column',
  width: width ?? '100%',
  overflow: 'hidden',
  borderLeft: `1px solid ${theme.colors.gray[2]}`,
  boxShadow: `0 0 1em ${theme.colors.gray[1]}`,
  backgroundColor: 'white',
}));
