import styled from "@emotion/styled";
import { ScrollArea } from "@mantine/core";

export const StyledScrollArea = styled(ScrollArea)(({ theme }) => ({
  scrollbarWidth: "thin",
  border: "none", 
  backgroundColor: "transparent", 
  borderRadius: theme.radius.sm,
}));

export const Container = styled.div({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
});

export const ContentWrapper = styled.div({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
});
