import { ReactFlowProvider } from 'reactflow';
import { AppWrapper, Workspace, WorkspaceWrapper } from './styles';
import { FileProvider, FileView } from '@morph-mapper/file-viewer';
import { Outlet } from 'react-router-dom';
import { useStore } from '@/store';
import { Header } from '../../molecules';
import { useGlobalEntryListener } from '@/hooks';
import { NavigateModal } from '../navigate-modal';

export const Overview = () => {
  const [direction, textContent, variant, file, text] = useStore(
    ({ ui, config: c }) => [
      ui.layoutDirection,
      c.textContent,
      c.getVariant(),
      c.getFile(),
      c.getTextContent(),
    ]
  );

  useGlobalEntryListener();

  return (
    <FileProvider file={file} variant={variant} textContent={text}>
      <AppWrapper>
        <Header />
        <NavigateModal />
        <WorkspaceWrapper direction={direction}>
          <Workspace>
            <FileView />
          </Workspace>
          <Workspace>
            <ReactFlowProvider>
              {textContent ? <Outlet /> : null}
            </ReactFlowProvider>
          </Workspace>
        </WorkspaceWrapper>
      </AppWrapper>
    </FileProvider>
  );
};

export default Overview;
