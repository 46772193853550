import { useStore } from '@/store';
import { useSchemaConfig } from './use-schema-config';
import { getEntries, getValues } from '@morph-mapper/utils';

// TODO: Discarding may lead to weird behaviour, the field removed may still be required elsewhere in the schema.
// Make some additional checks to reduce the likelihood of this happening. (Do not remove fields which do not generate an output for example)

// TODO: We currently check for every entry if it should be rendered or changed, instead we should calculate the relevant entries once. (Maybe with a tag or override?)
export const useSchemaEditor = () => {
  const [getByKey, discardFieldsById, preconfig] = useStore(
    ({ entries: e, config: c }) => [
      e.getByKey,
      c.getDiscardFields(),
      c.getPreconfiguration(),
    ]
  );
  const { getConfigurationByKey, getOptionByKey, getOptionValue } = useSchemaConfig();
  const mergeType = getByKey('mergingType')[0].getTemplateValue();

  const shouldDiscardWhenUpdate = (id: string) => {
    if (mergeType !== 'extend') {
      return false;
    }
    if (discardFieldsById.find((fieldId) => fieldId === id)) {
      return true;
    }

    return false;
  };

  const isValidConfiguration = (id: string) => {
    if (preconfig[id] === undefined) {
      return true;
    }

    const configKey = preconfig[id].key;
    const { conditions } = getConfigurationByKey(configKey);

    if (conditions === undefined) {
      return true;
    }

    for (const [configKey, options] of getEntries(conditions)) {
      if (options === undefined) {
        break;
      }

      for (const [optionKey, optionValue] of getEntries(options)) {
        // Get from the configKey the value of the optionKey and compare it with the optionValue.
        const idsWithKey = getValues(preconfig).filter(
          (config) => config.key === configKey
        );

        const succeeded = idsWithKey.every((config) => {
          const hasValue = optionValue.hasValue;
          if (hasValue === undefined) {
            return true;
          }

          return config.options[optionKey] === hasValue;
        });

        if (!succeeded) {
          return false;
        }
      }
    }

    return true;
  };

  const isValidOption = (id: string, optionKey: string): boolean => {
    const { conditions } = getOptionByKey(id, optionKey);
    if (!conditions) {
      return true; 
    }
  
    for (const [conditionKey, conditionValue] of Object.entries(conditions)) {
      if (!conditionValue) continue; 
  
      const currentValue = getOptionValue(id, conditionKey);
      const expectedValue = conditionValue.hasValue; 
  
      if (expectedValue !== undefined && currentValue !== expectedValue) {
        return false;
      }
    }
  
    return true; 
  };
  

  const shouldRenderField = (id: string) => {
    if (shouldDiscardWhenUpdate(id)) {
      return false;
    }
    if (!isValidConfiguration(id)) {
      return false;
    }

    return true;
  };

  return { shouldRenderField, isValidConfiguration, isValidOption };
};
