import { Box, Title } from '@mantine/core';
import { Container, ContentWrapper, StyledScrollArea } from './styles';
import { useDomainSelect } from './use-domain-select';
import { DomainItem } from '../../molecules';
import { useElementSize } from '@mantine/hooks';
import { useSchema } from '@morph-mapper/schemas';
import { useStore } from '@/store';
import { useEffect } from 'react';
import { useNotify } from '@/hooks';

export interface DomainSelectProps {
  children: (progress: () => void, disabled?: boolean) => JSX.Element;
}

export const DomainSelect = ({ children }: DomainSelectProps) => {
  const [domainAccess, definedCustomSchemes] = useStore(({ config: c }) => [
    c.domainAccess,
    c.definedCustomSchemes,
  ]);
  const { progressSetup } = useDomainSelect();
  const { ref, height } = useElementSize();
  const { getSchemaDomains, getWarnings } = useSchema(
    domainAccess,
    definedCustomSchemes
  );
  const { warn } = useNotify();

  const warnings = getWarnings();

  useEffect(() => {
    if (warnings.length === 0) return;
    warnings.forEach((warning) => {
      warn(warning);
    });
  }, [warnings]);

  if (getSchemaDomains().length === 0) {
    // TODO(D): fallback UI?
    return <div>No domains available for the current operator.</div>;
  }

  return (
    <>
      <Container>
        <ContentWrapper>
          <Title order={3} fw={500}>
            Select a domain
          </Title>

          <Box sx={{ flex: 1 }} ref={ref}>
            <StyledScrollArea h={height - 100}>
              {getSchemaDomains().map((domain) => (
                <DomainItem key={domain} domain={domain} />
              ))}
            </StyledScrollArea>
          </Box>
        </ContentWrapper>
      </Container>

      {children(progressSetup)}
    </>
  );
};
