import { hasProperty } from '@morph-mapper/utils';

export enum HandleType {
  Template = 'template',
  Options = 'options',
}

export type Handle = {
  id: string;
  type: HandleType;
};

export enum GraphOperation {
  Replace = 'replace',
  Insert = 'insert',
  Add = 'add',
  Dependency = 'add_dependency',
}

export enum OptionType {
  UserDefined = 'user-defined',
  Reference = 'reference',
  Dependency = 'dependency',
}

export type OptionEntry<T> = {
  type: OptionType;
  value: T;
};

export type OptionEntryMap = {
  type: OptionType;
  value: Record<string, OptionEntry<any>>;
};

export type NodeOptionsWrapper<T> = {
  [key in keyof T]: OptionEntry<any> | OptionEntryMap;
};

export type NodeGraphData = {
  dependencies: Record<string, boolean | Record<string, boolean>>;
  handles: {
    source: Handle[];
    target: Handle[];
  };
};

export type NodeData<T> = {
  graph: NodeGraphData;
  logic: {
    options: NodeOptionsWrapper<T>;
  };
};

/**
 * Type guards
 */

export const isOptionEntry = (
  entry: any,
  isDependency?: any
): entry is OptionEntry<any> => {
  if (entry === undefined) return false;

  // If not an object the following checks will fail
  if (typeof entry !== 'object') {
    return false;
  }

  // Dependency entries do not have a value (e.g. skip and columns)
  if (isDependency === true) {
    return hasProperty(entry, 'type');
  }

  return hasProperty(entry, 'type') && hasProperty(entry, 'value');
};

export const isOptionEntryMap = (option: any): option is OptionEntryMap => {
  if (option === undefined) return false;
  // If not an object the following checks will fail
  if (typeof option !== 'object') {
    return false;
  }
  // Arrays are also objects, so we need to exclude them when the value is an object
  if (typeof option.value !== 'object' || Array.isArray(option.value)) {
    return false;
  }

  return hasProperty(option, 'type') && hasProperty(option, 'value');
};
