import { Tabs, Text } from "@mantine/core";
import { SideBarWrapper, SideBarContent, TabsListHeader } from "./styles";
import { LuIterationCw } from "react-icons/lu";
import { PiBracketsCurlyBold } from "react-icons/pi";
import { EnvironmentPanel, Header, IteratorPanel } from "../../molecules";

export const ConfigureView = () => {
  return (
    <SideBarWrapper>
      <Header />
      <Tabs h={"100%"} defaultValue="env-variables">
        <TabsListHeader grow>
          <Tabs.Tab value="env-variables" icon={<PiBracketsCurlyBold />}>
            <Text>Environment Variables</Text>
          </Tabs.Tab>
          <Tabs.Tab value="iterators" icon={<LuIterationCw />}>
            <Text>Iterators</Text>
          </Tabs.Tab>
        </TabsListHeader>

        <SideBarContent>
          <Tabs.Panel h={"85%"} value="env-variables" pt="xs">
            <EnvironmentPanel />
          </Tabs.Panel>
          <Tabs.Panel h={"85%"} value="iterators" pt="xs">
            <IteratorPanel />
          </Tabs.Panel>
        </SideBarContent>
      </Tabs>
    </SideBarWrapper>
  );
};
