import { getEntries, getKeys } from '@morph-mapper/utils';
import { operatorConfig } from '../config';
import { Extractor, Path, Reference, XmlOperator, XmlSelector } from '../types';

import { match, P } from 'ts-pattern';
import { SchemaVariant } from '@morph-mapper/types';

const verifyIsOperator = (
  value: unknown,
  variant: SchemaVariant
): Extractor | undefined => {
  return match(variant)
    .with(SchemaVariant.Xml, (variant) => {
      for (const [type, config] of getEntries(operatorConfig[variant])) {
        if (config.value === value) {
          return type;
        }
      }

      return undefined;
    })
    .otherwise(() => {
      throw `Invalid schema variant: ${variant}`;
    });
};

const isArraySelector = (value: string): value is XmlSelector.Array => {
  return parseInt(value).toString() === value;
};

const createPathSegment = (
  referenceCount: number,
  type: Extractor,
  value: string,
  operation: string | null,
  next: string | null
): Reference => ({
  type,
  value,
  operation,
  prev: referenceCount === 0 ? null : (referenceCount - 1).toString(),
  next,
});

const parseToXmlPath = (
  elements: string[],
  index: number,
  referenceCount: number,
  parsedPath: Path,
  variant: SchemaVariant
) => {
  const value = elements[index];
  const nextElement = elements[index + 1];
  const nextReference = (referenceCount + 1).toString();

  match([value, nextElement])
    .with(
      [
        P._,
        P.when((op) => verifyIsOperator(op, variant) === XmlOperator.Attribute),
      ],
      () => {
        parsedPath[referenceCount] = createPathSegment(
          referenceCount,
          XmlOperator.Attribute,
          value,
          `${nextElement}.${elements[index + 2]}`,
          nextReference
        );

        index += 2; // skip next two elements
      }
    )
    .with(
      [P._, P.when((op) => verifyIsOperator(op, variant) !== undefined)],
      () => {
        parsedPath[referenceCount] = createPathSegment(
          referenceCount,
          verifyIsOperator(nextElement, variant)!,
          value,
          nextElement,
          nextReference
        );

        index++; // skip next element
      }
    )
    .with([P._, P.when((op) => isArraySelector(op!))], () => {
      parsedPath[referenceCount] = createPathSegment(
        referenceCount,
        XmlSelector.Array,
        value,
        nextElement,
        nextReference
      );

      index++; // skip next element
    })
    .otherwise(() => {
      parsedPath[referenceCount] = createPathSegment(
        referenceCount,
        XmlSelector.Base,
        value,
        null,
        nextReference
      );
    });

  return index;
};

const parseToJsonPath = (
  elements: string[],
  index: number,
  referenceCount: number,
  parsedPath: Path
) => {
  const value = elements[index];
  const nextElement = elements[index + 1];
  const nextReference = (referenceCount + 1).toString();

  match([value, nextElement])
    .with([P._, P.when((op) => isArraySelector(op!))], () => {
      parsedPath[referenceCount] = createPathSegment(
        referenceCount,
        XmlSelector.Array,
        value,
        nextElement,
        nextReference
      );

      index++; // skip next element
    })
    .otherwise(() => {
      parsedPath[referenceCount] = createPathSegment(
        referenceCount,
        XmlSelector.Base,
        value,
        null,
        nextReference
      );
    });

  return index;
};

export const parseStringToPath = (
  stringPath: string,
  variant: SchemaVariant
) => {
  const elements = stringPath.split('.');
  const parsedPath: Path = {};
  let referenceCount = 0;

  const pathKey = elements.shift();
  if (pathKey !== '$inputdocument') {
    throw `Invalid path: The path must start with "$inputdocument", instead got: ${pathKey}`;
  }

  for (let index = 0; index < elements.length; index++) {
    match(variant)
      .with(SchemaVariant.Xml, () => {
        index = parseToXmlPath(
          elements,
          index,
          referenceCount,
          parsedPath,
          variant
        );
      })
      .with(SchemaVariant.Json, () => {
        index = parseToJsonPath(elements, index, referenceCount, parsedPath);
      })
      .otherwise(() => {
        throw `Invalid schema variant: ${variant}`;
      });

    referenceCount++;
  }

  // Set the 'next' property of the last path segment to null
  if (parsedPath[referenceCount - 1]) {
    parsedPath[referenceCount - 1].next = null;
  }

  return parsedPath;
};
