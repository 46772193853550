import { OptionEntry, OptionType } from "@morph-mapper/types";
import { useState } from "react";

interface CreateParameterProps {
  value: Record<string, OptionEntry<any>>;
  handleChange: (value: Record<string, OptionEntry<any>>) => void;
}

export const useCreateParameter = ({
  value,
  handleChange,
}: CreateParameterProps) => {
  const [edit, setEdit] = useState(false);
  const [name, setName] = useState("");

  const handleNew = () => {
    setEdit(true);
  };
  const handleSetName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.currentTarget.value);
  };
  const handleCreate = () => {
    if (name.length <= 0) return;
    setEdit(false);
    setName("");
    handleChange({
      ...value,
      [name]: { type: OptionType.Dependency, value: "" },
    });
  };
  const handleCancel = () => {
    setEdit(false);
    setName("");
  };

  return {
    edit,
    name,
    handleNew,
    handleSetName,
    handleCreate,
    handleCancel,
  };
};
