import { Button, Group } from "@mantine/core";

interface ConfirmCellProps {
  handleAccept: () => void;
  handleDeny: () => void;
}

export const ConfirmCell = ({ handleAccept, handleDeny }: ConfirmCellProps) => {
  return (
    <Group>
      <Button onClick={handleAccept} color={"red"} compact>
        Confirm
      </Button>
      <Button onClick={handleDeny} variant="default" compact>
        Cancel
      </Button>
    </Group>
  );
};
