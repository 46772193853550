import { SchemaType } from '../../types';
import { SchemaVariant } from '@morph-mapper/types';
import {
  easyeomEmailInvoiceSchema,
  easyeomPDFInvoiceSchema,
  easyeomTextInvoiceSchema,
  easyeomXLSInvoiceSchema,
} from './invoice';

export const types = {
  [SchemaType.Invoice]: {
    [SchemaVariant.Pdf]: easyeomPDFInvoiceSchema,
    [SchemaVariant.Table]: easyeomXLSInvoiceSchema,
    [SchemaVariant.Email]: easyeomEmailInvoiceSchema,
    [SchemaVariant.Text]: easyeomTextInvoiceSchema,
  },
};
