import _ from 'lodash';
import { isTreeMap, TreeMap, TreeNode } from '@/types';
import { EntryType, ReservedNodeType } from '@morph-mapper/types';
import { getEntries } from '@morph-mapper/utils';
import { match, P } from 'ts-pattern';
import { reservedNames } from '@morph-mapper/schemas';

type Reference = {
  anchor: string;
  path: string[];
};

const findValuesWithPattern = (obj: any, pattern: RegExp) => {
  const results: string[] = [];

  const search = (obj: any, pattern: RegExp) => {
    _.forOwn(obj, (value) => {
      if (_.isObject(value)) {
        search(value, pattern);
      } else if (_.isString(value) && pattern.test(value)) {
        results.push(value);
      }
    });
  };

  search(obj, pattern);

  return results;
};

const searchSiblings = (parent: TreeMap, variable: string) => {
  for (const [id, name] of getEntries(parent.children.forwardMap)) {
    if (name === variable) {
      return id;
    }
  }
};

const parseValueToReference = (value: string): Reference => {
  const [anchor, ...path] = value.split('.');

  if (anchor === '' || path.some((el) => el === ''))
    throw 'The path associated with the reference is not parseable.';

  return {
    anchor,
    path,
  };
};

// Limitations: The final $data declaration must be a map type.
// TODO: refactor to support an interface instead of exposing the data structure directly.
// TODO: support composite data references
export const createTemplateReferencesFinder = (
  entries: Record<string, TreeNode>
) => {
  const findNearestIterator = (entryId: string): string => {
    const entry = entries[entryId];
    if (entry.parentId === undefined) {
      throw `Could not find iterator declaration for entry ${entry.key}`;
    }

    const parent = entries[entry.parentId];
    if (!isTreeMap(parent)) {
      throw 'Parent is not a map';
    }

    for (const [id, name] of getEntries(parent.children.forwardMap)) {
      if (name === ReservedNodeType.Iterator) {
        return id;
      }
    }

    return findNearestIterator(entry.parentId);
  };

  /**
   * We recursively search for $declareVariables available in the parent tree.
   * If we find a $declareVariable, we search the children for the variable declaration.
   *
   * @param entryId current parent entry id
   * @param variable target variable to find
   * @returnn the entry id of the variable declaration
   * @throws if the variable declaration is not found in the tree
   */
  const findVariableInDeclare = (
    entryId: string,
    reference: Reference
  ): string => {
    const { anchor } = reference;
    let declarationId = undefined;

    const entry = entries[entryId];
    if (entry.parentId === undefined) {
      throw `Could not find declaration of ${anchor} in a $declareVariable`;
    }

    const parent = entries[entry.parentId];
    if (!isTreeMap(parent)) {
      throw 'Parent should be of a map type.';
    }

    if (parent.key === ReservedNodeType.DeclareVariable) {
      declarationId = searchSiblings(parent, anchor);
    }

    const childId =
      parent.children.reverseMap[ReservedNodeType.DeclareVariable];
    if (childId !== undefined) {
      const entry = entries[childId];
      if (!isTreeMap(entry)) {
        throw 'DeclareVariable is not a map.';
      }
      declarationId = searchSiblings(entry, anchor);
    }

    return declarationId ?? findVariableInDeclare(entry.parentId, reference);
  };

  /**
   * We recursively search for $data available in the parent tree.
   *
   * @param entryId current parent entry id
   * @returns the entry id of the nearest $data
   * @throws if the $data declaration is not found in the tree
   */
  const findNearestData = (entryId: string): string => {
    const entry = entries[entryId];
    if (entry.parentId === undefined) {
      throw `Could not find declaration of $data`;
    }

    const parent = entries[entry.parentId];
    if (!isTreeMap(parent)) {
      throw 'Parent should be of a map type.';
    }

    for (const [id, name] of getEntries(parent.children.forwardMap)) {
      if (['data', '$data'].includes(name) && id !== entryId) {
        return id;
      }
    }

    return findNearestData(entry.parentId);
  };

  /**
   *
   *
   * @param entryId
   * @param variable
   * @returns
   */
  const findDeclarationInData = (entryId: string, variable: string): string => {
    const data = entries[entryId];
    if (!isTreeMap(data)) {
      throw 'Expected $data to be a map type.';
    }

    for (const [id, name] of getEntries(data.children.forwardMap)) {
      if (name === variable) {
        return id;
      }
    }

    for (const [id, name] of getEntries(data.children.forwardMap)) {
      if (['data', '$data'].includes(name)) {
        return findDeclarationInData(id, variable);
      }
    }

    throw `Could not find declaration in data for ${variable}`;
  };

  const findVariableInData = (entryId: string, variable: string) => {
    let dataId = findNearestData(entryId);
    if (dataId === undefined) {
      throw `Could not find data declaration for ${variable}`;
    }
    // Determine the variable we are looking for.
    const variableElements = variable.split('.');
    if (variableElements.length === 1) {
      return dataId;
    }

    // If the found $data is not a map we need to resolve the $data definition recursively until we find the $data which contains declarations.
    if (entries[dataId].type !== EntryType.Map) {
      const declarationIds: string[] = [];
      resolveReferences(dataId, (declarationId) => {
        declarationIds.push(declarationId);
      });

      dataId = declarationIds[0];
    }

    const parsedVariable = variableElements.slice(1).find((v) => isNaN(+v));
    if (parsedVariable === undefined) {
      throw `Incorrect data reference, received ${variable}`;
    }

    return findDeclarationInData(dataId, parsedVariable);
  };

  /**
   * Finds the declaration ID for a given variable within the context of a specific entry.
   *
   * @param {string} entryId - The ID of the entry in which the variable is being searched.
   * @param {string} variable - The variable whose declaration ID needs to be resolved.
   * @returns {string | undefined} - The ID of the resolved declaration, or `undefined` if no valid declaration is found.
   */
  const findVariableDeclaration = (
    entryId: string,
    variable: string
  ): string | undefined => {
    const entry = entries[entryId];
    if (entry.type === EntryType.Map) {
      throw 'Should not be map type.';
    }
    if (reservedNames.has(variable)) {
      return;
    }
    const reference = parseValueToReference(variable);
    const { anchor } = reference;
    if (anchor === 'inputdocument') {
      return;
    }

    const declarationId = match(variable)
      .with(P.string.regex(/^data/), () =>
        findVariableInData(entryId, variable)
      )
      .otherwise(() => findVariableInDeclare(entryId, reference));

    if (declarationId === undefined) {
      throw `Could not find declaration for variable ${variable} at ${entry.key}`;
    }

    return declarationId;
  };

  /**
   * Resolves references within an entry and registers dependencies using the provided `handleDeclaration` function.
   *
   * @param {string} entryId - The ID of the entry whose references need to be resolved.
   * @param {(entryId: string) => void} handleDeclaration - A callback function to handle a resolved dependency.
   * This function will be called with the ID of the resolved reference.
   *
   * @remarks
   * - Entries of type `Map` are skipped, as they cannot contain direct references.
   * - String values are checked for special patterns (`$` and `#>`) and processed accordingly.
   * - Object and array values are traversed recursively to process nested object structures.
   */
  const resolveReferences = (
    entryId: string,
    handleDeclaration: (entryId: string) => void
  ) => {
    const entry = entries[entryId];

    // A map can never contain a direct reference in its definition.
    if (entry.type === EntryType.Map) {
      return;
    }

    const processValue = (value: any, parentId: string) => {
      // If the value is a string, check for patterns.
      if (typeof value === 'string') {
        match(value)
          .with(P.string.regex(/^\$/), (val) => {
            const variableId = findVariableDeclaration(parentId, val.slice(1));
            if (variableId !== undefined) {
              handleDeclaration(variableId);
            } 
          })
          .with(P.string.regex(/^#>/), () => {
            const iteratorId = findNearestIterator(parentId);
            handleDeclaration(iteratorId);
          })
      }

      // If the value is an object or array, recursively process its keys/values.
      else if (typeof value === 'object' && value !== null) {
        Object.values(value).forEach((nestedValue) =>
          processValue(nestedValue, parentId)
        );
      }
    };

    // Process the entry's value.
    if (typeof entry.value === 'string') {
      processValue(entry.value, entryId);
    } else if (typeof entry.value === 'object' && entry.value !== null) {
      processValue(entry.value, entryId);
    }
  };

  return {
    findNearestIterator,
    findVariableDeclaration,
    resolveReferences,
  };
};
