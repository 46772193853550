import { notifications } from "@mantine/notifications";
import { nanoid } from "nanoid";

export const useNotify = () => {
  const close = (id: string) => {
    notifications.hide(id);
  };

  const success = (message: string) => {
    const id = nanoid();

    notifications.show({
      id,
      title: "Success",
      message,
      color: "green",
    });

    return id;
  };

  const info = (message: string) => {
    const id = nanoid();

    notifications.show({
      id,
      title: "Info",
      message,
      color: "blue",
    });

    return id;
  };

  const loading = (message: string) => {
    const id = nanoid();

    notifications.show({
      id,
      title: "Loading",
      withCloseButton: false,
      autoClose: false,
      message,
      color: "blue",
      loading: true,
    });

    return id;
  };

  const warn = (message: string) => {
    const id = nanoid();

    notifications.show({
      id,
      title: "Warning",
      message,
      color: "orange",
    });

    return id;
  };

  const error = (message: string) => {
    const id = nanoid();

    notifications.show({
      id,
      title: "Error",
      message,
      color: "red",
    });

    return id;
  };

  return { close, success, info, loading, warn, error };
};
