import { BaseFooter } from '@morph-mapper/ui';
import { useStore } from '../../context';
import { PathMode, SelectionMode } from '../../types';
import { match } from 'ts-pattern';
import { RelativePathMenuBar } from '../relative-path-menu-bar';
import { TextSelectionView } from '../text-selection-view';
import { PathSelectionView } from '../path-selection-view';

export const Footer = () => {
  const [selectionMode, referenceIds] = useStore(({ file: f, path: p }) => [
    f.getSelectionMode(),
    p.getReferenceIds(PathMode.Absolute),
  ]);

  if (referenceIds.length === 0) {
    return null;
  }

  return (
    <>
      <RelativePathMenuBar />
      <BaseFooter
        sx={(theme) => ({
          border: `1px solid ${theme.colors.gray[2]}`,
          zIndex: 0,
        })}
      >
        {match(selectionMode)
          .with(SelectionMode.Reference, () => <PathSelectionView />)
          .with(SelectionMode.Text, () => <TextSelectionView />)
          .exhaustive()}
      </BaseFooter>
    </>
  );
};
