import { Checkbox as MantineCheckbox } from "@mantine/core";
import type { InputProps } from "../../types";

export const Checkbox = ({ value, handleChange }: InputProps<boolean>) => {
  const handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(event.target.checked);
  };

  return <MantineCheckbox checked={value} onChange={handleCheckbox} />;
};
