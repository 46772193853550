import { GraphOperation } from "@morph-mapper/types";
import { useNavigate } from "react-router-dom";

export const useListDependency = (nodeId: string) => {
  const navigate = useNavigate();

  const handleCreateDependency = (property: string) => {
    navigate("nodes/new", {
      state: {
        operation: GraphOperation.Dependency,
        targetEdge: {
          target: nodeId,
          targetHandle: property,
          data: { property, type: "dependency" },
        },
      },
    });
  };

  return { handleCreateDependency };
};
