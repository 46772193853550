import { SchemaVariant } from '@morph-mapper/types';
import { getEntries } from '@morph-mapper/utils';
import { selectorConfig } from '../../config';
import { useStore } from '../../context';
import { JsonExtractor, JsonSelector } from '../../types';
import { match } from 'ts-pattern';

export const useJsonModal = () => {
  const id = useStore(({ path: p }) => p.getActiveReference());
  const [setExtractorById, setOperationById] = useStore(({ path: p, ui }) => [
    p.setExtractor,
    p.setOperation,
    ui.isConfigRefModalOpen(),
    ui.setConfigRefModal,
  ]);

  const setExtractor = (value: JsonExtractor | null) => {
    if (value === null) return;

    setExtractorById(id, value);

    match(value)
      .with(JsonSelector.Array, () => setOperation(`0`))
      .with(JsonSelector.Base, () => setOperation(null))
      .otherwise(() => null);
  };

  const setOperation = (value: string | number | null) => {
    if (value === '') return;

    setOperationById(id, value);
  };

  /**
   * Returns the available extractors for the current reference,
   * based on predefined rules.
   */
  const getExtractors = () => {
    const selectors = selectorConfig[SchemaVariant.Json];
    const extractors: {
      label: string;
      value: JsonSelector;
      group: string;
    }[] = [];

    getEntries(selectors).forEach(([type, { displayName }]) => {
      extractors.push({
        value: type,
        label: displayName,
        group: 'Selectors',
      });
    });

    return extractors;
  };

  return {
    setExtractor,
    setOperation,
    getExtractors,
  };
};
