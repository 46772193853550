import { fileToText } from '@/utils';
import api from './api';

export const fetchResultTextEntry = async (
  template: any,
  text: string
): Promise<any[]> => {
  const endpoint = '';
  const body = {
    str: JSON.stringify({
      template,
      text,
    }),
  };

  const { data } = await api.post(endpoint, body);
  const { output } = data;

  return output;
};

export const fetchResultXlsEntry = async (
  template: any,
  xls: string
): Promise<any[]> => {
  const endpoint = '/xls';
  const body = {
    str: JSON.stringify({ template, xls }),
    options: JSON.stringify({}),
  };

  const { data } = await api.post(endpoint, body);
  const { output } = data;

  return output;
};

export const fetchResultXmlEntry = async (
  template: any,
  xml: string
): Promise<any[]> => {
  const endpoint = '/xmlconverter';
  const body = {
    str: JSON.stringify({ template, xml }),
    options: JSON.stringify({}),
  };

  const { data } = await api.post(endpoint, body);
  const { output } = data;

  return output;
};

export const fetchResultJsonEntry = async (
  template: any,
  obj: string
): Promise<any[]> => {
  const endpoint = '/morphjson_inputdocument';
  const body = {
    str: JSON.stringify({
      template,
      data: JSON.parse(obj),
    }),
  };

  const { data } = await api.post(endpoint, body);
  const { output } = data;

  return output;
};

export const fetchResultText = async (template: any, text: string) => {
  const endpoint = '';
  const body = {
    str: JSON.stringify({
      template,
      text,
    }),
  };

  const { data } = await api.post(endpoint, body);
  const { output } = data;

  return output;
};

export const fetchResultXls = async (template: any, xls: string) => {
  const endpoint = '/xls';
  const body = {
    str: JSON.stringify({ template, xls }),
    options: JSON.stringify({}),
  };

  const { data } = await api.post(endpoint, body);
  const { output } = data;

  return output;
};

export const fetchResultXml = async (template: any, xml: string) => {
  const endpoint = '/xmlconverter';
  const body = {
    str: JSON.stringify({ template, xml }),
    options: JSON.stringify({}),
  };

  const { data } = await api.post(endpoint, body);
  const { output } = data;

  return output;
};

export const fetchResultJson = async (template: any, obj: string) => {
  const endpoint = '/morphjson_inputdocument';
  const body = {
    str: JSON.stringify({
      template,
      data: JSON.parse(obj),
    }),
  };

  const { data } = await api.post(endpoint, body);
  const { output } = data;

  return output;
};

export const fetchParsedPdf = async (file: File): Promise<string> => {
  const endpoint = '/convertpdf';
  const body = {
    str: JSON.stringify({
      filename: 'morphmapper.pdf',
      file: await fileToText(file),
      options: { scannedOptions: {} },
    }),
  };

  const { data } = await api.post(endpoint, body);
  const { output } = data;

  return output;
};

export const fetchParsedXls = async (file: File) => {
  return await fileToText(file);
};

export const fetchParsedXml = async (file: File) => {
  return await file.text();
};

export const fetchParsedEml = async (
  file: File
): Promise<{ text: string; html?: string }> => {
  const endpoint = '/reademl';
  const body = { eml: await fileToText(file) };

  const { data } = await api.post(endpoint, body);
  return { text: data.output, html: data.html };
};

export const fetchParsedTxt = async (file: File): Promise<string> => {
  return await file.text();
};

export interface ParsedPositionResult {
  entry: string;
  positionData: any;
}

export const parseContentByTemplatePosition = async (
  template: any,
  text: string
): Promise<ParsedPositionResult> => {
  if (!template || !text) return { entry: '', positionData: '' };
  const endpoint = '';
  const body = {
    str: JSON.stringify({
      template,
      text,
    }),
  };

  const { data } = await api.post(endpoint, body);
  const { output } = data;

  if (Object.keys(output[0]).length === 0)
    return { entry: '', positionData: '' };

  return {
    entry: output[0].entry,
    positionData: output[0].positionData,
  };
};
