import { useEffect, useState } from 'react';
import { useStore } from '../../context';
import { read, utils, WorkSheet } from 'xlsx';

export const useTableFile = () => {
  /* Global State */
  const file = useStore(({ file: f }) => f.getFile());
  /* Local State */
  const [rows, setRows] = useState<any[]>([]);
  const [columns, setColumns] = useState<{ key: string; name: string }[]>([]);

  /**
   * Effects
   **/
  useEffect(() => {
    if (!file) return;
    (async () => {
      const wb = read(await file.arrayBuffer());
      const ws = wb.Sheets[wb.SheetNames[0]]; // get the first worksheet
      const { columns, rows } = ws_to_rdg(ws);

      setColumns(columns);
      setRows(rows);
    })();
  }, [file]);

  /**
   * Converts an Excel worksheet into a format suitable for rendering in a table.
   * It extracts columns and rows, ensuring proper handling of empty cells
   * and applying custom formatting for date/time or numeric values when needed.
   *
   * @param {WorkSheet} ws - The Excel worksheet to process.
   * @returns {Object} An object containing:
   * - `rows`: An array of objects representing the table (file) rows.
   * - `columns`: An array of objects representing the table (file) columns.
   */
  const ws_to_rdg = (ws: WorkSheet) => {
    const range = utils.decode_range(ws['!ref'] || 'A1');

    const columnCount = range.e.c - range.s.c + 1;
    const rowCount = range.e.r - range.s.r + 1;

    const columns = Array.from({ length: columnCount }, (_, i) => ({
      key: utils.encode_col(i),
      name: utils.encode_col(i),
    }));

    const rows = Array.from({ length: rowCount }, (_, rowIndex) => {
      const rowData: Record<string, any> = {};
      for (let colIndex = 0; colIndex < columnCount; colIndex++) {
        const cellAddress = utils.encode_cell({
          r: rowIndex + range.s.r,
          c: colIndex + range.s.c,
        });
        const cell = ws[cellAddress];
        let value = cell?.v || '';

        if (typeof value === 'number') {
          const columnName = utils.encode_col(colIndex);
          if (isDateTimeColumn(columnName)) {
            value = dateConverter(value);
          } else if (isNumberColumn(columnName)) {
            // do nothing
          } else if (
            value > 60 &&
            Number.isInteger(value) &&
            dateConverter(value) !== String(value)
          ) {
            value = dateConverter(value);
          } else if (value > 0 && value < 1) {
            value = dateConverter(value);
          }
        }

        rowData[utils.encode_col(colIndex)] = value;
      }
      return rowData;
    });

    return { rows, columns };
  };

  const dateConverter = (excelDate: number): string => {
    const excelEpoch = new Date(Date.UTC(1899, 11, 30));
    const msPerDay = 86400000;
    const date = new Date(excelEpoch.getTime() + excelDate * msPerDay);

    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');

    if (excelDate >= 1 && year >= 2010 && year <= 2030) {
      return `${year}-${month}-${day}`;
    } else if (excelDate < 1) {
      return `${hours}:${minutes}`;
    }

    return String(excelDate); // Return as is if not a date/time
  };

  // hard coded column name indicators: excel stores dates as numbers internally, need to differentiate between date and number columns
  const isDateTimeColumn = (columnName: string): boolean => {
    const dateTimeIndicators = [
      'begin',
      'time',
      'end',
      'delay',
      'date',
      'uur',
      'tijd',
      'datum',
    ];
    const lowerColumnName = columnName.toLowerCase();
    const isDateTime = dateTimeIndicators.some((indicator) =>
      lowerColumnName.includes(indicator)
    );
    return isDateTime;
  };

  const isNumberColumn = (columnName: string): boolean => {
    const numberIndicators = [
      'nr',
      'number',
      'amount',
      'quantity',
      'dock',
      'id',
    ];
    const lowerColumnName = columnName.toLowerCase();
    const isNumber = numberIndicators.some((indicator) =>
      lowerColumnName.includes(indicator)
    );
    return isNumber;
  };

  return { rows, columns };
};
