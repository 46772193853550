import { useRef } from 'react';
import { NodeContext } from './context';
import { NodeStore, createNodeStore } from '../../store';
import { useStore } from '@/store';
import { useNavigate, useParams } from 'react-router-dom';
import { useLogicBlocks } from '@morph-mapper/node-logic';

interface NodeProviderProps extends React.PropsWithChildren {}

export const NodeProvider = ({ children }: NodeProviderProps) => {
  const { graphId, nodeId } = useParams();
  if (!graphId) throw new Error('No active graph');
  if (!nodeId) throw new Error('No active node');

  const variant = useStore(({ config: c }) => c.getVariant());
  const { logicBlocks } = useLogicBlocks(variant);
  const navigate = useNavigate();

  const globalStore = useStore();
  const storeRef = useRef<NodeStore>();
  if (!storeRef.current) {
    storeRef.current = createNodeStore(globalStore, {
      graphId,
      nodeId,
      logicBlocks,
      navigate,
    });
  }

  return (
    <NodeContext.Provider value={storeRef.current}>
      {children}
    </NodeContext.Provider>
  );
};
