import { useMemo, useRef } from 'react';
import { GraphEntryContext } from './context';
import { useStore } from '@/store';
import { GraphEntryStore, createGraphEntryStore } from '../../store';
import { useNavigate } from 'react-router-dom';

interface GraphEntryProviderProps extends React.PropsWithChildren {
  id: string;
}

export const GraphEntryProvider = ({
  children,
  id,
}: GraphEntryProviderProps) => {
  const navigate = useNavigate();

  const globalStore = useStore();
  const storeRef = useRef<GraphEntryStore>();

  storeRef.current = useMemo(() => {
    return createGraphEntryStore(globalStore, {
      id,
      navigate,
    });
  }, [globalStore]);

  return (
    <GraphEntryContext.Provider value={storeRef.current}>
      {children}
    </GraphEntryContext.Provider>
  );
};
