import { FooterProps as MantineFooterProps } from "@mantine/core";
import { StyledFooter } from "./styles";

interface BaseFooterProps extends Omit<MantineFooterProps, "height"> {
  children: React.ReactNode[] | React.ReactNode;
}

export const BaseFooter = ({ children, sx, ...props }: BaseFooterProps) => {
  return (
    <StyledFooter
      {...props}
      sx={sx ? sx : { justifyContent: "space-between" }}
      height={{ base: 60, xs: 60, sm: 60, md: 60 }}
    >
      {children}
    </StyledFooter>
  );
};
