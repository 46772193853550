import { useUpdateTemplateSave } from '@/services';
import { useStore } from '@/store';
import { useEffect } from 'react';
import { useNotify } from '@/hooks';
import { useNavigate } from 'react-router-dom';
import { useTimeout } from '@mantine/hooks';

export const useSaveState = () => {
  /* Global state */
  const [
    operatorId,
    id,
    name,
    path,
    domain,
    type,
    variant,
    preconfiguration,
    discardFields,
    isSavingTemplate,
    setIsSavingTemplate,
    userId,
    entries,
    graphs,
  ] = useStore(({ config: c, entries: e, graphs: g }) => [
    c.getOperatorId(),
    c.getId(),
    c.getName(),
    c.getPath(),
    c.getDomain(),
    c.getType(),
    c.getVariant(),
    c.getPreconfiguration(),
    c.getDiscardFields(),
    c.isSavingTemplate,
    c.setIsSavingTemplate,
    c.userId,
    e.entries,
    g.graphs,
  ]);
  /* Queries */
  const {
    mutate: updateTemplateSave,
    isSuccess,
    isError,
  } = useUpdateTemplateSave();

  const { success, warn, error } = useNotify();
  const navigate = useNavigate();

  const { start, clear } = useTimeout(() => {
    navigate(`/?userId=${userId}`);
  }, 4000);

  useEffect(() => {
    if (isSuccess) {
      success('Template has been saved.');
      if (isSavingTemplate) {
        start();
        success('Redirecting...')
        setIsSavingTemplate(false);
      }
    }
    if (isError) {
      error('Failed to save template.');
    }
  }, [isSuccess, isError]);

  /**
   * Handlers
   */
  const save = () => {
    updateTemplateSave({
      refId: id,
      operatorId,
      savePath: path,
      name,
      domain,
      type,
      variant,
      graphs,
      entries,
      config: {
        updateDiscardIds: discardFields,
        preconfiguration,
      },
    });
  };

  return { save, isSuccess, isError };
};
