import { useEntryParser } from '@/services';
import { useEffect, useState } from 'react';
import { useEntryContext, useNodeContext } from '../../context';

export const useFooter = () => {
  /* Context State */
  const entryId = useEntryContext((s) => s.getId());
  const nodeId = useNodeContext((s) => s.getId());
  /* Local State */
  const [output, setOutput] = useState<string>('');
  /* Queries */
  const { data } = useEntryParser(entryId, nodeId);

  // Flatten data for display
  const flattenedData =
    Array.isArray(data) && data.length === 1 && Array.isArray(data[0])
      ? data[0]
      : data;

  useEffect(() => {
    if (flattenedData === undefined) return;

    setOutput(JSON.stringify(flattenedData).replace(/"/g, ''));
  }, [flattenedData, data]);

  return { output };
};
