import styled from "@emotion/styled";

export const SideBarWrapper = styled.div(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: theme.colors.gray[1],
}));

export const SideBarContent = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 60px);
  overflow-y: auto;
  padding: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  gap: 1rem;
  padding-bottom: 3rem;
`;

export const SectionWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 1rem;
  padding-right: 1rem;
  padding-left: 1rem;
  row-gap: 1rem;
`;
