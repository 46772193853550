import { useStore } from '@/store';
import { useEffect } from 'react';
import { useDirtyEntry } from '@/services';
import { getEntries } from '@morph-mapper/utils';
import { DirtyType } from '@/types';
import { useRenderTemplate } from './use-render-template';

export const useGlobalEntryListener = () => {
  const [
    dirtyTemplates,
    dirtyUnderlyings,
    clearDirty,
    setComputed,
    setTemplateValue,
  ] = useStore(({ entries: e }) => [
    e.getAllDirty(DirtyType.Template),
    e.getAllDirty(DirtyType.Underlying),
    e.clearDirty,
    e.setComputed,
    e.setTemplateValue,
  ]);
  const { data } = useDirtyEntry(dirtyTemplates);
  const { renderItemEntry } = useRenderTemplate();

  useEffect(() => {
    if (dirtyUnderlyings.length === 0) return;
    dirtyUnderlyings.forEach((entryId) => {
      setTemplateValue(entryId, renderItemEntry(entryId));
    });

    clearDirty(DirtyType.Underlying);
  }, [dirtyUnderlyings]);

  useEffect(() => {
    if (dirtyTemplates.length === 0) return;
    if (data === undefined) return;

    getEntries(data).forEach(([key, result]) => {
      setComputed(key, result);
    });

    clearDirty(DirtyType.Template);
  }, [data, dirtyTemplates]);
};
