import { LOGIC_CATEGORIES, LOGIC_BLOCKS_RULESET } from '../logic';
import { getKeys, getValues } from '@morph-mapper/utils';
import { BlockRule, LogicBlockConfig, LogicBlockRecord } from '../types';
import { type SchemaVariant } from '@morph-mapper/types';

/**
 * This hook provides an external interface to the logic blocks configuration.
 * It is used to dyanmically fetch the implementation of a logic block based on the schema variant.
 */
export const useLogicBlocks = (variant: SchemaVariant) => {
  /**
   * @param categories Takes a record of category names and their display names
   * @param blocks Takes a record of logic blocks
   * @returns A record of category names and their respective logic blocks
   */
  const mapBlocksByCategory = <T extends Record<string, string>>(
    categories: T,
    blocks: LogicBlockRecord<any, Set<BlockRule>>
  ) => {
    const mappedBlocks = getKeys(categories).reduce((acc, key) => {
      return {
        ...acc,
        [key]: [],
      };
    }, {} as Record<keyof T, LogicBlockConfig<any, Set<BlockRule>, any>[]>);

    getValues(blocks).forEach((block) => {
      if (block.category === 'internal') return;
      const category = block.category as keyof T;

      mappedBlocks[category].push(block);
    });

    return mappedBlocks;
  };

  const logicBlocks = LOGIC_BLOCKS_RULESET[variant];
  const logicBlocksByCategory = mapBlocksByCategory(
    LOGIC_CATEGORIES,
    LOGIC_BLOCKS_RULESET[variant]
  );

  return { logicBlocks, logicBlocksByCategory };
};
