import { Getter, Setter } from '@dhmk/zustand-lens';
import { Node, Edge, LogicGraph } from '@/types';
import { Draft } from 'immer';
import { GraphsSlice, INode } from '.';

export interface IGraph {
  node: (nodeId: string) => INode;

  get: () => LogicGraph;
  getId: () => string;
  getNodeIds: () => string[];
  getNodes: () => Record<string, Node>;
  getEdges: () => Record<string, Edge>;
  setNodes: (nodes: Record<string, Node>) => void;
  setEdges: (edges: Record<string, Edge>) => void;
  delete: () => void;
}

export const createGraphAPI = (
  _set: Setter<Draft<GraphsSlice>>,
  get: Getter<Draft<GraphsSlice>>,
  id: string
): IGraph => {
  return {
    node: (nodeId: string) => {
      return get().node(id, nodeId);
    },

    get: () => {
      return get().get(id);
    },
    getId: () => {
      return get().getId(id);
    },
    getNodeIds: () => {
      return get().getNodeIds(id);
    },
    getNodes: () => {
      return get().getNodes(id);
    },
    getEdges: () => {
      return get().getEdges(id);
    },
    setNodes: (nodes) => {
      get().setNodes(id, nodes);
    },
    setEdges: (edges) => {
      get().setEdges(id, edges);
    },
    delete: () => {
      get().delete(id);
    },
  };
};
