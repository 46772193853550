import { Text } from "@mantine/core";
import { Dropzone } from "@mantine/dropzone";
import {
  DropzoneWrapper,
  FileText,
  FileUpload,
  StyledDropZone,
  StyledGroup,
  XError,
} from "./styles";
import { useFileDrop } from "./use-file-drop";

interface FileDropProps {
  fileExtenstions: string[];
  description: string;
  load: (file: File) => void;
}

export const FileDrop = ({
  fileExtenstions,
  description,
  load,
}: FileDropProps) => {
  const { handleFileAccept, handleFileReject } = useFileDrop(load);

  return (
    <DropzoneWrapper>
      <StyledDropZone
        maxFiles={1}
        multiple={false}
        onDrop={handleFileAccept}
        onReject={handleFileReject}
        accept={fileExtenstions}
        style={{ display: "flex", alignItems: "center" }}
      >
        <StyledGroup
          spacing="xs"
          style={{ width: "100%", justifyContent: "flex-start" }}
        >
          <Dropzone.Accept>
            <FileUpload style={{ display: "flex" }} size={50} />
          </Dropzone.Accept>
          <Dropzone.Reject>
            <XError style={{ display: "flex" }} size={50} />
          </Dropzone.Reject>
          <Dropzone.Idle>
            <FileText style={{ display: "flex" }} size={50} />
          </Dropzone.Idle>
          <div style={{ marginLeft: "1rem", flex: 1 }}>
            <Text size="lg" inline>
              Drag document here or click to select
            </Text>
            <Text size="md" color="dimmed" inline mt={7}>
              {description}
            </Text>
          </div>
        </StyledGroup>
      </StyledDropZone>
    </DropzoneWrapper>
  );
};
