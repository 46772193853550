import { Box, Menu, useMantineTheme } from '@mantine/core';
import { useNodeContext } from '../../context';
import { Options } from '@morph-mapper/ui';
import { TbSquareCheckFilled } from 'react-icons/tb';
import { OptionType } from '@morph-mapper/types';

interface OptionMenuProps {
  name: string;
}

export const OptionMenu = ({ name }: OptionMenuProps) => {
  const theme = useMantineTheme();
  const [optionType, setOptionType] = useNodeContext((s) => [
    s.getOptionType(name),
    s.setOptionType,
  ]);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Menu shadow="md" width={200}>
        <Menu.Target>
          <Options />
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Label>Configure variable</Menu.Label>
          <Menu.Item
            icon={
              optionType === OptionType.UserDefined && (
                <TbSquareCheckFilled color={theme.colors.blue[7]} size={15} />
              )
            }
            onClick={() => setOptionType(name, OptionType.UserDefined)}
          >
            Self defined
          </Menu.Item>
          <Menu.Item
            icon={
              optionType === OptionType.Dependency && (
                <TbSquareCheckFilled color={theme.colors.blue[7]} size={15} />
              )
            }
            onClick={() => setOptionType(name, OptionType.Dependency)}
          >
            Graph defined
          </Menu.Item>
          <Menu.Item
            icon={
              optionType === OptionType.Reference && (
                <TbSquareCheckFilled color={theme.colors.blue[7]} size={15} />
              )
            }
            onClick={() => setOptionType(name, OptionType.Reference)}
          >
            Variable reference
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </Box>
  );
};
