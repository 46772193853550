import { useSetupStore } from '../../store';
import { useNotify } from '@/hooks';
import { assertNotEmpty } from '@morph-mapper/utils';
import { useSchema } from '@morph-mapper/schemas';
import { useStore } from '@/store';
import { useSchemesbyOperatorId } from '@/services';
import { useEffect } from 'react';

export const useDomainSelect = () => {
  /* Context state */
  const [operatorid, step, setCurrentStep, domain, setDomain] = useSetupStore((s) => [
    s.operatorId,
    s.currentStep,
    s.setCurrentStep,
    s.domain,
    s.setDomain,
  ]);
  const [domainAccess, setDefinedCustomSchemes, definedCustomSchemes] = useStore(({  config: c }) => [c.domainAccess, c.setDefinedCustomSchemes, c.definedCustomSchemes]);

  /* Hooks */
  const { warn } = useNotify();
  const { data: schemes } = useSchemesbyOperatorId(operatorid?.toString());
  const { getSchemaDomains } = useSchema(domainAccess, definedCustomSchemes);

  useEffect(() => { 
    if (schemes === undefined) return;
    setDefinedCustomSchemes(schemes);
  } , [schemes]);


  /**
   * Handlers
   */
  // We auto select the domain if there is only one domain.
  const skipIfOneDomain = () => {
    const domains = getSchemaDomains();

    assertNotEmpty(domains, 'No domains available for the current operator.');

    if (domains.length > 1) return;

    setCurrentStep(step + 1);
    setDomain(domains[0]);
  };

  // Ensure domain is set when the user clicks the next button.
  const progressSetup = () => {
    skipIfOneDomain();

    if (domain === undefined) {
      warn('Please select a domain');
      return;
    }

    setCurrentStep(step + 1);
  };

  return { progressSetup };
};
