import { useState } from "react";
import { pdfjs } from "react-pdf";

export const usePdf = () => {
  /* Local State */
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  /**
   * Handlers
   **/
  const onDocumentLoadSuccess = (pdf: pdfjs.PDFDocumentProxy) => {
    setNumPages(pdf.numPages);
    setPageNumber(1);
  };

  const changePage = (offset: number) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };

  return {
    numPages,
    pageNumber,
    onDocumentLoadSuccess,
    previousPage,
    nextPage,
  };
};
