import { SchemaType } from '@morph-mapper/schemas';
import { type SchemaVariant } from '@morph-mapper/types';
import { StateCreator } from 'zustand';

export interface SetupSlice {
  name: string | undefined;
  variant: SchemaVariant | undefined;
  type: SchemaType | undefined;
  file: File | undefined;
  templateId: string | undefined;
  currentStep: number;
  domain: string | undefined;
  operatorId: number | undefined;
  userId: string | undefined;

  isRenameCategoryOpen: boolean;
  isCreateModalOpen: boolean;
  isEditModalOpen: boolean;
  isDuplicateModalOpen: boolean;
  modalId: string | undefined;
  categoryName: string | undefined;

  getName: () => string;
  getDomain: () => string;
  getType: () => SchemaType;
  getVariant: () => SchemaVariant;
  getOperatorId: () => number;
  getIUserId: () => string;

  setName: (name: string) => void;
  setVariant: (variant: SchemaVariant) => void;
  setType: (type: SchemaType) => void;
  setFile: (file: File) => void;
  setTemplateId: (templateId: string | undefined) => void;
  setCurrentStep: (currentStep: number) => void;
  setDomain: (domain: string) => void;
  setOperatorId: (operatorId: number) => void;
  setUserId: (userId: string) => void;

  setIsRenameCategoryOpen: (isOpen: boolean) => void;
  setIsCreateModalOpen: (isOpen: boolean) => void;
  setIsEditModalOpen: (isOpen: boolean) => void;
  setIsDuplicateModalOpen: (isOpen: boolean) => void;
  setModalId: (id: string | undefined) => void;
  setCategoryName: (name: string) => void;
}

export const createSetupSlice: StateCreator<
  SetupSlice,
  [['zustand/immer', never]],
  [],
  SetupSlice
> = (set, get) => ({
  name: undefined,
  variant: undefined,
  type: undefined,
  schemaId: undefined,
  file: undefined,
  templateId: undefined,
  currentStep: 0,
  domain: undefined,
  operatorId: undefined,
  userId: undefined,

  isRenameCategoryOpen: false,
  isCreateModalOpen: false,
  isEditModalOpen: false,
  isDuplicateModalOpen: false,
  modalId: undefined,
  categoryName: undefined,

  getName: () => {
    const name = get().name;
    if (!name) throw new Error('Name not set');

    return name;
  },
  getDomain: () => {
    const domain = get().domain;
    if (!domain) throw new Error('Domain not set');

    return domain;
  },
  getOperatorId: () => {
    const operatorId = get().operatorId;
    if (!operatorId) throw new Error('OperatorId not set');

    return operatorId;
  },
  getIUserId: () => {
    const userId = get().userId;
    if (!userId) throw new Error('UserId not set');

    return userId;
  },
  getType: () => {
    const type = get().type;
    if (!type) throw new Error('Type not set');

    return type;
  },
  getVariant: () => {
    const variant = get().variant;
    if (!variant) throw new Error('Variant not set');

    return variant;
  },

  setName: (name) => {
    set((s) => {
      s.name = name;
    });
  },
  setVariant: (variant) => {
    set((s) => {
      s.variant = variant;
    });
  },
  setType: (type) => {
    set((s) => {
      s.type = type;
    });
  },
  setFile: (file) => {
    set((s) => {
      s.file = file;
    });
  },

  setTemplateId: (templateId) => {
    set((s) => {
      s.templateId = templateId;
    });
  },
  setCurrentStep: (newStep) => {
    set((state) => {
      state.currentStep = newStep;
    });
  },
  setDomain: (selectedDomain) => {
    set((s) => {
      s.domain = selectedDomain;
    });
  },
  setOperatorId: (operatorId) => {
    set((s) => {
      s.operatorId = operatorId;
    });
  },
  setUserId: (userId) => {
    set((s) => {
      s.userId = userId;
    });
  },
  setIsRenameCategoryOpen: (isOpen) => {
    set((s) => {
      s.isRenameCategoryOpen = isOpen;
    });
  },

  setIsCreateModalOpen: (isOpen) => {
    set((s) => {
      s.isCreateModalOpen = isOpen;
    });
  },

  setIsEditModalOpen: (isOpen) => {
    set((s) => {
      s.isEditModalOpen = isOpen;
    });
  },

  setIsDuplicateModalOpen: (isOpen) => {
    set((s) => {
      s.isDuplicateModalOpen = isOpen;
    });
  },

  setModalId: (id) => {
    set((s) => {
      s.modalId = id;
    });
  },

  setCategoryName: (name) => {
    set((s) => {
      s.categoryName = name;
    });
  },
});
