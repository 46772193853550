import { useMantineTheme } from "@mantine/core";
import { LuEraser } from "react-icons/lu";
import { Action, ActionProps } from "../action";

export const Reset = ({ variant, ...props }: ActionProps) => {
  const { colors } = useMantineTheme();

  return (
    <Action
      Icon={LuEraser}
      color={variant === undefined ? "red" : undefined}
      variant={variant === undefined ? "outline" : variant}
      borderColor={
        variant === undefined ? `1px solid ${colors.red[3]}` : undefined
      }
      {...props}
    />
  );
};
