import { TreeNode } from '@/types';
import { useRef } from 'react';

export const useEntryOverride = () => {
  const overrides = useRef<Record<string, Partial<TreeNode>>>({});

  const setOverride = <T extends keyof TreeNode>(
    id: string,
    key: T,
    newValue: TreeNode[T]
  ) => {
    overrides.current = {
      ...overrides.current,
      [id]: {
        ...overrides.current[id],
        [key]: newValue,
      },
    };
  };

  const clearOverride = (id: string) => {
    delete overrides.current[id];
  };

  const override = (entry: TreeNode): TreeNode => {
    const id = entry.id; // Assuming each entry has an 'id'
    // FIXME: remove type assertion
    return { ...entry, ...overrides.current[id] } as TreeNode;
  };

  return {
    setOverride,
    clearOverride,
    override,
  };
};
