import { Button, Modal, NumberInput, Select, TextInput } from "@mantine/core";
import { useStore } from "../../context";
import { XmlOperator, XmlSelector } from "../../types";
import { match } from "ts-pattern";
import { Body, FormLayout, Footer } from "./styles";
import { ArraySelectorDisplay, OperatorDisplay } from "../../atoms";
import { useXmlModal } from "./use-xml-modal";

export const XmlModal = () => {
  const id = useStore(({ path: p }) => p.getActiveReference());
  const [{ type, value: refValue, operation }, isModalOpen, setModal] =
    useStore(({ path: p, ui }) => [
      p.getReference(id),
      ui.isConfigRefModalOpen(),
      ui.setConfigRefModal,
    ]);
  const {
    setExtractor,
    setOperation,
    handleSetAttribute,
    getExtractors,
    getAttributes,
  } = useXmlModal();

  if (refValue === null) return null;

  return (
    <Modal
      opened={isModalOpen}
      onClose={() => setModal(false)}
      title={"Edit reference"}
      centered
    >
      <Body>
        <FormLayout>
          {match(type)
            .with(XmlSelector.Array, () => (
              <ArraySelectorDisplay
                label="Current reference"
                value={refValue}
                count={operation || ""}
              />
            ))
            .with(XmlOperator.Array, XmlOperator.Value, () => (
              <OperatorDisplay
                label="Current reference"
                value={refValue}
                operation={operation?.toString() || ""}
              />
            ))
            .otherwise(() => {
              return (
                <TextInput
                  label="Current reference"
                  value={refValue}
                  disabled
                />
              );
            })}

          <Select
            label="Select type of reference"
            value={type}
            onChange={setExtractor}
            data={getExtractors()}
            withinPortal
          />

          {match(type)
            .with(XmlSelector.Base, () => null)
            .with(XmlSelector.Array, () => {
              let value = operation;

              if (value === null) {
                setOperation(0);
                return null;
              }

              if (typeof value === "string") {
                value = parseInt(value);
              }

              return (
                <NumberInput
                  label="Select array index"
                  defaultValue={0}
                  value={value}
                  onChange={setOperation}
                />
              );
            })
            .with(XmlOperator.Attribute, () => (
              <Select
                label="Select attribute"
                // TODO: refactor
                value={`${operation}`.split(".")[1]}
                onChange={handleSetAttribute}
                data={getAttributes()}
                withinPortal
              />
            ))
            .otherwise(() => null)}
        </FormLayout>
        <Footer>
          <Button onClick={() => setModal(false)}>Close</Button>
        </Footer>
      </Body>
    </Modal>
  );
};
