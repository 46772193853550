import { IMapEntry, StoreSlice } from '@/store';
import { EntryType } from '@morph-mapper/types';
import { createStore } from 'zustand';
import { immer } from 'zustand/middleware/immer';

export interface EntryProps {
  id: string;
}

export type InitEntryProps = EntryProps;

export interface EntryState extends EntryProps {
  getId: () => string;
}

export type EntryStore = ReturnType<typeof createEntryStore>;

export const createEntryStore = (
  global: StoreSlice,
  initProps: InitEntryProps
) => {
  return createStore<EntryState & IMapEntry>()(
    immer((set, get) => ({
      ...global.entries.entry(initProps.id, EntryType.Map),
      ...initProps,
    }))
  );
};
