import { SchemaVariant } from '@morph-mapper/types';
import { LOGIC_BLOCKS as defaultBlocks } from './default';
import { LOGIC_BLOCKS as tableBlocks } from './table';
import { LOGIC_BLOCKS as xmlBlocks } from './xml';
import { LOGIC_BLOCKS as emailBlocks } from './email';
import { LOGIC_BLOCKS as textBlocks } from './text';
import { LOGIC_BLOCKS as jsonBlocks } from './json';
import { STRUCTURE_BLOCKS as structureBlocks } from './structure';
import { wrapValidation, transformRulesToSet } from '../utils';

export * from './base/shared';

export const STRUCTURE_BLOCKS_RULESET = {
  [SchemaVariant.Pdf]: structureBlocks,
  [SchemaVariant.Table]: structureBlocks,
  [SchemaVariant.Xml]: structureBlocks,
  [SchemaVariant.Email]: structureBlocks,
  [SchemaVariant.Text]: structureBlocks,
  [SchemaVariant.Json]: structureBlocks,
};

export const LOGIC_BLOCKS_RULESET = {
  [SchemaVariant.Pdf]: wrapValidation(transformRulesToSet(defaultBlocks)),
  [SchemaVariant.Table]: wrapValidation(transformRulesToSet(tableBlocks)),
  [SchemaVariant.Xml]: wrapValidation(transformRulesToSet(xmlBlocks)),
  [SchemaVariant.Email]: wrapValidation(transformRulesToSet(emailBlocks)),
  [SchemaVariant.Text]: wrapValidation(transformRulesToSet(textBlocks)),
  [SchemaVariant.Json]: wrapValidation(transformRulesToSet(jsonBlocks)),
};
