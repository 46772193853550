import { useRef } from 'react';
import { SimpleEntryContext } from './context';
import { useStore } from '@/store';
import { SimpleEntryStore, createSimpleEntryStore } from '../../store';

interface SimpleEntryProviderProps extends React.PropsWithChildren {
  id: string;
}

export const SimpleEntryProvider = ({
  children,
  id,
}: SimpleEntryProviderProps) => {
  const globalStore = useStore();
  const storeRef = useRef<SimpleEntryStore>();

  if (!storeRef.current) {
    storeRef.current = createSimpleEntryStore(globalStore, {
      id,
    });
  }

  return (
    <SimpleEntryContext.Provider value={storeRef.current}>
      {children}
    </SimpleEntryContext.Provider>
  );
};
