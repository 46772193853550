import { type SchemaVariant } from '@morph-mapper/types';

/**
 * Selector definitions for different schema variants.
 *
 * The selector determines how the value of a node is extracted from the file,
 * selectors make use of how values are represented in the morph templating language.
 *
 * The implementation of the selector can be found in the config/selectors.ts file.
 */
export enum XmlSelector {
  Base = 'selector_base',
  Array = 'selector_array',
}

export enum TableSelector {
  Base = 'selector_base',
}

export enum TextSelector {
  Base = 'selector_base',
}

export enum JsonSelector {
  Base = 'selector_base',
  Array = 'selector_array',
}

export type Selector =
  | XmlSelector
  | TableSelector
  | TextSelector
  | JsonSelector;

/**
 *
 * Configuration types for selectors.
 *
 */
export type SelectorConfig = {
  displayName: string;
};

export type SelectorMap = {
  [SchemaVariant.Pdf]: never;
  [SchemaVariant.Table]: never;
  [SchemaVariant.Xml]: XmlSelector;
  [SchemaVariant.Text]: never;
  [SchemaVariant.Email]: never;
  [SchemaVariant.Json]: JsonSelector;
};

export type SelectorConfigRecord = {
  [K in keyof SelectorMap]: Record<SelectorMap[K], SelectorConfig>;
};
